import React from 'react';
import i18nextTranslate from "Lang/i18nextTranslate";
import { i18nextKeys } from "Lang/i18nextKeys";
import Text from '../Text';

const Error = ({
  error = null,
  dataQa = "error",
  className = "truncate"
}) => {
  return (
    <Text
      textStyle="h3"
      color="color-red"
      className={`mt-2 ${className} ${error ? 'visible' : 'invisible'}`}
      dataQa={dataQa}
    >
      {error || i18nextTranslate(i18nextKeys.errorMessageFillOutThisField)}
    </Text>
  );
};

export default Error;
