import React from "react";

const CopyIcon = ({
  size = "14",
  ...props
}) => (
  <svg
  width={size}
  height={size}
  viewBox="0 0 14 14"
  fill="currentColor"
  className="cursor-pointer color-white"
    {...props}
  >
    <path d="M10.5014 14.0011H3.33594C3.06761 14.0011 2.81028 13.8945 2.62054 13.7048C2.43081 13.5151 2.32422 13.2577 2.32422 12.9894V3.48145C2.32422 3.21312 2.43081 2.95579 2.62054 2.76605C2.81028 2.57632 3.06761 2.46973 3.33594 2.46973H10.5027C10.7711 2.46973 11.0284 2.57632 11.2181 2.76605C11.4079 2.95579 11.5145 3.21312 11.5145 3.48145V12.9894C11.5145 13.1224 11.4882 13.2541 11.4373 13.3769C11.3864 13.4997 11.3117 13.6113 11.2176 13.7053C11.1236 13.7993 11.0119 13.8737 10.889 13.9245C10.766 13.9753 10.6343 14.0013 10.5014 14.0011ZM3.69004 12.6339H10.1459V3.83691H3.69141L3.69004 12.6339Z" />
    <path d="M13.3164 12.25C13.1351 12.25 12.9612 12.178 12.833 12.0498C12.7048 11.9216 12.6328 11.7477 12.6328 11.5664V1.36719H5.82422C5.64292 1.36719 5.46904 1.29517 5.34084 1.16697C5.21265 1.03877 5.14063 0.864894 5.14062 0.683594C5.14063 0.502293 5.21265 0.328419 5.34084 0.20022C5.46904 0.0720213 5.64292 0 5.82422 0L12.9883 0C13.2566 0 13.5139 0.106592 13.7037 0.296326C13.8934 0.48606 14 0.743394 14 1.01172V11.5664C14 11.7477 13.928 11.9216 13.7998 12.0498C13.6716 12.178 13.4977 12.25 13.3164 12.25Z" />
  </svg>
);

export default CopyIcon;
