import React, { useContext, useEffect, useMemo, useState } from "react";
import { LogOnMount, useAmplitude } from "react-amplitude-hooks";
import { generatePath, useHistory, useLocation } from "react-router-dom";
import Fade from "Components/shared/Fade";
import { Button } from "Components/shared/buttons";
import LoadingSpinner from "Components/shared/LoadingSpinner/LoadingSpinner";
import QRCode from "qrcode.react";
import api from "Api";
import { RedemptionStatus } from "Enums";
import i18nextTranslate from "Lang/i18nextTranslate";
import { i18nextKeys } from "Lang/i18nextKeys";
import { Trans } from 'react-i18next';
import i18nextTranslateDynamically from "Lang/i18nextTranslateDynamically";
import { useFormatNumber } from "Utils/formatNumber";
import { getTranslation } from 'Helpers/translations';
import linkify from 'Helpers/linkify';
import useConfigSettings from "Hooks/useConfigSettings";
import { ROUTES } from "Router/Routes";
import { AuthContext } from "States/auth/authState";
import { ConfigContext } from "States/config/configState";
import { LangContext } from 'States/lang/langState';
import Text from "Components/shared/Text";
import { UiContext } from "States/ui/uiState";
import LoadingFlashingDots from "Components/shared/LoadingFlashingDots";
import ConfirmationModal from "Components/shared/ConfirmationModal";
import { copyToClipboardAndNotify } from "Utils/utils";
import { CopyIcon, EditAltIcon, WarningIcon } from "Components/shared/symbols";

let interval;

const RedeemQr = () => {
  const history = useHistory();
  const location = useLocation();
  const { lang } = useContext(LangContext);
  const [state, setState] = useState(null);
  const [qrProcessing, setQrProcessing] = useState(false);
  const [showAbortModal, setShowAbortModal] = useState(false);
  const {
    isFetching,
    data: settings
  } = useConfigSettings.query({
    refetchOnMount: false
  });
  const formatNumber = useFormatNumber();
  const { isAdmin } = useContext(AuthContext);
  const {
    config: {
      imprint: { email }
    }
  } = useContext(ConfigContext);
  const {
    breakpoints: { md }
  } = useContext(UiContext);
  const { logEvent } = useAmplitude();

  const defaultLanguageCode = useMemo(() => {
    if (!settings) {
      return undefined;
    }
    const defaultLanguage = settings.Languages.find(({ IsDefault }) => IsDefault);
    return defaultLanguage?.Code;
  }, [settings]);

  const translatedAssetName = useMemo(() => {
    if (!state?.redemptionMethod?.userPays) {
      return undefined;
    }
    const translation = getTranslation("Name", lang, defaultLanguageCode, state?.redemptionMethod?.userPays.Translations) ||
    state?.redemptionMethod?.userPays.Name;
    return translation;
  },
    [lang, state]
  );

  const qrCodeJSON = useMemo(() => {
    return JSON.stringify({
      T: "I",
      ID: state?.redemption?.InvoiceId
    });
  }, [state]);

  useEffect(() => {
    setState(location.state);
  }, [location]);

  useEffect(() => {
    const unlistenHistory = history.listen(({ pathname }) => {
      if (
        pathname === ROUTES.redemptionError || pathname === ROUTES.redemptionFinish ||
        (history.action === "REPLACE" && (pathname === ROUTES.redemptionForm || pathname === ROUTES.redemption))
      ) {
        unlistenHistory();
        return;
      }
      logEvent("Inovoice not paid", {
        reason: "left"
      });
      unlistenHistory();
    });
  }, [])

  useEffect(() => {
    if (state) {
      interval = setInterval(() => {
        api.Redemptions.details(state.redemption.Id)
          .then((redemption) => {
            if (redemption.Status === RedemptionStatus.values.Received) {
              history.push({
                pathname: ROUTES.redemptionFinish,
                state
              });
            } else if (redemption.Status === RedemptionStatus.values.Processing) {
              setQrProcessing(true);
            } else if (redemption.Status !== RedemptionStatus.values.New) {
              history.push({
                pathname: ROUTES.redemptionError,
                state: {
                  referenceCode: state.redemption.ReferenceCode
                }
              });
            }
          })
          .catch(() => {
            clearInterval(interval);
            history.push(ROUTES.redemptionError);
          });
      }, 8000);
      return () => clearInterval(interval);
    }
  }, [state]); // eslint-disable-line

  const abortRedemption = async () => {
    try {
      await api.Redemptions.cancel(state.redemption.Id);
      logEvent("Inovoice not paid", {
        reason: "aborted"
      });
      history.replace(
        settings.RestrictedMode && !isAdmin
        ? generatePath(
            ROUTES.redemptionForm,
            { id: state.redemptionMethod.id }
          )
        : ROUTES.redemption
      );
    } catch {
      history.push(ROUTES.redemptionError);
    }
  };

  const copyQrData = () => {
    copyToClipboardAndNotify(qrCodeJSON, i18nextTranslate(i18nextKeys.redeemQrData));
  };

  const buttonWidth = {
    xxl: '190px',
    xl: '170px',
    md: '160px',
    default: '200px'
  };

  return (
    <LogOnMount eventType="QR generated">
      {!qrProcessing ? (
        <div className="h-full flex flex-col justify-center">
          {!state || isFetching ? (
            <Fade show={!state || isFetching} className="mr-auto ml-auto">
              <LoadingSpinner dataQa="loading-qr" />
            </Fade>
          ) : (
            <Fade show={state && !isFetching}>
              <div className="flex flex-col items-center">
                <Text
                  dataQa="qr-title"
                  className="mb-16 color-4 text-center"
                  textStyle="h1"
                  uppercase
                >
                  {i18nextTranslateDynamically(i18nextKeys.redeemQrInfo, {
                    stateReceiveAmount: formatNumber(state?.redemption?.NetAmount.PayoutUnitsString),
                    stateReceiveUom: state?.redemptionMethod?.userGets?.uom,
                    stateSendAmount: formatNumber(state?.redemption?.GrossAmount.AssetUnitsString),
                    stateSendUom: state?.redemptionMethod?.userPays?.UnitOfMeasureCode ||
                      state?.redemptionMethod?.userPays?.CurrencyCode,
                    stateSendItemName: translatedAssetName
                  })}
                </Text>
                <Text
                  textStyle="p1"
                  className="mb-40"
                  dataQa="qr-description"
                  center
                >
                  <Trans i18nKey={i18nextKeys.redeemQrDescription}>
                    {settings.MobileApplicationName}
                    <EditAltIcon className="inline" />
                  </Trans>
                </Text>
                <QRCode
                  value={qrCodeJSON}
                  size={222}
                  includeMargin={false}
                  style={{
                    border: "8px solid white"
                  }}
                  data-qa="qr-code"
                />
                <Text
                  dataQa="reference"
                  className="text-sm mt-32"
                  textStyle="h2"
                  dangerouslySetContent
                >
                  {i18nextTranslateDynamically(
                    i18nextKeys.redeemQrInstructions,
                    { referenceCode: state.redemption.ReferenceCode }
                  )}
                </Text>
                <div className="flex flex-col md:flex-row gap-16 mt-32 md:mt-40">
                  <Button
                    dataQa="abort"
                    text={i18nextTranslate(i18nextKeys.buttonAbort)}
                    width={buttonWidth}
                    onClick={() => setShowAbortModal(true)}
                    tertiary
                  />
                  <Button
                    dataQa="qr-copy"
                    text={i18nextTranslate(i18nextKeys.redeemQrCopy)}
                    icon={CopyIcon}
                    className="flex-row-reverse"
                    width={buttonWidth}
                    onClick={copyQrData}
                    secondary
                  />
                </div>
              </div>
            </Fade>
          )}
        </div>
      ) : (
        <div className="h-full flex flex-col flex-grow justify-center items-center">
          <LoadingFlashingDots />
          <Text
            className="text-center mt-48 mb-24"
            textStyle="h1"
            style={{
              width: md ? "544px" : "100%"
            }}
            dataQa="processing-title"
            dangerouslySetContent
          >
            {i18nextTranslateDynamically(i18nextKeys.redeemTransferInfo, {
              stateAmount: formatNumber(state?.redemption?.GrossAmount.AssetUnitsString),
              stateUom: state?.redemptionMethod?.userPays?.UnitOfMeasureCode ||
                state?.redemptionMethod?.userPays?.CurrencyCode,
              stateItemName: translatedAssetName
            })}
          </Text>

          <Text
            className="text-center"
            textStyle="p3"
            style={{
              width: md ? "488px" : "100%"
            }}
            dataQa="processing-description"
            dangerouslySetContent
          >
            {linkify(i18nextTranslateDynamically(i18nextKeys.redeemTransferDescription, {
              userEmail: state?.userEmail || "",
              supporterEmail: email
            }))}
          </Text>
        </div>
      )}
      <ConfirmationModal
        show={showAbortModal}
        close={() => setShowAbortModal(false)}
        onConfirm={abortRedemption}
        confirmButtonText={i18nextTranslate(i18nextKeys.buttonAbort)}
        icon={<WarningIcon size="30" />}
        title={i18nextTranslate(i18nextKeys.redemptionAbort)}
        dataQa="redemption-abortModal"
      />
    </LogOnMount>
  );
};

export default RedeemQr;
