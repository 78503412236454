import React, { useContext, useState } from 'react';
import { DIRECTION } from 'Helpers/icons';
import { SingleChevron } from 'Components/shared/symbols';
import { UiContext } from 'States/ui/uiState';
import i18nextTranslate from 'Lang/i18nextTranslate';
import { i18nextKeys } from 'Lang/i18nextKeys';
import formatDate from 'Utils/formatDate';

const CaseHistoryTableBody = ({ items, getRowProps }) => {
  const [openRows, setOpenRows] = useState([]);

  const {
    breakpoints: { sm },
  } = useContext(UiContext);

  return (
    <div
      data-qa="kyc-cases-table-contentWrapper"
      className="justify-center w-full"
    >
      {items.map((kycCase, index) => {
        const { Id, OpenDate, CloseDate, Status } = kycCase;
        return (
          <div
            className={`block rounded bg-3--2`}
            data-qa={`kyc-cases-table-row-${index}`}
            key={Id}
          >
            <div
              className={`grid ${index % 2 ? 'bg-2' : 'bg-3--2'}`}
              style={{
                gridTemplateColumns: !sm
                  ? 'minmax(0, 1fr) minmax(0, 1fr) auto'
                  : 'minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr)',
                cursor: 'pointer',
              }}
              onClick={() => getRowProps(Id)}
            >
              <span className="py-16 px-12 truncate">
                {!sm && (
                  <span className="font-bold">
                    {i18nextTranslate(i18nextKeys.kycManagementDetailsTableId)}
                    {`: `}
                  </span>
                )}
                <span className="block truncate color-4">{Id}</span>
              </span>
              {sm && (
                <span className="py-16 px-12">
                  <span>{formatDate(OpenDate)}</span>
                </span>
              )}
              {sm && (
                <span className="py-16 px-12">
                  <span>{CloseDate ? formatDate(CloseDate) : '-'}</span>
                </span>
              )}
              <span className="py-16 px-12">
                {!sm && (
                  <span className="font-bold">
                    {i18nextTranslate(
                      i18nextKeys.kycManagementDetailsTableCaseStatus
                    )}
                    {`: `}
                    <br />
                  </span>
                )}

                <span>{Status}</span>
              </span>
              {!sm && (
                <span
                  className="grid cols-min items-center p-20 cursor-pointer"
                  onClick={(e) => {
                    e.stopPropagation();
                    if (openRows.find((el) => el === index) !== undefined) {
                      setOpenRows(openRows.filter((el) => el !== index));
                    } else {
                      setOpenRows([...openRows, index]);
                    }
                  }}
                >
                  <SingleChevron
                    size="16"
                    direction={
                      openRows.find((el) => el === index) !== undefined
                        ? DIRECTION.up
                        : DIRECTION.down
                    }
                    className="color-4"
                  />
                </span>
              )}
            </div>
            <div
              style={{
                display:
                  openRows.find((el) => el === index) !== undefined && !sm
                    ? 'grid'
                    : 'none',
                gridTemplateColumns: 'minmax(0, 1fr) minmax(0, 1fr) auto',
                paddingRight: '3.25rem',
              }}
              className={`flex-row pt-0 ${index % 2 ? 'bg-2' : 'bg-4_7'
                }`}
            >
              <span className="py-16 px-12">
                <span className="font-bold">
                  {i18nextTranslate(
                    i18nextKeys.kycManagementDetailsTableOpenDate
                  )}
                  {`: `}
                </span>
                <span>{formatDate(OpenDate)}</span>
              </span>
              <span className="py-16 px-12">
                <span className="font-bold">
                  {i18nextTranslate(
                    i18nextKeys.kycManagementDetailsTableCompletionDate
                  )}
                  {`: `}
                </span>
                <span>{CloseDate ? formatDate(CloseDate) : '-'}</span>
              </span>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default CaseHistoryTableBody;
