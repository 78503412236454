import React, { useRef } from 'react';
import Modal from 'Components/shared/Modal';
import LoadingSpinner from 'Components/shared/LoadingSpinner';
import UserInfoForm from './UserInfoForm';

const UserInfoModal = ({
  show,
  close,
  user,
  onUserInfoFormSubmit,
  isLoading,
}) => {
  const modalContainerRef = useRef(null);

  return (
    <Modal
      dataQa="account-user-info-modal"
      show={show}
      close={close}
      modalContainerRef={modalContainerRef}
    >
      {isLoading ? (
        <div className="flex justify-center bg-3 px-32 py-32">
          <LoadingSpinner />
        </div>
      ) : (
        <div className="flex-col bg-3 rounded modalContent">
          <div
            style={{
              position: 'relative',
            }}
          >
            <UserInfoForm
              user={user}
              isLoading={isLoading}
              onSubmit={onUserInfoFormSubmit}
              close={close}
              modalContainerRef={modalContainerRef}
            />
          </div>
        </div>
      )}
    </Modal>
  );
};

export default UserInfoModal;
