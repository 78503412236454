import React, { useContext } from 'react';
import { UiContext } from 'States/ui/uiState';
import { i18nextKeys } from 'Lang/i18nextKeys';
import i18nextTranslate from 'Lang/i18nextTranslate';
import codeToCountry from 'Utils/country-codes';
import formatPhoneNumber from 'Utils/formatPhoneNumber';
import { TileCardContent } from "Components/shared/Tile";

const UserDetailsSummary = ({
  beneficiary,
  addressTitle,
  showPhoneNumber = false,
  copyOnClick = false
}) => {
  const { breakpoints: { md } } = useContext(UiContext);

  const userData = [
    {
      title: i18nextTranslate(i18nextKeys.redemptionBeneficiaryInfo),
      items: [
        {
          name: i18nextTranslate(i18nextKeys.commonFirstName),
          value: beneficiary.FirstName,
          dataQa: "beneficiary-firstName"
        },
        {
          name: i18nextTranslate(i18nextKeys.commonLastName),
          value: beneficiary.LastName,
          dataQa: "beneficiary-lastName"
        },
        ...(showPhoneNumber
          ? [{
              name: i18nextTranslate(i18nextKeys.commonPhoneNumber),
              value: formatPhoneNumber(beneficiary.PhoneNumber),
              dataQa: "beneficiary-phoneNumber"
            }]
          : []
        )
      ],
      dataQa: "beneficiary-info"
    },
    {
      title: i18nextTranslate(addressTitle),
      items: [
        {
          name: i18nextTranslate(i18nextKeys.commonAddressStreet),
          value: beneficiary.AddressLine,
          dataQa: "beneficiary-street"
        },
        {
          name: i18nextTranslate(i18nextKeys.commonAddressZip),
          value: beneficiary.ZipCode,
          dataQa: "beneficiary-zip"
        },
        {
          name: i18nextTranslate(i18nextKeys.commonAddressCity),
          value: beneficiary.City,
          dataQa: "beneficiary-city"
        },
        {
          name: i18nextTranslate(i18nextKeys.commonAddressRegion),
          value: beneficiary.State,
          dataQa: "beneficiary-region"
        },
        {
          name: i18nextTranslate(i18nextKeys.commonAddressCountry),
          value: i18nextTranslate(codeToCountry[beneficiary.Country]),
          dataQa: "beneficiary-country"
        }
      ],
      dataQa: "beneficiary-address"
    }
  ];

  return (
    <div className={`grid gap-24 ${md ? 'grid-cols-2' : 'grid-cols-1'}`}>
      <TileCardContent
        data={userData}
        copyOnClick={copyOnClick}
      />
    </div>
  );
};

export default UserDetailsSummary;
