import React from 'react';
import i18nextTranslate from 'Lang/i18nextTranslate';
import { i18nextKeys } from 'Lang/i18nextKeys';
import linkify from 'Helpers/linkify';
import Text from 'Components/shared/Text';

const DescriptionTableRow = ({
  unitOfMeasure,
  currencyCode,
  description,
  name,
  price,
  currency,
  showConversionRate
}) => {
  return (
    <>
      <td colSpan={6}>
        <div className="flex flex-col gap-16 p-16 pt-4">
          {showConversionRate ? (
            <p className="flex items-center gap-8">
              <Text textStyle="h3">
                {`${i18nextTranslate(i18nextKeys.cartTableHeaderPrice)}:`}
              </Text>
              <Text>
                1 {unitOfMeasure || currencyCode || name} = {price} {currency}
              </Text>
            </p>
          ) : null}
          <Text
            textStyle="p3"
            dangerouslySetContent
          >
            {linkify(description)}
          </Text>
        </div>
      </td>
    </>
  );
};

export default DescriptionTableRow;
