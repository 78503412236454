const formatDateTime = (
  date,
  options = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  }
) => {
  return new Date(date).toLocaleString(navigator.language, options);
};

export default formatDateTime;
