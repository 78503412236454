import React from 'react';

const PlusSymbol = ({
  size = "14",
  className,
  ...props
}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 14 14"
    fill="currentColor"
    className={className}
    {...props}
  >
    <path d="M11.5 7.00011C11.5 7.43511 11.1475 7.78765 10.7122 7.78765H7.78745V10.7125C7.78745 11.1476 7.43479 11.5001 6.99983 11.5C6.78232 11.5 6.58543 11.412 6.44304 11.2695C6.30047 11.1269 6.21233 10.9301 6.21243 10.7127L6.21231 7.78753H3.28721C3.06983 7.78753 2.8731 7.69951 2.73041 7.55683C2.58808 7.4145 2.5 7.21762 2.5 7.00011C2.49988 6.56518 2.85243 6.21257 3.28762 6.21257H6.21243V3.28772C6.21243 2.85265 6.56517 2.5 7.00017 2.5C7.43501 2.50012 7.78743 2.85242 7.78767 3.28742V6.21257H10.7128C11.1477 6.2128 11.4998 6.56518 11.5 7.00011Z" />
  </svg>
);

export default PlusSymbol;
