import React from 'react';
import { Link } from 'react-router-dom';
import Text from 'Components/shared/Text';

const SidebarLink = ({
  route,
  text,
  onClick,
  dataQa,
  active,
  state = {}
}) => {
  return (
    <li
      data-qa={dataQa}
      className={`p-16 h3 rounded-l-lg ${active ? 'sidebarLink-active-highlight' : ''
        }`}
      onClick={onClick}
    >
      <Link
        to={{
          pathname: route,
          state
        }}
      >
        <Text
          textStyle="h3"
          className="font-extrabold pb-2 border-b border-transparent hover:border-current transition duration-100 ease-in"
          color="color-7"
          inline
        >
          {text}
        </Text>
      </Link>
    </li>
  );
};

export default SidebarLink;
