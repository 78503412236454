import React, { useEffect, useState, useReducer, createContext } from 'react';
import uiReducer from './uiReducer';
import { SET_SIDEBAR } from './uiTypes';

export const UiContext = createContext();

const UiState = ({ children, screens }) => {
  const [breakpoints, setBreakpoints] = useState({});

  const initialState = {
    smallScreenSidebarShowing: false
  };

  const [state, dispatch] = useReducer(uiReducer, initialState);

  const setSmallScreenSidebarShowing = (value) => {
    dispatch({ type: SET_SIDEBAR, payload: { value } });
  };

  useEffect(() => {
    const mediaQueryLists = {};
    const matches = {};

    const breakpoints = Object.entries(screens)
      .map(([breakpointName, value]) => [
        breakpointName,
        `(min-width: ${value})`
      ]);

    const handleBreakpointMatch = () => {
      const matches = breakpoints.reduce(
        (acc, [name]) => ({
          ...acc,
          [name]: mediaQueryLists[name].matches
        }),
        {}
      );
      setBreakpoints(matches);
    };

    breakpoints.forEach(([name, mediaQuery]) => {
      mediaQueryLists[name] = window.matchMedia(mediaQuery);
      mediaQueryLists[name].addListener(handleBreakpointMatch);
      matches[name] = mediaQueryLists[name].matches;
    });
    setBreakpoints(matches);
  }, [screens]);

  return (
    <UiContext.Provider
      value={{
        breakpoints,
        smallScreenSidebarShowing: state.smallScreenSidebarShowing,
        setSmallScreenSidebarShowing
      }}
    >
      {children}
    </UiContext.Provider>
  );
};

export default UiState;
