import formatDate from './formatDate';

const formatDateRange = (startDate, endDate) => {
  const formattedStartDate = formatDate(startDate);
  if (!endDate) return formattedStartDate;
  const formattedEndDate = formatDate(endDate);

  return `${formattedStartDate} - ${formattedEndDate}`;
};

export default formatDateRange;
