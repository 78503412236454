import React, { useEffect, useState } from "react";
import { Trans } from "react-i18next";
import api from "Api";
import useHandleError from "Utils/handleError";
import { i18nextKeys } from "Lang/i18nextKeys";
import { CheckboxWithText } from "../formElements";

const Terms = () => {
  const [termsLink, setTermsLink] = useState(null);
  const handleError = useHandleError();

  useEffect(() => {
    const getLinks = async () => {
      try {
        const {
          TermsAndConditions,
          Success,
          ErrorMessage
        } = await api.User.getLegalLinks();
        if (!Success) {
          throw ErrorMessage;
        } else {
          setTermsLink(TermsAndConditions);
        }
      } catch (error) {
        handleError({ error });
      }
    };
    getLinks();
  }, []);

  return (
    <CheckboxWithText
      name="terms"
      dataQa="terms"
      required
      showErrors
    >
      <Trans i18nKey={i18nextKeys.commonTerms}>
        <a
          {...(termsLink
            ? { onClick: () => window.open(termsLink, '_blank') }
            : {}
          )}
        >
          .
        </a>
      </Trans>
    </CheckboxWithText>
  );
};

export default Terms;
