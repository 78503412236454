import React from 'react';

function WarningIcon({ size, ...props }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="currentColor"
      className="color-8"
      {...props}
    >
      <g clipPath="url(#clip0_9137_97031)">
        <path
          d="M8 10.9647C7.55269 10.9647 7.17773 11.3396 7.17773 11.7869C7.17773 12.2342 7.55269 12.6092 8 12.6092C8.43086 12.6092 8.82226 12.2342 8.80252 11.8067C8.82226 11.3363 8.4506 10.9647 8 10.9647Z"
          className="fill-current"
        />
        <path
          d="M15.6107 13.8787C16.127 12.9874 16.1303 11.925 15.6172 11.037L10.4666 2.11711C9.95679 1.2192 9.03585 0.686371 8.00309 0.686371C6.97033 0.686371 6.0494 1.22249 5.53959 2.11382L0.382367 11.0436C-0.130724 11.9415 -0.127435 13.0104 0.392235 13.9018C0.905326 14.7832 1.82297 15.3128 2.84915 15.3128H13.1373C14.1668 15.3128 15.091 14.7767 15.6107 13.8787ZM14.4924 13.2341C14.2062 13.7274 13.6997 14.0202 13.134 14.0202H2.84586C2.28673 14.0202 1.7835 13.734 1.50393 13.2505C1.22107 12.7605 1.21779 12.175 1.50064 11.6817L6.65787 2.75518C6.93744 2.26511 7.43737 1.97568 8.00309 1.97568C8.56552 1.97568 9.06874 2.2684 9.34831 2.75847L14.5022 11.6849C14.7785 12.1651 14.7752 12.744 14.4924 13.2341Z"
          className="fill-current"
        />
        <path
          d="M7.7969 5.19237C7.4055 5.3042 7.16211 5.65942 7.16211 6.09028C7.18184 6.35012 7.19829 6.61324 7.21802 6.87308C7.27394 7.86308 7.32985 8.83335 7.38576 9.82335C7.4055 10.1588 7.66533 10.4022 8.00082 10.4022C8.3363 10.4022 8.59942 10.1424 8.61587 9.80362C8.61587 9.5997 8.61587 9.41222 8.6356 9.20501C8.67178 8.57022 8.71125 7.93544 8.74743 7.30065C8.76716 6.88952 8.80334 6.47839 8.82308 6.06726C8.82308 5.91925 8.80334 5.78769 8.74743 5.65613C8.57969 5.28775 8.18829 5.10028 7.7969 5.19237Z"
          className="fill-current"
        />
      </g>
      <defs>
        <clipPath id="clip0_9137_97031">
          <rect
            width="16"
            height="16"
            className="fill-current"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default WarningIcon;
