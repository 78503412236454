import React from 'react';
import Error from './Error';
import Text from '../Text';

const DateField = ({
  className = 'w-1/2 px-12',
  dataQa,
  meta: { touched, error },
  input,
  valid,
  required,
  label,
  disabled,
}) => {
  return (
    <div data-qa={dataQa} className={`${className} mb-12 md:mb-4`}>
      {label ? (
        <Text
          textStyle="h3"
          className="truncate mb-4"
        >
          <label>
            {label}{required ? " *" : null}
          </label>
        </Text>
      ) : null}
      <input
        type="date"
        className={`appearance-none flex w-full h-40 px-16 dateInput
                  bg-9 text-gray-700
                  focus:outline-none focus:bg-9-10 color-10
                  border rounded inputField ${touched && error ? 'border-red-500' : ''}
                  ${valid ? 'border-green-500' : ''}`}
        {...input}
        disabled={disabled}
      />
      <Error error={touched && error} />
    </div>
  );
};

export default DateField;
