import React from 'react';

const MinusSymbol = ({
  size = "14",
  className,
  ...props
}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 15 15"
    fill="currentColor"
    className={className}
    {...props}
  >
    <path d="M11.2122 8.28748C11.6475 8.28748 12 7.93495 12 7.49994C11.9998 7.06502 11.6477 6.71264 11.2128 6.7124H6.71243H3.78762C3.35243 6.7124 2.99988 7.06502 3 7.49994C3 7.71746 3.08808 7.91434 3.23041 8.05667C3.3731 8.19935 3.56983 8.28737 3.78721 8.28737L8.28745 8.28748H11.2122Z" />
  </svg>
);

export default MinusSymbol;
