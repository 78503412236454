import React, { useReducer, createContext } from 'react';
import { useAmplitude } from 'react-amplitude-hooks';
import api from 'Api';
import { USER_STATUS } from 'Enums';
import useHandleError from 'Utils/handleError';
import authReducer from './authReducer';
import {
  SET_ADMIN,
  SET_AUTHENTICATED,
  SET_EMAIL_CONFIRMED,
  SET_LOADING,
  SET_STATUS
} from './authTypes';

export const AuthContext = createContext();

const AuthState = (props) => {
  const initialState = {
    isActive: false,
    isAuthenticated: false,
    isAdmin: false,
    isBlocked: false,
    isEmailConfirmed: false,
    loading: true,
    error: null,
    user: null,
    status: USER_STATUS.New
  };

  const [state, dispatch] = useReducer(authReducer, initialState);
  const handleError = useHandleError();
  const { logEvent } = useAmplitude();

  const setAuthenticated = (isAuthenticated, user = null) => {
    dispatch({ type: SET_AUTHENTICATED, payload: { isAuthenticated, user } });
  };

  const setAdmin = (isAdmin) => {
    dispatch({ type: SET_ADMIN, payload: isAdmin });
  };

  const getStatus = async (ignoreNew = false) => {
    if (
      state.status === USER_STATUS.Rejected ||
      (ignoreNew && state.status === USER_STATUS.New)
    ) {
      return state.status;
    }
    setLoading(true);
    try {
      let { State: newStatus } = await api.User.getStatus();
      setStatus(newStatus);
      return newStatus;
    } catch (error) {
      if (error.response?.status === 404) {
        return state.status;
      } else {
        handleError({ error });
      }
    } finally {
      setLoading(false);
    }
  };

  const setStatus = (status) => {
    if (status === state.status) {
      return;
    }
    dispatch({ type: SET_STATUS, payload: status });
  };

  const checkEmailConfirmation = async (ignoreNew = false) => {
    if (ignoreNew && state.status === USER_STATUS.New) {
      return;
    }
    if (state.isEmailConfirmed) {
      return state.isEmailConfirmed;
    }
    setLoading(true);
    try {
      const isConfirmed = await api.User.getEmailConfirmationStatus();
      if (isConfirmed) {
        dispatch({ type: SET_EMAIL_CONFIRMED });
        logEvent("Email confirmed");
      }
      return isConfirmed;
    } catch (error) {
      handleError({ error });
    } finally {
      setLoading(false);
    }
  };

  const setLoading = (bool) => {
    dispatch({ type: SET_LOADING, payload: bool });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        checkEmailConfirmation,
        setLoading,
        setAuthenticated,
        setAdmin,
        getStatus
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthState;
