import React from 'react';
import { DIRECTION } from 'Helpers/icons';
import { AbortSymbol, SingleChevron } from 'Components/shared/symbols';

const MobileActionButtons = ({
  handleRemoveAsset,
  toggleRow,
  rowExpanded
}) => {
  return (
    <td
      className="h-104 pr-16 py-16 pl-24"
      role="presentation"
    >
      <div className="h-full flex flex-col justify-between">
        <AbortSymbol
          size="16"
          className="cursor-pointer color-4"
          onClick={handleRemoveAsset}
        />
        <SingleChevron
          size="16"
          direction={rowExpanded ? DIRECTION.up : DIRECTION.down}
          onClick={toggleRow}
          className="cursor-pointer color-6"
        />
      </div>
    </td>
  );
};

export default MobileActionButtons;
