import i18nextTranslate from 'Lang/i18nextTranslate';
import { i18nextKeys } from 'Lang/i18nextKeys';

const REDEMPTION_STATUS = {
  Draft: 'Draft',
  PaymentTimeout: 'PaymentTimeout',
  Received: 'Received',
  New: 'New',
  Processing: 'Processing',
  PayoutError: 'PayoutError',
  Paid: 'Paid',
  Aborted: 'Aborted',
  Error: 'Error',
};

const enumToFriendlyString = (e) => {
  const {
    Draft,
    PayoutError,
    Received,
    PaymentTimeout,
    New,
    Processing,
    Paid,
    Aborted,
    Error,
  } = REDEMPTION_STATUS;
  switch (e) {
    case Draft: {
      return i18nextTranslate(i18nextKeys.enumRedemptionStatusDraft);
    }
    case PaymentTimeout: {
      return i18nextTranslate(i18nextKeys.enumRedemptionStatusPaymentTimeout);
    }
    case New: {
      return i18nextTranslate(i18nextKeys.enumRedemptionStatusNew);
    }
    case Processing: {
      return i18nextTranslate(i18nextKeys.enumRedemptionStatusProcessing);
    }
    case PayoutError: {
      return i18nextTranslate(i18nextKeys.enumRedemptionStatusPayoutError);
    }
    case Received: {
      return i18nextTranslate(i18nextKeys.enumRedemptionStatusReceived);
    }
    case Paid: {
      return i18nextTranslate(i18nextKeys.enumRedemptionStatusPaid);
    }
    case Aborted: {
      return i18nextTranslate(i18nextKeys.enumRedemptionStatusAborted);
    }
    case Error: {
      return i18nextTranslate(i18nextKeys.enumRedemptionStatusError);
    }
    default: {
      return i18nextTranslate(i18nextKeys.enumInvalidEnum);
    }
  }
};

const friendlyStringToEnum = (str) => {
  const {
    Draft,
    PayoutError,
    Received,
    Processing,
    PaymentTimeout,
    New,
    Paid,
    Aborted,
    Error,
  } = REDEMPTION_STATUS;
  switch (str) {
    case i18nextTranslate(i18nextKeys.enumRedemptionStatusDraft): {
      return Draft;
    }
    case i18nextTranslate(i18nextKeys.enumRedemptionStatusPaymentTimeout): {
      return PaymentTimeout;
    }
    case i18nextTranslate(i18nextKeys.enumRedemptionStatusNew): {
      return New;
    }
    case i18nextTranslate(i18nextKeys.enumRedemptionStatusProcessing): {
      return Processing;
    }
    case i18nextTranslate(i18nextKeys.enumRedemptionStatusPayoutError): {
      return PayoutError;
    }
    case i18nextTranslate(i18nextKeys.enumRedemptionStatusReceived): {
      return Received;
    }
    case i18nextTranslate(i18nextKeys.enumRedemptionStatusPaid): {
      return Paid;
    }
    case i18nextTranslate(i18nextKeys.enumRedemptionStatusAborted): {
      return Aborted;
    }
    case i18nextTranslate(i18nextKeys.enumRedemptionStatusError): {
      return Error;
    }
    default: {
      return (
        REDEMPTION_STATUS[str] || i18nextTranslate(i18nextKeys.enumInvalidEnum)
      );
    }
  }
};

const RedemptionStatus = {
  values: REDEMPTION_STATUS,
  enumToFriendlyString,
  friendlyStringToEnum,
};

export default RedemptionStatus;
