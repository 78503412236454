import React from 'react';

const ClockIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="currentColor"
    className="color-4"
  >
    <path d="M9.99964 0C4.48624 0 0 4.48588 0 10C0 15.5141 4.48624 20 9.99964 20C15.5141 20 19.9996 15.5141 19.9996 10C19.9996 4.48588 15.5141 0 9.99964 0ZM9.99964 18.6667C5.22095 18.6667 1.33326 14.779 1.33326 10C1.33326 5.22095 5.22131 1.33326 9.99964 1.33326C14.779 1.33326 18.6664 5.22095 18.6664 10C18.6664 14.779 14.7794 18.6667 9.99964 18.6667Z" />
    <path d="M14.9523 9.276H10.6665V3.60919C10.6665 3.24094 10.368 2.94238 10.0001 2.94238C9.63254 2.94238 9.33398 3.2413 9.33398 3.60919V10.6089H14.9523C15.3202 10.6089 15.6187 10.3107 15.6184 9.94246C15.6184 9.57456 15.3195 9.276 14.9523 9.276Z" />
  </svg>
);

export default ClockIcon;
