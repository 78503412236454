import React, { useCallback, useEffect, useState } from "react";
import { Field as FinalField } from "react-final-form";
import api from "Api";
import i18nextTranslate from "Lang/i18nextTranslate";
import i18nextTranslateDynamically from "Lang/i18nextTranslateDynamically";
import { i18nextKeys } from "Lang/i18nextKeys";
import linkify from "Helpers/linkify";
import {
  MemoizedValidationField,
  DropdownField,
  FormField
} from "Components/shared/formElements";
import Text from "Components/shared/Text";
import { SubsectionHeading } from "../../shared";
import coinify_guide from "Assets/images/coinify_guide.png";

const ENV_URLS = [
  {
    value: "https://api.payment.sandbox.coinify.com/v1/",
    label: i18nextTranslate(i18nextKeys.commonSandbox)
  },
  {
    value: "https://api.payment.coinify.com/v1/",
    label: i18nextTranslate(i18nextKeys.commonProduction)
  }
];

const CryptoCurrency = ({
  providerConfig,
  fieldWidth
}) => {
  const [selectedEnv, setSelectedEnv] = useState(ENV_URLS[0]);

  const validate = useCallback(
    (value) => {
      if (!value) {
        return i18nextTranslateDynamically(
          i18nextKeys.validatorMessage,
          { field: i18nextTranslate(i18nextKeys.configPaymentApiKey) }
        );
      }
    }, []
  );

  const validateAsync = useCallback(
    async (value, { coinify }) => {
      try {
        await api.Config.PaymentProviderConfigurations.validate({
          ...providerConfig,
          ApiUrl: coinify.ApiUrl,
          ApiSecret: coinify.ApiSecret
        });
        return undefined;
      } catch {
        return i18nextTranslate(
          i18nextKeys.configPaymentCryptoCurrencyAccountInvalid
        );
      }
    }, []
  );

  useEffect(() => {
    if (!providerConfig?.ApiUrl) {
      return;
    }
    const currentEnvOption = ENV_URLS.find(
      ({ value }) => value === providerConfig.ApiUrl
    );
    setSelectedEnv(currentEnvOption);
  }, [providerConfig?.ApiUrl]);

  return providerConfig ? (
    <div className="flex gap-88 xxl:gap-96">
      <div
        className="flex flex-col"
        style={{ width: fieldWidth }}
      >
        <SubsectionHeading
          text={i18nextTranslate(
            i18nextKeys.configPaymentCryptoCurrencyAccountTitle
          )}
          dataQa="coinify"
        />
        <div className="flex flex-col gap-24">
          <Text
            textStyle="p4"
            className="mb-24 xxl:mb-32"
            dataQa="coinify-name-description"
            dangerouslySetContent
          >
            {linkify(i18nextTranslate(
              i18nextKeys.configPaymentCryptoCurrencyAccountDescription
            ))}
          </Text>
          <FinalField
            name="coinify.ApiUrl"
            initialValue={providerConfig.ApiUrl || ENV_URLS[0]}
            subscription={{
              error: true,
              invalid: true,
              touched: true
            }}
          >
            {({ input, meta }) => (
              <DropdownField
                label={i18nextTranslate(
                  i18nextKeys.commonEnvironment
                )}
                options={ENV_URLS}
                input={{
                  value: selectedEnv,
                  onChange: (value, option) => {
                    setSelectedEnv(option);
                    input.onChange(value);
                  }
                }}
                meta={meta}
                dataQa="coinify-url"
                required
              />
            )}
          </FinalField>
          <MemoizedValidationField
            debounceTime={3000}
            name="coinify.ApiKey"
            label={i18nextTranslate(
              i18nextKeys.configPaymentApiKey
            )}
            initialValue={providerConfig.ApiKey}
            subscription={{
              active: true,
              error: true,
              touched: true,
              value: true
            }}
            validate={validate}
            validateAsync={validateAsync}
            validateFields={[]}
            className="mb-8"
            dataQa="coinify-name"
            required
          >
            {(props) => <FormField {...props} />}
          </MemoizedValidationField>
        </div>
      </div>
      <div
        className="flex flex-col gap-6"
        style={{ width: fieldWidth }}
      >
        <Text textStyle="h3">
          {i18nextTranslate(
            i18nextKeys.configPaymentCryptoCurrencyAccountKeyDescription
          )}
        </Text>
        <img
          data-qa="coinify-key-image-1"
          src={coinify_guide}
          alt="Coinify account key"
        />
      </div>
    </div>
  ) : null;
};

export default CryptoCurrency;
