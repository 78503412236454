import React from "react";
import Text from "Components/shared/Text";
import Tooltip from "Components/shared/Tooltip";

const SubsectionHeading = ({
  text,
  tooltipText,
  color = "color-4",
  className = "mb-16 xxl:mb-20",
  dataQa = "config-section"
}) => {
  return (
    <div className={`flex items-center gap-8 ${className}`}>
      <Text
        textStyle="p2"
        color={color}
        dataQa={`${dataQa}-title`}
      >
        {text}
      </Text>

      {tooltipText ? (
        <Tooltip
          text={tooltipText}
          dataQa={dataQa}
          useIcon
        />
      ) : null}
    </div>
  );
};

export default SubsectionHeading;
