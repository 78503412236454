import React, { useContext, useMemo } from 'react';
import { DropdownField } from 'Components/shared/formElements';
import codeToCountry from 'Utils/country-codes';
import i18nextTranslate from 'Lang/i18nextTranslate';
import { LangContext } from 'States/lang/langState';

const CountryInput = ({
  input: { value, onChange },
  menuPlacement = "auto",
  required = false,
  showErrors = true,
  ...props
}) => {
  const { lang } = useContext(LangContext);

  const options = useMemo(() => {
    return Object.entries(codeToCountry).map((entry) => ({
      value: entry[0],
      label: i18nextTranslate(entry[1]),
    }));
  }, [lang]);

  return (
    <DropdownField
      dataQa="country"
      {...props}
      input={{
        value: {
          value: value,
          label: i18nextTranslate(codeToCountry[value])
        },
        onChange,
      }}
      options={options}
      menuPlacement={menuPlacement}
      showErrors={showErrors}
      required={required}
      isSearchable
    />
  );
};

export default CountryInput;
