import React, { useContext } from 'react';
import { UiContext } from 'States/ui/uiState';
import Text from 'Components/shared/Text';

const FormStep = ({ number, text, dataQa }) => {
  const {
    breakpoints: { xxl }
  } = useContext(UiContext);

  return (
    <div
      className="flex gap-16 items-center mb-24"
      data-qa={dataQa}
    >
      <div
        className="border border-color-4--20 rounded-full inline-flex items-center justify-center cursor-default"
        style={{
          width: xxl ? '28px' : '24px',
          height: xxl ? '28px' : '24px'
        }}
      >
        <Text
          textStyle="h3"
          color="color-4"
          dataQa={`${dataQa}-number`}
        >
          {number}
        </Text>
      </div>
      <Text
        textStyle="p2"
        color="color-4"
        dataQa={`${dataQa}-text`}
      >
        {text}
      </Text>
    </div>
  );
};

export default FormStep;
