import { useContext, useEffect, useRef } from 'react';
import { AuthContext } from '../../States/auth/authState';
import { getUserManager } from '../../Helpers/auth';

const Logout = () => {
  const { setLoading: setLoadingContext } = useContext(AuthContext);
  const setLoading = useRef(setLoadingContext);

  useEffect(() => {
    setLoading.current(true);
    async function logout() {
      const userManager = await getUserManager();
      userManager.signoutRedirect();
    }
    logout();
  }, []);
  return null;
};

export default Logout;
