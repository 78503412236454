import { createValidator } from 'revalidate';
import { i18nextKeys } from "Lang/i18nextKeys";
import i18nextTranslate from "Lang/i18nextTranslate";
import i18nextTranslateDynamically from "Lang/i18nextTranslateDynamically";

const translationValidator = (defaultLanguage, required = false) => createValidator(
  () => (translation) => {
    const translations = Object.entries(translation);
    let isTranslationAvailable = false
    for (const [lang, value] of translations) {
      if (value === "") {
        continue;
      }
      if (typeof value !== "string") {
        return i18nextTranslateDynamically(
          i18nextKeys.helperValidatorString,
          { field: `"${lang}" ${i18nextTranslate(i18nextKeys.configTranslationsField).toLowerCase()}` }
        );
      }
      isTranslationAvailable = true;
      if (lang === defaultLanguage) {
        continue;
      }
      if (translation[defaultLanguage] && typeof translation[defaultLanguage] !== "string") {
        return i18nextTranslateDynamically(
          i18nextKeys.helperValidatorString,
          { field: `"${defaultLanguage}" ${i18nextTranslate(i18nextKeys.configTranslationsField).toLowerCase()}` }
        );
      }
      if (!translation[defaultLanguage]) {
        return i18nextTranslateDynamically(
          i18nextKeys.validatorTranslationLanguageDefault,
          { language: defaultLanguage }
        );
      }
    }
    if (required && !isTranslationAvailable) {
      return i18nextTranslateDynamically(
        i18nextKeys.validatorTranslationLanguageDefault,
        { language: defaultLanguage }
      );
    }
  },
  () => {}
);

export default translationValidator;
