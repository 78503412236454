import React, { useEffect, useState, useContext } from "react";
import { Field as FinalField } from 'react-final-form';
import { UiContext } from 'States/ui/uiState';
import i18nextTranslate from "Lang/i18nextTranslate";
import { i18nextKeys } from "Lang/i18nextKeys";
import { STORAGE_KEYS } from "Enums";
import {
  CheckboxWithText,
  DropdownField,
  FormField
} from "Components/shared/formElements";
import WalletFormModal from "Components/CryptoWallet/WalletFormModal";
import OptionAddAnother from "Components/shared/formElements/Dropdown/OptionAddAnother";
import Text from "Components/shared/Text";
import WalletSummary from "Components/CryptoWallet/WalletSummary";
import { HorizontalRule } from "Components/shared/Tile";
import { walletAddressValidator } from 'Components/shared/formElements/validators';
import { isRequiredWithMessage } from "Utils/validators";

const CryptoWalletSelection = ({
  wallets,
  selectedWallet,
  selectWallet = () => {},
  refetchUserWallets = () => {},
  disabled = false
}) => {
  const [showWalletModal, setShowWalletModal] = useState(false);
  const [savedWallet, setSavedWallet] = useState(
    JSON.parse(sessionStorage.getItem(STORAGE_KEYS.redemptionForm))?.wallet
  );
  const { breakpoints: { md } } = useContext(UiContext);

  const newWalletOption = {
    Id: null,
    Name: i18nextTranslate(i18nextKeys.redemptionAnotherWallet),
    addAnother: true
  };

  useEffect(() => {
    if (disabled) {
      return;
    }
    if (!wallets?.length || (savedWallet && !savedWallet.Id)) {
      selectWallet(newWalletOption);
      return;
    }
    if (savedWallet?.Id) {
      const existingWallet = wallets.find(
        ({ Id }) => Id === savedWallet.Id
      );
      if (existingWallet) {
        selectWallet(existingWallet);
      }
    }
  }, [wallets]);

  const onWalletChange = (id, wallet) => {
    setSavedWallet(null);
    selectWallet(wallet);
  };

  const onWalletFormSubmit = () => {
    refetchUserWallets();
    setShowWalletModal(false);
  };

  return (
    <>
      <div className="flex flex-col gap-24 xl:gap-32">
        <div
          className={`flex flex-col md:flex-row md:justify-between gap-32 md:gap-0 ${
            selectedWallet?.Id ? 'items-start' : ''
          } ${
            selectedWallet?.addAnother ? 'items-center' : ''
          }`
        }>
          <DropdownField
            replacedComponents={{ Option: OptionAddAnother }}
            label={i18nextTranslate(i18nextKeys.redemptionSelectWalletLabel)}
            placeholder=""
            options={[
              newWalletOption,
              {
                label: i18nextTranslate(i18nextKeys.redemptionChooseExistingWallet),
                options: wallets
              }
            ]}
            labelKey="Name"
            valueKey="Id"
            input={{
              value: selectedWallet,
              onChange: onWalletChange
            }}
            meta={{}}
            style={{ width: md ? '45%' : '100%' }}
            onEdit={() => setShowWalletModal(true)}
            showEditIcon={selectedWallet?.Id}
            showErrors={false}
            disabled={disabled || !wallets?.length}
            required
          />
          {!!selectedWallet?.Id && (
            <Text
              textStyle="p4"
              className="self-end"
              style={{ width: md ? '45%' : '100%' }}
              dataQa="wallet-edit-warning"
            >
              {i18nextTranslate(i18nextKeys.redemptionWalletChangesWarning)}
            </Text>
          )}
          {selectedWallet?.addAnother && (
            <CheckboxWithText
              name="wallet.IsActive"
              checked={savedWallet?.IsActive}
              text={i18nextTranslate(i18nextKeys.commonWalletSave)}
              className="items-center"
              style={{ width: md ? '45%' : '100%' }}
              dataQa="wallet-save"
            />
          )}
        </div>
        {!!selectedWallet?.Id && (
          <>
            <HorizontalRule
              xlMargin="xl:-mx-32"
              xxlMargin="xxl:-mx-32"
            />
            <WalletSummary
              wallet={selectedWallet}
              dataQa="wallet-selected"
            />
          </>
        )}
        {selectedWallet?.addAnother && (
          <>
            <HorizontalRule
              xlMargin="xl:-mx-32"
              xxlMargin="xxl:-mx-32"
            />
            <div className="flex flex-col md:flex-row md:justify-between md:items-center gap-32 md:gap-0">
              <FinalField
                name="wallet.Name"
                label={i18nextTranslate(i18nextKeys.commonWalletName)}
                initialValue={savedWallet?.Name}
                subscription={{
                  active: true,
                  error: true,
                  touched: true,
                  value: true
                }}
                validate={isRequiredWithMessage(
                  i18nextTranslate(i18nextKeys.commonWalletName)
                )}
                validateFields={[]}
                render={(props) => (
                  <FormField
                    containerStyle={{ width: md ? '45%' : '100%' }}
                    dataQa="wallet-name"
                    {...props}
                  />
                )}
                required
              />
              <FinalField
                name="wallet.Address"
                label={i18nextTranslate(i18nextKeys.commonWalletAddress)}
                initialValue={savedWallet?.Address}
                subscription={{
                  active: true,
                  error: true,
                  touched: true,
                  value: true
                }}
                validate={(value, { wallet }) => {
                  return walletAddressValidator(wallet.Type)(value);
                }}
                validateFields={[]}
                render={(props) => (
                  <FormField
                    containerStyle={{ width: md ? '45%' : '100%' }}
                    dataQa="wallet-address"
                    {...props}
                  />
                )}
                required
              />
            </div>
          </>
        )}
      </div>
      {showWalletModal && (
        <WalletFormModal
          show={showWalletModal}
          close={() => setShowWalletModal(false)}
          onWalletFormSubmit={onWalletFormSubmit}
          wallet={selectedWallet}
          wallets={wallets}
          disableWalletType
        />
      )}
    </>
  );
};

export default CryptoWalletSelection;