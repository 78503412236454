import React from 'react';
import { CollectMethod, PaymentMethod } from 'Enums';
import {
  CryptoSymbol,
  BankTransferConfigSymbol,
  TokenCardSymbol,
  WalletSymbol
} from '../Components/shared/symbols/index';

export const DIRECTION = {
  right: "0",
  down: "90",
  left: "180",
  up: "270",
};

export const IconCircleWrapper = ({
  icon,
  dataQa,
  size,
  small = false
}) => {
 return icon ? (
  <div
    className={`flex items-center justify-center ${
      small ? 'bg-4--20' : 'bg-4_20'
    } rounded-full color-white`}
    style={{
      width: size,
      height: size
    }}
    data-qa={`${dataQa}-icon-wrapper`}
  >
    {icon}
  </div>
  ) : null;
};

export const getCollectionMethodIcon = ({
  size = "50",
  className = "color-4",
  collectionMethod
}) => {
  switch (collectionMethod) {
    case CollectMethod.values.TokenCard: {
      return (
        <TokenCardSymbol
          size={size}
          className={className}
        />
      );
    }
    default: {
      return (
        <WalletSymbol
          size={size}
          className={className}
        />
      );
    }
  }
};

export const getPaymentMethodIcon = ({
  size = "50",
  className = "color-4",
  paymentMethod
}) => {
  switch (paymentMethod) {
    case PaymentMethod.values.BankTransfer: {
      return (
        <BankTransferConfigSymbol
          size={size}
          className={className}
        />
      );
    }
    case PaymentMethod.values.CreditCard: {
      return (
        <TokenCardSymbol
          size={size}
          className={className}
        />
      );
    }
    case PaymentMethod.values.CryptoCurrency: {
      return (
        <CryptoSymbol
          size={size}
          className={className}
        />
      );
    }
    default: {
      return null;
    }
  }
};
