import React, { useContext, useMemo } from "react";
import { i18nextKeys } from "Lang/i18nextKeys";
import i18nextTranslate from "Lang/i18nextTranslate";
import i18nextTranslateDynamically from "Lang/i18nextTranslateDynamically";
import { CollectMethod } from "Enums";
import { UiContext } from "States/ui/uiState";
import codeToCountry from 'Utils/country-codes';
import { useFormatNumber } from 'Utils/formatNumber';
import { objectArrayToObject } from "Utils/utils";
import Text from "Components/shared/Text";
import Tooltip from "Components/shared/Tooltip";
import FeeTooltip from "./FeeTooltip";

const FeesFooter = ({
  collectionMethod,
  country,
  currency,
  fees
}) => {
  const formatNumber = useFormatNumber();
  const {
    breakpoints: { md, lg }
  } = useContext(UiContext);

  const rowData = [
    {
      type: "Country",
      label: `${i18nextTranslate(i18nextKeys.commonFeeCountry)} (${
        i18nextTranslate(codeToCountry[country])
      }):`,
      description: i18nextTranslate(i18nextKeys.checkoutFeeCountryDescription),
      dataQa: "country"
    },
    {
      type: "CollectionMethod",
      label: `${i18nextTranslate(i18nextKeys.checkoutFeeMethod)} (${
        CollectMethod.enumToFriendlyString(collectionMethod)
      }):`,
      description: i18nextTranslate(i18nextKeys.checkoutFeeMethodDescription),
      dataQa: "collection"
    },
    {
      type: "total",
      label: i18nextTranslate(i18nextKeys.checkoutFeeTotal),
      dataQa: "total"
    }
  ];

  const feesObj = useMemo(() => objectArrayToObject(fees, "Type"), [fees]);

  return (
    <tfoot
      className="bg-4_15"
      data-qa="fees"
    >
      {rowData.map(({ type, label, description, dataQa }, index) => (
        <tr key={`fee-${type}`}>
          <td
            colSpan={lg ? 3 : md ? 2 : 1}
            className={`pl-12 ${!index ? "pt-8" : "pt-2"} ${
              index === rowData.length - 1 ? "pb-8" : "pb-2"
            }`}
          >
            <Text
              textStyle="text-small-bold"
              dataQa={`fee-${dataQa}-label`}
            >
              {label}
            </Text>
          </td>
          <td className={`text-sm ${
            !index ? "pt-8" : "pt-2"} ${index === rowData.length - 1 ? "pb-8" : "pb-2"
          } pr-12 md:pr-0`}
            colSpan={md ? 1 : 2}
          >
            <div
              className={`flex justify-end items-center gap-8 ${
                type === "total" ? "pr-20 xxl:pr-22" : ""
              }`}
            >
              <Text
                textStyle="text-small"
                dataQa={`fee-${dataQa}-amount`}
              >
                {formatNumber(feesObj[type].TotalAmount)} {currency}
              </Text>
              {type !== "total" && (
                <Tooltip
                  text={
                    <FeeTooltip
                      description={description}
                      percentageText={i18nextTranslateDynamically(
                        i18nextKeys.commonFeePercentage,
                        { percentage: formatNumber(feesObj[type].VariableRate) }
                      )}
                      units={currency}
                      fee={feesObj[type]}
                    />
                  }
                  width="400px"
                  dataQa={`fee-${dataQa}`}
                  useIcon
                />
              )}
            </div>
          </td>
          {md && (
            <td colSpan="2" />
          )}
        </tr>
      ))}
    </tfoot>
  );
};

export default FeesFooter;
