import React, { useContext } from "react";
import { UiContext } from "States/ui/uiState";
import Tooltip from "Components/shared/Tooltip";
import Text from "../Text";
import Error from "./Error";

const FormField = ({
  className = "",
  meta: { active, touched, error },
  valid,
  input,
  placeholder,
  label,
  tooltipText,
  disabled,
  type = "text",
  dataQa = "field",
  multiline,
  height,
  style,
  containerStyle = {},
  maxLength,
  required = false,
  showNumberOfChars = false,
  showErrors = true
}) => {
  const {
    breakpoints: { xxl },
  } = useContext(UiContext);

  const elementProps = {
    ...input,
    disabled,
    placeholder,
    className: `appearance-none block w-full py-8 
      text-gray-700
      focus:outline-none color-10
      ${valid ? "border-green-500" : ""}
      ${touched && error
        ? 'bg-lightRed'
        : disabled
          ? 'bg-9--10'
          : active
            ? 'bg-9-10 border-color-5 border'
            : 'bg-9'
      }
      border rounded`,
    style: {
      paddingRight: "10px",
      paddingLeft: "10px",
      height: height ?? xxl ? '38px' : '35px',
      ...style
    }
  };
  const textAreaStyles = {
    resize: "none"
  };
  const inputElement = (
    <input
      id={dataQa}
      type={type}
      data-qa={`${dataQa}-input`}
      {...elementProps}
    />
  );

  const textAreaElement = (
    <textarea
      id={dataQa}
      {...elementProps}
      style={{
        ...elementProps.style,
        ...textAreaStyles,
        height: height ?? "8.938rem"
      }}
      className={`${elementProps.className} text-sm`}
      data-qa={`${dataQa}-input`}
    />
  );

  return (
    <div
      className={`${className}`}
      style={containerStyle}
    >
      {!!label && (
        <div className="flex gap-6 items-center mb-4">
          {tooltipText ? (
            <Tooltip
              text={tooltipText}
              dataQa={dataQa}
              useIcon
            />
          ) : null}
          <Text
            textStyle="h3"
            className="truncate"
            dataQa={`${dataQa}-input-label`}
          >
              
            <label htmlFor={dataQa}>
              {label}{required ? " *" : null}
            </label>
          </Text>
        </div>
      )}
      {multiline ? textAreaElement : inputElement}
      <div className="flex place-content-between">
        {showErrors && (
          <Error
            error={(active || touched) && error}
            dataQa={`${dataQa}-input-error`}
          />
        )}
        <div>
          {maxLength && (
            <p
              className={`mt-2 text-xs ${
                (active || touched) && error ? 'color-red' : ''
              }`}
            >
              {showNumberOfChars && `${input.value.length} / ${maxLength}`}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default FormField;
