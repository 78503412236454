import { PaymentMethod } from "Enums";
import { combineValidators, composeValidators } from "revalidate";
import { i18nextKeys } from "Lang/i18nextKeys";
import i18nextTranslateDynamically from "Lang/i18nextTranslateDynamically";
import { isFieldOneOf, isFieldRequired, isRequiredArray } from "Utils/validators";
import customTermsArrayValidator from "./customTermsValidator";

const paymentMethodValidator = (defaultLanguage) => (method) => combineValidators({
  type: composeValidators(
    isFieldRequired,
    isFieldOneOf([PaymentMethod.values.BankTransfer])('type')
  )('type'),
  customTerms: composeValidators(
    isRequiredArray("customTerms"),
    customTermsArrayValidator(defaultLanguage)
  )("customTerms")
})(method);

const paymentMethodArrayValidator = (defaultLanguage) => ({ field }, methods) => {
  if (methods.length) {
    return methods.map(paymentMethodValidator(defaultLanguage));
  } else {
    return i18nextTranslateDynamically(i18nextKeys.helperValidatorArrayEmpty, { field });
  }
};

const checkoutConfigValidator = (defaultLanguage) => ({
  paymentMethods: composeValidators(
    isRequiredArray("paymentMethods"),
    paymentMethodArrayValidator(defaultLanguage)
  )("paymentMethods")
});

export default checkoutConfigValidator;
