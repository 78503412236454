import { useContext } from 'react';
import { useMutation, useQuery } from 'react-query';
import api from 'Api';
import { EnvContext } from 'States/env/envState';
import queryClients from 'Helpers/queryClients';
import useHandleError from 'Utils/handleError';

const CONFIG_SETTINGS_QUERY_KEY = 'configSettingsQuery';

const useConfigSettingsQuery = ({
  queryKeys = [],
  queryFnArgs = [],
  onError = () => {},
  ...config
}) => {
  const { env } = useContext(EnvContext);
  const handleError = useHandleError();
  return useQuery({
    queryKey: [CONFIG_SETTINGS_QUERY_KEY, ...queryKeys],
    queryFn: () => api.Config.Settings.get(env?.TenantId),
    refetchOnWindowFocus: false,
    staleTime: 10000,
    retry: false,
    placeholderData: {
      Languages: [],
      RestrictedMode: false
    },
    onError: (error) => {
      onError(error);
      handleError({ error });
    },
    ...config,
  });
};

const CONFIG_SETTINGS_MUTATION_KEY = 'configSettingsMutation';

const useConfigSettingsMutation = ({
  mutationKeys = [],
  onError = () => {},
  ...config
}) => {
  const handleError = useHandleError();
  return useMutation({
    mutationKey: [CONFIG_SETTINGS_MUTATION_KEY, ...mutationKeys],
    mutationFn: ({ data }) => api.Config.Settings.patch(data),
    onMutate: async () => {
      await queryClients.rootQueryClient.cancelQueries(
        CONFIG_SETTINGS_QUERY_KEY
      );
    },
    onSuccess: () => {
      queryClients.rootQueryClient.invalidateQueries(
        CONFIG_SETTINGS_QUERY_KEY
      );
    },
    onError: (error) => {
      onError(error);
      handleError({ error })
    },
    ...config,
  });
};

const useConfigSettings = {
  query: useConfigSettingsQuery,
  mutation: useConfigSettingsMutation,
};

export default useConfigSettings;
