import React, { useEffect, useState } from 'react';
import Big from 'big.js';
import { useFormatNumber } from 'Utils/formatNumber';
import { i18nextKeys } from 'Lang/i18nextKeys';
import i18nextTranslate from 'Lang/i18nextTranslate';
import i18nextTranslateDynamically from "Lang/i18nextTranslateDynamically";
import Text from 'Components/shared/Text';
import Tooltip from "Components/shared/Tooltip";
import FeeTooltip from 'Features/Cart/FeeTooltip';

const FeeBlock = ({
  calculation,
  redeemUnits,
  payoutUnits,
  className = "",
  style = {},
  dataQa = "pay"
}) => {
  const formatNumber = useFormatNumber();
  const [totalFeesInAssetUnits, setTotalFeesInAssetUnits] = useState(0);
  const [totalFeesInPayoutUnits, setTotalFeesInPayoutUnits] = useState(0);

  useEffect(() => {
    const calculateTotalFees = () => {
      const { Issuer, Method, Country } = calculation.Fees;
      const totalFeesInAssetUnits = Big(Issuer.TotalAmount.AssetUnitsString)
        .plus(Method.TotalAmount.AssetUnitsString)
        .plus(Country.TotalAmount.AssetUnitsString)
        .toString();
      const totalFeesInPayoutUnits = Big(Issuer.TotalAmount.PayoutUnitsString)
        .plus(Method.TotalAmount.PayoutUnitsString)
        .plus(Country.TotalAmount.PayoutUnitsString)
        .toString();
      setTotalFeesInAssetUnits(totalFeesInAssetUnits);
      setTotalFeesInPayoutUnits(totalFeesInPayoutUnits);
    };
    if (calculation?.Fees) {
      calculateTotalFees();
    }
  }, [calculation]);

  const feesData = [
    {
      type: "Issuer",
      label: i18nextTranslate(i18nextKeys.redemptionFeeIssuer),
      description: i18nextTranslate(i18nextKeys.redemptionFeeIssuerDescription)
    },
    {
      type: "Method",
      label: i18nextTranslate(i18nextKeys.commonFeeMethod),
      description: i18nextTranslate(i18nextKeys.redemptionFeeMethodDescription)
    },
    {
      type: "Country",
      label: i18nextTranslate(i18nextKeys.commonFeeCountry),
      description: i18nextTranslate(i18nextKeys.redemptionFeeCountryDescription)
    }
  ];

  return (
    <div
      className={`flex flex-col gap-8 ${className}`}
      style={style}
      data-qa={dataQa}
    >
      <div
        className="flex justify-between gap-12"
        data-qa={`${dataQa}-net`}
      >
        <Text
          textStyle='p2'
          dataQa={`${dataQa}-net-label`}
        >
          {i18nextTranslate(i18nextKeys.redemptionPayReceiveNetAmount)}
        </Text>
        <Text
          textStyle='p2'
          dataQa={`${dataQa}-net-value`}
        >
          {`${formatNumber(calculation.NetAmount.AssetUnitsString)} ${redeemUnits}`}
        </Text>
      </div>
      <div
        className="flex justify-between gap-12"
        data-qa={`${dataQa}-fees-total`}
      >
        <Text
          textStyle='p2'
          dataQa={`${dataQa}-fees-total-label`}
        >
          {i18nextTranslate(i18nextKeys.redemptionPayReceiveFees)}
        </Text>
        <Text
          textStyle='p2'
          dataQa={`${dataQa}-fees-total-value`}
        >
        {`${formatNumber(totalFeesInAssetUnits)} ${redeemUnits} (${
            formatNumber(totalFeesInPayoutUnits)
          } ${payoutUnits})`}
        </Text>
      </div>
      <div className="flex flex-col gap-4">
        {feesData.map(({ type, label, description }) => {
          const dataQa = type.toLowerCase();
          return (
            <div
              key={`fee-${type}`}
              className="flex justify-between gap-12"
            >
              <Text
                textStyle="text-small"
                dataQa={`fee-${dataQa}-label`}
              >
                {label}
              </Text>
              <div className="flex items-center gap-8">
                <Text
                  textStyle="text-small"
                  dataQa={`fee-${dataQa}-value`}
                >
                  {`${formatNumber(calculation.Fees[type]?.TotalAmount.AssetUnitsString)} ${redeemUnits} (${
                    formatNumber(calculation.Fees[type]?.TotalAmount.PayoutUnitsString)
                  } ${payoutUnits})`}
                </Text>
                <Tooltip
                  text={
                    <FeeTooltip
                      description={description}
                      percentageText={i18nextTranslateDynamically(
                        i18nextKeys.redemptionFeePercentage,
                        { percentage: formatNumber(calculation.Fees[type]?.VariableRate) }
                      )}
                      units={redeemUnits}
                      altUnits={payoutUnits}
                      fee={{
                        FixedAmount: calculation.Fees[type]?.FixedAmount.AssetUnitsString,
                        VariableAmount: calculation.Fees[type]?.VariableAmount.AssetUnitsString,
                        TotalAmount: calculation.Fees[type]?.TotalAmount.AssetUnitsString
                      }}
                      altFee={{
                        FixedAmount: calculation.Fees[type]?.FixedAmount.PayoutUnitsString,
                        VariableAmount: calculation.Fees[type]?.VariableAmount.PayoutUnitsString,
                        TotalAmount: calculation.Fees[type]?.TotalAmount.PayoutUnitsString
                      }}
                      dataQa={dataQa}
                    />
                  }
                  width="400px"
                  dataQa={`fee-${dataQa}`}
                  useIcon
                />
              </div>
            </div>
          );
        })}
      </div>

      <hr className="border-color-5" />
      <div
        className="flex justify-between gap-12"
        data-qa={`${dataQa}-total`}
      >
        <Text
          textStyle='p2'
          dataQa={`${dataQa}-total-label`}
        >
          {i18nextTranslate(i18nextKeys.redemptionPayReceiveTotalAmount)}
        </Text>
        <Text
          textStyle='p2'
          dataQa={`${dataQa}-total-value`}
        >
          {`${formatNumber(calculation.GrossAmount.AssetUnitsString)} ${redeemUnits}`}
        </Text>
      </div>
    </div>
  );
};

export default FeeBlock;
