const STORAGE_KEYS = {
  allowedAsset: 'allowedAsset',
  allowedRedemption: 'allowedRedemption',
  authReturnPath: 'authReturnPath',
  order: 'order',
  redemptionForm: 'redemptionForm',
  emailResendTimeout: 'emailResendTimeout'
};

export default STORAGE_KEYS;
