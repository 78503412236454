import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useAmplitude } from 'react-amplitude-hooks';
import { DefaultCard } from 'Components/shared/cards';
import InfoModal from 'Components/shared/Modal/InfoModal';
import { EnvContext } from 'States/env/envState';
import { ConfigContext } from 'States/config/configState';
import { LangContext } from 'States/lang/langState';
import { isValidUrl } from 'Utils/utils';
import { useFormatNumber } from 'Utils/formatNumber';
import { i18nextKeys } from 'Lang/i18nextKeys';
import i18nextTranslateDynamically from 'Lang/i18nextTranslateDynamically';
import { getTranslation } from 'Helpers/translations';
import useConfigSettings from 'Hooks/useConfigSettings';
import usePurchaseMethods from 'Hooks/usePurchaseMethods';
import { PRICE_SOURCE } from 'Enums';
import AssetCardButtonGroup from './AssetCardButtonGroup';
import AssetModal from './AssetModal';

const AssetCard = ({ asset, dataQa }) => {
  const { logEvent } = useAmplitude();
  const formatNumber = useFormatNumber();
  const { env } = useContext(EnvContext);
  const {
    config: { kycTierFunctionalRestrictions }
  } = useContext(ConfigContext);
  const { lang } = useContext(LangContext);
  const [showAssetModal, setShowAssetModal] = useState(null);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const timerInterval = useRef(-1);
  const secondsOpen = useRef(0);

  const onFocus = () => {
    if (timerInterval.current !== -1) {
      logEvent(
        'Purchase details checked',
        { useful: secondsOpen.current >= 3 }
      );
      clearInterval(timerInterval.current);
      timerInterval.current = -1;
      secondsOpen.current = 0;
    }
  };

  // TODO: secondsOpen does not properly get read if eventListener isn't refreshed at every change.
  // Maybe there is a better way to send the correct seconds without reloading eventListener
  useEffect(() => {
    window.addEventListener('focus', onFocus);
    return () => {
      window.removeEventListener('focus', onFocus);
    };
  }, []);

  const {
    data: {
      currencyCode,
      defaultLanguage
    }
  } = useConfigSettings.query({
    select: useCallback(
      ({ Currency, Languages }) => {
        const defaultLanguage = Languages.find(({ IsDefault }) => IsDefault);
        return {
          currencyCode: Currency.Code,
          defaultLanguage
        }
      }, []
    )
  });

  const { data: currentPrice } = usePurchaseMethods.methodPollingQuery({
    enabled: !!asset.PriceSource && asset.PriceSource !== PRICE_SOURCE.none && !!asset.PriceUpdatedOn,
    id: asset.Id
  });

  const requiredTier = Math.max(
    kycTierFunctionalRestrictions?.availability?.purchase || 0,
    asset.KycTier || 0
  );

  const translatedName = useMemo(() => {
    const name = getTranslation("Name", lang, defaultLanguage.Code, asset.Translations) || asset.Name;
    if (!asset.Ticker) {
      return name;
    }
    const ticker = getTranslation("Ticker", lang, defaultLanguage.Code, asset.Translations) || asset.Ticker;
    return `${name} (${ticker || asset.Ticker})`;
  }, [lang]);

  const translatedDescription = useMemo(() => getTranslation(
    "Description", lang, defaultLanguage.Code, asset.Translations
  ) || asset.Description, [lang]);

  const openAssetInfo = useCallback(() => {
    if (isValidUrl(translatedDescription)) {
      const timer = setInterval(
        () => secondsOpen.current = secondsOpen.current + 1,
        1000
      );
      timerInterval.current = timer;
      window.open(translatedDescription, '_blank', 'noopener', 'noreferrer');
    } else {
      setShowInfoModal(true);
      logEvent('Purchase details checked');
    }
  }, []);

  const closeInfoModal = () => {
    setShowInfoModal(false);
  };

  const openAssetModal = useCallback(() => {
    setShowAssetModal(true);
  }, []);

  return (
    <>
      <DefaultCard
        dataQa={dataQa}
        name={translatedName}
        iconUrl={`${env.BlobUrl}/${asset.Icon}`}
        description={i18nextTranslateDynamically(
          i18nextKeys.purchaseAssetCardPriceTag,
          {
            price: formatNumber(currentPrice || asset.Price),
            currency: currencyCode,
          }
        )}
        buttonGroup={() =>
          AssetCardButtonGroup({
            openAssetInfo,
            openAssetModal,
            kycTier: requiredTier,
          })
        }
      />
      {showAssetModal && (
        <AssetModal
          show={showAssetModal}
          close={() => setShowAssetModal(false)}
          asset={{
            ...asset,
            Name: translatedName,
            Description: translatedDescription
          }}
        />
      )}
      {showInfoModal && (
        <InfoModal
          show={showInfoModal}
          close={closeInfoModal}
          content={{
            title: translatedName,
            description: translatedDescription,
            icon: `${env.BlobUrl}/${asset.Icon}`
          }}
          dataQa="checkout-collectionMethod-infoModal"
        />
      )}
    </>
  );
};

export default AssetCard;
