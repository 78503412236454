import React, { useContext, useState, useRef } from 'react';
import { usePopper } from 'react-popper';
import { UiContext } from 'States/ui/uiState';
import DropdownItem from './DropdownItem';
import './Dropdown.css';

const Dropdown = ({
  options,
  optionSelected = () => { },
  icon: FilterIcon,
  dataQa = "column"
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const {
    breakpoints: { xxl }
  } = useContext(UiContext);

  const triggerRef = useRef(null);
  const dropdownRef = useRef(null);
  const { styles, attributes } = usePopper(
    triggerRef.current,
    dropdownRef.current,
    {
      strategy: 'fixed',
      placement: 'bottom-end',
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 12],
          },
        }
      ],
    }
  );

  return (
    <>
      <div
        data-qa={`${dataQa}-filter-overlay`}
        onClick={(e) => {
          e.stopPropagation();
          setDropdownOpen(false);
        }}
        className={`${dropdownOpen ? 'visible' : 'invisible'
          } absolute top-0 left-0 w-full h-full`}
      ></div>
      <div className="relative">
        <FilterIcon
          reference={triggerRef}
          onClick={(e) => {
            e.stopPropagation();
            setDropdownOpen(!dropdownOpen);
          }}
          size={xxl ? "16" : "14"}
          className="cursor-pointer"
          data-qa={`${dataQa}-filter-button`}
        />
        <div
          ref={dropdownRef}
          data-qa={`${dataQa}-filter-dropdown`}
          className={`dropdown ${dropdownOpen ? 'block' : 'hidden'
            } bg-white rounded-md text-black w-max z-50`}
          style={styles.popper}
          {...attributes.popper}
        >
          {options.map((option) => {
            return (
              <DropdownItem
                key={option}
                onClick={(option) => {
                  optionSelected(option);
                  setDropdownOpen(false);
                }}
                name={option}
              />
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Dropdown;
