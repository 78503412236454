import React, { useReducer, createContext } from 'react';
import axios from 'axios';
import envReducer from './envReducer';
import { LOAD_ENV_SUCCESS, ENV_ERROR } from './envTypes';
import envConf from '../../Utils/getEnvironmentConfig';
import getDiscoveryServiceEndpoint from 'Utils/getDiscoveryServiceEndpoint';

const instance = axios.create();
instance.defaults.headers.common = {};

export const EnvContext = createContext();

const EnvState = (props) => {
  const initialState = {
    env: null,
    loading: true,
    error: null,
  };

  const [state, dispatch] = useReducer(envReducer, initialState);

  const loadEnv = async () => {
    try {
      if (process.env.NODE_ENV === 'development') {
        // local instance doesn't use discovery service but instead env.dev.json
        const { data } = await instance.get(envConf);
        dispatch({
          type: LOAD_ENV_SUCCESS,
          payload: { env: data },
        });
      } else {
        const {
          data: { DISCOVERY_API_HOST },
        } = await instance.get(envConf);
        const { data } = await instance.get(
          getDiscoveryServiceEndpoint(DISCOVERY_API_HOST)
        );
        dispatch({
          type: LOAD_ENV_SUCCESS,
          payload: { env: data },
        });
      }
    } catch (err) {
      dispatch({ type: ENV_ERROR });
      throw err;
    }
  };

  return (
    <EnvContext.Provider
      value={{
        env: state.env,
        loading: state.loading,
        error: state.error,
        loadEnv,
      }}
    >
      {props.children}
    </EnvContext.Provider>
  );
};

export default EnvState;
