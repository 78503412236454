import { SET_SIDEBAR } from './uiTypes';

const uiReducer = (state, action) => {
  switch (action.type) {
    default:
      return state;

    case SET_SIDEBAR: {
      return {
        ...state,
        smallScreenSidebarShowing: action.payload.value,
      };
    }
  }
};

export default uiReducer;
