import React from 'react';
import DefaultCardKycButtonGroup from 'Components/shared/cards/DefaultCardKycButtonGroup';
import { i18nextKeys } from 'Lang/i18nextKeys';

const AssetCardButtonGroup = ({
  openAssetInfo,
  openAssetModal,
  kycTier
}) => (
  <DefaultCardKycButtonGroup
    buttonConfig={{
      topButton: {
        show: true,
        label: {
          insufficientTier: i18nextKeys.buttonMoreDetails,
          sufficientTier: i18nextKeys.buttonMoreDetails
        },
        onClick: openAssetInfo
      },
      bottomButton: {
        show: true,
        label: {
          insufficientTier: i18nextKeys.kycGetTierToBuy,
          sufficientTier: i18nextKeys.buttonAddToCart
        },
        onClick: openAssetModal,
        origin: "asset tile"
      }
    }}
    requiredTier={kycTier}
  />
);

export default AssetCardButtonGroup;
