import React from 'react';

const NavigationStep = ({ text, index, active, refs }) => {
  return (
    <li
      className={active ? 'active' : 'opacity-50'}
      style={{ minWidth: 'max-content' }}
      ref={refs[text]}
      data-qa={`navigation-step-${index + 1}${active ? "-active" : ""}`}
    >
      <div
        id={`bradcrumb-${index}`}
        className={`${active ? 'bg-4_15' : 'bg-2'}
          color-4 text-sm font-semibold
          border-color-4 border
          cursor-default rounded-full h-32 w-32
          inline-flex items-center justify-center`
        }
        data-qa={`navigation-step-${index + 1}-number`}
      >
        {index + 1}
      </div>
      <span
        className={`${active ? 'color-4' : 'color-8'} text-sm pl-8 cursor-default`}
        data-qa={`navigation-step-${index + 1}-text`}
      >
        {text}
      </span>
    </li>
  );
};

export default NavigationStep;
