import React, { useState, useEffect, useContext } from 'react';
import api from 'Api';
import useHandleError from 'Utils/handleError.js';
import { ConfigContext } from 'States/config/configState';
import useConfigSettings from 'Hooks/useConfigSettings.js';
import useFeatureAvailability from "Hooks/useFeatureAvailability";
import LoadingSpinner from 'Components/shared/LoadingSpinner';
import { validate, getValidationMessages } from './jsonConfigValidation.js';
import Header from 'Components/shared/Header';
import { Button } from 'Components/shared/buttons';
import i18nextTranslate from 'Lang/i18nextTranslate';
import { i18nextKeys } from 'Lang/i18nextKeys';

// Order of imports matters here, see https://github.com/securingsincity/react-ace/issues/725#issuecomment-543109155
import 'ace-builds';
import AceEditor from 'react-ace';
import ace from 'ace-builds/src-noconflict/ace';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-solarized_dark';
import 'ace-builds/src-noconflict/ext-language_tools';
import 'ace-builds/src-noconflict/ext-beautify';
import 'ace-builds/src-noconflict/ext-searchbox';
import jsonWorkerUrl from "ace-builds/src-noconflict/worker-json";
ace.config.setModuleUrl('ace/mode/json_worker', jsonWorkerUrl);

const JsonConfigManagement = () => {
  const [validationError, setValidationError] = useState(null);
  const [storedConfig, setStoredConfig] = useState(null);
  const [allowConfigSave, setAllowConfigSave] = useState(false);
  const [allowValidation, setAllowValidation] = useState(false);
  const { setConfig: setCurrentlyLoadedConfig } = useContext(ConfigContext);
  const [loading, setLoading] = useState(false);
  const handleError = useHandleError();

  const {
    isLoading: loadingFeatures,
    data: features
  } = useFeatureAvailability.query({
    onError: (error) => {
      handleError({ error });
    }
  });

  const {
    isLoading: loadingSettings,
    data: settings
  } = useConfigSettings.query({});

  useEffect(() => {
    const getStoredConfig = async () => {
      try {
        const configBE = await api.Config.getFullConfig();
        const configJsonString = JSON.stringify(configBE, undefined, 2);
        setStoredConfig(configJsonString);
      } catch (error) {
        const message = i18nextTranslate(
          i18nextKeys.errorConfigManagementCouldNotParseLoadConfig
        );
        handleError({ error, message });
      }
    };
    getStoredConfig();
  }, []);

  const onConfigChange = (configString) => {
    setStoredConfig(configString);
    setAllowConfigSave(false);
    setAllowValidation(true);
  };

  const validateConfig = (configString) => {
    let config;
    try {
      config = JSON.parse(configString);
    } catch (err) {
      setValidationError({
        validationMessages: [
          i18nextTranslate(i18nextKeys.errorConfigManagementInvalidJson),
        ],
      });
      return false;
    }

    let validationResult = validate(config, features, settings);
    if (Object.keys(validationResult).length) {
      const validationMessages = getValidationMessages(validationResult);
      if (validationMessages.length) {
        setValidationError({
          validationMessages,
        });
        return false;
      } else {
        setAllowConfigSave(true);
        setValidationError(null);
        return true;
      }
    }
  };

  const handleButtonClick = async () => {
    setAllowValidation(false);
    if (!allowConfigSave) {
      if (validateConfig(storedConfig)) {
        setAllowValidation(true);
      }
    } else {
      setLoading(true);
      try {
        await api.Config.saveConfig(storedConfig);
        setCurrentlyLoadedConfig(JSON.parse(storedConfig));
      } catch (error) {
        const message = i18nextTranslate(
          i18nextKeys.errorConfigManagementSaveConfigFail
        );
        handleError({ error, message });
      } finally {
        setLoading(false);
        setAllowConfigSave(false);
      }
    }
  };

  return (
    <div>
      <Header text={i18nextTranslate(i18nextKeys.configManagementHeader)} />
      <div className="flex flex-col items-center">
        {storedConfig !== null ? (
          <div className='flex flex-col items-center gap-24 w-full'>
            <Button
              text={
                allowConfigSave
                  ? i18nextTranslate(i18nextKeys.buttonSave)
                  : i18nextTranslate(i18nextKeys.buttonValidate)
              }
              onClick={handleButtonClick}
              loading={loading || loadingFeatures || loadingSettings}
              disabled={!allowValidation}
              width={{
                xxl: '190px',
                md: '170px',
                default: '140px'
              }}
            />
            <div style={{ maxWidth: '1200px', minWidth: '80%' }}>
              {validationError && (
                <ul
                  className="mb-24 overflow-y-auto color-red"
                  style={{ maxHeight: '240px' }}
                >
                  {validationError.validationMessages.map(
                    (errorMessage, idx) => {
                      return <li key={idx}>- {errorMessage}</li>;
                    }
                  )}
                </ul>
              )}

              <AceEditor
                placeholder={i18nextTranslate(
                  i18nextKeys.configManagementPlaceholder
                )}
                mode="json"
                theme="solarized_dark"
                name="ConfigEditor"
                onChange={onConfigChange}
                fontSize={16}
                debounceChangePeriod={300}
                width={'100%'}
                showPrintMargin={false}
                showGutter={true}
                highlightActiveLine={true}
                value={storedConfig}
                setOptions={{
                  enableBasicAutocompletion: false,
                  enableLiveAutocompletion: false,
                  enableSnippets: false,
                  showLineNumbers: true,
                  tabSize: 2,
                }}
              />
            </div>
          </div>
        ) : (
          <LoadingSpinner />
        )}
      </div>
    </div>
  );
};

export default JsonConfigManagement;
