import { LOAD_ENV_SUCCESS, ENV_ERROR } from './envTypes';

const envReducer = (state, action) => {
  switch (action.type) {
    default:
      return state;

    case LOAD_ENV_SUCCESS: {
      return {
        ...state,
        env: action.payload.env,
        error: null,
        loading: false,
      };
    }

    case ENV_ERROR: {
      return {
        ...state,
        error: true,
        loading: false,
        env: {},
      };
    }
  }
};

export default envReducer;
