import React, { useContext, useEffect, useState } from "react";
import preval from 'preval.macro';
import api from "Api";
import { i18nextKeys } from "Lang/i18nextKeys";
import i18nextTranslate from "Lang/i18nextTranslate";
import useHandleError from "Utils/handleError";
import { copyToClipboardAndNotify } from "Utils/utils";
import { LangContext } from "States/lang/langState";
import { Button, TextButton } from "Components/shared/buttons";
import SimplePage from "Components/shared/SimplePage";
import { BuildIcon, CopyIcon, LinkIcon } from 'Components/shared/symbols';

const About = () => {
  const handleError = useHandleError();
  const [links, setLinks] = useState({
    TermsAndConditions: null,
    DataPolicy: null
  });
  const [loading, setLoading] = useState(true);
  const [versions, setVersions] = useState(null);
  const { lang } = useContext(LangContext);

  const copy = () => copyToClipboardAndNotify(
    versions,
    null,
    i18nextTranslate(i18nextKeys.aboutCopyNotification)
  );

  useEffect(() => {
    const getLinks = async () => {
      try {
        const {
          TermsAndConditions,
          DataPolicy,
          Success,
          ErrorMessage
        } = await api.User.getLegalLinks();
        if (!Success) {
          throw ErrorMessage;
        } else {
          setLinks({ TermsAndConditions, DataPolicy })
        }
      } catch (error) {
        handleError({ error });
      }
    };
    const getVersions = async () => {
      const wlpFE = [
        {
          Name: 'Build version',
          Version: process.env.REACT_APP_VERSION,
        },
        {
          Name: 'Build date',
          Version: preval`module.exports = new Date().toLocaleString();`,
        }
      ];
      try {
        const {
          Name: backendName,
          Version: backendVersion,
          Dependencies: backendDependencies
        } = await api.DebugInfo.getWlpBeVersion();
        const versionsArray = [
          { Name: "App language", Version: lang},
          { Name: "Preferred language", Version: navigator.language},
          { Name: "User agent", Version: navigator.userAgent},
          ...wlpFE,
          { Name: backendName, Version: backendVersion },
          ...backendDependencies
        ];
        let versionsStr = "";
        for (const [index, item] of versionsArray.entries()) {
          versionsStr = versionsStr.concat(
            index ? "\n" : "",
            `${item.Name.replaceAll(".", " ")}: ${item.Version}`
          );
        }
        setVersions(versionsStr);
      } catch (error) {
        handleError({ error });
      } finally {
        setLoading(false);
      }
    };
    getLinks();
    getVersions();
  }, []);

  const headerLinks = (
    <div className="flex gap-40 lg:gap-48">
      <TextButton
        text={i18nextTranslate(i18nextKeys.aboutTerms)}
        icon={LinkIcon}
        onClick={() => {window.open(links.TermsAndConditions)}}
        disabled={!links.TermsAndConditions}
        dataQa="terms-button"
      />
      <TextButton
        text={i18nextTranslate(i18nextKeys.aboutDp)}
        icon={LinkIcon}
        onClick={() => {window.open(links.DataPolicy)}}
        disabled={!links.DataPolicy}
        dataQa="dp-button"
      />
    </div>
  );

  return (
    <SimplePage
      header={i18nextTranslate(i18nextKeys.about)}
      headerChildren={headerLinks}
      icon={BuildIcon}
      title={i18nextTranslate(i18nextKeys.aboutTitle)}
      dataQa="about"
    >
      <Button
        dataQa="button-copy"
        text={i18nextTranslate(i18nextKeys.aboutCopyButton)}
        icon={CopyIcon}
        onClick={copy}
        className="flex-row-reverse mt-8"
        width={{
          xxl: '320px',
          default: '240px'
        }}
        disabled={!versions}
        loading={loading}
      />
    </SimplePage>
  );
};

export default About;
