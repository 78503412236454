import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { i18nextKeys } from 'Lang/i18nextKeys';
import i18nextTranslate from 'Lang/i18nextTranslate';
import i18nextTranslateDynamically from 'Lang/i18nextTranslateDynamically';
import { useFormatNumber } from 'Utils/formatNumber';
import usePaymentMethods from 'Hooks/usePaymentMethods';
import { Button } from 'Components/shared/buttons';
import LoadingSpinner from 'Components/shared/LoadingSpinner';
import Warning from 'Components/shared/Warning';

const OrderFooter = ({
  totalAmount,
  currency,
  proceedButtonAction,
  pendingCartOperation,
  checkoutDisabled
}) => {
  const history = useHistory();
  const formatNumber = useFormatNumber();

  const {
    isLoading: loadingPaymentMethods,
    data: paymentLimit
  } = usePaymentMethods.methodsQuery({
    refetchOnWindowFocus: false,
    select: useCallback(
      ({ value: paymentMethods }) => {
        let maxLimit = null;
        for (const method of paymentMethods) {
          if (method.MaxAmount === null) {
            return null;
          }
          maxLimit = Math.max(maxLimit, method.MaxAmount);
        }
        return maxLimit;
      },
      []
    )
  });

  const isOverPaymentLimit = paymentLimit !== null && Number(totalAmount) > paymentLimit;

  const buttonWidth = {
    xxl: '190px',
    md: '170px',
    default: '156px'
  };

  return loadingPaymentMethods ? (
    <div className="flex justify-center">
      <LoadingSpinner />
    </div>
  ) : (
    <div className="flex flex-col gap-16 px-16 md:px-0">
      {isOverPaymentLimit && (
        <Warning
          visibleText={i18nextTranslateDynamically(
            i18nextKeys.checkoutOrderWarning,
            {
              limit: paymentLimit,
              currency
            }
          )}
        />
      )}
      <div
        data-qa="checkout-orderOverview"
        className="flex justify-between items-end gap-16 md:gap-32 xl:gap-40"
      >
        <div className="flex flex-col-reverse flex-grow md:flex-row md:items-center justify-between gap-24 md:gap-0">
          <Button
            dataQa="checkout-orderOverview-backButton"
            text={i18nextTranslate(i18nextKeys.buttonBack)}
            onClick={() => history.goBack()}
            width={buttonWidth}
            tertiary
          />
          <div className="flex flex-col md:flex-row gap-4 md:gap-16">
            <span className="text-xxsm md:text-sm font-bold uppercase">
              {i18nextTranslate(i18nextKeys.cartTotalAmount)}:
            </span>
            <span
              data-qa="checkout-orderOverview-totalAmount"
              className={`text-sm ${
                isOverPaymentLimit ? "color-red" : "color-8"
              }`}
            >
              {formatNumber(totalAmount)} {currency}
            </span>
          </div>
        </div>
        <Button
          dataQa="checkout-orderOverview-confirmButton"
          style={{ height: "min-content" }}
          text={i18nextTranslate(i18nextKeys.buttonConfirmAndContinue)}
          disabled={checkoutDisabled || isOverPaymentLimit}
          onClick={proceedButtonAction}
          loading={pendingCartOperation}
          width={buttonWidth}
        />
      </div>
    </div>
  );
};

export default OrderFooter;
