import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useAmplitude } from 'react-amplitude-hooks';
import api from 'Api';
import { KYC_PROVIDER } from 'Enums/KycProvider';
import Header from 'Components/shared/Header';
import { TextButton } from 'Components/shared/buttons';
import Fade from 'Components/shared/Fade';
import GlobalFilter from "Components/shared/Table/GlobalFilter";
import Table from 'Components/shared/Table';
import { dateSort } from 'Components/shared/Table/sorters';
import { SelectColumnFilter } from 'Components/shared/Table/Filter';
import { GearSymbol } from 'Components/shared/symbols';
import formatDate from 'Utils/formatDate';
import useHandleError from 'Utils/handleError';
import { getOdataOrderByString } from 'Utils/utils';
import { ConfigContext } from 'States/config/configState';
import { LangContext } from 'States/lang/langState';
import { UiContext } from 'States/ui/uiState';
import i18nextTranslate from 'Lang/i18nextTranslate';
import { i18nextKeys } from 'Lang/i18nextKeys';
import formatDateRange from 'Utils/formatDateRange';
import getVerificationState from 'Utils/getVerificationState';
import KycSettingsModal from './KycSettingsModal';

const KycManagementOverview = () => {
  const PAGE_SIZE = 20;
  const handleError = useHandleError();
  const {
    config: { kycTiersDescription }
  } = useContext(ConfigContext);
  const { lang } = useContext(LangContext);
  const {
    breakpoints: {
      md, xxl
    }
  } = useContext(UiContext);
  const [isLoading, setIsLoading] = useState(true);
  const [kycEntries, setKycEntries] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [globalFilter, setGlobalFilter] = useState("");
  const [settingsModalOpen, setSettingsModalOpen] = useState(false);
  const fetchIdRef = React.useRef(0);
  const { logEvent } = useAmplitude();

  const showKycSettings = kycTiersDescription[1]?.providers?.[0].name !== KYC_PROVIDER.manual;

  const fetchData = useCallback(
    (pageIndex, pageSize, [orderBy], [filter], globalFilter) => {
      const fetchId = ++fetchIdRef.current;
      setIsLoading(true);
      (async () => {
        try {
          // Abort if this is not the latest fetch
          if (fetchId !== fetchIdRef.current) {
            return;
          }
          const filterOdata = filter && `${filter.id} eq '${filter.value}'`;
          const globalFilterOdata = `contains(cast(UserId, Edm.String), '${globalFilter}') ` +
              `or contains(User/Email, '${globalFilter}') ` +
              `or contains(concat(concat(User/FirstName, ' '), User/LastName), '${globalFilter}')`;

          const res = await api.Kyc.list({
            orderBy: orderBy && getOdataOrderByString(orderBy),
            top: pageSize,
            skip: pageSize * pageIndex,
            filter:
              filter && globalFilter
                ? `${filterOdata} and (${globalFilterOdata})`
                : filter
                  ? filterOdata
                  : globalFilter
                    ? globalFilterOdata
                    : null,
          });

          const { value } = res;
          const totalCount = res['@odata.count'];

          setPageCount(Math.ceil(totalCount / PAGE_SIZE));
          setKycEntries(value);
          setIsLoading(false);
        } catch (error) {
          const message = i18nextTranslate(
            i18nextKeys.errorKycManagementOverviewLoadingError
          );
          handleError({ error, message });
        }
      })();
    },
    []
  );

  const toKycDetails = ({ original: { Id } }) => {
    window.open(`kyc/${Id}`);
  };

  const search = (filter) => {
    if (filter) {
      logEvent("KYC search", {
        parameter: filter
      });
    }
    setGlobalFilter(filter);
  };

  const columns = useMemo(
    () => [
      {
        Header: i18nextTranslate(i18nextKeys.kycManagementOverviewId),
        accessor: 'UserId',
        id: 'UserId',
        sortType: 'basic',
        color: 'color-4',
        width: xxl ? '118px' : '96px',
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: i18nextTranslate(i18nextKeys.kycManagementOverviewUser),
        accessor: ({ User }) =>
          User?.FirstName && User?.LastName
            ? `${User.FirstName} ${User.LastName}`
            : '-',
        id: 'User',
        sortType: 'basic',
        color: 'color-4',
        width: xxl ? '160px' : '112px',
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: i18nextTranslate(i18nextKeys.commonEmail),
        accessor: ({ User }) => User?.Email || '-',
        id: 'EmailAddress',
        sortType: 'basic',
        color: 'color-8',
        width: xxl ? '160px' : '137px',
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: i18nextTranslate(
          i18nextKeys.kycManagementOverviewOpenCompletionDate
        ),
        accessor: ({ OpenDate, CloseDate }) =>
          formatDateRange(OpenDate, CloseDate),
        id: 'OpenDate',
        sortType: (rowA, rowB) => dateSort(rowA, rowB, 'OpenDate'),
        color: 'color-8',
        width: '180px',
        disableFilters: true,
      },
      {
        Header: i18nextTranslate(i18nextKeys.kycManagementOverviewCaseStatus),
        id: 'Status',
        accessor: 'Status',
        sortType: 'basic',
        color: 'color-8',
        width: xxl ? '119px' : '88px',
        options: ['Open', 'Approved', 'Declined'],
        Filter: SelectColumnFilter,
        filter: 'equals',
        dataQa: "status",
        disableSortBy: true,
      },
      {
        Header: i18nextTranslate(
          i18nextKeys.kycManagementOverviewRequestStatus
        ),
        id: 'RequestStatus',
        accessor: ({ Verification }) => getVerificationState(Verification),
        sortType: 'basic',
        color: 'color-8',
        width: xxl ? '119px' : '85px',
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: i18nextTranslate(i18nextKeys.kycManagementOverviewUpdated),
        accessor: ({ Verification }) =>
          Verification?.UpdatedOn ? formatDate(Verification.UpdatedOn) : '-',
        id: 'Updated',
        sortType: 'basic',
        color: 'color-8',
        width: xxl ? '90px' : '82px',
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: i18nextTranslate(i18nextKeys.commonKycTier),
        accessor: 'RequestedTierNumber',
        id: 'RequestedTierNumber',
        sortType: 'basic',
        color: 'color-4',
        width: '40px',
        disableFilters: true,
        disableSortBy: true,
      },
    ],
    [lang]
  );

  return (
    <div data-qa="admin-kyc" className="select-none">
      <Fade show={true}>
        <Header
          text={i18nextTranslate(i18nextKeys.headerKycCases)}
          headerButton={
            showKycSettings ? (
              <TextButton
                text={i18nextTranslate(i18nextKeys.kycManagementStatusSettings)}
                icon={GearSymbol}
                onClick={() => setSettingsModalOpen(true)}
                className="self-center"
                dataQa="kyc-settings-open"
              />
            ) : null
          }
        >
        <GlobalFilter
          globalFilter={globalFilter}
          setGlobalFilter={search}
          placeholder={i18nextTranslate(i18nextKeys.commonSearchEmailUserId)}
          width={!md ? "100%" : null}
          dataQa="admin-kyc-search"
        />
        </Header>
        <div className="w-full flex flex-col items-center">
          <Table
            dataQa="admin-kyc"
            columns={columns}
            defaultSortBy="OpenDate"
            defaultFilters={[{ id: 'Status', value: 'Open' }]}
            defaultPageSize={PAGE_SIZE}
            pageCount={pageCount}
            fetchData={fetchData}
            isLoading={isLoading}
            data={kycEntries}
            getRowProps={(row) => ({
              onClick: () => toKycDetails(row),
            })}
            outerGlobalFilter={globalFilter}
            uppercaseHeader={false}
            evenRowsClassName="bg-3"
            featureCellBg="bg-4_3"
          />
        </div>
      </Fade>
      {showKycSettings && settingsModalOpen && (
        <KycSettingsModal
          show={settingsModalOpen}
          close={() => setSettingsModalOpen(false)}
        />
      )}
    </div>
  );
};

export default KycManagementOverview;
