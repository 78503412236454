import api from "Api";
import { useQuery } from "react-query";

const PAYMENT_METHODS_QUERY_KEY = 'paymentMethodsQuery';

const usePaymentMethodsQuery = (config) =>
  useQuery({
    queryKey: PAYMENT_METHODS_QUERY_KEY,
    queryFn: () => api.Config.PaymentMethods.getAll(),
    ...config
  });

const PAYMENT_METHOD_QUERY_KEY = 'paymentMethodQuery';

const usePaymentMethodQuery = (config) =>
  useQuery({
    queryKey: PAYMENT_METHOD_QUERY_KEY,
    queryFn: (type) => api.Config.PaymentMethods.get(type),
    ...config
  });

const usePaymentMethods = {
  methodsQuery: usePaymentMethodsQuery,
  methodQuery: usePaymentMethodQuery
};

export default usePaymentMethods;
