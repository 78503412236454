import React, { useContext, useEffect, useMemo } from "react";
import { generatePath, useHistory } from "react-router-dom";
import { ROUTES } from "Router/Routes";
import { i18nextKeys } from "Lang/i18nextKeys";
import i18nextTranslate from "Lang/i18nextTranslate";
import { UiContext } from "States/ui/uiState";
import { PAYMENT_PROVIDER } from "Enums";
import { PAYMENT_METHOD } from "Enums/PaymentMethod";
import Text from "Components/shared/Text";
import { getPaymentMethodIcon } from "Helpers/icons";
import MethodTile from "../MethodTile";

const PaymentMethods = ({
  paymentMethods,
  paymentProviders,
  defaultLanguage,
  disabled = false,
  isPurchaseEnabled = false,
  pristine = true,
  showNotification = () => {}
}) => {
  const history = useHistory();

  const {
    breakpoints: { xxl }
  } = useContext(UiContext);

  const getIcon = (methodType) => getPaymentMethodIcon({
    size: xxl ? "40" : "32",
    className: "color-4_50 flex-shrink-0",
    paymentMethod: methodType
  });

  const openPaymentMethod = (paymentMethod) => {
    const pathname = generatePath(
      ROUTES.admin.config.payment,
      { type: paymentMethod.Type }
    );
    history.push({
      pathname,
      state: {
        paymentMethod // Not used for now
      }
    });
  };

  useEffect(() => {
    if (!pristine && isSingleEnabledMethod) {
      showNotification();
    }
  }, [paymentMethods]);

  const isSingleEnabledMethod = useMemo(() => {
    if (!isPurchaseEnabled) {
      return false;
    }
    const enabledMethods = paymentMethods.filter(
      ({ IsEnabled }) => IsEnabled
    );
    return enabledMethods.length < 2;
  }, [paymentMethods, isPurchaseEnabled]);

  const isProviderConfigured = (paymentMethod) => {
    switch (paymentMethod.Type) {
      case PAYMENT_METHOD.BankTransfer: {
        const { Name, Account, BankName, BankAddress } = paymentMethod.BeneficiaryDetails;
        return !!(Name && Account && BankName && BankAddress);
      }
      case PAYMENT_METHOD.CreditCard: {
        const { ApiUrl, ApiSecret } = paymentProviders[PAYMENT_PROVIDER.Payrexx];
        return !!(ApiUrl && ApiSecret);
      }
      case PAYMENT_METHOD.CryptoCurrency: {
        const { ApiKey } = paymentProviders[PAYMENT_PROVIDER.Coinify];
        return !!ApiKey;
      }
      default: {
        return false;
      }
    }
  };

  const isDescriptionConfigured = ({ Translations }) => {
    const isDescriptionAvailable = Translations.some(
      ({ LanguageCode, Description }) =>
        LanguageCode === defaultLanguage.Code &&
        !!Description
    );
    return isDescriptionAvailable;
  };

  return (
    <>
      <Text
        textStyle="h2"
        className="mb-32"
        style={{
          scrollMargin: 60
        }}
        dataQa="paymentConfig-title"
        uppercase
      >
        {i18nextTranslate(i18nextKeys.purchaseConfigurationPaymentConfigurationsTitle)}
      </Text>
      <Text
        textStyle="p2"
        color="color-4"
        className="mb-12 xxl:mb-16"
        dataQa="paymentMethods-title"
      >
        {i18nextTranslate(i18nextKeys.configPaymentTitle)}
      </Text>
      <Text
        textStyle="p4"
        className="mb-32"
        style={{ maxWidth: xxl ? "700px" : "600px" }}
      >
        {i18nextTranslate(i18nextKeys.configPaymentDescription)}
      </Text>
      <div className="flex flex-col gap-16">
        {paymentMethods.map((paymentMethod, index) =>
          <MethodTile
            key={paymentMethod.Type}
            toggleName={`paymentMethods[${index}].IsEnabled`}
            translationKeyPrefix="configPayment"
            method={paymentMethod}
            openMethod={openPaymentMethod}
            getIcon={getIcon}
            contentWidth={xxl ? "700px" : "600px"}
            isSingleEnabledMethod={isSingleEnabledMethod}
            isConfigured={
              isDescriptionConfigured(paymentMethod) &&
              isProviderConfigured(paymentMethod)
            }
            disabled={disabled}
          />
        )}
      </div>
    </>
  );
};

export default PaymentMethods;
