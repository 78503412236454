import React from 'react';

const DownloadSymbol = ({
  size = "14",
  className,
  ...props
}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 14 14"
    fill="currentColor"
    className={className}
    {...props}
  >
    <path d="M12.5 11.8125C12.5 12.1922 12.1922 12.5 11.8125 12.5H2.1875C1.80781 12.5 1.5 12.1922 1.5 11.8125C1.5 11.4328 1.80781 11.125 2.1875 11.125H11.8125C12.1922 11.125 12.5 11.4328 12.5 11.8125ZM6.51387 9.63459C6.64813 9.76884 6.82404 9.83596 7 9.83596C7.17591 9.83596 7.35191 9.76882 7.48613 9.63459L9.92198 7.19875C10.1905 6.93026 10.1905 6.49497 9.92198 6.22648C9.65349 5.95799 9.2182 5.95799 8.94971 6.22648L7.6875 7.48868V2.1875C7.6875 1.80781 7.37969 1.5 7 1.5C6.62031 1.5 6.3125 1.80781 6.3125 2.1875V7.48868L5.05029 6.22648C4.7818 5.95799 4.34651 5.95799 4.07802 6.22648C3.80953 6.49497 3.80953 6.93026 4.07802 7.19875L6.51387 9.63459Z" />
  </svg>
);

export default DownloadSymbol;
