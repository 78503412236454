import React, { useEffect, useContext } from 'react';
import { UiContext } from 'States/ui/uiState';
import i18nextTranslate from 'Lang/i18nextTranslate';
import { i18nextKeys } from 'Lang/i18nextKeys';
import './modal.css';

const Modal = ({
  show,
  close,
  children,
  width = 'w-11/12',
  dataQa,
  modalContainerRef,
  shouldShowCloseButton = true,
}) => {

  const {
    breakpoints: { xxl },
  } = useContext(UiContext);

  useEffect(() => {
    const handleKeydown = (event) => {
      event = event || window.event;
      let isEscape = false;
      if ('key' in event) {
        isEscape = event.key === 'Escape' || event.key === 'Esc';
      } else {
        isEscape = event.keyCode === 27;
      }
      if (isEscape) {
        close();
      }
    };

    if (show) {
      window.addEventListener('keydown', handleKeydown);
    }
    return () => window.removeEventListener('keydown', handleKeydown);
    // eslint-disable-next-line
  }, [show]);

  return (
    <div
      className={`modal fixed w-full h-full top-0 left-0
                     ${!show ? 'opacity-0 pointer-events-none' : ''}
                    flex items-center justify-center z-50`}
    >
      <div
        onClick={close}
        className="modal-overlay absolute w-full h-full bg-2--80_60"
        data-qa={`${dataQa}-backdrop`}
      ></div>

      <div
        className={`modal-container bg-3 ${width} mx-auto rounded shadow-lg z-50 overflow-y-auto modalContent`}
        ref={modalContainerRef}
        style={{ maxWidth: xxl ? '872px' : '704px', maxHeight: '85vh' }}
        data-qa={dataQa}
      >
        {
          shouldShowCloseButton ? (
            <div
              onClick={close}
              className="modal-close absolute top-0 right-0 cursor-pointer flex flex-col items-center mt-16 mr-16 color-8 text-sm z-50"
              data-qa={`${dataQa}-close`}
            >
              <svg
                className="fill-current color-8"
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
              >
                <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
              </svg>
              <span className="text-sm">
                {i18nextTranslate(i18nextKeys.modalEscape)}
              </span>
            </div>
          ) : null
        }
        {children}
      </div>
    </div>
  );
};

export default Modal;
