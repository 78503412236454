import React, { useState } from 'react';
import i18nextTranslate from 'Lang/i18nextTranslate';
import { i18nextKeys } from 'Lang/i18nextKeys';
import Text from 'Components/shared/Text';
import useConfigSettings from 'Hooks/useConfigSettings';
import LoadingSpinner from 'Components/shared/LoadingSpinner';
import SubsectionHeading from '../shared/SubsectionHeading';
import useHandleError from "Utils/handleError";

const MobileApplicationConfig = ({ dataQa = "app" }) => {
  const handleError = useHandleError();

  const { isFetching } = useConfigSettings.query({
    refetchOnMount: 'always',
    onSuccess: (data) => {
      if (data?.MobileApplicationName) {
        setValue(data.MobileApplicationName);
      }
    },
  });
  const featureAvailabilityMutation = useConfigSettings.mutation({
    onError: (error) => {
      handleError({ error });
    },
  });
  const [value, setValue] = useState('');
  const handleChange = (e) => {
    setValue(e.target.value);
    featureAvailabilityMutation.mutate({
      data: {
        MobileApplicationName: e.target.value,
      },
    });
  };

  return (
    <div className="mt-40 xxl:mt-48">
      <SubsectionHeading
        text={i18nextTranslate(i18nextKeys.tenantSettingsMobileTitle)}
        tooltipText={i18nextTranslate(i18nextKeys.tenantSettingsMobileDescription)}
        dataQa={dataQa}
      />
      <Text
        textStyle="h3"
        className="mb-4"
        dataQa={`${dataQa}-name-label`}
      >
        <label>
          {i18nextTranslate(i18nextKeys.tenantSettingsMobileInputLabel)}
        </label>
      </Text>
      <div className="flex items-center">
        <input
          className="appearance-none rounded-md block h-40 px-16 bg-9 text-gray-700 focus:outline-none focus:bg-9-10 inputField color-10"
          style={{ width: '45%' }}
          value={value}
          onChange={handleChange}
          onBlur={() => { }}
          disabled={isFetching}
          data-qa={`${dataQa}-name-input`}
        />
        {isFetching && (
          <LoadingSpinner
            classes="ml-48"
            size="h-24 w-24"
            dataQa={`${dataQa}-name-loading`}
          />
        )}
      </div>
    </div>
  );
};

export default MobileApplicationConfig;
