import React from 'react';
import { DIRECTION } from 'Helpers/icons';
import { AbortSymbol, SingleChevron } from 'Components/shared/symbols';

const DesktopActionButtons = ({
  handleRemoveAsset,
  toggleRow,
  rowExpanded
}) => {
  return (
    <>
      <td
        className="px-8 py-8"
        role="presentation"
      >
        <AbortSymbol
          size="16"
          className="mx-auto cursor-pointer color-4"
          onClick={handleRemoveAsset}
        />
      </td>
      <td
        className="px-8 pr-16 py-8 color-6"
        role="presentation"
      >
        <SingleChevron
          size="16"
          direction={rowExpanded ? DIRECTION.up : DIRECTION.down}
          onClick={toggleRow}
          className="mx-auto cursor-pointer"
        />
      </td>
    </>
  );
};

export default DesktopActionButtons;
