import React from 'react';
import Text from 'Components/shared/Text';
import Toggle from 'Components/shared/Toggle';
import Tooltip from 'Components/shared/Tooltip';

const FeatureToggle = ({
  title,
  description,
  tooltipText,
  isEnabled,
  onChange,
  disabled = false,
  dataQa = "feature",
  toggleGap = "16",
  textGap = "12",
  width = "auto"
}) => {
  return (
    <div
      className={`flex gap-${toggleGap}`}
      style={{ width }}
    >
      <div>
        {tooltipText ? (
          <Tooltip
            text={tooltipText}
            dataQa={dataQa}
          >
            <Toggle
              toggled={isEnabled}
              onToggle={onChange}
              disabled={disabled}
              dataQa={dataQa}
            />
          </Tooltip>
        ) : (
          <Toggle
            toggled={isEnabled}
            onToggle={onChange}
            disabled={disabled}
            dataQa={dataQa}
          />
        )}
      </div>
      <div className={`flex flex-col gap-${textGap}`}>
        {!!title && (
          <Text
            textStyle="p2"
            dataQa={`${dataQa}-title`}
          >
            {title}
          </Text>
        )}
        <Text
          textStyle="p4"
          dataQa={`${dataQa}-description`}
          style={{ width }}
        >
          {description}
        </Text>
      </div>
    </div>
  );
};

export default FeatureToggle;
