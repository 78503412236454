import React from 'react';

const TableRowWrapper = ({
  index,
  children,
  rowExpanded = true,
  dataQa = 'cart-table-row'
}) => (
  <tr
    data-qa={dataQa}
    className={`${
      index % 2
        ? 'bg-2'
        : 'bg-4_7'
      } ${
      rowExpanded
        ? 'table-row'
        : 'hidden'
      }
    `}
  >
    {children}
  </tr>
);

export default TableRowWrapper;
