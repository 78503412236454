import i18nextTranslate from "../Lang/i18nextTranslate";
import {i18nextKeys} from "../Lang/i18nextKeys";

export const PAYMENT_METHOD = {
  None: 'None',
  CreditCard: 'CreditCard',
  CryptoCurrency: 'CryptoCurrency',
  BankTransfer: 'BankTransfer',
};

export const DISPLAY_ORDER = {
  BankTransfer: 1,
  CreditCard: 2,
  CryptoCurrency: 3
};

const enumToFriendlyString = (e) => {
  const { CreditCard, CryptoCurrency, BankTransfer } = PAYMENT_METHOD;
  switch (e) {
    case CreditCard: {
      return i18nextTranslate(i18nextKeys.commonCreditCard);
    }
    case CryptoCurrency: {
      return i18nextTranslate(i18nextKeys.commonCryptocurrency);
    }
    case BankTransfer: {
      return i18nextTranslate(i18nextKeys.commonBankTransfer);
    }
    default: {
      return i18nextTranslate(i18nextKeys.enumNone);
    }
  }
};

const friendlyStringToEnum = (str) => {
  const { CreditCard, CryptoCurrency, BankTransfer } = PAYMENT_METHOD;
  switch (str) {
    case i18nextTranslate(i18nextKeys.commonCreditCard): {
      return CreditCard;
    }
    case i18nextTranslate(i18nextKeys.commonCryptocurrency): {
      return CryptoCurrency;
    }
    case i18nextTranslate(i18nextKeys.commonBankTransfer): {
      return BankTransfer;
    }
    default: {
      return PAYMENT_METHOD[str] || PAYMENT_METHOD.None;
    }
  }
};

const PaymentMethod = {
  values: PAYMENT_METHOD,
  order: DISPLAY_ORDER,
  enumToFriendlyString,
  friendlyStringToEnum,
};

export default PaymentMethod;
