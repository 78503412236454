import React, { useContext } from 'react';
import { i18nextKeys } from 'Lang/i18nextKeys';
import i18nextTranslate from 'Lang/i18nextTranslate';
import { UiContext } from "States/ui/uiState";
import { DIRECTION, IconCircleWrapper } from 'Helpers/icons';
import Fade from 'Components/shared/Fade';
import { Arrow, TimeoutIcon } from 'Components/shared/symbols';
import { TextButton } from 'Components/shared/buttons';

const CheckoutTimeExpired = ({ onCheckoutFinish }) => {
  const {
    breakpoints: { xxl },
  } = useContext(UiContext);

  return (
    <Fade show={true}>
      <div
        data-qa="checkout-timeExpired"
        className="flex flex-col items-center color-8"
      >
        <IconCircleWrapper
          icon={<TimeoutIcon size={xxl ? 72 : 60} />}
          size={xxl ? "144px" : "120px"}
        />
        <div className="mt-48 w-full flex flex-col items-center">
          <h1 className="font-semibold text-2xl mb-16">
            {i18nextTranslate(i18nextKeys.checkoutTimeExpiredPrimaryMessage)}
          </h1>
          <p className="font-light">
            {i18nextTranslate(
              i18nextKeys.checkoutTimeExpiredSecondaryMessage
            )}
          </p>
        </div>
        <TextButton
          text={i18nextTranslate(i18nextKeys.checkoutTimeExpiredGoBack)}
          onClick={onCheckoutFinish}
          className="absolute flex-row-reverse"
          style={{ bottom: '90px' }}
          icon={Arrow}
          iconDirection={DIRECTION.left}
        />
      </div>
    </Fade>
  );
};

export default CheckoutTimeExpired;
