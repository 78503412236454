import React from 'react';

function ForkliftSymbol({ size }) {
  return (
    <svg
      width={size}
      height={size}
      style={{
        msTransform: 'rotate(360deg)',
        WebkitTransform: 'rotate(360deg)',
      }}
      viewBox="0 0 24 24"
      transform="rotate(360)"
      fill="currentColor"
      className="color-4"
    >
      <path d="M6 4v7H4c-1.11 0-2 .89-2 2v4a3 3 0 003 3 3 3 0 003-3h2a3 3 0 003 3 3 3 0 003-3v-4l-4-9H6m11 1v14h5v-1.5h-3.5V5H17m-9.5.5h3.7l3.3 7.5h-7V5.5M5 15.5A1.5 1.5 0 016.5 17 1.5 1.5 0 015 18.5 1.5 1.5 0 013.5 17 1.5 1.5 0 015 15.5m8 0a1.5 1.5 0 011.5 1.5 1.5 1.5 0 01-1.5 1.5 1.5 1.5 0 01-1.5-1.5 1.5 1.5 0 011.5-1.5z" />
    </svg>
  );
}

export default ForkliftSymbol;
