import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
  useMemo,
} from 'react';
import useHandleError from 'Utils/handleError';
import { useHistory } from 'react-router-dom';
import { LogOnMount } from 'react-amplitude-hooks';
import api from 'Api';
import Fade from 'Components/shared/Fade';
import Header from 'Components/shared/Header';
import LoadingSpinner from 'Components/shared/LoadingSpinner';
import KycLevelInfoBox from 'Components/shared/KycLevelInfoBox';
import { Tile, HorizontalRule } from 'Components/shared/Tile';
import UserInformation from 'Components/UserDetails/UserInformation';
import KycInformation from './KycInformation';
import i18nextTranslate from 'Lang/i18nextTranslate';
import { i18nextKeys } from 'Lang/i18nextKeys';
import ResponseModal from './Modal/ResponseModal';
import CaseHistoryTableBody from './History/CaseHistoryTableBody';
import CaseHistoryTableHeader from './History/CaseHistoryTableHeader';
import { UiContext } from 'States/ui/uiState';

const KycManagementDetails = ({ match }) => {
  const handleError = useHandleError();
  const history = useHistory();
  const {
    breakpoints: { sm, md }
  } = useContext(UiContext);
  const [isLoading, setIsLoading] = useState(true);
  const [tableIsLoading, setTableIsLoading] = useState(true);
  const [showProviderResponseModal, setShowProviderResponseModal] = useState(
    false
  );
  const [showCaseDeclineReasonModal, setShowCaseDeclineReasonModal] = useState(
    false
  );
  const [
    showVerificationDeclineReasonModal,
    setShowVerificationDeclineReasonModal,
  ] = useState(false);

  const [kycRecord, setKycRecord] = useState(null);
  const [userData, setUserData] = useState(null);
  const { id } = match.params;
  const [kycEntries, setKycEntries] = useState([]);
  const fetchIdRef = React.useRef(0);

  useEffect(() => {
    if (id) {
      (async () => {
        try {
          const kycRecord = await api.Kyc.get(id);
          const userData = await api.User.get(kycRecord.UserId);
          setKycRecord(kycRecord);
          setUserData(userData);
        } catch (error) {
          const message = i18nextTranslate(
            i18nextKeys.errorKycManagementDetailsLoadError
          );
          handleError({ error, message });
        }
      })();
    }
  }, [id, history]);

  useEffect(() => {
    if (kycRecord && userData) {
      setIsLoading(false);
      fetchData();
    }
  }, [kycRecord, userData]);

  const toKycManagementOverview = () => {
    history.push('/admin/kyc');
  };

  const fetchData = useCallback(() => {
    if (kycRecord) {
      const fetchId = ++fetchIdRef.current;
      setTableIsLoading(true);
      (async () => {
        try {
          // Abort if this is not the latest fetch
          if (fetchId !== fetchIdRef.current) {
            return;
          }
          const res = await api.Kyc.list({
            filter: `UserId eq ${kycRecord.UserId}`,
          });
          const { value } = res;

          setKycEntries(value);
          setTableIsLoading(false);
        } catch (error) {
          setTableIsLoading(false);
          const message = i18nextTranslate(
            i18nextKeys.errorKycDetailsCardsSubmission
          );
          handleError({ error, message });
        }
      })();
    }
  }, [kycRecord]);

  const toKycDetails = (Id) => {
    window.open(`${Id}`);
  };

  const providerResponse = useMemo(() => {
    if (!kycRecord) {
      return;
    }
    try {
      const jsonResponse = JSON.parse(kycRecord.Verification?.HttpResponseBody);
      return <pre>{JSON.stringify(jsonResponse, null, 2)}</pre>;
    } catch {
      return <p>kycRecord.Verification?.HttpResponseBody</p>;
    }
  }, [kycRecord]);

  return (
    <LogOnMount eventType="KYC details">
      <Fade show={true}>
        <Header
          text={i18nextTranslate(i18nextKeys.kycManagementDetailsHeader)}
          onArrowBackClick={toKycManagementOverview}
          arrowBack
        />
        <div
          className="w-full flex flex-col justify-center color-8 m-auto"
          style={{ maxWidth: '970px' }}
        >
          {isLoading ? (
            <div className="flex justify-center">
              <LoadingSpinner />
            </div>
          ) : (
            <div className="flex flex-col gap-16 md:gap-24">
              <KycInformation
                verification={kycRecord.Verification}
                kycRecord={kycRecord}
                userData={userData}
                onShowProviderResponse={() =>
                  setShowProviderResponseModal(true)
                }
                onShowVerificationDeclineReason={() =>
                  setShowVerificationDeclineReasonModal(true)
                }
                onShowCaseDeclineReason={() =>
                  setShowCaseDeclineReasonModal(true)
                }
              />
              <Tile title={i18nextTranslate(i18nextKeys.commonInfoUser)}>
                <div className="flex flex-col gap-24">
                  <UserInformation
                    user={userData}
                    singleColumn={!md}
                    showEmail
                  />
                  <HorizontalRule />
                  <div className="flex flex-col cursor-default">
                    <div className="flex items-center">
                      <label
                        data-qa="admin-kycDetails-recordInfo-tierlabel"
                        htmlFor="recordLevel"
                        className="pr-8"
                      >
                        {i18nextTranslate(
                          i18nextKeys.kycManagementUserInformationTierLabel
                        )}
                      </label>
                      <KycLevelInfoBox
                        isLoading={false}
                        kycTierNumber={kycRecord?.User?.TierNumber}
                        dataQa="kyc-management-kyc-currentCase"
                      />
                    </div>
                  </div>
                </div>
              </Tile>
              <div>
                <h2 className="color-4 mb-4">
                  {i18nextTranslate(i18nextKeys.kycManagementDetailsKycHistory)}
                </h2>
                <div className="w-full">
                  {tableIsLoading ? (
                    <div className="flex justify-center ">
                      <LoadingSpinner />
                    </div>
                  ) : (
                    <>
                      {sm && <CaseHistoryTableHeader />}
                      <CaseHistoryTableBody
                        items={kycEntries}
                        getRowProps={(row) => toKycDetails(row)}
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
        <ResponseModal
          dataQa="providerResponse"
          showModal={showProviderResponseModal}
          hideModal={() => setShowProviderResponseModal(false)}
          title={i18nextTranslate(
            i18nextKeys.kycManagementDetailsProviderResponse
          )}
          response={providerResponse}
        />
        <ResponseModal
          dataQa="caseDeclineReason"
          showModal={showCaseDeclineReasonModal}
          hideModal={() => setShowCaseDeclineReasonModal(false)}
          title={i18nextTranslate(i18nextKeys.kycManagementDetailsAdminReason)}
          response={kycRecord?.Comment ? kycRecord.Comment : '-'}
        />
        <ResponseModal
          dataQa="verificationDeclineReason"
          showModal={showVerificationDeclineReasonModal}
          hideModal={() => setShowVerificationDeclineReasonModal(false)}
          title={i18nextTranslate(i18nextKeys.kycManagementDetailsAdminReason)}
          response={kycRecord?.Verification?.DeclinedReason}
        />
      </Fade>
    </LogOnMount>
  );
};

export default KycManagementDetails;
