import React from "react";
import { components } from "react-select";

const OptionEmptyValue = (props) => {
  const { data, children } = props;

  return data.emptyOption ? (
    <components.Option {...props}>
      <div className="font-bold">
        {children}
      </div>
    </components.Option>
  ) : (
    <components.Option {...props}>
      {children}
    </components.Option>
  )
}

export default OptionEmptyValue;
