import React, { useContext } from 'react';
import QRCode from 'qrcode.react';
import { UiContext } from 'States/ui/uiState';
import Modal from 'Components/shared/Modal';
import { Button } from 'Components/shared/buttons';
import Text from 'Components/shared/Text';
import { i18nextKeys } from 'Lang/i18nextKeys';
import i18nextTranslate from 'Lang/i18nextTranslate';
import { copyToClipboardAndNotify } from 'Utils/utils';

const WalletConfirmationModal = ({
  showQrModal,
  selectedWallet,
  close,
  onSelectCollectionMethod,
  dataQa = "wallet-confirmation"
}) => {
  const {
    breakpoints: { md },
  } = useContext(UiContext);
  let ethereumAddress = selectedWallet?.ethereumAddress;

  const buttonWidth = {
    xxl: '190px',
    md: '170px',
    default: '140px'
  };

  return (
    <Modal
      show={showQrModal}
      width={md ? 'w-auto' : 'w-11/12'}
      close={close}
      dataQa="checkout-collectionMethod-qrModal"
    >
      <div className="flex flex-col color-8 p-16 md:py-32 md:px-144">
        <div className="flex flex-col items-center gap-24">
          <Text
            textStyle="h2"
            dataQa={`${dataQa}-title`}
            uppercase
          >
            {i18nextTranslate(
              md
                ? i18nextKeys.collectionMethodDetailsHeader
                : i18nextKeys.collectionMethodDetailsHeaderSmallScreen
            )}
          </Text>
          {!md && (
            <Text className="text-center">
              {i18nextTranslate(
                i18nextKeys.collectionMethodDetailsDescriptionSmallScreen
              )}
            </Text>
          )}
          {md && (
            <QRCode
              value={JSON.stringify({
                T: 'V',
                A: ethereumAddress,
              })}
              size={160}
              style={{
                border: "8px solid white"
              }}
              data-qa="wallet-qr"
            />
          )}
          <Text
            className="cursor-pointer"
            onClick={() =>
              copyToClipboardAndNotify(
                ethereumAddress,
                i18nextTranslate(
                  i18nextKeys.collectionMethodDetailsEthAddress
                )
              )
            }
          >
            {ethereumAddress}
          </Text>
        </div>
        <div className="flex gap-16 justify-center w-full mt-32">
          <Button
            dataQa={`${dataQa}-cancelButton`}
            onClick={close}
            text={i18nextTranslate(i18nextKeys.buttonCancel)}
            width={buttonWidth}
            secondary
          />
          <Button
            dataQa={`${dataQa}-confirmButton`}
            onClick={() => onSelectCollectionMethod(selectedWallet)}
            text={i18nextTranslate(i18nextKeys.buttonConfirm)}
            width={buttonWidth}
          />
        </div>
      </div>
    </Modal>
  );
};

export default WalletConfirmationModal;
