import React from 'react';
import i18nextTranslate from 'Lang/i18nextTranslate';
import { i18nextKeys } from 'Lang/i18nextKeys';

const DropdownItem = ({ onClick = () => { }, name }) => {
  return (
    <div
      data-qa={`table-dropdown-item-${name}`}
      className="dropdown-item hover:bg-gray-300 rounded-md cursor-pointer px-16 py-8 font-normal"
      onClick={() => onClick(name)}
    >
      {(() => {
        switch (name) {
          case 'Open':
            return i18nextTranslate(
              i18nextKeys.kycManagementDetailsDropdownOpen
            );
          case 'Approved':
            return i18nextTranslate(
              i18nextKeys.kycManagementDetailsDropdownApproved
            );
          case 'Declined':
            return i18nextTranslate(
              i18nextKeys.kycManagementDetailsDropdownDeclined
            );
          default:
            return name;
        }
      })()}
    </div>
  );
};

export default DropdownItem;
