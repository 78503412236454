import React from 'react';

const DropdownArrow = () => (
  <svg
    aria-hidden="true"
    width=".75rem"
    height=".75rem"
    viewBox="0 0 16 16"
    fill="currentColor"
  >
    <path d="M2 5.56 2.413 5h11.194l.393.54L8.373 11h-.827L2 5.56z" />
  </svg>
);

export default DropdownArrow;
