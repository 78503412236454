import React, { useState } from "react";
import "./Text.css";

function Text({
  dataQa,
  textStyle = "p1",
  style,
  className = "",
  children,
  showNumberOfLines = null,
  showFullTextOnClick = false,
  uppercase = false,
  lowercase = false,
  center = false,
  inline = false, // TODO: temporary while moving from text-paragraph for usecases without line clamp
  color = "color-8",
  dangerouslySetContent,
  reference,
  onClick = () => {},
  ...props
}) {
  const [lineClamp, setLineClamp] = useState(showNumberOfLines ?? 'none');

  const classNames = `
    ${inline ? "" : "text-paragraph"}
    ${textStyle}
    ${uppercase ? "uppercase" : ""}
    ${lowercase ? "lowercase" : ""}
    ${color}
    ${center ? "text-center" : ""}
    ${className}
  `;

  const processedProps = {
    "data-qa": dataQa,
    className: `${classNames} ${lineClamp === 0 ? 'inline-block' : ''}`,
    onClick: () => {
      if(showFullTextOnClick && showNumberOfLines) {
        setLineClamp(0);
      }
      onClick();
    },
    style: {
      ...showNumberOfLines && {
        lineClamp,
        WebkitLineClamp: lineClamp
      },
      ...style
    },
    ref: reference,
    ...props
  };

  return (
    dangerouslySetContent ? (
      <span {...processedProps} dangerouslySetInnerHTML={{__html: children}} />
    ) : (
      <span {...processedProps}>
        {children}
      </span>
    )
  );
}

export default Text;
