const USER_STATUS = {
  New: "New", // FE only
  Unconfirmed: "Unconfirmed",
  Confirmed: "Confirmed",
  Approved: "Approved",
  Rejected: "Rejected",
  Disabled: "Disabled"
};

export default USER_STATUS;
