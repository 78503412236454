import Urls from 'Utils/urls';
import http from './http';
import errorNotification from 'Helpers/errorNotification';

const Addresses = {
  create: (address) => http.post(`${Urls.get().wlpApi}/Addresses`, address),
  update: (address) => http.put(
    `${Urls.get().wlpApi}/Addresses/${address.Id}`,
    address,
    { headers: { 'Prefer': 'return=representation' } }
  )
};

const Assets = {
  getAsset: (uniqueAssetId) =>
    http.get(`${Urls.get().wlpApi}/assets('${uniqueAssetId}')?$expand=Translations`),
  getAssets: (tenantId, ids = []) =>
    http.get(`${Urls.get().wlpApi}/assets`, {
      headers: { 'tenant-id': tenantId },
      params: {
        $expand: "Translations",
        ...ids.length && {
          $filter: `Id in (${ids.join()})`
        }
      }
    }),
    getAssetsConfig: (tenantId, filter) => http.get(
      `${Urls.get().wlpApi}/PurchaseMethods`,
      {
        headers: { 'tenant-id': tenantId },
        params: {
          ...filter && { $filter: filter }
        }
      }
    ),
    getAssetConfig: (tenantId, id) => http.get(
      `${Urls.get().wlpApi}/PurchaseMethods('${id}')`,
      {
        headers: { 'tenant-id': tenantId }
      }
    )
};

const BankAccounts = {
  create: (bankAccount) =>
    http.post(`${Urls.get().wlpApi}/BankAccounts`, bankAccount),
  put: (bankAccount) =>
    http.put(
      `${Urls.get().wlpApi}/BankAccounts/${bankAccount.Id}`,
      bankAccount
    ),
  patch: (bankAccount) =>
    http.patch(
      `${Urls.get().wlpApi}/BankAccounts/${bankAccount.Id}`,
      bankAccount
    ),
  list: () => http.get(`${
      Urls.get().wlpApi
    }/BankAccounts?$expand=IntermediaryBankAccount&$filter=IsActive`),
  delete: (bankAccountId) =>
    http.delete(`${Urls.get().wlpApi}/BankAccounts/${bankAccountId}`),
};

const IntermediaryBankAccounts = {
  post: (intermediary) =>
    http.post(`${Urls.get().wlpApi}/IntermediaryBankAccounts`, intermediary),
  put: (intermediary) =>
    http.put(
      `${Urls.get().wlpApi}/IntermediaryBankAccounts/${intermediary.Id}`,
      intermediary
    ),
  patch: (intermediary) =>
    http.patch(
      `${Urls.get().wlpApi}/IntermediaryBankAccounts/${intermediary.Id}`,
      intermediary
    ),
  delete: (intermediaryId) =>
    http.delete(`${Urls.get().wlpApi}/IntermediaryBankAccounts/${intermediaryId}`),
};

const Cart = {
  get: () => http.get(`${Urls.get().wlpApi}/ShoppingCartItems`),
  addItem: (cartItem) =>
    http.post(`${Urls.get().wlpApi}/ShoppingCartItems`, cartItem),
  updateItem: (id, cartItem) =>
    http.patch(`${Urls.get().wlpApi}/ShoppingCartItems/${id}`, cartItem),
  removeItem: (id) =>
    http.delete(`${Urls.get().wlpApi}/ShoppingCartItems/${id}`),
  empty: () => http.delete(`${Urls.get().wlpApi}/EmptyShoppingCart`),
};

const Config = {
  getFullConfig: () => http.get(`${Urls.get().wlpApiHost}/config/fe`),
  getReducedConfig: (tenantId) => http.get(
    `${Urls.get().wlpApiHost}/config/login`,
    { headers: { 'tenant-id': tenantId } }
  ),
  saveConfig: (config) =>
    http.post(`${Urls.get().wlpApiHost}/config/fe`, config),
  getTimeouts: () => http.get(`${Urls.get().wlpApiHost}/config/timeouts`),

  Features: {
    get: (tenantId) =>
      http.get(`${Urls.get().wlpApi}/Features`, {
        headers: tenantId
          ? { 'tenant-id': tenantId }
          : {}
      }),
    getAvailability: () => http.get(
      `${Urls.get().wlpApi}/Features/GetFeaturesAvailability`
    ),
    getFeature: (Name) =>
      http.get(`${Urls.get().wlpApi}/Features`, {
        params: {
          $filter: `Name eq '${Name}'`
        }
      }),
    patch: (type, data) => http.patch(
      `${
        Urls.get().wlpApi
      }/Features(Enums.FeatureType'${type}')`,
      data,
      { headers: { 'Prefer': 'return=representation' } }
    )
  },

  Users: {
    getAll: ({ top, skip, filter }) =>
      http.get(`${Urls.get().wlpApi}/Users`, {
        params: {
          $count: true,
          $filter: `${filter ? ` and ${filter}` : ''}`,
          $skip: skip,
          $top: top,
        },
      }),
    getUsers: ({ top, skip, filter }) =>
      http.get(`${Urls.get().wlpApi}/Users`, {
        params: {
          $count: true,
          $filter: `IsAdmin eq false${filter ? ` and ${filter}` : ''}`,
          $skip: skip,
          $top: top,
        },
      }),
    getAdmins: ({ top, skip, filter }) =>
      http.get(`${Urls.get().wlpApi}/Users`, {
        params: {
          $count: true,
          $filter: `IsAdmin${filter ? ` and (${filter})` : ''}`,
          $skip: skip,
          $top: top,
        },
      }),
    patch: (id, data) => http.patch(`${Urls.get().wlpApi}/Users(${id})`, data),
  },

  Settings: {
    get: (tenantId) =>
      http.get(`${Urls.get().wlpApi}/Settings`, {
        headers: tenantId
          ? { 'tenant-id': tenantId }
          : {},
      }),
    patch: (data) => http.patch(`${Urls.get().wlpApi}/Settings`, data),
  },

  Languages: {
    get: () => http.get(`${Urls.get().wlpApi}/Languages`)
  },

  Currencies: {
    get: () => http.get(`${Urls.get().wlpApi}/Currencies`)
  },

  CollectionMethods: {
    getAll: () => http.get(`${Urls.get().wlpApi}/CollectionMethods`),
    get: (type) => http.get(
      `${
        Urls.get().wlpApi
      }/CollectionMethods(Enums.CollectionMethodType'${type}')`
    ),
    patch: (type, data) => http.patch(
      `${
        Urls.get().wlpApi
      }/CollectionMethods(Enums.CollectionMethodType'${type}')`,
      data,
      { headers: { 'Prefer': 'return=representation' } }
    ),
    put: (id, data) => http.patch(
      `${Urls.get().wlpApi}/CollectionMethods(${id})`,
      data
    )
  },

  PaymentMethods: {
    getAll: () =>
      http.get(`${Urls.get().wlpApi}/PaymentMethods`),
    get: (type) => http.get(
      `${
        Urls.get().wlpApi
      }/PaymentMethods(Enums.PaymentMethodType'${type}')`
    ),
    patch: (type, data) => http.patch(
      `${
        Urls.get().wlpApi
      }/PaymentMethods(Enums.PaymentMethodType'${type}')`,
      data,
      { headers: { 'Prefer': 'return=representation' } }
    )
  },

  PaymentProviderConfigurations: {
    getAll: () =>
      http.get(`${Urls.get().wlpApi}/PaymentProviderConfigurations`),
    get: (type) => http.get(
      `${
        Urls.get().wlpApi
      }/PaymentProviderConfigurations(Enums.PaymentProviderType'${type}')`
    ),
    patch: (type, data) => http.patch(
      `${
        Urls.get().wlpApi
      }/PaymentProviderConfigurations(Enums.PaymentProviderType'${type}')`,
      data,
      { headers: { 'Prefer': 'return=representation' } }
    ),
    validate: (data) => http.post(
      `${Urls.get().wlpApi}/PaymentProviderConfigurations/Validate`,
      data
    )
  },

  Templates: {
    get: (language, type) => http.get(
      `${Urls.get().wlpApi}/DocumentTemplates(languageCode='${
        language
      }',type=Enums.DocumentTemplateType'${
        type
      }')`,
    ),
    getByType: (type) =>
      http.get(`${Urls.get().wlpApi}/DocumentTemplates`, {
        params: {
          $filter: `type eq Enums.DocumentTemplateType'${type}'`
        }
      }),
    post: (data) => http.post(
      `${Urls.get().wlpApi}/DocumentTemplates/Upload`,
      data
    ),
    delete: (language, type) => http.delete(
      `${Urls.get().wlpApi}/DocumentTemplates(languageCode='${
        language
      }',type=Enums.DocumentTemplateType'${
        type
      }')`,
    ),
    validate: (data, signal, onProgress = () => {}) => http.post(
      `${Urls.get().wlpApi}/DocumentTemplates/Validate`,
      data,
      { onUploadProgress: onProgress, signal }
    ),
    testNew: (data, signal, onProgress = () => {}) => http.post(
      `${Urls.get().wlpApi}/DocumentTemplates/GenerateInvoice`,
      data,
      {
        responseType: "blob",
        onUploadProgress: onProgress,
        signal
      }
    ),
    testCurrent: (
      language,
      type,
      signal
    ) => http.get(
      `${Urls.get().wlpApi}/DocumentTemplates(languageCode='${
        language
      }',type=Enums.DocumentTemplateType'${
        type
      }')/GenerateInvoice`,
      {
        responseType: "blob",
        signal
      }
    ),
  }
};

const DebugInfo = {
  getWlpBeVersion: () => http.get(`${Urls.get().wlpApiHost}/version`)
};

const Kyc = {
  create: (queryString) => http.get(`${Urls.get().kycApi}/new?${queryString}`),
  get: (id) =>
    http.get(`${Urls.get().kycApi}/Cases(${id})?$expand=Verification,User`),
  list: ({ orderBy, filter, skip, top }) =>
    http.get(
      `${Urls.get().kycApi}/Cases?$expand=User,Verification&$count=true${
        filter ? `&$filter=${filter}` : ''
      }${orderBy ? `&$orderBy=${orderBy}` : ''}${skip ? `&$skip=${skip}` : ''}${
        top ? `&$top=${top}` : ''
      }`
    ),
  export: () =>
    http.get(`${Urls.get().kycApi}/exportkyc`, {
      responseType: 'blob',
    }),
  createCase: (data) => http.post(`${Urls.get().kycApi}/cases/open`, data),
  closeCase: (id, data) =>
    http.post(`${Urls.get().kycApi}/Cases(${id})/Close`, data),

  getFeatureList: () => http.get(`${Urls.get().wlpApi}/KycPermissions`),
  getTierList: () => http.get(`${Urls.get().kycApi}/Tiers`),
  getUserKycData: ({ user = 'Current()', expand = 'LatestCase' } = {}) =>
    http.get(`${Urls.get().kycApi}/Users/${user}${expand ? `?$expand=${expand}` : ''}`),
  getSettings: () => http.get(`${Urls.get().kycApi}/Settings`),
  updateSettings: (settings) => http.patch(`${Urls.get().kycApi}/Settings`, settings),
};

const PurchaseAsset = {
  setTransactionId: (PurchasedAssetId, TransactionId) =>
    http.post(`${Urls.get().wlpApi}/SetPurchasedAssetTransactionId`, {
      PurchasedAssetId,
      TransactionId,
    }),
};

const PurchaseOrder = {
  list: ({ orderBy, filter, skip, top }) => http.get(
      `${Urls.get().wlpApi}/Orders`,
      {
        params: {
          $count: "true",
          $expand: "User",
          ...!!filter && { $filter: filter },
          ...!!orderBy && { $orderBy: orderBy },
          ...!!skip && { $skip: skip },
          ...!!top && { $top: top }
        }
      }
  ),

  details: (id, sections = []) => {
    sections = sections.length
      ? sections
      : [
          'PurchasedAssets',
          'InvoiceAddress',
          'ShippingAddress',
          'User($expand=HomeAddress)',
          'Fees'
        ];
    return http.get(
      `${Urls.get().wlpApi}/Orders/${id}?$expand=${sections.join()}`
    );
  },
  updateDelta: (id, purchaseOrder) =>
    http.patch(`${Urls.get().wlpApi}/Orders/${id}`, purchaseOrder),
  getPaymentLink: ({
    orderId,
    paymentMethod,
    successRedirectUrl,
    failRedirectUrl,
  }) =>
    http.post(`${Urls.get().wlpApi}/CreatePaymentLink`, {
      OrderId: orderId,
      PaymentMethod: paymentMethod,
      SuccessRedirectUrl: successRedirectUrl,
      FailedRedirectUrl: failRedirectUrl,
    }),
  create: (order) => http.post(`${Urls.get().wlpApi}/Orders`, order),
  export: () =>
    http.get(`${Urls.get().wlpApi}/ExportOrders`, {
      responseType: 'blob',
    }),
  getInvoice: (purchaseId) =>
    http.get(`${Urls.get().wlpApi}/Orders/${purchaseId}/ExportInvoice`, {
      responseType: 'blob',
    }),
  getFees: ({ country, collectionMethod }) => http.get(
    `${Urls.get().wlpApi}/Orders/Calculate(collectionMethodType=Enums.CollectionMethodType'${
      collectionMethod
    }',countryCode='${country}')`
  )
};

const Redemptions = {
  getLimits: ({
    methodId,
    country
  }) => http.get(
    `${Urls.get().wlpApi}/Redemptions/CalculateMethod(redemptionMethodId='${
      methodId
    }',countryCode=${
      country ? `'${country}'` : null
    })`
  ),
  getAmountsAndFees: ({
    methodId,
    country,
    fieldName,
    amount
  }) => http.get(
    `${Urls.get().wlpApi}/Redemptions/Calculate(redemptionMethodId='${
      methodId
    }',countryCode=${
      country ? `'${country}'` : null
    },${fieldName}=${amount}m)`
  ),
  create: (redemption) =>
    http.post(`${Urls.get().wlpApi}/Redemptions/CreateRedemption`, redemption),
  cancel: (id) => http.post(`${Urls.get().wlpApi}/Redemptions(${id})/Cancel`),
  list: ({ orderBy, filter, skip, top }) => http.get(
    `${Urls.get().wlpApi}/Redemptions/Translate`,
    {
      params: {
        $count: "true",
        ...!!filter && { $filter: filter },
        ...!!orderBy && { $orderBy: orderBy },
        ...!!skip && { $skip: skip },
        ...!!top && { $top: top }
      }
    }
  ),
  fullDetails: (id) =>
    http.get(
      `${Urls.get().wlpApi}/Redemptions/${id}`,
      {
        params: {
          $expand: "PayoutMethodData($expand=BankAccount($expand=IntermediaryBankAccount),BeneficiaryAddress,Wallet)," +
            "User($expand=HomeAddress),Fees"
        }
      }
    ),
  details: (id) => http.get(`${Urls.get().wlpApi}/Redemptions/${id}`),
  updateDelta: (id, redemptionRequest) =>
    http.patch(`${Urls.get().wlpApi}/Redemptions/${id}`, redemptionRequest),
  export: () =>
    http.get(`${Urls.get().wlpApi}/ExportRedemptions`, {
      responseType: 'blob',
    }),
  getRedemptionPdf: (redemptionId) =>
    http.get(`${Urls.get().wlpApi}/Redemptions/${redemptionId}/ExportRedemption`, {
      responseType: 'blob',
    }),
};

const Sparks = {
  send: (payload) => http.post(`${Urls.get().wlpApi}/SendSparks`, payload),
};

const User = {
  get: (sub, expand = 'HomeAddress') =>
    http.get(
      `${Urls.get().wlpApi}/Users/${sub}${expand ? `?$expand=${expand}` : ''}`
    ),
  getWithWallets: (sub, walletType) =>
    http.get(
      `${Urls.get().wlpApi
      }/Users/${sub}?$expand=HomeAddress,Wallets($filter=Type eq '${walletType}' and IsActive)`
    ),
  getStatus: () => http.get(`${Urls.get().wlpApi}/Users/Status`),
  getRegistrationTemplate: () => http.get(`${Urls.get().wlpApi}/Users/RegistrationTemplate`),
  signUp: (data) => http.post(`${Urls.get().wlpApi}/Users/SignUp`, data),
  update: (user) => http.put(`${Urls.get().wlpApi}/users/${user.Id}`, user),
  updateDelta: (id, user) =>
    http.patch(`${Urls.get().wlpApi}/users/${id}`, user),
  getEmailConfirmationStatus: () => http.get(`${Urls.get().authApi}/user/EmailValidated`)
    .then(({
      EmailValidated,
      ErrorMessage,
      Success
    }) => {
      if (Success) {
        return EmailValidated;
      } else {
        errorNotification({}, ErrorMessage);
      }
    }),
  resendConfirmationEmail: () => http.post(`${Urls.get().authApi}/user/ResendConfirmationEmail`)
    .then(({ ErrorMessage, Success }) => {
      if (Success) {
        return Success;
      } else {
        return errorNotification({}, ErrorMessage);
      }
    }),
  getLegalLinks: () => http.get(`${Urls.get().authApi}/Account/LegalDocumentsLinks`)
};

const Wallets = {
  getBalance: (uniqueAssetId, address) =>
    http.get(
      `${Urls.get().wlpApi}/Wallets/Balance(address='${address}',assetId='${uniqueAssetId}')`
    ),
  getExternal: () => http.get(`${Urls.get().wlpApi}/Wallets/ExternalWallets`),
  get: ({ filter }) =>
    http.get(
      `${Urls.get().wlpApi}/Wallets${filter ? `?$filter=${filter}` : ''}`
    ),
  create: (wallet) => http.post(`${Urls.get().wlpApi}/Wallets`, wallet),
  delete: (walletId) => http.delete(`${Urls.get().wlpApi}/Wallets/${walletId}`),
  put: (wallet) =>
    http.put(`${Urls.get().wlpApi}/Wallets/${wallet.Id}`, wallet),
};

const api = {
  Addresses,
  Assets,
  BankAccounts,
  IntermediaryBankAccounts,
  Cart,
  Config,
  DebugInfo,
  Kyc,
  PurchaseAsset,
  PurchaseOrder,
  Redemptions,
  Sparks,
  User,
  Wallets
};

export default api;
