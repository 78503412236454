import React, { useContext } from 'react';
import { UiContext } from 'States/ui/uiState';
import i18nextTranslate from 'Lang/i18nextTranslate';
import { i18nextKeys } from 'Lang/i18nextKeys';

const TableHeader = ({ position = 'static' }) => {
  const {
    breakpoints: { md, lg },
  } = useContext(UiContext);

  return (
    <thead>
      <tr>
        <th
          className={`${position} top-0 bg-2 p-12 pl-16 text-left font-bold text-xs w-1/3 xl:font-bold xxl:text-sm`}
        >
          {i18nextTranslate(i18nextKeys.cartTableHeaderItem)}
        </th>
        {lg && (
          <th className="p-12 text-right font-bold text-xs w-1/6 xl:font-bold xxl:text-sm">
            {i18nextTranslate(i18nextKeys.cartTableHeaderPrice)}
          </th>
        )}
        <th
          className={`${position} top-0 bg-2 font-bold text-xs w-1/4 md:w-1/3 text-left xl:text-xs xl:font-bold xxl:text-sm`}
        >
          {i18nextTranslate(i18nextKeys.cartTableHeaderQuantity)}
        </th>
        {md && (
          <>
            <th
              className={`${position} top-0 bg-2 p-12 text-right font-bold text-xs pr-24 w-1/5 xl:font-bold xxl:text-sm`}
            >
              {i18nextTranslate(i18nextKeys.cartTableHeaderTotal)}
            </th>
            <th className="p-12 px-8" />
            <th className="p-12 px-8" />
          </>
        )}
        {lg && (
          <>
            <th
              className={`${position} top-0 bg-2`}
              style={{ width: '5%' }}
            />
            <th
              className={`${position} top-0 bg-2`}
              style={{ width: '5%' }}
            />
          </>
        )}
      </tr>
    </thead>
  );
};

export default TableHeader;
