import { useContext } from 'react';
import { AuthContext } from '../States/auth/authState';
import { KycContext } from 'States/kyc/kycState';

const useKycTierCheck = (requiredTier = 0) => {
  const { isAdmin, isAuthenticated } = useContext(AuthContext);
  const { isKycEnabled, currentKycTier } = useContext(KycContext);

  // if KYC is disabled, the user holds a sufficient tier by default
  const isUserTierSufficient =
    !isKycEnabled || !isAuthenticated || isAdmin || currentKycTier >= (requiredTier || 0);

  return { isUserTierSufficient };
};

export default useKycTierCheck;
