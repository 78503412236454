import React from 'react';

const BankTransferConfigSymbol = ({
  size = "40",
  className = "color-4",
}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 40 40"
    fill="currentColor"
    className={className}
  >
    <g>
      <path d="M36.0259 32.0459H6.47779C5.66141 32.0459 5 32.7073 5 33.5244C5 34.3401 5.66141 35.0015 6.47779 35.0015H36.0252C36.8416 35.0015 37.503 34.3401 37.503 33.5244C37.5023 32.7073 36.8409 32.0459 36.0259 32.0459Z" />
      <path d="M8.33325 28.2063C7.51687 28.2063 6.85547 28.8677 6.85547 29.6841C6.85547 30.5005 7.51687 31.1626 8.33325 31.1626H34.1876C35.004 31.1626 35.6654 30.5005 35.6654 29.6841C35.6654 28.8677 35.004 28.2063 34.1876 28.2063H33.8188V14.909H34.1876C34.5965 14.909 34.9258 14.5783 34.9258 14.1708C34.9258 13.7633 34.5951 13.4326 34.1876 13.4326H8.33325C7.92506 13.4326 7.59505 13.7633 7.59505 14.1708C7.59505 14.5783 7.92576 14.909 8.33325 14.909H8.7027V28.2056L8.33325 28.2063ZM30.8633 14.909V28.2056H26.4313V14.909H30.8633ZM23.4757 14.909V28.2056H19.0437V14.909H23.4757ZM11.6569 14.909H16.0889V28.2056H11.6569V14.909Z" />
      <path d="M6.47781 12.1025H36.0252C36.0301 12.1025 36.0356 12.1025 36.0398 12.1025C36.8568 12.1025 37.5176 11.4411 37.5176 10.6247C37.5176 9.97576 37.0997 9.42574 36.5192 9.22649L21.859 2.62972C21.4736 2.45676 21.0329 2.45676 20.6469 2.62972L5.87176 9.27768C5.23595 9.56411 4.88657 10.2546 5.03324 10.9354C5.17922 11.6161 5.78112 12.1025 6.47781 12.1025Z" />
    </g>
  </svg>
);


export default BankTransferConfigSymbol;
