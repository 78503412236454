import React from 'react';

const CreditCardSymbol = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 244 196"
    fill="currentColor"
    className="color-4"
  >
    <path d="M219.334.667H24.667C11.247.667.333 11.58.333 25v146c0 13.42 10.914 24.333 24.334 24.333h194.667c13.419 0 24.333-10.913 24.333-24.333V25c0-13.42-10.914-24.333-24.333-24.333zm-91.25 146a30.418 30.418 0 010-60.834 30.054 30.054 0 0118.25 6.23c-7.349 5.548-12.167 14.271-12.167 24.187s4.818 18.639 12.167 24.187a30.057 30.057 0 01-18.25 6.23zm48.666 0a30.42 30.42 0 01-30.416-30.417 30.416 30.416 0 1130.416 30.417z" />
  </svg>
);

export default CreditCardSymbol;
