export const isTenantFeatureAvailable = (features, featureType) => {
  const feature = features?.find((feat) => {
    if (Array.isArray(featureType)) {
      return featureType.some(type => feat.Type === type && feat.IsEnabled);
    } else {
      return feat.Type == featureType;
    }
  });
  return feature?.IsEnabled || false
};
