import React, { useContext, useMemo } from "react";
import { i18nextKeys } from "Lang/i18nextKeys";
import i18nextTranslate from "Lang/i18nextTranslate";
import { getTranslation } from "Helpers/translations";
import { LangContext } from "States/lang/langState";
import { TileInfoCard } from "Components/shared/Tile";
import { useFormatNumber } from 'Utils/formatNumber';

const AssetInformation = ({ asset, translations, defaultLanguage, index }) => {
  const { lang } = useContext(LangContext);
  const formatNumber = useFormatNumber();
  const assetName = useMemo(() =>
    getTranslation("Name", lang, defaultLanguage.Code, translations) || asset.Name,
  [lang]);

  return (
    <>
      <TileInfoCard
        dataQa={`order-summary-asset-${index}-id`}
        title={i18nextTranslate(i18nextKeys.orderUniqueAssetId)}
        text={asset.UniqueAssetId}
      />
      <TileInfoCard
        dataQa={`order-summary-asset-${index}-name`}
        title={i18nextTranslate(i18nextKeys.orderAssetName)}
        text={assetName}
      />
      <TileInfoCard
        dataQa={`order-summary-asset-${index}-sparks`}
        title={i18nextTranslate(i18nextKeys.commonSparks)}
        text={formatNumber(asset.Sparks)}
      />
      <TileInfoCard
        dataQa={`order-summary-asset-${index}-units`}
        title={i18nextTranslate(i18nextKeys.commonUnits)}
        text={`${formatNumber(asset.Units)} ${asset.UnitOfMeasure || asset.CurrencyCode}`}
      />
    </>
  );
};

export default AssetInformation;
