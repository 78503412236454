import React from 'react';
import LoadingSpinner from '../shared/LoadingSpinner/LoadingSpinner';

const LoadingPage = () => (
  <div className="bg-2 flex h-screen justify-center items-center">
    <LoadingSpinner size="h-128 w-128" />
  </div>
);

export default LoadingPage;
