import React, { useContext } from "react";
import { useHistory } from 'react-router-dom';
import { ROUTES } from "Router/Routes";
import { i18nextKeys } from "Lang/i18nextKeys";
import i18nextTranslate from "Lang/i18nextTranslate";
import linkify from "Helpers/linkify";
import i18nextTranslateDynamically from "Lang/i18nextTranslateDynamically";
import { ConfigContext } from 'States/config/configState';
import { Button } from "Components/shared/buttons";
import SimplePage from "Components/shared/SimplePage";
import { UserProfileIcon } from 'Components/shared/symbols';

const AccountBlocked = () => {
  const history = useHistory();
  const {
    config: { imprint },
  } = useContext(ConfigContext);

  const logout = () => {
    history.push(ROUTES.logout);
  };

  return (
    <SimplePage
      header={i18nextTranslate(i18nextKeys.signupTitle)}
      icon={UserProfileIcon}
      title={i18nextTranslate(i18nextKeys.signupBlockedTitle)}
      description={linkify(
        i18nextTranslateDynamically(
          i18nextKeys.signupBlockedDescription,
          { email: imprint?.email }
        )
      )}
      dataQa="account-blocked"
    >
      <Button
        dataQa="button-logout"
        text={i18nextTranslate(i18nextKeys.buttonLogout)}
        onClick={logout}
        className="mt-16"
        width={{
          xxl: '190px',
          default: '170px'
        }}
        tertiary
      />
    </SimplePage>
  );
};

export default AccountBlocked;
