import React, { useEffect, useState } from "react";
import { Field as FinalField, Form as FinalForm } from "react-final-form";
import { combineValidators } from "revalidate";
import AnimateHeight from "react-animate-height";
import Modal from "Components/shared/Modal";
import LoadingSpinner from "Components/shared/LoadingSpinner/LoadingSpinner";
import { FormField } from "Components/shared/formElements";
import { additionalDataValidator } from "Components/shared/formElements/validators";
import { Button } from "Components/shared/buttons";
import useHandleError from "Utils/handleError";
import i18nextTranslate from "Lang/i18nextTranslate";
import { i18nextKeys } from "Lang/i18nextKeys";

const ADDITIONAL_DATA_MAX_LENGTH = 2048;

const additionalDataModalValidator = combineValidators({
  ...additionalDataValidator("additionalData", ADDITIONAL_DATA_MAX_LENGTH)
});

let formRef;

const AdditionalDataModal = ({
  id,
  additionalData,
  updateRemoteData,
  updateLocalData,
  show,
  close
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const handleError = useHandleError();

  useEffect(() => {
    if (show && formRef) {
      formRef.restart();
    }
  }, [show]);

  const updateAdditionalData = async ({ additionalData }) => {
    try {
      setIsLoading(true);
      await updateRemoteData(id, {
        AdditionalData: additionalData
      });
      updateLocalData(additionalData);
      if (formRef) {
        setTimeout(formRef.restart);
      }
      close();
    } catch (error) {
      const message = i18nextTranslate(i18nextKeys.errorAdditionalData);
      handleError({ error, message });
    } finally {
      setIsLoading(false);
    }
  };

  const buttonWidth = {
    xxl: '190px',
    md: '170px',
    default: '140px'
  };

  return (
    <Modal dataQa="admin-additionalDataModal" show={show} close={close}>
      <div className="flex flex-col bg-3 px-24 pt-24 pb-32">
        <AnimateHeight duration={750} height={isLoading ? "auto" : 0}>
          <LoadingSpinner classes="m-auto" />
        </AnimateHeight>
        <AnimateHeight className="w-full" duration={750} height={!isLoading ? "auto" : 0}>
          <p
            className="text-sm uppercase font-extrabold mb-24"
            data-qa={`admin-purchaseDetails-sendModal-description`}
          >
            {i18nextTranslate(i18nextKeys.commonAdditionalData)}
          </p>
          <FinalForm
            validate={additionalDataModalValidator}
            onSubmit={updateAdditionalData}
            initialValues={{ additionalData }}
            subscription={{ hasValidationErrors: true }}
            render={({ handleSubmit, hasValidationErrors, form }) => {
              formRef = form;
              return (
                <>
                  <FinalField
                    name="additionalData"
                    subscription={{
                      active: true,
                      error: true,
                      touched: true,
                      value: true
                    }}
                    render={(props) => (
                      <FormField
                        dataQa="admin-additionalDataModal-additionalData"
                        multiline
                        maxLength={ADDITIONAL_DATA_MAX_LENGTH}
                        showNumberOfChars
                        style={{
                          lineHeight: "1.194rem",
                          marginBottom: "0.125rem"
                        }}
                        {...props}
                      />
                    )}
                    keepDirtyOnReinitialize
                    required
                  />
                  <div className="flex place-content-between mt-24">
                    <Button
                      dataQa="admin-additionalDataModal-closeButton"
                      text={i18nextTranslate(i18nextKeys.buttonCancel)}
                      onClick={close}
                      width={buttonWidth}
                      secondary
                    />
                    <Button
                      dataQa="admin-additionalDataModal-replaceButton"
                      text={i18nextTranslate(i18nextKeys.buttonSave)}
                      onClick={handleSubmit}
                      disabled={hasValidationErrors}
                      width={buttonWidth}
                    />
                  </div>
                </>
              );
            }}
          />
        </AnimateHeight>
      </div>
    </Modal>
  );
};

export default AdditionalDataModal;
