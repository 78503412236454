import React, { useEffect, useContext, useState } from "react";
import { Field as FinalField } from "react-final-form";
import { FieldArray as FinalFieldArray } from "react-final-form-arrays";
import { i18nextKeys } from "Lang/i18nextKeys";
import i18nextTranslate from "Lang/i18nextTranslate";
import i18nextTranslateDynamically from "Lang/i18nextTranslateDynamically";
import codeToCountry from 'Utils/country-codes';
import { isRequiredWithMessage } from "Utils/validators";
import useConfigSettings from 'Hooks/useConfigSettings';
import { LangContext } from 'States/lang/langState';
import LoadingSpinner from 'Components/shared/LoadingSpinner';
import { DropdownField, NumberInput } from "Components/shared/formElements";
import Text from "Components/shared/Text";
import { Accordion } from "Features/admin/ConfigManagement/shared";

const CountryFeesForm = ({
  title,
  countryFees = [],
  fieldWidth
}) => {
  const [countryOptions, setCountryOptions] = useState([])
  const [selectedCountry, setSelectedCountry] = useState(null);
  const { lang } = useContext(LangContext);

  const {
    data: {
      Currency = {}
    }
  } = useConfigSettings.query({});

  useEffect(() => {
    let countryOptions = Object.entries(codeToCountry).reduce((countries, entry) => {
      const existingValue = countryFees.find(
        ({ CountryCode }) => {
          return entry[0] === CountryCode;
        }
      );
      if (existingValue) {
        return countries;
      } else {
        countries.push({
          value: entry[0],
          label: i18nextTranslate(entry[1]),
        });
        return countries;
      }
    }, []);
    countryOptions.sort((a, b) => a.label.localeCompare(b.label));
    setCountryOptions(countryOptions);
    setSelectedCountry(countryOptions[0]);
  }, [lang])

  const removeCountryOption = () => {
    const updatedCountryOptions = countryOptions.filter(
      ({ value }) => value !== selectedCountry.value
    );
    setSelectedCountry(updatedCountryOptions[0] || null);
    setCountryOptions(updatedCountryOptions);
  };

  const restoreCountryOption = (value, label) => {
    const updatedCountryOptions = [
      ...countryOptions,
      { value, label }
    ];
    updatedCountryOptions.sort((a, b) => a.label.localeCompare(b.label));
    if (!selectedCountry) {
      setSelectedCountry(updatedCountryOptions[0]);
    }
    setCountryOptions(updatedCountryOptions);
  };

  return !selectedCountry ? (
    <div className="flex justify-center">
      <LoadingSpinner />
    </div>
  ) : (
    <div className="flex flex-col gap-24 xxl:gap-32">
      <div className="flex flex-col gap-16 xxl:gap-20">
        <Text
          textStyle="p2"
          color="color-4"
          dataQa="fee-country-title"
        >
          {title}
        </Text>
        <Text
          textStyle="p4"
          dataQa="fee-country-description"
        >
          {i18nextTranslate(
            i18nextKeys.configCollectionFeesCountryDescription
          )}
        </Text>
      </div>
      <div className="flex flex-col gap-16 xxl:gap-20">
        <Text dataQa="fee-country-title">
          {i18nextTranslate(i18nextKeys.configCollectionFeesCountry)}
        </Text>
        <FinalFieldArray
          name="CountryFees"
          initialValue={countryFees}
          subscription={{ value: true }}
        >
          {({ fields }) => (
            <div className={`flex flex-col ${fields.length ? "gap-24" : "gap-0"}`}>
              <DropdownField
                label={i18nextTranslate(i18nextKeys.configCollectionFeesCountryButton)}
                options={countryOptions}
                input={{
                  value: selectedCountry,
                  onChange: (value, option) => setSelectedCountry(option)
                }}
                buttonText={i18nextTranslate(
                  i18nextKeys.configCollectionFeesCountryButton)
                }
                onButtonClick={() => {
                  removeCountryOption();
                  fields.push({
                    CountryCode: selectedCountry.value
                  });
                }}
                style={{ width: fieldWidth }}
                dataQa="fee-country-select"
                isSearchable
                withButton
              />
              <div className="flex flex-col gap-8">
                {fields.map((name, index) => {
                  const countryCode = fields.value[index].CountryCode;
                  const country = i18nextTranslate(codeToCountry[countryCode]);
                  return (
                    <Accordion
                      key={`fee-country-${countryCode}`}
                      text={country}
                      removeAccordion={(text) => {
                        restoreCountryOption(countryCode, text);
                        fields.remove(index);
                      }}
                      fieldWidth={fieldWidth}
                      dataQa={`fee-country-${countryCode}-accordion`}
                    >
                      <div
                        key={name}
                        className="flex flex-col gap-24"
                        style={{ width: fieldWidth }}
                      >
                        <FinalField
                          name={`${name}.VariableFee`}
                          subscription={{
                            error: true,
                            invalid: true,
                            touched: true,
                            value: true
                          }}
                          validate={isRequiredWithMessage(
                            i18nextTranslate(i18nextKeys.commonFee)
                          )}
                          validateFields={[]}
                          render={({ input, meta }) => {
                            return (
                              <NumberInput
                                scale={2}
                                labelText={`${i18nextTranslateDynamically(
                                  i18nextKeys.configCollectionFeesCountryVariable,
                                  { country }
                                )} (${i18nextTranslate(
                                  i18nextKeys.commonFeePercentage
                                )})`}
                                dataQa={`fee-country-${countryCode}-variable`}
                                innerLabelText="%"
                                showInnerLabel
                                staticInnerLabel
                                decimal
                                required
                                {...input}
                                {...meta}
                              />
                            )
                          }}
                        />
                        <FinalField
                          name={`${name}.FixedFee`}
                          subscription={{
                            error: true,
                            invalid: true,
                            touched: true,
                            value: true
                          }}
                          validate={isRequiredWithMessage(
                            i18nextTranslate(i18nextKeys.commonFee)
                          )}
                          validateFields={[]}
                          render={({ input, meta }) => {
                            return (
                              <NumberInput
                                scale={2}
                                labelText={i18nextTranslateDynamically(
                                  i18nextKeys.configCollectionFeesCountryFixed,
                                  { country }
                                )}
                                dataQa={`fee-country-${countryCode}-fixed`}
                                innerLabelText={Currency.Code}
                                showInnerLabel
                                staticInnerLabel
                                decimal
                                required
                                {...input}
                                {...meta}
                              />
                            )
                          }}
                        />
                      </div>
                    </Accordion>
                  );
                })}
              </div>
            </div>
          )}
        </FinalFieldArray>
      </div>
      <div className="flex flex-col gap-16 xxl:gap-20">
        <Text dataQa="fee-country-default-title">
          {i18nextTranslate(i18nextKeys.configCollectionFeesCountryDefault)}
        </Text>
        <div
          className="flex flex-col gap-24"
          style={{ width: fieldWidth }}
        >
          <FinalField
            name="FallBackCountryFees.VariableFee"
            subscription={{
              error: true,
              invalid: true,
              touched: true,
              value: true
            }}
            validate={isRequiredWithMessage(
              i18nextTranslate(i18nextKeys.commonFee)
            )}
            validateFields={[]}
            render={({ input, meta }) => (
              <NumberInput
                scale={2}
                labelText={`${i18nextTranslate(
                  i18nextKeys.configCollectionFeesCountryDefaultVariable
                )} (${i18nextTranslate(
                  i18nextKeys.commonFeePercentage
                )})`}
                innerLabelText="%"
                dataQa="fee-country-default-variable"
                showInnerLabel
                staticInnerLabel
                decimal
                required
                {...input}
                {...meta}
              />
            )}
          />
          <FinalField
            name="FallBackCountryFees.FixedFee"
            subscription={{
              error: true,
              invalid: true,
              touched: true,
              value: true
            }}
            validate={isRequiredWithMessage(
              i18nextTranslate(i18nextKeys.commonFee)
            )}
            validateFields={[]}
            render={({ input, meta }) => (
              <NumberInput
                scale={2}
                labelText={i18nextTranslate(
                  i18nextKeys.configCollectionFeesCountryDefaultFixed
                )}
                innerLabelText={Currency.Code}
                dataQa="fee-country-default-fixed"
                showInnerLabel
                staticInnerLabel
                decimal
                required
                {...input}
                {...meta}
              />
            )}
          />
        </div>
      </div>
    </div>
  );
}

export default CountryFeesForm;
