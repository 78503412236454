import React from "react";
import formatDate from 'Utils/formatDate';
import i18nextTranslate from 'Lang/i18nextTranslate';
import { i18nextKeys } from 'Lang/i18nextKeys';
import { TileCardContent } from "Components/shared/Tile";
import codeToCountry from "Utils/country-codes";
import formatPhoneNumber from "Utils/formatPhoneNumber";

const UserInformation = ({
  user,
  showFirstName = false,
  showLastName = false,
  showEmail = false,
  singleColumn = false
}) => {
  const personalInfo = {
    title: i18nextTranslate(i18nextKeys.commonInfoPersonal),
    items: [
      ...showFirstName ? [{
        name: i18nextTranslate(i18nextKeys.commonFirstName),
        value: user?.FirstName
      }] : [],
      ...showLastName ? [{
        name: i18nextTranslate(i18nextKeys.commonLastName),
        value: user?.LastName
      }] : [],
      ...showEmail ? [{
        name: i18nextTranslate(i18nextKeys.commonEmail),
        value: user?.EmailAddress
      }] : [],
      {
        name: i18nextTranslate(i18nextKeys.commonPhoneNumber),
        value: formatPhoneNumber(user?.PhoneNumber)
      },
      {
        name: i18nextTranslate(i18nextKeys.commonBirthDate),
        value: user?.BirthDate && formatDate(user?.BirthDate)
      },
      {
        name: i18nextTranslate(i18nextKeys.commonNationality),
        value: user?.Nationality
      }
    ]
  };

  const personalAddress = {
    title: i18nextTranslate(i18nextKeys.commonAddressPersonal),
    items: [
      {
        name: i18nextTranslate(i18nextKeys.commonAddressStreet),
        value: user?.HomeAddress?.AddressLine
      },
      {
        name: i18nextTranslate(i18nextKeys.commonAddressZip),
        value: user?.HomeAddress?.ZipCode
      },
      {
        name: i18nextTranslate(i18nextKeys.commonAddressCity),
        value: user?.HomeAddress?.City
      },
      {
        name: i18nextTranslate(i18nextKeys.commonAddressRegion),
        value: user?.HomeAddress?.State
      },
      {
        name: i18nextTranslate(i18nextKeys.commonAddressCountry),
        value: i18nextTranslate(codeToCountry[user?.HomeAddress?.Country])
      }
    ]
  };

  return (
    <div
      className="grid col-gap-24 row-gap-32"
      style={{
        gridTemplateColumns: singleColumn ? "1fr" : "repeat(2, 1fr)"
      }}
    >
      <TileCardContent
        data={[personalInfo]}
      />
      <TileCardContent
        data={[personalAddress]}
      />
    </div>
  );
};

export default UserInformation;
