import React, { useContext } from "react";
import { LogOnMount } from "react-amplitude-hooks";
import { useHistory, useLocation } from "react-router-dom";
import { IconCircleWrapper } from 'Helpers/icons';
import linkify from 'Helpers/linkify';
import Text from "Components/shared/Text";
import i18nextTranslateDynamically from "Lang/i18nextTranslateDynamically";
import { i18nextKeys } from "Lang/i18nextKeys";
import { UiContext } from "States/ui/uiState";
import { Button } from "Components/shared/buttons";
import i18nextTranslate from "Lang/i18nextTranslate";
import { ROUTES } from "Router/Routes";
import { ConfigContext } from "States/config/configState";
import { ErrorIcon } from "Components/shared/symbols";

const RedeemError = () => {
  const location = useLocation();
  const history = useHistory();

  const {
    breakpoints: { md, xxl }
  } = useContext(UiContext);

  const navigateToRedemption = () => {
    history.replace(ROUTES.redemption);
  };

  const {
    config: {
      imprint
    }
  } = useContext(ConfigContext);

  return (
    <LogOnMount
      eventType="Inovoice not paid"
      eventProperties={{
        reason: "error"
      }}
    >
      <div
        className="h-full self-center flex flex-col flex-grow justify-center items-center gap-32"
        style={{ width: md ? "544px" : "100%" }}
      >
        <IconCircleWrapper
          icon={<ErrorIcon size={xxl ? 72 : 60} />}
          size={xxl ? "144px" : "120px"}
        />
        <div className="flex flex-col gap-24">
          <Text
            textStyle="p2"
            dangerouslySetContent
            center
          >
            {i18nextTranslateDynamically(i18nextKeys.redeemErrorTitle, {
              refCode: location?.state?.referenceCode
            })}
          </Text>

          <Text
            textStyle="p4"
            dangerouslySetContent
            center
          >
            {linkify(i18nextTranslateDynamically(i18nextKeys.redeemErrorDescription, {
              supporterEmail: imprint?.email
            }))}
          </Text>
        </div>

        <Button
          text={i18nextTranslate(i18nextKeys.redeemErrorStartOver)}
          onClick={navigateToRedemption}
          width={{
            xxl: "190px",
            default: "170px"
          }}
          tertiary
        />
      </div>
    </LogOnMount>
  );
};

export default RedeemError;
