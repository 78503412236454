import React from 'react';

const SearchIcon = (props) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <path
      d="M6.5 11C8.98528 11 11 8.98528 11 6.5C11 4.01472 8.98528 2 6.5 2C4.01472 2 2 4.01472 2 6.5C2 8.98528 4.01472 11 6.5 11Z"
      stroke="currentColor"
    />
    <path
      d="M9.9668 10.0605L14.4998 14.4995"
      stroke="currentColor"
    />
  </svg>
);

export default SearchIcon;
