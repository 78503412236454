import i18next from './i18nextConfig';

/**
 * Handle translation of strings that include a variable.
 * So, e.g. the translation resource file includes the following string: "{{what}} is {{how}}"
 * "what" and "how" are then variables that should get replaced with values dynamically
 *
 * @param i18nextTranslationKey     key to find the translation in the translation resource files
 * @param variableCollection        variables and their values used in the translation string in the format
 *                                  e.g. { what: 'i18next', how: 'great' }
 * @returns {string}
 */
export default function i18nextTranslateDynamically (i18nextTranslationKey, variableCollection) {
    return i18next.t(i18nextTranslationKey, variableCollection);
}