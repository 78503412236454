import React, { useCallback, useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { LogOnMount } from 'react-amplitude-hooks';
import Header from 'Components/shared/Header';
import { Tile } from 'Components/shared/Tile';
import Text from 'Components/shared/Text';
import useConfigSettings from 'Hooks/useConfigSettings';
import { ConfigContext } from 'States/config/configState';
import { LangContext } from "States/lang/langState";
import { UiContext } from 'States/ui/uiState';
import { capitalizeString } from 'Utils/utils';
import Urls from 'Utils/urls';
import LoadingSpinner from 'Components/shared/LoadingSpinner';
import NoDataPage from 'Components/shared/NoDataPage/NoDataPage';
import i18nextTranslate from 'Lang/i18nextTranslate';
import { i18nextKeys } from 'Lang/i18nextKeys';

const instance = axios.create();
instance.defaults.headers.common = {};

const Imprint = () => {
  const {
    config: { imprint },
  } = useContext(ConfigContext);
  const { lang } = useContext(LangContext);
  const {
    breakpoints: { md },
  } = useContext(UiContext);

  const {
    data: defaultLanguage
  } = useConfigSettings.query({
    select: useCallback(
      ({ Languages }) => Languages.find(({ IsDefault }) => IsDefault),
      []
    )
  });

  const [imprintData, setImprintData] = useState({});
  const [loading, setLoading] = useState(true);
  const [html, setHtml] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        if (
          !imprint &&
          !imprint.customHtmlSourcePath &&
          !Object.keys(imprint.customHtmlSourcePath).length
        ) {
          return;
        }
        const templatePath = imprint.customHtmlSourcePath[lang] ||
          imprint.customHtmlSourcePath[defaultLanguage?.Code] ||
          imprint.customHtmlSourcePath["en"] ||
          Object.values(imprint.customHtmlSourcePath).find(path => !!path);
        if (templatePath) {
          const { data } = await instance.get(
            `${Urls.get()?.blob}/${templatePath}`
          );
          if (data) {
            setHtml(data);
          }
        }
      } catch (err) {
      } finally {
        setLoading(false);
      }
    })();
  }, [lang]);

  useEffect(() => {
    let imprintLocalData = {};
    if (imprint) {
      imprintLocalData = Object.fromEntries(
        Object.entries(imprint)
          .filter(([key, value]) => key !== 'customHtmlSourcePath' && value.length)
      );
      setImprintData(imprintLocalData);
    }
  }, [imprint]);

  const noContent = !loading && !Object.keys(imprintData).length && !html;

  return (
    <div data-qa="imprint">
      <Header text={i18nextTranslate(i18nextKeys.imprintHeader)} />
      {loading ? (
        <LoadingSpinner classes="m-auto" />
      ) : (
        <LogOnMount eventType="Imprint checked">
          {noContent ? (
            <NoDataPage style={{ height: "60vh" }} />
          ) : (
            <div className="flex flex-col gap-32">
              {!!Object.keys(imprintData).length && (
                <Tile
                  dataQa="imprint-data"
                  defaultPadding="p-16"
                >
                  <div className="flex gap-56 xl:gap-64 xxl:gap-72">
                    <div className="flex flex-col gap-24 md:gap-8 xxl:gap-10">
                      {Object.keys(imprintData).map((labelKey, index) => (
                        <div
                          key={labelKey + index}
                          className="flex flex-col gap-4"
                        >
                          <Text textStyle="p2">
                            {i18nextTranslate(
                              i18nextKeys['imprint' + capitalizeString(labelKey)]
                            )}:
                          </Text>
                          {!md && (
                            <Text>
                              {imprintData[labelKey]}
                            </Text>
                          )}
                        </div>
                      ))}
                    </div>
                    {md && (
                      <div className="flex flex-col gap-8 xxl:gap-10">
                        {Object.values(imprintData).map((value, index) => (
                          <Text key={value + index}>
                            {value}
                          </Text>
                        ))}
                      </div>
                    )}
                  </div>
                </Tile>
              )}
             {html && (
                <div
                  style={{ minHeight: '100%' }}
                  dangerouslySetInnerHTML={{ __html: html }}
                ></div>
              )}
            </div>
          )}
        </LogOnMount>
      )}
    </div>
  );
};

export default Imprint;
