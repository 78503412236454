import React from "react";

const EditAltIcon = (props) => (
  <svg
    width="13"
    height="12"
    viewBox="0 0 13 12"
    fill="currentColor"
    {...props}
  >
    <path
      d="M1.24974 12H4.42858C4.52725 12.0006 4.62506 11.9816 4.71641 11.9443C4.80775 11.907 4.89084 11.8521 4.96089 11.7826L10.149 6.58695L12.2782 4.50271C12.3485 4.43302 12.4043 4.35009 12.4423 4.25873C12.4804 4.16737 12.5 4.06938 12.5 3.97041C12.5 3.87143 12.4804 3.77344 12.4423 3.68208C12.4043 3.59072 12.3485 3.5078 12.2782 3.4381L9.09939 0.221768C9.02969 0.151497 8.94677 0.0957219 8.85541 0.0576592C8.76405 0.0195966 8.66605 0 8.56708 0C8.46811 0 8.37011 0.0195966 8.27875 0.0576592C8.18739 0.0957219 8.10447 0.151497 8.03477 0.221768L5.92054 2.3435L0.717433 7.53911C0.647948 7.60916 0.592974 7.69225 0.555664 7.78359C0.518354 7.87493 0.499442 7.97275 0.500013 8.07141V11.2503C0.500013 11.4491 0.579002 11.6398 0.719603 11.7804C0.860204 11.921 1.0509 12 1.24974 12ZM8.56708 1.81119L10.6888 3.93292L9.6242 4.99753L7.50247 2.8758L8.56708 1.81119ZM1.99947 8.3788L6.44535 3.93292L8.56708 6.05465L4.1212 10.5005H1.99947V8.3788Z" />
  </svg>
);

export default EditAltIcon;
