import React, { useContext } from 'react';
import { UiContext } from 'States/ui/uiState';
import { DIRECTION } from 'Helpers/icons';
import { SingleChevron, DoubleChevron } from 'Components/shared/symbols';
import i18nextTranslate from 'Lang/i18nextTranslate';
import { i18nextKeys } from 'Lang/i18nextKeys';
import i18nextTranslateDynamically from 'Lang/i18nextTranslateDynamically';
import Text from '../Text';

const PaginationFooter = ({
  gotoPage,
  previousPage,
  nextPage,
  canPreviousPage,
  canNextPage,
  pageOptions,
  pageIndex,
  pageCount,
  dataQa = "pagination"
}) => {
  const {
    breakpoints: { xxl }
  } = useContext(UiContext);

  return (
    <div className="pagination color-8_80 w-full flex justify-between mt-24">
      <div className="flex items-center gap-16">
        <div className="flex items-center gap-4">
          <button
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
            data-qa={`${dataQa}-page-first`}
          >
            <DoubleChevron
              size={ xxl ? "20" : "16" }
              left
            />
          </button>
          <button
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
            data-qa={`${dataQa}-page-previous`}
          >
            <SingleChevron
              direction={DIRECTION.left}
              size={ xxl ? "20" : "16" }
            />
          </button>
        </div>

        <Text
          textStyle="h3"
          color="color-8_80"
          dataQa={`${dataQa}-page-current`}
        >
          {i18nextTranslateDynamically(
            i18nextKeys.tablePaginationPage,
            { thisPage: pageIndex + 1, lastPage: pageOptions.length }
          )}
        </Text>
      </div>

      <div className="flex gap-16">
        <div className="flex items-center gap-10">
          <Text
            textStyle="text-small"
            color="color-8_80"
            dataQa={`${dataQa}-page-number-label`}
          >
            {i18nextTranslate(i18nextKeys.tablePaginationGoToPage)}
          </Text>
          <input
            className="bg-2 text-right border hide-number color-8_80 border-color-8_80 pr-10 pl-4"
            type="number"
            defaultValue={pageIndex + 1}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              gotoPage(page);
            }}
            style={{ width: xxl ? '48px' : '44px' }}
            data-qa={`${dataQa}-page-number-input`}
          />
        </div>

        <div className="flex items-center gap-4">
          <button
            onClick={() => nextPage()}
            disabled={!canNextPage}
            data-qa={`${dataQa}-page-next`}
          >
            <SingleChevron
              direction={DIRECTION.right}
              size={ xxl ? "20" : "16" }
            />
          </button>
          <button
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
            data-qa={`${dataQa}-page-last`}
          >
            <DoubleChevron size={ xxl ? "20" : "16" } />
          </button>
        </div>
      </div>
    </div>
  );
};

export default PaginationFooter;
