import React from "react";
import i18nextTranslate from "Lang/i18nextTranslate";
import { i18nextKeys } from "Lang/i18nextKeys";
import Modal from "Components/shared/Modal";
import LoadingFlashingDots from "Components/shared/LoadingFlashingDots";

const KycProviderRedirectionModal = ({ show, close }) => (
  <Modal
    dataQa="kyc-tiers-providerRedirectionModal"
    show={show}
    close={close}
  >
    <div className="color-8 text-center py-40 px-16 sm:px-40">
      <LoadingFlashingDots />
      <h1 className="font-bold text-xl mb-16 mt-32">
        {i18nextTranslate(i18nextKeys.kycPoviderRedirectonModalHeading)}
      </h1>
      <p className="px-40 sm:px-96 md:px-128">
        {i18nextTranslate(i18nextKeys.kycPoviderRedirectonModalText)}
      </p>
    </div>
  </Modal>
);

export default KycProviderRedirectionModal;
