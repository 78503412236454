import React, { useContext } from 'react';
import { UiContext } from 'States/ui/uiState';
import LoadingSpinner from 'Components/shared/LoadingSpinner';
import Text from '../Text';

const Button = ({
  text,
  icon: Icon,
  onClick,
  className = "",
  style = {},
  type = 'button',
  width = { default: "auto" },
  dataQa,
  loading = false,
  fullWidth = false,
  secondary = false,
  tertiary = false,
  outlined = false,
  disabled = false,
  small = false,
  square = false,
  ...props
}) => {
  const {
    breakpoints: { xxl, xl, lg, md },
  } = useContext(UiContext);

  const regularButtonHeight = xxl ? '38px' : '35px';
  const smallButtonHeight = xxl ? '19px' : '16px';

  const buttonWidth = fullWidth
    ? "100%"
    : (xxl && width.xxl ||
      xl && width.xl ||
      lg && width.lg ||
      md && width.md ||
      width.default);

  let buttonClassNames = "";

  if (outlined) {
    buttonClassNames = `${
      disabled ? "color-white" : "color-8"
    } border border-color-5 bg-transparent hover:bg-4_7 active:bg-4_15`;
  } else if (tertiary) {
    buttonClassNames = `${
      disabled ? "color-white" : "color-8"
    } bg-3 hover:bg-3--5`
  } else if (secondary) {
    buttonClassNames = "color-white bg-4--20 hover:bg-4--40"
  } else {
    buttonClassNames = "color-white bg-4 hover:bg-4--20";
  }

  return (
    <button
      disabled={disabled}
      data-qa={dataQa}
      onClick={onClick}
      style={{
        width: square ? regularButtonHeight : buttonWidth,
        ...small
          ? { minHeight: smallButtonHeight }
          : { minHeight: regularButtonHeight },
        ...style
      }}
      type={type}
      className={`flex-shrink-0 flex items-center justify-center gap-8 rounded ${
          buttonClassNames
        } active:outline-none active:border active:border-color-5 ${
          small ? 'px-6' : 'px-12 py-2'
        } disabled:cursor-default disabled:bg-grey disabled:border-none ${
          className
        } transition duration-300 ease-in-out`}
      {...props}
    >
      {loading ? (
        <LoadingSpinner
          size="h-16 w-16"
          classes="m-auto border-color-8"
          dataQa={`${dataQa}-loading`}
        />
      ) : (
        <>
          {!!text && (
            <Text
              textStyle={ small ? "h3" : "text-button"}
              color="text-current"
              className=""
              uppercase={!small}
              dataQa={`${dataQa}-text`}
            >
              {text}
            </Text>
          )}
          {Icon ? (
            <Icon
              size={xxl ? 16 : 14}
              className="flex-shrink-0"
            />
          ) : null}
        </>
      )}
    </button>
  );
};

export default Button;
