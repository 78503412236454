import { UserManager, WebStorageStateStore } from 'oidc-client';

let userManager = null;
export async function getUserManager(env) {
  if (userManager) {
    return userManager;
  }

  const { protocol, hostname, port = '' } = window.location;
  const baseRedirectUri = `${protocol}//${hostname}${port ? `:${port}` : ''}`;
  const loginRedirectUri = `${baseRedirectUri}`;
  const postlogoutRedirectUri = `${baseRedirectUri}`;

  const authConfig = env.AuthConfig;
  userManager = new UserManager({
    authority: env.Endpoints.AuthApi,
    client_id: authConfig.ClientId,
    redirect_uri: `${authConfig.RedirectUri || loginRedirectUri}/auth/callback`,
    post_logout_redirect_uri: `${
      authConfig.PostLogoutRedirectUri || postlogoutRedirectUri
    }`,
    response_type: 'id_token token',
    scope: `${authConfig.Scopes}`,
    userStore: new WebStorageStateStore({ store: localStorage }),
  });

  return userManager;
}
