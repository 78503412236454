import i18nextTranslate from "../Lang/i18nextTranslate";
import {i18nextKeys} from "../Lang/i18nextKeys";

const TRANSACTION_STATUS = {
  NotInitiated: 'NotInitiated',
  Received: 'Received',
  Submitted: 'Submitted',
  Committed: 'Committed',
  Confirmed: 'Confirmed',
  RevokedByBlockchain: 'RevokedByBlockchain',
  RevokedByUser: 'RevokedByUser',
  RevokedByTxServer: 'RevokedByTxServer',
};

const enumToFriendlyString = (e) => {
  const {
    NotInitiated,
    Received,
    Submitted,
    Committed,
    Confirmed,
    RevokedByBlockchain,
    RevokedByUser,
    RevokedByTxServer
  } = TRANSACTION_STATUS;
  switch (e) {
    case NotInitiated: {
      return i18nextTranslate(i18nextKeys.enumTransactionStatusNotInitiated);
    }
    case Received: {
      return i18nextTranslate(i18nextKeys.enumTransactionStatusReceived);
    }
    case Submitted: {
      return i18nextTranslate(i18nextKeys.enumTransactionStatusSubmitted);
    }
    case Committed: {
      return i18nextTranslate(i18nextKeys.enumTransactionStatusCommitted);
    }
    case Confirmed: {
      return i18nextTranslate(i18nextKeys.enumTransactionStatusConfirmed);
    }
    case RevokedByBlockchain: {
      return i18nextTranslate(i18nextKeys.enumTransactionStatusRevokedByBlockchain);
    }
    case RevokedByUser: {
      return i18nextTranslate(i18nextKeys.enumTransactionStatusRejectedByUser);
    }
    case RevokedByTxServer: {
      return i18nextTranslate(i18nextKeys.enumTransactionStatusFailed);
    }
    default: {
      return i18nextTranslate(i18nextKeys.enumInvalidEnum);
    }
  }
};

const TransactionStatus = {
  values: TRANSACTION_STATUS,
  enumToFriendlyString
};

export default TransactionStatus;
