import React from 'react';

const AbortSymbol = ({
  size = "12",
  onClick = () => {},
  ...props
}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 12 12"
    fill="currentColor"
    onClick={onClick}
    {...props}
  >
    <path
      d="M3 3L9 9"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M3 9L9 3"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
);

export default AbortSymbol;
