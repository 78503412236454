import TENANT_FEATURE from 'Enums/TenantFeature';
import { i18nextKeys } from 'Lang/i18nextKeys';
import i18nextTranslate from 'Lang/i18nextTranslate';
import i18nextTranslateDynamically from 'Lang/i18nextTranslateDynamically';

export const getTranslation = (
  key,
  language,
  defaultLanguage,
  translations = [],
  returnObj = false
) => {
  if (!translations.length) {
    return null;
  }
  const translation = translations.find(
    (translation) =>
      translation.LanguageCode === language &&
      !!translation[key]
  );
  if (translation) {
    return returnObj ? translation : translation[key];
  }
  const defaultTranslation = translations.find(
    (translation) =>
      translation.LanguageCode === defaultLanguage &&
      !!translation[key]
  );

  if (defaultTranslation) {
    return returnObj ? defaultTranslation : defaultTranslation[key];
  }

  const englishTranslation = translations.find(
    (translation) =>
      translation.LanguageCode === "en" &&
      !!translation[key]
  );

  if (englishTranslation) {
    return returnObj ? englishTranslation : englishTranslation[key];
  }

  const fallbackTranslation = translations.find(
    (translation) => !!translation[key]
  );

  if (fallbackTranslation) {
    return returnObj ? fallbackTranslation : fallbackTranslation[key];
  }

  return null;
};

export const getFeatureTitleKey = (featureName) => {
  switch (featureName) {
    case TENANT_FEATURE.purchase: {
      return i18nextKeys.tenantSettingsFeaturesPurchaseTitle;
    }
    case TENANT_FEATURE.kyc: {
      return i18nextKeys.tenantSettingsFeaturesKycTitle;
    }
    case TENANT_FEATURE.redeem: {
      return i18nextKeys.tenantSettingsFeaturesRedeemTitle;
    }
    case TENANT_FEATURE.customFeaturedAsset: {
      return i18nextKeys.tenantSettingsFeaturesCustomPurchaseTitle;
    }
    default: {
      return i18nextKeys.enumInvalidEnum;
    }
  }
};

export const getFeatureDescriptionKey = (featureName) => {
  switch (featureName) {
    case TENANT_FEATURE.purchase: {
      return i18nextKeys.tenantSettingsFeaturesPurchaseDescription;
    }
    case TENANT_FEATURE.kyc: {
      return i18nextKeys.tenantSettingsFeaturesKycDescription;
    }
    case TENANT_FEATURE.redeem: {
      return i18nextKeys.tenantSettingsFeaturesRedeemDescription;
    }
    case TENANT_FEATURE.customFeaturedAsset: {
      return i18nextKeys.tenantSettingsFeaturesCustomPurchaseDescription;
    }
    default: {
      return i18nextKeys.enumInvalidEnum;
    }
  }
};

export const getFeatureToggleTextConfirmationTitle = (featureName, isEnabled) => {
  switch (featureName) {
    case TENANT_FEATURE.purchase:
    case TENANT_FEATURE.redeem:
    case TENANT_FEATURE.kyc: {
      return i18nextTranslateDynamically(
        isEnabled
          ? i18nextKeys.featureToggleDisable
          : i18nextKeys.featureToggleActivate,
        {
          featureName: i18nextTranslate(
            getFeatureTitleKey(featureName)
          )
        }
      );
    }
    case TENANT_FEATURE.restrictedMode: {
      return i18nextTranslate(isEnabled
        ? i18nextKeys.tenantSettingsFeaturesRestrictedModeDisableTitle
        : i18nextKeys.tenantSettingsFeaturesRestrictedModeEnableTitle);
    }
    default: {
      return null;
    }
  }
};

export const getFeatureToggleTextConfirmationKey = (featureName, isEnabled) => {
  switch (featureName) {
    case TENANT_FEATURE.purchase: {
      return isEnabled
        ? i18nextKeys.featureToggleModalPurchaseDisableText
        : i18nextKeys.featureToggleModalPurchaseActivateText;
    }
    case TENANT_FEATURE.redeem: {
      return isEnabled
        ? i18nextKeys.featureToggleModalRedeemDisableText
        : i18nextKeys.featureToggleModalRedeemActivateText;
    }
    case TENANT_FEATURE.kyc: {
      return isEnabled
        ? i18nextKeys.featureToggleModalKycDisableText
        : i18nextKeys.featureToggleModalKycActivateText;
    }
    case TENANT_FEATURE.customFeaturedAsset: {
      return isEnabled
        ? i18nextKeys.featureToggleModalCustomPurchaseDisableText
        : i18nextKeys.featureToggleModalCustomPurchaseActivateText;
    }
    case TENANT_FEATURE.restrictedMode: {
      return isEnabled
        ? i18nextKeys.tenantSettingsFeaturesRestrictedModeDisableDescription
        : i18nextKeys.tenantSettingsFeaturesRestrictedModeEnableDescription;
    }
    default: {
      return null;
    }
  }
};
