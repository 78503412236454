import React, { useContext } from 'react';
import { UiContext } from 'States/ui/uiState';

const Overlay = () => {
  const {
    smallScreenSidebarShowing,
    setSmallScreenSidebarShowing,
  } = useContext(UiContext);

  return (
    <div
      onClick={() => setSmallScreenSidebarShowing(false)}
      className={`${
        smallScreenSidebarShowing ? '' : 'hidden'
      } absolute w-full h-full bg-gray-900 opacity-50 lg:hidden`}
    ></div>
  );
};

export default Overlay;
