import { i18nextKeys } from '../Lang/i18nextKeys';
export default {
  AF: i18nextKeys.langAF,
  AX: i18nextKeys.langAX,
  AL: i18nextKeys.langAL,
  DZ: i18nextKeys.langDZ,
  AS: i18nextKeys.langAS,
  AD: i18nextKeys.langAD,
  AO: i18nextKeys.langAO,
  AI: i18nextKeys.langAI,
  AQ: i18nextKeys.langAQ,
  AG: i18nextKeys.langAG,
  AR: i18nextKeys.langAR,
  AM: i18nextKeys.langAM,
  AW: i18nextKeys.langAW,
  AU: i18nextKeys.langAU,
  AT: i18nextKeys.langAT,
  AZ: i18nextKeys.langAZ,
  BS: i18nextKeys.langBS,
  BH: i18nextKeys.langBH,
  BD: i18nextKeys.langBD,
  BB: i18nextKeys.langBB,
  BY: i18nextKeys.langBY,
  BE: i18nextKeys.langBE,
  BZ: i18nextKeys.langBZ,
  BJ: i18nextKeys.langBJ,
  BM: i18nextKeys.langBM,
  BT: i18nextKeys.langBT,
  BO: i18nextKeys.langBO,
  BA: i18nextKeys.langBA,
  BW: i18nextKeys.langBW,
  BV: i18nextKeys.langBV,
  BR: i18nextKeys.langBR,
  IO: i18nextKeys.langIO,
  BN: i18nextKeys.langBN,
  BG: i18nextKeys.langBG,
  BF: i18nextKeys.langBF,
  BI: i18nextKeys.langBI,
  KH: i18nextKeys.langKH,
  CM: i18nextKeys.langCM,
  CA: i18nextKeys.langCA,
  CV: i18nextKeys.langCV,
  KY: i18nextKeys.langKY,
  CF: i18nextKeys.langCF,
  TD: i18nextKeys.langTD,
  CL: i18nextKeys.langCL,
  CN: i18nextKeys.langCN,
  CX: i18nextKeys.langCX,
  CC: i18nextKeys.langCC,
  CO: i18nextKeys.langCO,
  KM: i18nextKeys.langKM,
  CG: i18nextKeys.langCG,
  CD: i18nextKeys.langCD,
  CK: i18nextKeys.langCK,
  CR: i18nextKeys.langCR,
  CI: i18nextKeys.langCI,
  HR: i18nextKeys.langHR,
  CU: i18nextKeys.langCU,
  CY: i18nextKeys.langCY,
  CZ: i18nextKeys.langCZ,
  DK: i18nextKeys.langDK,
  DJ: i18nextKeys.langDJ,
  DM: i18nextKeys.langDM,
  DO: i18nextKeys.langDO,
  EC: i18nextKeys.langEC,
  EG: i18nextKeys.langEG,
  SV: i18nextKeys.langSV,
  GQ: i18nextKeys.langGQ,
  ER: i18nextKeys.langER,
  EE: i18nextKeys.langEE,
  ET: i18nextKeys.langET,
  FK: i18nextKeys.langFK,
  FO: i18nextKeys.langFO,
  FJ: i18nextKeys.langFJ,
  FI: i18nextKeys.langFI,
  FR: i18nextKeys.langFR,
  GF: i18nextKeys.langGF,
  PF: i18nextKeys.langPF,
  TF: i18nextKeys.langTF,
  GA: i18nextKeys.langGA,
  GM: i18nextKeys.langGM,
  GE: i18nextKeys.langGE,
  DE: i18nextKeys.langDE,
  GH: i18nextKeys.langGH,
  GI: i18nextKeys.langGI,
  GR: i18nextKeys.langGR,
  GL: i18nextKeys.langGL,
  GD: i18nextKeys.langGD,
  GP: i18nextKeys.langGP,
  GU: i18nextKeys.langGU,
  GT: i18nextKeys.langGT,
  GG: i18nextKeys.langGG,
  GN: i18nextKeys.langGN,
  GW: i18nextKeys.langGW,
  GY: i18nextKeys.langGY,
  HT: i18nextKeys.langHT,
  HM: i18nextKeys.langHM,
  VA: i18nextKeys.langVA,
  HN: i18nextKeys.langHN,
  HK: i18nextKeys.langHK,
  HU: i18nextKeys.langHU,
  IS: i18nextKeys.langIS,
  IN: i18nextKeys.langIN,
  ID: i18nextKeys.langID,
  IR: i18nextKeys.langIR,
  IQ: i18nextKeys.langIQ,
  IE: i18nextKeys.langIE,
  IM: i18nextKeys.langIM,
  IL: i18nextKeys.langIL,
  IT: i18nextKeys.langIT,
  JM: i18nextKeys.langJM,
  JP: i18nextKeys.langJP,
  JE: i18nextKeys.langJE,
  JO: i18nextKeys.langJO,
  KZ: i18nextKeys.langKZ,
  KE: i18nextKeys.langKE,
  KI: i18nextKeys.langKI,
  KR: i18nextKeys.langKR,
  KP: i18nextKeys.langKP,
  KW: i18nextKeys.langKW,
  KG: i18nextKeys.langKG,
  LA: i18nextKeys.langLA,
  LV: i18nextKeys.langLV,
  LB: i18nextKeys.langLB,
  LS: i18nextKeys.langLS,
  LR: i18nextKeys.langLR,
  LY: i18nextKeys.langLY,
  LI: i18nextKeys.langLI,
  LT: i18nextKeys.langLT,
  LU: i18nextKeys.langLU,
  MO: i18nextKeys.langMO,
  MK: i18nextKeys.langMK,
  MG: i18nextKeys.langMG,
  MW: i18nextKeys.langMW,
  MY: i18nextKeys.langMY,
  MV: i18nextKeys.langMV,
  ML: i18nextKeys.langML,
  MT: i18nextKeys.langMT,
  MH: i18nextKeys.langMH,
  MQ: i18nextKeys.langMQ,
  MR: i18nextKeys.langMR,
  MU: i18nextKeys.langMU,
  YT: i18nextKeys.langYT,
  MX: i18nextKeys.langMX,
  FM: i18nextKeys.langFM,
  MD: i18nextKeys.langMD,
  MC: i18nextKeys.langMC,
  MN: i18nextKeys.langMN,
  ME: i18nextKeys.langME,
  MS: i18nextKeys.langMS,
  MA: i18nextKeys.langMA,
  MZ: i18nextKeys.langMZ,
  MM: i18nextKeys.langMM,
  NA: i18nextKeys.langNA,
  NR: i18nextKeys.langNR,
  NP: i18nextKeys.langNP,
  NL: i18nextKeys.langNL,
  AN: i18nextKeys.langAN,
  NC: i18nextKeys.langNC,
  NZ: i18nextKeys.langNZ,
  NI: i18nextKeys.langNI,
  NE: i18nextKeys.langNE,
  NG: i18nextKeys.langNG,
  NU: i18nextKeys.langNU,
  NF: i18nextKeys.langNF,
  MP: i18nextKeys.langMP,
  NO: i18nextKeys.langNO,
  OM: i18nextKeys.langOM,
  PK: i18nextKeys.langPK,
  PW: i18nextKeys.langPW,
  PS: i18nextKeys.langPS,
  PA: i18nextKeys.langPA,
  PG: i18nextKeys.langPG,
  PY: i18nextKeys.langPY,
  PE: i18nextKeys.langPE,
  PH: i18nextKeys.langPH,
  PN: i18nextKeys.langPN,
  PL: i18nextKeys.langPL,
  PT: i18nextKeys.langPT,
  PR: i18nextKeys.langPR,
  QA: i18nextKeys.langQA,
  RE: i18nextKeys.langRE,
  RO: i18nextKeys.langRO,
  RU: i18nextKeys.langRU,
  RW: i18nextKeys.langRW,
  BL: i18nextKeys.langBL,
  SH: i18nextKeys.langSH,
  KN: i18nextKeys.langKN,
  LC: i18nextKeys.langLC,
  MF: i18nextKeys.langMF,
  PM: i18nextKeys.langPM,
  VC: i18nextKeys.langVC,
  WS: i18nextKeys.langWS,
  SM: i18nextKeys.langSM,
  ST: i18nextKeys.langST,
  SA: i18nextKeys.langSA,
  SN: i18nextKeys.langSN,
  RS: i18nextKeys.langRS,
  SC: i18nextKeys.langSC,
  SL: i18nextKeys.langSL,
  SG: i18nextKeys.langSG,
  SK: i18nextKeys.langSK,
  SI: i18nextKeys.langSI,
  SB: i18nextKeys.langSB,
  SO: i18nextKeys.langSO,
  ZA: i18nextKeys.langZA,
  GS: i18nextKeys.langGS,
  ES: i18nextKeys.langES,
  LK: i18nextKeys.langLK,
  SD: i18nextKeys.langSD,
  SR: i18nextKeys.langSR,
  SJ: i18nextKeys.langSJ,
  SZ: i18nextKeys.langSZ,
  SE: i18nextKeys.langSE,
  CH: i18nextKeys.langCH,
  SY: i18nextKeys.langSY,
  TW: i18nextKeys.langTW,
  TJ: i18nextKeys.langTJ,
  TZ: i18nextKeys.langTZ,
  TH: i18nextKeys.langTH,
  TL: i18nextKeys.langTL,
  TG: i18nextKeys.langTG,
  TK: i18nextKeys.langTK,
  TO: i18nextKeys.langTO,
  TT: i18nextKeys.langTT,
  TN: i18nextKeys.langTN,
  TR: i18nextKeys.langTR,
  TM: i18nextKeys.langTM,
  TC: i18nextKeys.langTC,
  TV: i18nextKeys.langTV,
  UG: i18nextKeys.langUG,
  UA: i18nextKeys.langUA,
  AE: i18nextKeys.langAE,
  GB: i18nextKeys.langGB,
  US: i18nextKeys.langUS,
  UM: i18nextKeys.langUM,
  UY: i18nextKeys.langUY,
  UZ: i18nextKeys.langUZ,
  VU: i18nextKeys.langVU,
  VE: i18nextKeys.langVE,
  VN: i18nextKeys.langVN,
  VG: i18nextKeys.langVG,
  VI: i18nextKeys.langVI,
  WF: i18nextKeys.langWF,
  EH: i18nextKeys.langEH,
  YE: i18nextKeys.langYE,
  ZM: i18nextKeys.langZM,
  ZW: i18nextKeys.langZW,
};
