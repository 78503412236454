import React, { useEffect, useState, useContext } from "react";
import { UiContext } from 'States/ui/uiState';
import i18nextTranslate from "Lang/i18nextTranslate";
import { i18nextKeys } from "Lang/i18nextKeys";
import { STORAGE_KEYS } from "Enums";
import {
  CheckboxWithText,
  DropdownField,
} from "Components/shared/formElements";
import OptionAddAnotherBankAccount from "Components/shared/formElements/Dropdown/OptionAddAnotherBankAccount";
import Text from "Components/shared/Text";
import { HorizontalRule } from "Components/shared/Tile";
import BankAccountFormModal from 'Components/BankAccount/Form/BankAccountFormModal';
import BankDetailsForm from 'Components/BankAccount/Form/BankDetailsForm';
import BankAddressForm from 'Components/BankAccount/Form/BankAddressForm';
import BankAccountSummary from "Components/BankAccount/Summary/BankAccountSummary";

const BankAccountSelection = ({
  bankAccounts,
  selectedBankAccount,
  selectBankAccount = () => {},
  refetchBankAccounts = () => {},
  changeFormValue,
  disabled = false
}) => {
  const [savedBankAccount, setSavedBankAccount] = useState(
    JSON.parse(sessionStorage.getItem(STORAGE_KEYS.redemptionForm))?.bankAccount
  );
  const [showIntermediatyBankForm, setShowIntermediaryBankForm] = useState(false);
  const [showBankAccountModal, setShowBankAccountModal] = useState(false);
  const { breakpoints: { md } } = useContext(UiContext);

  const newBankAccountOption = {
    Id: null,
    IBAN: i18nextTranslate(i18nextKeys.redemptionAnotherBankAccount),
    addAnother: true
  };

  useEffect(() => {
    if (disabled) {
      return;
    }
    if (!bankAccounts?.length || (savedBankAccount && !savedBankAccount?.Id)) {
      selectBankAccount(newBankAccountOption);
      if (savedBankAccount?.IntermediaryBankAccount) {
        setShowIntermediaryBankForm(true);
      }
      return;
    }
    if (savedBankAccount?.Id) {
      const existingBankAccount = bankAccounts.find(
        ({ Id }) => Id === savedBankAccount.Id
      );
      if (existingBankAccount) {
        selectBankAccount(existingBankAccount);
      }
    }
  }, [bankAccounts]);

  useEffect(() => {
    if (selectedBankAccount?.Id) {
      changeFormValue('bankAccount', selectedBankAccount);
    }
  }, [selectedBankAccount]);

  const onBankAccountChange = (id, bankAccount) => {
    if (savedBankAccount) {
      setSavedBankAccount(null);
    }
    if (bankAccount.addAnother && selectedBankAccount?.Id) {
      changeFormValue('bankAccount', null);
    }
    selectBankAccount(bankAccount);
  };

  const onBankFormSubmit = () => {
    refetchBankAccounts();
    setShowBankAccountModal(false);
  };

  return (
    <div className="flex flex-col gap-24 xl:gap-32">
      <div
        className={`flex flex-col md:flex-row md:justify-between gap-32 md:gap-0 ${
          selectedBankAccount?.Id ? 'items-start' : ''
        } ${
          selectedBankAccount?.addAnother ? 'items-center' : ''
        }`
      }>
        <DropdownField
          replacedComponents={{ Option: OptionAddAnotherBankAccount }}
          label={i18nextTranslate(i18nextKeys.redemptionSelectBankAccountLabel)}
          placeholder=""
          options={[
            newBankAccountOption,
            {
              label: i18nextTranslate(i18nextKeys.redemptionChooseExistingBankAccount),
              options: bankAccounts
            }
          ]}
          labelKey="IBAN"
          valueKey="Id"
          input={{
            value: selectedBankAccount,
            onChange: onBankAccountChange
          }}
          meta={{}}
          style={{ width: !md ? '100%' : selectedBankAccount?.Id ? '50%' : '45%' }}
          dataQa="bank-select"
          id="redemption-bank-account-dropdown"
          onEdit={() => setShowBankAccountModal(true)}
          showEditIcon={selectedBankAccount?.Id}
          showErrors={false}
          disabled={disabled || !bankAccounts?.length}
          required
        />
        {!!selectedBankAccount?.Id && (
          <Text
            textStyle="p4"
            style={{ width: md ? '45%' : '100%' }}
            dataQa="bank-edit-warning"
          >
            {i18nextTranslate(i18nextKeys.redemptionBankAccountChangesWarning)}
          </Text>
        )}
        {selectedBankAccount?.addAnother && (
          <CheckboxWithText
            name="bankAccount.IsActive"
            checked={!!savedBankAccount?.IsActive}
            text={i18nextTranslate(i18nextKeys.redemptionAddToMyBankAccounts)}
            className="items-center"
            style={{ width: md ? '45%' : '100%' }}
            dataQa="bank-save"
          />
        )}
      </div>
      {!!selectedBankAccount?.Id && (
        <>
          <HorizontalRule
            xlMargin="xl:-mx-32"
            xxlMargin="xxl:-mx-32"
          />
          <BankAccountSummary
            bankAccount={selectedBankAccount}
          />
        </>
      )}
      {selectedBankAccount?.addAnother && (
        <>
          <HorizontalRule
            xlMargin="xl:-mx-32"
            xxlMargin="xxl:-mx-32"
          />
          <div className="flex flex-col md:flex-row md:justify-between gap-32 md:gap-0">
            <BankDetailsForm
              initialValues={savedBankAccount}
              style={{ width: md ? '45%' : '100%' }}
            />
            <BankAddressForm
              initialValues={savedBankAccount}
              addIntermediaryBank={() => setShowIntermediaryBankForm(true)}
              isIntermediaryPresent={showIntermediatyBankForm}
              style={{ width: md ? '45%' : '100%' }}
            />
          </div>
          {showIntermediatyBankForm && (
            <>
              <HorizontalRule
                xlMargin="xl:-mx-32"
                xxlMargin="xxl:-mx-32"
              />
              <div className="flex flex-col md:flex-row md:justify-between gap-32 md:gap-0">
                <BankDetailsForm
                  initialValues={savedBankAccount?.IntermediaryBankAccount}
                  removeIntermediaryBank={() => setShowIntermediaryBankForm(false)}
                  style={{ width: md ? '45%' : '100%' }}
                  showDeleteButton={md}
                  intermediary
                />
                <BankAddressForm
                  initialValues={savedBankAccount?.IntermediaryBankAccount}
                  style={{ width: md ? '45%' : '100%' }}
                  removeIntermediaryBank={() => setShowIntermediaryBankForm(false)}
                  showDeleteButton={!md}
                  intermediary
                />
              </div>
            </>
          )}
        </>
      )}
      {showBankAccountModal && (
        <BankAccountFormModal
          show={showBankAccountModal}
          close={() => setShowBankAccountModal(false)}
          onBankFormSubmit={onBankFormSubmit}
          bankAccount={selectedBankAccount}
        />
      )}
    </div>
  );
};

export default BankAccountSelection;