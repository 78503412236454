import React, { useCallback, useContext, useState, useEffect, useRef } from 'react';
import { Form as FinalForm } from 'react-final-form';
import { STORAGE_KEYS } from "Enums";
import useHandleError from 'Utils/handleError';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import api from 'Api';
import { ConfigContext } from 'States/config/configState';
import LoadingSpinner from 'Components/shared/LoadingSpinner';
import {
  Tile,
  TileInfoCard,
  HorizontalRule
} from 'Components/shared/Tile';
import Stamp from 'Components/shared/Stamp';
import { CustomTerms, Terms } from 'Components/shared/Terms';
import BankAccountSummary from 'Components/BankAccount/Summary/BankAccountSummary';
import UserDetailsSummary from 'Components/UserDetails/UserDetailsSummary';
import PayReceiveSummary from '../PayReceive/PayReceiveSummary';
import RedemptionFormFooter from '../RedemptionFormFooter';
import {
  BANK_TRANSFER,
  BITCOIN,
  ETH,
  ERC20,
  PHYSICAL
} from 'Enums/RedemptionMethodTypes';
import i18nextTranslate from 'Lang/i18nextTranslate';
import { i18nextKeys } from 'Lang/i18nextKeys';
import { useAmplitude } from 'react-amplitude-hooks';
import useConfigSettings from 'Hooks/useConfigSettings';
import { ROUTES } from 'Router/Routes';

const RedemptionSummary = () => {
  const handleError = useHandleError();
  const history = useHistory();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const {
    id: redemptionTypeId
  } = useParams();
  const { logEvent } = useAmplitude();
  const payReceiveRef = useRef(null);

  const {
    config: {
      redeemSettings : {
        additionalInfoRedemptionRequest
      }
    }
  } = useContext(ConfigContext);

  const { data: defaultLanguageCode } = useConfigSettings.query({
    select: useCallback(({ Languages }) => {
      const language = Languages.find(({ IsDefault }) => IsDefault)
      return language?.Code
    }, [])
  });

  useEffect(() => {
    if (
      !location.state?.redemptionForm ||
      !location.state?.amountBreakdown ||
      !location.state?.redemptionMethod ||
      !sessionStorage.getItem(STORAGE_KEYS.redemptionForm)
    ) {
      history.replace(ROUTES.redemption);
    }
  }, []);

  const {
    customTermsSummaryScreen: termsConfig,
    type: redemptionType
  } = location.state?.redemptionMethod || {};

  const onRedeemSummarySubmit = async () => {
    try {
      setIsLoading(true);
      const {
        beneficiary,
        bankAccount,
        wallet,
        AdditionalDetails
      } = location?.state?.redemptionForm;
      const updatedAmounts = payReceiveRef.current.getUpdatedAmounts();
      const redeemPayload = {
        RedemptionMethodId: redemptionTypeId,
        SparkAmount: updatedAmounts
          ? updatedAmounts.GrossAmount.AssetSparks
          : location.state.amountBreakdown.GrossAmount.AssetSparks,
        ReturnUrl: `${window.location.origin}${ROUTES.redemptions}/`,
        BeneficiaryAddress: beneficiary,
        AdditionalDetails,
        ...bankAccount && { BankAccount: bankAccount },
        ...wallet && { Wallet: wallet }
      };
      const redemption = await api.Redemptions.create(redeemPayload);
      logEvent("Redeem summary confirmed", {
        "redemption type": redemptionType
      });
      sessionStorage.removeItem(STORAGE_KEYS.redemptionForm);

      if ((!bankAccount?.Id && bankAccount?.IsActive)
        || (!wallet?.Id && wallet?.IsActive)) {
        savePaymentMethod({ bankAccount, wallet });
      }

      history.push({
        pathname: ROUTES.redemptionQR,
        state: {
          redemption,
          redemptionMethod: location.state.redemptionMethod,
          redemptionForm: location.state.redemptionForm,
          userEmail: location.state.userEmail
        },
      });
    } catch (error) {
      setIsLoading(false);
      const message = i18nextTranslate(
        i18nextKeys.errorRedeemSummaryRedemptionCreationFailure
      );
      handleError({ error, message });
    }
  };

  const savePaymentMethod = async ({ bankAccount, wallet }) => {
    try {
      if (bankAccount) {
        await api.BankAccounts.create(bankAccount);
      }
      if (wallet) {
        await api.Wallets.create(wallet);
      }
    } catch (error) {
      handleError({ error });
    }
  };

  const RedemptionMethodSummary = () => {
    switch(redemptionType) {
      case BANK_TRANSFER:
        return (
          <>
            <div className="flex flex-col gap-24">
              <Stamp
                label={i18nextTranslate(i18nextKeys.commonBankAccount)}
                badge={location.state.redemptionForm?.bankAccount?.Currency}
                upperText={location.state.redemptionForm?.bankAccount?.BankName}
                lowerText={location.state.redemptionForm?.bankAccount?.IBAN}
                dataQa="wallet-summary"
              />
              <BankAccountSummary
                bankAccount={location.state.redemptionForm?.bankAccount}
              />
            </div>
            <HorizontalRule />
          </>
        );
      case BITCOIN:
      case ETH:
      case ERC20:
        return (
          <Stamp
            label={i18nextTranslate(i18nextKeys.commonWalletCrypto)}
            badge={location.state.redemptionForm?.wallet?.Type}
            upperText={location?.state?.redemptionForm.wallet.Name}
            lowerText={location?.state?.redemptionForm.wallet.Address}
            dataQa="bank-summary"
          />
        );
      case PHYSICAL:
        return (
          <>
            <UserDetailsSummary
              beneficiary={location?.state?.redemptionForm.beneficiary}
              showPhoneNumber
            />
            <HorizontalRule />
          </>
        );
      default:
        return null;
    }
  };

  return isLoading || !location.state ? (
    <LoadingSpinner
      classes="mr-auto ml-auto"
      dataQa="loading-page"
    />
  ) : (
    <div className='flex flex-col gap-16 md:gap-24'>
      <Tile
        title={i18nextTranslate(i18nextKeys.redemptionSummaryHeader)}
        dataQa="summary"
      >
        <div className="flex flex-col gap-24 xl:gap-32">
          <RedemptionMethodSummary />
          {!!location.state.redemptionForm?.AdditionalDetails && (
            <>
              <TileInfoCard
                title={additionalInfoRedemptionRequest.name}
                titleTextStyle="p2"
                text={location.state.redemptionForm?.AdditionalDetails}
              />
              <HorizontalRule />
            </>
          )}
          <PayReceiveSummary
            ref={payReceiveRef}
            amountBreakdown={location.state.amountBreakdown}
            redemptionMethod={location.state.redemptionMethod}
            beneficiaryCountry={location.state.beneficiaryCountry}
          />
        </div>
      </Tile>

      <FinalForm
        onSubmit={onRedeemSummarySubmit}
        subscription={{ invalid: true }}
        render={({ handleSubmit, invalid }) => (
          <form
            className="flex flex-col gap-16 md:gap-24"
            onSubmit={handleSubmit}
          >
            <div className="flex flex-col gap-16">
              {termsConfig?.map((translations, index) => (
                <CustomTerms
                  key={index}
                  index={index}
                  defaultLanguageCode={defaultLanguageCode}
                  translations={translations}
                />
              ))}
              {undefined}
              <Terms />
            </div>
            <RedemptionFormFooter
              submitButtonText={i18nextTranslate(i18nextKeys.buttonConfirmAndSubmit)}
              submitDisabled={invalid}
              backButtonAction={() => history.goBack()}
            />
          </form>
        )}
      />
    </div>
  );
};

export default RedemptionSummary;
