import { combineValidators, isOneOf } from 'revalidate';
import { walletAddressValidator } from 'Components/shared/formElements/validators';
import i18nextTranslate from 'Lang/i18nextTranslate';
import { i18nextKeys } from 'Lang/i18nextKeys';
import { isRequiredWithMessage } from 'Utils/validators';
import { BTC_OPTION, ETH_OPTION } from 'Enums/WalletType';

export const options = [BTC_OPTION, ETH_OPTION];

const walletValidator = (values) => {
  return combineValidators({
    Address: walletAddressValidator(values.Type),
    Type: isOneOf(options)('Crypto'),
    Name: isRequiredWithMessage(i18nextTranslate(i18nextKeys.commonWalletName)),
  })(values);
};

export default walletValidator;
