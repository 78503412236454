import React, { useEffect, useState, useRef } from 'react';
import HeaderCell from 'Components/shared/Table/HeaderCell';
import TableCell from 'Components/shared/Table/TableCell';
import {
  useAsyncDebounce,
  useFilters,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';
import { DefaultColumnFilter } from 'Components/shared/Table/Filter';
import PaginationFooter from 'Components/shared/Table/PaginationFooter';
import LoadingSpinner from 'Components/shared/LoadingSpinner';
import i18nextTranslate from 'Lang/i18nextTranslate';
import { i18nextKeys } from 'Lang/i18nextKeys';
import ModalGlobalFilter from 'Components/shared/Table/ModalGlobalFilter';
import NoDataPage from 'Components/shared/NoDataPage/NoDataPage';
import { useRowSelect } from 'react-table';
import i18nextTranslateDynamically from 'Lang/i18nextTranslateDynamically';
import { Button } from 'Components/shared/buttons';

const AddAdminModalTable = ({
  dataQa,
  columns,
  data,
  fetchData,
  pageCount: controlledPageCount,
  isLoading,
  defaultSortBy = 'OpenCompletionDate',
  defaultFilters = [],
  defaultPageSize = 50,
  getRowProps = () => { },
  showGlobalFilter = false,
  uppercaseHeader = true,
  selectedRowFilter,
  close,
  setAdmin,
}) => {
  const [showCheckedOnly, setShowCheckedOnly] = useState(false);
  const skipPageResetRef = useRef(true);
  const tableInstance = useTable(
    {
      columns,
      data,
      defaultColumn: { Filter: DefaultColumnFilter },
      initialState: {
        sortBy: [{ id: defaultSortBy, desc: true }],
        filters: defaultFilters,
        pageSize: defaultPageSize,
      },
      manualSortBy: true,
      disableMultiSort: true,
      manualPagination: true,
      pageCount: controlledPageCount,
      autoResetPage: !skipPageResetRef.current,
      manualGlobalFilter: true,
      autoResetGlobalFilter: false,
      filterTypes: {
        selectedRowFilter: selectedRowFilter,
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setGlobalFilter,
    state: {
      pageIndex,
      pageSize,
      sortBy,
      filters,
      globalFilter,
      selectedRowIds,
    },
    setFilter,
    toggleAllRowsSelected,
    selectedFlatRows,
  } = tableInstance;
  const fetchDataDebounced = useAsyncDebounce(fetchData, 175);

  useEffect(() => {
    skipPageResetRef.current = !globalFilter;
    fetchDataDebounced(pageIndex, pageSize, sortBy, filters, globalFilter && globalFilter.trim());
  }, [fetchDataDebounced, pageIndex, pageSize, sortBy, globalFilter]);

  useEffect(() => {
    setFilter('selection', showCheckedOnly);
  }, [setFilter, showCheckedOnly]);

  const buttonWidth = {
    xxl: "190px",
    default: "170px"
  };

  return (
    <div className="w-full">
      {showGlobalFilter && (
        <div className="flex">
          <ModalGlobalFilter
            dataQa={`${dataQa}-search`}
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
          />
        </div>
      )}
      <div className="flex my-16 color-6 text-xs justify-between">
        <div>
          {showCheckedOnly && (
            <span
              className="cursor-pointer"
              onClick={() => setShowCheckedOnly(!showCheckedOnly)}
            >
              ←
            </span>
          )}
        </div>
        <div className="flex gap-16">
          <span
            className="cursor-pointer"
            onClick={() => {
              toggleAllRowsSelected(false);
              setShowCheckedOnly(false);
            }}
            data-qa={`${dataQa}-selection-show`}
          >
            {i18nextTranslate(
              i18nextKeys.commonClearSelection
            )}
          </span>
          {!showCheckedOnly && (
            <span
              className="cursor-pointer"
              onClick={() => setShowCheckedOnly(!showCheckedOnly)}
              data-qa={`${dataQa}-selection-clear`}
            >
              {i18nextTranslateDynamically(
                i18nextKeys.tenantSettingsAdminTableSelectionAmount,
                { amount: Object.keys(selectedRowIds).length }
              )}
            </span>
          )}
        </div>
      </div>
      <div className="w-full">
        {isLoading && page.length === 0 ? (
          <LoadingSpinner
            classes="m-auto"
            dataQa={`${dataQa}-table-loading`}
          />
        ) : page.length > 0 ? (
          <>
            <div
              className="w-full overflow-auto"
              style={{ maxHeight: '45vh' }}
            >
              <table
                className="w-full"
                {...getTableProps()}
                data-qa={`${dataQa}-table`}
              >
                <thead
                  data-qa={`${dataQa}-table-row-header`}
                  style={{ position: 'sticky', top: '0' }}
                  className="bg-3 z-10"
                >
                  {
                    // Loop over the header rows
                    headerGroups.map((headerGroup) => (
                      // Apply the header row props
                      <tr
                        {...headerGroup.getHeaderGroupProps()}
                        className="react-table-row"
                      >
                        {headerGroup.headers.map((column, index) => (
                          <HeaderCell
                            key={column.id}
                            column={column}
                            dataQa={`${dataQa}-table-header-${column.dataQa || index}`}
                          />
                        ))}
                      </tr>
                    ))
                  }
                </thead>
                {/* Apply the table body props */}
                <tbody {...getTableBodyProps()}>
                  {
                    // Loop over the table rows of the current page
                    page.map((row, indexRow) => {
                      // Prepare the row for display
                      prepareRow(row);
                      return (
                        // Apply the row props
                        <tr
                          {...row.getRowProps(getRowProps(row))}
                          className={`react-table-row cursor-pointer ${indexRow % 2 ? 'bg-2' : 'bg-4_7'
                            }`}
                          data-qa={`${dataQa}-table-row-${indexRow}`}
                        >
                          {
                            // Loop over the rows cells
                            row.cells.map((cell, indexCell) => (
                              <TableCell
                                key={indexCell}
                                cell={cell}
                                dataQa={`${dataQa}-table-row-${indexRow}-${
                                  cell.column.dataQa || `cell-${indexCell}`
                                }`}
                              />
                            ))
                          }
                        </tr>
                      );
                    })
                  }
                </tbody>
              </table>
            </div>
            <PaginationFooter
              gotoPage={gotoPage}
              previousPage={previousPage}
              nextPage={nextPage}
              pageCount={pageCount}
              canPreviousPage={canPreviousPage}
              canNextPage={canNextPage}
              pageOptions={pageOptions}
              pageIndex={pageIndex}
              dataQa={dataQa}
            />
          </>
        ) : (
          <NoDataPage
            style={{ height: "50vh" }}
            dataQa={`${dataQa}-table-empty`}
          />
        )}
        <div className="flex justify-between w-full mt-24">
          <Button
            text={i18nextTranslate(i18nextKeys.buttonCancel)}
            onClick={close}
            dataQa={`${dataQa}-cancel`}
            width={buttonWidth}
            secondary
          />
          <Button
            type="submit"
            text={i18nextTranslate(i18nextKeys.buttonPromoteToAdmin)}
            dataQa={`${dataQa}-addUser`}
            onClick={() => setAdmin(
              selectedFlatRows.map((r) => r.original),
              () => {
                toggleAllRowsSelected(false);
                setShowCheckedOnly(false);
              }
            )}
            width={buttonWidth}
          />
        </div>
      </div>
    </div>
  );
};

export default AddAdminModalTable;
