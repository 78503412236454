import React, { useContext, useState } from "react";
import {
  Field as FinalField,
  FormSpy as FinalFormSpy
} from "react-final-form";
import { FieldArray as FinalFieldArray } from "react-final-form-arrays";
import AnimateHeight from 'react-animate-height';
import { i18nextKeys } from "Lang/i18nextKeys";
import i18nextTranslate from "Lang/i18nextTranslate";
import { UiContext } from 'States/ui/uiState';
import { Button } from "Components/shared/buttons";
import ConfirmationModal from "Components/shared/ConfirmationModal";
import { PlusSymbol, TrashCanSymbol } from "Components/shared/symbols";
import { FeatureToggle } from "Features/admin/ConfigManagement/shared";
import AdditionalIdentifier from "./AdditionalIdentifier";

const AdditionalIdentifiers = ({
  identifiers,
  contentWidth,
  fieldWidth,
  textAreaHeight
}) => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [identifierIndex, setIdentifierIndex] = useState(null);
  const {
    breakpoints: { xxl },
  } = useContext(UiContext);

  const fieldGroupName = "AdditionalBankIdentifiers";

  const defaultValue = {
    Value: null
  };

  const isEqual = (previousValue, newValue) => {
    if (previousValue?.length !== newValue?.length) {
      return false;
    }
    return true;
  };

  return (
    <div>
      <div style={{ width: contentWidth }}>
        <FinalField
          name={`${fieldGroupName}.AreEnabled`}
          type="checkbox"
          initialValue={identifiers.AreEnabled}
          subscription={{ value: true }}
          validateFields={[]}
        >
          {({ input: { checked, onChange } }) => (
            <FeatureToggle
              title={i18nextTranslate(i18nextKeys.configPaymentBankTransferIdentifiersTitle)}
              description={i18nextTranslate(i18nextKeys.configPaymentBankTransferIdentifiersDescription)}
              isEnabled={checked}
              onChange={onChange}
              dataQa="intermediary"
            />
          )}
        </FinalField>
      </div>
      <FinalFieldArray
        name={`${fieldGroupName}.Identifiers`}
        initialValue={identifiers.Identifiers}
        isEqual={isEqual}
        subscription={{}}
      >
        {({ fields: fieldArray }) => (
          <>
            <FinalFormSpy subscription={{ values: true }}>
              {({ values: { AdditionalBankIdentifiers } }) => (
                <AnimateHeight
                  duration={750}
                  height={AdditionalBankIdentifiers.AreEnabled ? 'auto' : 0}
                >
                  <div className="flex flex-col gap-24 mt-32">
                    {fieldArray.map((name, index) => (
                      <AdditionalIdentifier
                        key={name}
                        fieldNamePrefix={name}
                        translations={AdditionalBankIdentifiers.Identifiers[index].Translations}
                        fieldWidth={fieldWidth}
                        textAreaHeight={textAreaHeight}
                        remove={() => {
                          setIdentifierIndex(index);
                          setShowConfirmation(true);
                        }}
                      />
                    ))}
                    <Button
                      text={i18nextTranslate(
                        i18nextKeys.configPaymentBankTransferIdentifiersAdd
                      )}
                      icon={PlusSymbol}
                      width={{
                        xxl: "220px",
                        default: "180px"
                      }}
                      className="flex-row-reverse"
                      onClick={() => fieldArray.push(defaultValue)}
                      disabled={AdditionalBankIdentifiers.Identifiers?.length >= 3}
                    />
                  </div>
                </AnimateHeight>
              )}
            </FinalFormSpy>
            <ConfirmationModal
              show={showConfirmation}
              close={() => setShowConfirmation(false)}
              icon={<TrashCanSymbol size={xxl ? "36" : "30"} />}
              title={i18nextTranslate(
                i18nextKeys.configPaymentBankTransferIdentifiersConfirmation
              )}
              confirmButtonText={i18nextTranslate(i18nextKeys.buttonRemove)}
              onConfirm={() => {
                fieldArray.remove(identifierIndex);
                setIdentifierIndex(null);
                setShowConfirmation(false);
              }}
              dataQa="identifier-confirmation-modal"
            />
          </>
        )}
      </FinalFieldArray>
    </div>
  );
};

export default AdditionalIdentifiers;
