import React from 'react';

const EditIcon = (props) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="currentColor"
    {...props}
  >
    <path d="M14.2203 4.30962L13.2078 5.32212L10.6765 2.78775L11.689 1.77525C11.8953 1.569 12.2547 1.569 12.4609 1.77525L14.2203 3.53775C14.4328 3.75025 14.4328 4.09712 14.2203 4.30962ZM5.7609 12.7784L3.22964 10.244L9.7734 3.69087L12.3047 6.22525L5.7609 12.7784ZM2.64527 11.4659L4.53902 13.3627L1.90152 14.1065L2.64527 11.4659ZM15.1234 2.63462L13.364 0.872124C13.0203 0.528374 11.7797 -0.121626 10.789 0.872124L1.87339 9.79399C1.79527 9.87212 1.73902 9.96587 1.71089 10.0721L0.367141 14.8565C0.304641 15.0784 0.370266 15.3159 0.529641 15.4815C0.692141 15.6471 1.01714 15.6752 1.15464 15.644L5.9359 14.2971C6.04215 14.269 6.1359 14.2127 6.21402 14.1346L15.1234 5.21275C15.8328 4.50337 15.8328 3.34712 15.1234 2.63462Z" />
  </svg>
);

export default EditIcon;
