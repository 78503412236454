import React from 'react';

const EmailIcon = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 60 60"
    fill="currentColor"
  >
    <path d="M57.3003 22.4741L49.1406 15.6742V7.32815C49.1406 6.42209 48.406 5.68752 47.5 5.68752H37.1744L31.0745 0.400887C30.4577 -0.133629 29.5421 -0.133629 28.9255 0.400887L22.8255 5.68752H12.5C11.5939 5.68752 10.8594 6.42209 10.8594 7.32815V15.6742L2.69967 22.4741C2.32572 22.7857 2.10938 23.2475 2.10938 23.7344V54.3594C2.10938 55.2654 2.84394 56 3.75 56H56.25C57.156 56 57.8906 55.2654 57.8906 54.3594V23.7344C57.8906 23.2475 57.6743 22.7857 57.3003 22.4741ZM20.7651 39.0469L5.39062 51.005V27.0888L20.7651 39.0469ZM24.0003 40.6875H35.9995L51.4683 52.7188H8.53165L24.0003 40.6875ZM39.2348 39.0469L54.6093 27.0888V51.005L39.2348 39.0469ZM53.6344 23.6903L49.1406 27.1855V19.9454L53.6344 23.6903ZM30 3.81174L32.1644 5.68752H27.8356L30 3.81174ZM45.8594 8.96877V29.7375L35.9996 37.4063H24.0003L14.1406 29.7375V8.96877H45.8594ZM10.8594 27.1855L6.36559 23.6903L10.8594 19.9455V27.1855Z" />
    <path d="M22.6716 32.6785C24.6864 34.2482 27.1961 35.1513 29.7385 35.2217C29.7541 35.2221 29.7694 35.2223 29.7848 35.2223C30.6699 35.2223 31.3994 34.5174 31.4239 33.6271C31.4489 32.7213 30.7349 31.9667 29.8293 31.9417C25.9726 31.835 22.5922 29.1487 21.6087 25.4089C20.4243 20.9055 22.9749 16.2706 27.4154 14.857C29.6682 14.14 32.0599 14.3511 34.1494 15.4516C36.2316 16.5481 37.7548 18.3898 38.4409 20.6394C38.8684 22.262 38.7231 23.7792 37.9567 25.706C37.7679 26.1809 37.1857 27.2854 35.8618 27.2854C35.7154 27.2854 35.5851 27.1764 35.5204 27.1113C35.3336 26.9236 35.2229 26.6444 35.2243 26.3646L35.2695 17.2498C35.274 16.3438 34.543 15.6056 33.637 15.6011C33.6342 15.6011 33.6315 15.6011 33.6287 15.6011C32.8282 15.6011 32.1615 16.1757 32.018 16.9361C31.2779 16.5469 30.4542 16.3403 29.5874 16.3403C28.0114 16.3403 26.4679 17.0486 25.3526 18.2834C24.2217 19.5357 23.5989 21.2394 23.5989 23.0806C23.5989 24.9136 24.1843 26.6357 25.2471 27.9299C26.3494 29.272 27.8912 30.0419 29.4769 30.0419C30.6908 30.0419 31.8058 29.6091 32.7253 28.8613C32.8651 29.0621 33.0212 29.2519 33.1945 29.426C33.9265 30.1618 34.8738 30.5669 35.8618 30.5669C38.5848 30.5669 40.3044 28.6825 41.0058 26.9188C42.0434 24.3098 42.2282 22.1042 41.6045 19.768C41.6 19.7509 41.5952 19.7338 41.5901 19.717C40.6517 16.6078 38.5523 14.062 35.6784 12.5485C32.805 11.035 29.5168 10.7446 26.4201 11.7305C23.4848 12.6649 21.0431 14.6787 19.5448 17.4011C18.0459 20.1243 17.6518 23.2647 18.4353 26.2436C19.1137 28.8227 20.5786 31.0478 22.6716 32.6785ZM29.477 26.7606C28.2229 26.7606 26.8802 25.2819 26.8802 23.0806C26.8802 20.9154 28.2567 19.6215 29.5875 19.6215C30.9424 19.6215 31.9015 20.9436 31.9605 22.8602L31.9584 23.2727C31.8805 25.2155 30.7976 26.7606 29.477 26.7606Z" />
  </svg>
);

export default EmailIcon;
