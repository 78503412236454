import React from 'react';
import DefaultCardKycButtonGroup from 'Components/shared/cards/DefaultCardKycButtonGroup';
import { i18nextKeys } from 'Lang/i18nextKeys';

const RedeemMethodSelectionButtonGroup = ({
  openModal,
  goToForm,
  kycTier
}) => (
  <DefaultCardKycButtonGroup
    buttonConfig={{
      topButton: {
        show: true,
        label: {
          insufficientTier: i18nextKeys.buttonMoreInfo,
          sufficientTier: i18nextKeys.buttonMoreInfo,
        },
        onClick: openModal,
      },
      bottomButton: {
        show: true,
        label: {
          insufficientTier: i18nextKeys.kycGetTierToSelect,
          sufficientTier: i18nextKeys.buttonSelectThisMethod,
        },
        onClick: goToForm,
        origin: "redemption method tile"
      }
    }}
    requiredTier={kycTier}
  />
);

export default RedeemMethodSelectionButtonGroup;
