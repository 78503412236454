import React, { useState, useContext, useEffect } from 'react';
import { Form as FinalForm } from 'react-final-form';
import api from 'Api';
import { UiContext } from 'States/ui/uiState';
import useHandleError from 'Utils/handleError';
import BankDetailsForm from 'Components/BankAccount/Form/BankDetailsForm';
import BankAddressForm from 'Components/BankAccount/Form/BankAddressForm';
import Modal from 'Components/shared/Modal';
import LoadingSpinner from 'Components/shared/LoadingSpinner';
import Text from 'Components/shared/Text';
import TabBar from 'Components/shared/TabBar/TabBar';
import TabBarItem from 'Components/shared/TabBar/TabBarItem';
import { Button } from 'Components/shared/buttons';
import { TrashCanSymbol } from 'Components/shared/symbols';
import { i18nextKeys } from 'Lang/i18nextKeys';
import i18nextTranslate from 'Lang/i18nextTranslate';

const TABS = {
  main: 'mainBankAccount',
  intermediary: 'intermediaryBankAccount'
}

const BankAccountFormModal = ({
  show,
  close,
  onBankFormSubmit,
  bankAccount
}) => {
  const [loading, setLoading] = useState(false);
  const [showIntermediaryBank, setShowIntermediaryBank] = useState(false);
  const [activeTab, setActiveTab] = useState(TABS.main);
  const {
    breakpoints: { md }
  } = useContext(UiContext);
  const handleError = useHandleError();

  useEffect(() => {
    if (bankAccount.IntermediaryBankAccount) {
      setShowIntermediaryBank(true);
    }
  }, [bankAccount]);

  const buttonWidth = {
    xxl: '190px',
    md: '170px',
    default: '140px'
  };

  const submitForm = async ({ bankAccount: formFields }) => {
    setLoading(true);
    const {
      IntermediaryBankAccount: intermediaryBankFields,
      ...mainBankFields
    } = formFields;
    try {
      if (bankAccount.Id) {
        await api.BankAccounts.put(mainBankFields);
        if (showIntermediaryBank) {
          if (bankAccount.IntermediaryBankAccount) {
            await api.IntermediaryBankAccounts.put(intermediaryBankFields);
          } else {
            await api.IntermediaryBankAccounts.post({
              ...intermediaryBankFields,
              BankAccountId: bankAccount.Id
            });
          }
        } else if (
          !showIntermediaryBank
          && bankAccount.IntermediaryBankAccount
        ) {
          await api.IntermediaryBankAccounts.delete(bankAccount.IntermediaryBankAccount.Id);
        }
      } else {
        await api.BankAccounts.create(formFields);
      }
      onBankFormSubmit();
    } catch (error) {
      const message = i18nextTranslate(
        i18nextKeys.errorBankAccountsCardsStoreBankAccount
      );
      handleError({ error, message });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal dataQa="bank-account-modal" show={show} close={close}>
      {loading ? (
        <div className="flex justify-center bg-3 px-32 py-32">
          <LoadingSpinner />
        </div>
      ) : (
        <>
          <Text
            textStyle="h2"
            className="px-16 md:px-24 pt-20 md:pt-24 pb-8 md:pb-16"
            uppercase
          >
            {i18nextTranslate(
              bankAccount?.Id
                ? i18nextKeys.cardsBankAccountsFormHeaderEdit
                : i18nextKeys.cardsBankAccountsFormHeaderAdd
            )}
          </Text>
          <TabBar className="px-16 md:px-24">
            <TabBarItem
              title={i18nextTranslate(i18nextKeys.commonBankAccount)}
              active={activeTab === TABS.main}
              onClick={() => setActiveTab(TABS.main)}
              dataQa="tabBar-main"
            />
            {showIntermediaryBank && (
              <TabBarItem
                title={i18nextTranslate(i18nextKeys.commonBankIntermediaryAccount)}
                dataQa="tabBar-intermediary"
                active={activeTab === TABS.intermediary}
                onClick={() => setActiveTab(TABS.intermediary)}
                icon={TrashCanSymbol}
                onIconClick={() => {
                  setShowIntermediaryBank(false);
                  setActiveTab(TABS.main);
                }}
              />
            )}
          </TabBar>
          <div
            className="flex flex-col items-center overflow-y-auto color-white pt-24 md:pt-32 px-16 md:px-24"
            style={{ height: md ? '733px' : '610px' }}
          >
            <FinalForm
              destroyOnUnregister
              initialValues={{ bankAccount }}
              onSubmit={submitForm}
              render={({ handleSubmit, invalid, pristine, errors }) => {
                const {
                  bankAccount: {
                    IntermediaryBankAccount,
                    ...mainBankAccountErrors
                  } = {}
                } = errors;
                return (
                  <div className="w-full h-full flex flex-col justify-between gap-24 md:gap-32">
                    <div
                      className={`${
                        activeTab === TABS.main ? '' : 'hidden'
                      } flex flex-col md:flex-row md:justify-between gap-32 md:gap-0`}
                    >
                      <BankDetailsForm style={{ width: md ? '45%' : '100%' }} />
                      <BankAddressForm
                        addIntermediaryBank={() => {
                          setShowIntermediaryBank(true);
                          setActiveTab(TABS.intermediary);
                        }}
                        isIntermediaryPresent={showIntermediaryBank}
                        style={{ width: md ? '45%' : '100%' }}
                      />
                    </div>
                    {showIntermediaryBank && (
                      <div
                        className={`${
                          activeTab === TABS.intermediary ? '' : 'hidden'
                        } flex flex-col md:flex-row md:justify-between gap-32 md:gap-0`}
                      >
                        <BankDetailsForm
                          style={{ width: md ? '45%' : '100%' }}
                          intermediary
                        />
                        <BankAddressForm
                          style={{ width: md ? '45%' : '100%' }}
                          intermediary
                        />
                      </div>
                    )}
                    <div className="flex justify-between w-full pb-20 md:pb-24">
                      <Button
                        dataQa="bank-cancel"
                        text={i18nextTranslate(i18nextKeys.buttonCancel)}
                        onClick={close}
                        width={buttonWidth}
                        secondary
                      />

                      <Button
                        dataQa="bank-save"
                        disabled={(invalid || pristine)
                          && !(
                            bankAccount.IntermediaryBankAccount
                            && !showIntermediaryBank
                            && !Object.keys(mainBankAccountErrors).length
                          )
                        }
                        text={i18nextTranslate(
                          bankAccount?.Id
                            ? i18nextKeys.buttonSaveChanges
                            : i18nextKeys.buttonSave
                          )
                        }
                        onClick={handleSubmit}
                        width={buttonWidth}
                      />
                    </div>
                  </div>
                );
              }}
            />
          </div>
        </>
      )}
    </Modal>
  );
};

export default BankAccountFormModal;
