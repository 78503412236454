import React from 'react';
import { Field as FinalField } from 'react-final-form';
import { checkboxValidator } from './validators';
import i18nextTranslate from 'Lang/i18nextTranslate';
import { i18nextKeys } from 'Lang/i18nextKeys';
import linkify from "Helpers/linkify";
import Text from '../Text';
import Error from './Error';
import Checkbox from './Checkbox';

const CheckboxWithText = ({
  name,
  children,
  text,
  textStyle = {},
  style = {},
  className = "",
  checked = false,
  dataQa = "condition",
  showErrors = false,
  required = false,
  disabled = false
}) => {
  return (
    <label
      className={`flex gap-12 ${className}`}
      style={style}
      data-qa={dataQa}
    >
      <FinalField
        name={name}
        type="checkbox"
        initialValue={checked}
        subscription={{
          error: true,
          modified: true,
          value: true
        }}
        validate={(value) => {
          return checkboxValidator({
            message: i18nextTranslate(i18nextKeys.errorConfirmationRequired)
          })(value, required);
        }}
        render={({ input, meta }) => (
          <>
            <Checkbox
              dataQa={`${dataQa}-checkbox`}
              required={required}
              disabled={disabled}
              {...input}
            />
            <div className="flex flex-col">
              <Text
                className="cursor-pointer"
                style={textStyle}
                dataQa={`${dataQa}-text`}
                dangerouslySetContent={!!text}
              >
                {text ? linkify(text) : children}
              </Text>
              {showErrors && !input.checked && meta.modified && meta.error && (
                <Error
                  error={meta.error}
                  dataQa={`${dataQa}-error`}
                />
              )}
            </div>
          </>
        )}
      />
    </label>
  );
};

export default CheckboxWithText;
