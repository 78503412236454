import React from "react";
import { Field as FinalField } from "react-final-form";
import i18nextTranslate from "Lang/i18nextTranslate";
import { i18nextKeys } from "Lang/i18nextKeys";
import { isMaxLengthExceeded, isRequiredIfWithMessage } from "Utils/validators";
import { FormField } from "Components/shared/formElements";
import { SubsectionHeading } from "Features/admin/ConfigManagement/shared";
import { TranslationsForm } from "Features/admin/ConfigManagement/shared/translations";

const BankAccount = ({
  account = {},
  maxLength = 60,
  fieldWidth,
  textAreaHeight
}) => {
  const fieldConfig = [
    [
      {
        name: "Name",
        label: i18nextTranslate(i18nextKeys.configPaymentBankTransferBeneficiaryName),
        tooltip: i18nextTranslate(i18nextKeys.configPaymentBankTransferBeneficiaryNameTooltip),
        required: true
      },
      {
        name: "BankName",
        label: i18nextTranslate(i18nextKeys.commonBankBeneficiaryName),
        tooltip: i18nextTranslate(i18nextKeys.configPaymentBankTransferNameTooltip),
        required: true
      },
      {
        name: "Swift",
        label: i18nextTranslate(i18nextKeys.commonBankBeneficiarySwift),
        tooltip: i18nextTranslate(i18nextKeys.configPaymentBankTransferSwiftTooltip),
        required: false
      }
    ],
    [
      {
        name: "Account",
        label: i18nextTranslate(i18nextKeys.commonBankBeneficiaryAccount),
        tooltip: i18nextTranslate(i18nextKeys.configPaymentBankTransferAccountTooltip),
        required: true
      },
      {
        name: "BankAddress",
        label: i18nextTranslate(i18nextKeys.commonBankBeneficiaryAddress),
        tooltip: i18nextTranslate(i18nextKeys.configPaymentBankTransferAddressTooltip),
        required: true
      },
      {
        name: "BIC",
        label: i18nextTranslate(i18nextKeys.commonBankBeneficiaryBic),
        tooltip: i18nextTranslate(i18nextKeys.configPaymentBankTransferBicTooltip),
        required: false
      }
    ]
  ];

  const defaultTranslation = {
    Name: null,
    BankName: null,
    Account: null,
    BankAddress: null,
    Swift: null,
    BIC: null
  };

  return (
    <div className="flex flex-col gap-16">
      <SubsectionHeading
        text={i18nextTranslate(
          i18nextKeys.configPaymentBankTransferBeneficiaryTitle
        )}
        tooltipText={i18nextTranslate(
          i18nextKeys.configPaymentBankTransferBeneficiaryTooltip
        )}
        className=""
        dataQa="description"
      />
      <TranslationsForm
        fieldArrayName="BeneficiaryDetails.Translations"
        translations={account.Translations}
        defaultValue={defaultTranslation}
        fieldWidth={fieldWidth}
        fields={(props) => (
          <div className="flex gap-88 xxl:gap-96">
            {fieldConfig.map((column, index) => (
              <div
                key={`beneficiary-${index}`}
                className="flex flex-col gap-32"
                style={{ width: fieldWidth }}
              >
                {column.map((field, index) => (
                  <div
                    key={`beneficiary-field-${index}`}
                    className="flex flex-col gap-8"
                  >
                    {props.isDefaultLanguage && (
                      <FinalField
                        name={`BeneficiaryDetails.${field.name}`}
                        label={field.label}
                        tooltipText={field.tooltip}
                        initialValue={account[field.name]}
                        required={field.required}
                        validate={isRequiredIfWithMessage(
                          () => field.required,
                          field.label
                        )}
                        validateFields={[]}
                        subscription={{
                          active: true,
                          error: true,
                          touched: true,
                          value: true
                        }}
                      >
                        {(props) => <FormField {...props} />}
                      </FinalField>
                    )}
                    <FinalField
                      name={`${props.fieldNamePrefix}.${field.name}`}
                      label={`${i18nextTranslate(
                        i18nextKeys.configPaymentBankTransferTranslationBeneficiaryLabel
                      )} (${field.label})`}
                      validate={isMaxLengthExceeded(maxLength)}
                      validateFields={[]}
                      maxLength={maxLength}
                      subscription={{
                        active: true,
                        error: true,
                        touched: true,
                        value: true
                      }}
                    >
                      {(props) => (
                        <FormField
                          height={textAreaHeight}
                          multiline
                          showNumberOfChars
                          {...props}
                        />
                      )}
                    </FinalField>
                  </div>
                ))}
              </div>
            ))}
          </div>
        )}
      />
    </div>
  );
};

export default BankAccount;
