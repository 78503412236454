import React, { useContext, useEffect, useState } from 'react';
import Big from 'big.js';
import { useAmplitude } from 'react-amplitude-hooks';
import { Form as FinalForm } from 'react-final-form';
import { generatePath, useHistory } from "react-router-dom";
import { ROUTES } from 'Router/Routes';
import api from 'Api';
import useHandleError from "Utils/handleError";
import { i18nextKeys } from 'Lang/i18nextKeys';
import i18nextTranslate from 'Lang/i18nextTranslate';
import { BTC_OPTION, ETH_OPTION } from 'Enums/WalletType';
import {
  BANK_TRANSFER,
  BITCOIN,
  ETH,
  ERC20,
  PHYSICAL,
  walletTypeForRedeemMethodType
} from 'Enums/RedemptionMethodTypes';
import { PRICE_SOURCE, STORAGE_KEYS } from 'Enums';
import { AuthContext } from 'States/auth/authState';
import { ConfigContext } from 'States/config/configState';
import useConfigSettings from "Hooks/useConfigSettings";
import useKycTierCheck from 'Hooks/useKycTierCheck';
import { objectArrayToObject } from 'Utils/utils';
import LoadingSpinner from 'Components/shared/LoadingSpinner';
import { Tile } from 'Components/shared/Tile';
import UserDetailsForm from 'Components/UserDetails/UserDetailsForm';
import PayReceive, { REDEMPTION_AMOUNT_FIELD } from '../PayReceive/PayReceive';
import RedemptionFormFooter from '../RedemptionFormFooter';
import AdditionalDetails from './AdditionalDetails';
import CryptoWalletSelection from './CryptoWalletSelection';
import BankAccountSelection from './BankAccountSelection';

const RedemptionDetailsForm = ({
  redemptionMethod
}) => {
  const [selectedBankAccount, setSelectedBankAccount] = useState(null);
  const [selectedWallet, setSelectedWallet] = useState(null);
  const [user, setUser] = useState({});
  const [bankAccounts, setBankAccounts] = useState([]);
  const [beneficiaryCountry, setBeneficiaryCountry] = useState(null);
  const [amountOptions, setAmountOptions] = useState([]);
  const [loadingAmounts, setLoadingAmounts] = useState(false);
  const [loading, setLoading] = useState(true);
  const [savedForm, setSavedForm] = useState({});
  const [disableForm, setDisableForm] = useState(false);
  const history = useHistory();
  const { logEvent } = useAmplitude();
  const handleError = useHandleError();

  const {
    user: {
      profile: { sub }
    },
    isAdmin
  } = useContext(AuthContext);

  const {
    config: { kycTierFunctionalRestrictions }
  } = useContext(ConfigContext);

  const requiredKycTier = Math.max(
    kycTierFunctionalRestrictions?.availability?.redemption || 0,
    redemptionMethod.kycTier || 0
  );
  const { isUserTierSufficient } = useKycTierCheck(requiredKycTier);

  const {
    isLoading: loadingSettings,
    data: settings
  } = useConfigSettings.query({
    refetchOnMount: false
  });

  useState(() => {
    if (!isAdmin && settings.RestrictedMode && !isUserTierSufficient) {
      setDisableForm(true);
    }
  }, [isAdmin, isUserTierSufficient, settings]);

  const physicalRedemption = redemptionMethod.type === PHYSICAL;

  const disableSubmit = () => {
    switch (redemptionMethod.type) {
      case BANK_TRANSFER:
        return !selectedBankAccount;
      case BITCOIN:
      case ETH:
      case ERC20:
        return !selectedWallet;
      default:
        return false;
    }
  };

  const refetchUserWallets = async (changeFormValue) => {
    const walletType = walletTypeForRedeemMethodType(
      redemptionMethod.type
    );
    const { Wallets } = await api.User.get(
      sub,
      `Wallets($filter=Type eq '${walletType}' and IsActive)`
    );
    setUser({ ...user, Wallets });
    const updatedWallet = Wallets.find(({ Id }) => Id === selectedWallet.Id);
    changeFormValue('wallet', updatedWallet);
    setSelectedWallet(updatedWallet);
  };

  const fetchBankAccounts = async () => {
    const { value: BankAccounts } = await api.BankAccounts.list();
    setBankAccounts(BankAccounts);
    const updatedBankAccount = BankAccounts.find(({ Id }) => Id === selectedBankAccount.Id);
    setSelectedBankAccount(updatedBankAccount);
  };

  const getAmountOptions = async ({
    amountToPay,
    amountToReceive,
    updatedFieldName
  }) => {
    try {
      setLoadingAmounts(true);
      let { Calculations } = await api.Redemptions.getAmountsAndFees({
        methodId: redemptionMethod.id,
        country: beneficiaryCountry,
        fieldName: updatedFieldName || REDEMPTION_AMOUNT_FIELD.pay,
        amount: amountToPay || amountToReceive
      });
      if (amountOptions.length) {
        logEvent("Amount set_redemption", {
          input: amountToPay ? "you pay" : "you receive",
          rounded: Calculations.length > 1
        });
      }
      Calculations[0].Fees = objectArrayToObject(Calculations[0].Fees, "Type");
      if (Calculations.length > 1) {
        Calculations[1].Fees = objectArrayToObject(Calculations[1].Fees, "Type");
        Calculations.sort((a, b) =>
          Big(a.GrossAmount.AssetUnitsString) - Big(b.GrossAmount.AssetUnitsString)
        );
        Calculations[0].updatedFieldName = updatedFieldName;
      };
      setAmountOptions(Calculations);
      setLoadingAmounts(false);
    } catch (error) {
      handleError({ error })
    }
  }

  const selectWallet = (wallet, changeFormValue) => {
    if (wallet.addAnother) {
      changeFormValue('wallet', {
        Type: redemptionMethod.type === BITCOIN
          ? BTC_OPTION
          : ETH_OPTION,
      });
    } else {
      changeFormValue('wallet', wallet);
    }
    setSelectedWallet(wallet);
  };

  useEffect(() => {
    const prefillForm = async () => {
      try {
        let user;
        switch (redemptionMethod.type) {
          case BANK_TRANSFER:
            const { value } = await api.BankAccounts.list();
            setBankAccounts(value);
          case PHYSICAL:
          case BANK_TRANSFER:
            user = await api.User.get(sub);
            break;
          default:
            const walletType = walletTypeForRedeemMethodType(
              redemptionMethod.type
            );
            user = await api.User.getWithWallets(sub, walletType);
        }
        let savedValues = JSON.parse(
          sessionStorage.getItem(STORAGE_KEYS.redemptionForm)
        );
        if (savedValues) {
          if (savedValues.userId === user.Id &&
            savedValues.redemptionMethodId === redemptionMethod.id) {
              setSavedForm(savedValues)
            } else {
              savedValues = null;
              sessionStorage.removeItem(STORAGE_KEYS.redemptionForm);
            }
        }
        setUser(user);
        setBeneficiaryCountry(
          savedValues?.beneficiary?.Country || user?.HomeAddress?.Country
        );
        if (savedValues?.amountBreakdown) {
          if (redemptionMethod.userGets.priceSource && redemptionMethod.userGets.priceSource !== PRICE_SOURCE.none) {
            await getAmountOptions({ amountToPay: savedValues.amountBreakdown.GrossAmount.AssetUnitsString });
          } else {
            setAmountOptions([savedValues.amountBreakdown]);
          }
        }
        setLoading(false);
      } catch (error) {
        const message = i18nextTranslate(
          i18nextKeys.errorRedeemDetailsUserDataRetrievingError
        );
        handleError({ error, message });
      }
    };

    if (sub && redemptionMethod) {
      prefillForm();
    }
  }, [sub, history, redemptionMethod]);

  const submitForm = (formValues) => {
    try {
      logEvent("Redeem details provided", {
        "redemption type": redemptionMethod.type
      });
      sessionStorage.setItem(
        STORAGE_KEYS.redemptionForm,
        JSON.stringify({
          userId: user.Id,
          redemptionMethodId: redemptionMethod.id,
          amountBreakdown: amountOptions[0],
          ...formValues
        })
      );
      history.push({
        pathname: generatePath(
          ROUTES.redemptionSummary,
          { id: redemptionMethod.id }
        ),
        state: {
          amountBreakdown: amountOptions[0],
          redemptionMethod,
          redemptionForm: formValues,
          userEmail: user.EmailAddress,
          beneficiaryCountry
        }
      });
    } catch (error) {
      const message = i18nextTranslate(
        i18nextKeys.errorRedeemDetailsRedemptionCreationFailure
      );
      handleError({ error, message });
    }
  };

  const navigateToMethodSelection = () => {
    sessionStorage.removeItem(STORAGE_KEYS.redemptionForm);
    history.push(ROUTES.redemption);
  };

  return (
    <>
      {loading || loadingSettings ? (
        <LoadingSpinner
          classes="mr-auto ml-auto"
          dataQa="loading-form"
        />
      ) : (
        <FinalForm
          destroyOnUnregister
          onSubmit={submitForm}
          subscription={{
            active: true,
            invalid: true
          }}
          render={({ handleSubmit, invalid, active, form }) => (
            <form
              className="w-full flex flex-col"
              onSubmit={handleSubmit}
            >
              <div className="w-full flex flex-col gap-16 md:gap-24">
                <PayReceive
                  activeField={active}
                  redemptionMethod={redemptionMethod}
                  beneficiaryCountry={beneficiaryCountry}
                  amounts={amountOptions}
                  getAmounts={getAmountOptions}
                  setAmounts={setAmountOptions}
                  loading={loadingAmounts}
                  disabled={disableForm}
                />
                {redemptionMethod.type !== PHYSICAL && (
                  <Tile
                    dataQa={
                      redemptionMethod.type === BANK_TRANSFER
                        ? "bank"
                        : "wallet"
                    }
                    title={i18nextTranslate(
                      redemptionMethod.type === BANK_TRANSFER
                        ? i18nextKeys.redemptionSelectBankAccountHeader
                        : i18nextKeys.redemptionSelectWalletHeader
                    )}
                    xlPadding="xl:p-32"
                    xxlPadding="xxl:p-32"
                    expandable
                  >
                    {redemptionMethod.type === BANK_TRANSFER ? (
                      <BankAccountSelection
                        bankAccounts={bankAccounts}
                        selectedBankAccount={selectedBankAccount}
                        selectBankAccount={setSelectedBankAccount}
                        refetchBankAccounts={fetchBankAccounts}
                        changeFormValue={form.change}
                        disabled={disableForm}
                      />
                    ) : (
                      <CryptoWalletSelection
                        wallets={user.Wallets}
                        selectedWallet={selectedWallet}
                        selectWallet={(wallet) =>
                          selectWallet(wallet, form.change)}
                        refetchUserWallets={() => refetchUserWallets(form.change)}
                        disabled={disableForm}
                    />
                    )}
                  </Tile>
                )}
                <UserDetailsForm
                  user={savedForm.beneficiary || user}
                  title={i18nextTranslate(i18nextKeys.redemptionBeneficiaryEnterDetails)}
                  firstStepTitle={i18nextTranslate(i18nextKeys.redemptionBeneficiaryInfo)}
                  secondStepTitle={i18nextTranslate(
                    physicalRedemption
                      ? i18nextKeys.commonAddressShipping
                      : i18nextKeys.redemptionBeneficiaryAddress
                  )}
                  includePhoneNumber={physicalRedemption}
                  onCountryChange={setBeneficiaryCountry}
                  fieldGroup="beneficiary"
                  disabled={disableForm}
                  expandable
                />
                <AdditionalDetails
                  initialValue={savedForm.AdditionalDetails}
                />
                <RedemptionFormFooter
                  submitButtonText={i18nextTranslate(i18nextKeys.buttonContinue)}
                  submitDisabled={invalid || disableSubmit()}
                  requiredKycTier={isUserTierSufficient ? 0 : requiredKycTier}
                  backButtonAction={navigateToMethodSelection}
                  hideBackButton={settings.RestrictedMode && !isAdmin}
                />
              </div>
            </form>
          )}
        />
      )}
    </>
  );
};

export default RedemptionDetailsForm;
