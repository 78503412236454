import React, { useRef } from "react";
import { Field as FinalField } from "react-final-form";

const MemoizedValidationField = ({
  debounceTime = 0,
  validate,
  validateAsync,
  disabled,
  ...props
}) => {
  const clearDebounceRef = useRef(null);
  const lastValue = useRef(null);
  const lastResult = useRef(null);

  const memoizeAndDebounce = (value, values, meta) => {
    if (clearDebounceRef.current) {
      clearDebounceRef.current();
    }
    if (value === lastValue.current) {
      return lastResult.current;
    }
    lastValue.current = value;
    if (typeof validate === "function") {
      const result = validate(value, values, meta);
      if (result) {
        lastResult.current = result;
        return lastResult.current;
      }
    }
    // checking modified as a partial workaround for
    // https://github.com/final-form/react-final-form-arrays/issues/147
    if (meta.pristine || !meta.modified || value === undefined) {
      return;
    }
    if (debounceTime) {
      return new Promise(resolve => {
        const timeoutId = setTimeout(() => {
          lastResult.current = validateAsync(value, values, meta);
          resolve(lastResult.current);
        }, debounceTime);
        clearDebounceRef.current = () => {
          clearTimeout(timeoutId);
          resolve();
        };
      });
    } else {
      lastResult.current = validateAsync(value, values, meta);
      return lastResult.current;
    }
  };

  return <FinalField validate={memoizeAndDebounce} {...props} />;
};

export default MemoizedValidationField;