import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { LogOnMount } from 'react-amplitude-hooks';
import { AuthContext } from 'States/auth/authState';
import { KycContext } from 'States/kyc/kycState';
import useHandleError from 'Utils/handleError';
import api from 'Api';
import Fade from 'Components/shared/Fade';
import Header from 'Components/shared/Header';
import { DownloadSymbol } from 'Components/shared/symbols';
import { Tile } from "Components/shared/Tile";
import LoadingSpinner from 'Components/shared/LoadingSpinner';
import KycLevelInfoBox from 'Components/shared/KycLevelInfoBox';
import { Button } from 'Components/shared/buttons';
import UserInformation from 'Components/UserDetails/UserInformation';
import RequestInformation from './RequestInformation';
import RedemptionRequest from './RedemptionRequest';
import { i18nextKeys } from 'Lang/i18nextKeys';
import i18nextTranslate from 'Lang/i18nextTranslate';
import { ROUTES } from 'Router/Routes';
import RedemptionPayoutDetails from "./RedemptionPayoutDetails";
import { UiContext } from "States/ui/uiState";

const RedemptionDetails = ({ match, isAdmin = false }) => {
  const { isKycEnabled, currentKycTier } = useContext(KycContext);
  const handleError = useHandleError();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [redemptionRequest, setRedemptionRequest] = useState({});
  const [redemptionUserKycTier, setRedemptionUserKycTier] = useState(0);
  const {
    user: {
      profile: { sub },
    },
  } = useContext(AuthContext);
  const { id } = match.params;
  const {
    breakpoints: { md, xl }
  } = useContext(UiContext);

  useEffect(() => {
    (async () => {
      try {
        const redemptionRequestRes = await api.Redemptions.fullDetails(id);
        /* TODO: this should be prevented via filter and/or backend
            because the response for redemption can still be seen via network tab
        */
        if (!isAdmin && redemptionRequestRes.UserId !== sub) {
          return history.push(ROUTES.redemptions);
        }
        const asset = await api.Assets.getAsset(redemptionRequestRes.RedeemUniqueAssetId);
        setRedemptionRequest({ ...redemptionRequestRes, asset });
        if (!isAdmin) {
          try {
            const { TierNumber } = await api.Kyc.getUserKycData({
              user: redemptionRequestRes.UserId,
              expand: false
            });
            setRedemptionUserKycTier(TierNumber);
          } catch (error) {
            if (error.response?.status === 404) {
              return;
            } else {
              handleDataError(error);
            }
          }
        }
      } catch (error) {
        handleDataError(error);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [id, history]);

  const handleDataError = (error) => {
    const message = i18nextTranslate(
      i18nextKeys.errorRedeemManagementDetailsLoadingError
    );
    handleError({ error, message });
  };

  const toRedeemManagementOverview = () => history.push(
    isAdmin
      ? ROUTES.admin.redemptions
      : ROUTES.redemptions
  );

  const openRedemptionPdf = async () => {
    try {
      const data = await api.Redemptions.getRedemptionPdf(id);
      const link = URL.createObjectURL(data);
      window.open(link, '_blank');
    } catch (error) {
      const message = i18nextTranslate(i18nextKeys.errorRedemptionDetailsPdf);
      handleError({ error, message });
    }
  };

  const downloadPdf = (
    <Button
      text={i18nextTranslate(i18nextKeys.redemptionDetailsPdf)}
      icon={DownloadSymbol}
      onClick={openRedemptionPdf}
      dataQa="redemptionDetails-openPdf"
      width={{
        md: "302px",
        xl: "100%",
        default: "100%"
      }}
      secondary
    />
  )

  return (
    <LogOnMount
      eventType="Redemption details"
      eventProperties={{
        screen: isAdmin ? "redemption management" : "account"
      }}
    >
      <Fade show={true}>
        <Header
          text={i18nextTranslate(i18nextKeys.redeemManagementDetailsHeader)}
          onArrowBackClick={toRedeemManagementOverview}
          arrowBack
        />
        <div
          className="w-full flex flex-col xl:flex-row gap-16 md:gap-24 justify-center color-8 m-auto"
        >
          {isLoading ? (
            <LoadingSpinner />
          ) : (
            <>
              <div className="flex flex-col gap-16 md:gap-24 w-full xl:w-2/3">
                {!xl && downloadPdf}
                <RequestInformation
                  redemptionRequest={redemptionRequest}
                  setRedemptionRequest={setRedemptionRequest}
                  isAdmin={isAdmin}
                />
                <RedemptionRequest
                  redemptionRequest={redemptionRequest}
                />
                <RedemptionPayoutDetails
                  redemptionRequest={redemptionRequest}
                />
              </div>

              <div className="flex flex-col gap-24 w-full xl:w-1/3">
                {xl && downloadPdf}
                <Tile
                  title={i18nextTranslate(i18nextKeys.commonInfoUser)}
                  expandable
                >
                  <div className="flex flex-col gap-32">
                    <UserInformation
                      user={redemptionRequest.User}
                      singleColumn={!md || xl}
                      showFirstName
                      showLastName
                      showEmail
                    />
                    {isKycEnabled && (
                      <KycLevelInfoBox
                        kycTierNumber={isAdmin ? currentKycTier : redemptionUserKycTier}
                        isLoading={false}
                        dataQa="redemption-details-user-data-kyc-tier"
                        fullWidth={!md || xl}
                      />
                    )}
                  </div>
                </Tile>
              </div>
            </>
          )}
        </div>
      </Fade>
    </LogOnMount>
  );
};

export default RedemptionDetails;
