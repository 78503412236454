import React from "react";
import "./LoadingFlashingDots.css"

const LoadingFlashingDots = ({
  number = 4,
  delay = 400,
  dataQa = "loading-dots"
}) => (
  <div
    className="flex justify-center align-middle"
    data-qa={dataQa}
  >
    {[...Array(number)].map((value, index) => (
      <div
        key={index}
        className="inline-block bg-4 flashing-dot"
        style={{ animationDelay: `${index * delay}ms` }}
      >
      </div>
    ))}
  </div>
);

export default LoadingFlashingDots
