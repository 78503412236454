import React, { useState } from "react";
import AnimateHeight from 'react-animate-height';
import Text from "./Text";

const ExpandableText = ({
  visibleText = "",
  expandableText = "",
  showButtonText = "",
  hideButtonText = "",
  className = "",
  dataQa = "description"
}) => {
  const [expanded, setExpanded] = useState(false);

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  return (
    <div
      className={className}
      data-qa={dataQa}
    >
      <Text
        textStyle="p4"
        dataQa={`${dataQa}-visible`}
      >
        {visibleText}
      </Text>
      <AnimateHeight
        duration={750}
        height={expanded ? 'auto' : 0}
      >
        <Text
          textStyle="p4"
          dataQa={`${dataQa}-expandable`}
        >
          {expandableText}
        </Text>
      </AnimateHeight>
      <button
        type="button"
        onClick={toggleExpanded}
        data-qa={`${dataQa}-toggle`}
      >
        <Text
          className="underline"
          color="color-4"
          dataQa={`${dataQa}-toggle-text`}
        >
          {expanded
            ? hideButtonText
            : showButtonText
          }
        </Text>
      </button>
    </div>
  );
};

export default ExpandableText;
