import React from "react";
import {
  Field as FinalField,
  FormSpy as FinalFormSpy
} from "react-final-form";
import AnimateHeight from 'react-animate-height';
import { i18nextKeys } from "Lang/i18nextKeys";
import i18nextTranslate from "Lang/i18nextTranslate";
import { isRequiredIfWithMessage } from "Utils/validators";
import { FormField } from "Components/shared/formElements";
import { FeatureToggle, SubsectionHeading } from "../../../shared";
import { Description, TranslationsForm } from "../../../shared/translations";

const IntermediaryBankAccount = ({
  account,
  contentWidth,
  fieldWidth,
  textAreaHeight
}) => {
  const fieldGroupName = "IntermediaryBank";
  const fieldConfig = [
    {
      name: "BankName",
      label: i18nextTranslate(i18nextKeys.commonBankIntermediaryName)
    },
    {
      name: "Swift",
      label: i18nextTranslate(i18nextKeys.commonBankIntermediarySwift)
    },
    {
      name: "Account",
      label: i18nextTranslate(i18nextKeys.commonBankIntermediaryIban)
    }
  ];

  const defaultTranslation = {
    Description: null
  };

  return (
    <div style={{ width: contentWidth }}>
      <FinalField
        name={`${fieldGroupName}.IsEnabled`}
        type="checkbox"
        initialValue={account.IsEnabled}
        subscription={{ value: true }}
        validateFields={[]}
      >
        {({ input: { checked, onChange } }) => (
          <FeatureToggle
            title={i18nextTranslate(i18nextKeys.commonBankIntermediaryDetails)}
            description={i18nextTranslate(i18nextKeys.configPaymentBankTransferIntermediaryDescription)}
            isEnabled={checked}
            onChange={onChange}
            dataQa="intermediary"
          />
        )}
      </FinalField>
      <FinalFormSpy subscription={{ values: true }}>
        {({ values: { IntermediaryBank } }) => (
          <AnimateHeight
            duration={750}
            height={IntermediaryBank.IsEnabled ? 'auto' : 0}
          >
            <div
              className="flex flex-col gap-16 mt-32 xxl:mt-40"
            >
              <SubsectionHeading
                text={i18nextTranslate(
                  i18nextKeys.configPaymentBankTransferIntermediaryTranslationTitle,
                )}
                tooltipText={i18nextTranslate(
                  i18nextKeys.configPaymentBankTransferIntermediaryTranslationTooltip
                )}
                className=""
                dataQa="intermediary-description"
              />
              <TranslationsForm
                fieldArrayName={`${fieldGroupName}.Translations`}
                translations={account.Translations}
                defaultValue={defaultTranslation}
                fieldWidth={fieldWidth}
                fields={(props) => (
                  <Description
                    fieldWidth={fieldWidth}
                    textAreaHeight={textAreaHeight}
                    {...props}
                  />
                )}
              />
            </div>
            <div
              className="mt-40 flex flex-col gap-24">
              <SubsectionHeading
                text={i18nextTranslate(
                  i18nextKeys.commonBankIntermediaryDetails
                )}
                tooltipText={i18nextTranslate(
                  i18nextKeys.configPaymentBankTransferIntermediaryTooltip
                )}
                className=""
                dataQa="intermediary-account"
              />
              {fieldConfig.map((field, index) => (
                <div key={`intermediary-account-field-${index}`}>
                  <FinalField
                    name={`${fieldGroupName}.${field.name}`}
                    label={field.label}
                    initialValue={account[field.name]}
                    required={IntermediaryBank.IsEnabled}
                    subscription={{
                      active: true,
                      error: true,
                      touched: true,
                      value: true
                    }}
                    validate={isRequiredIfWithMessage(
                      ({ IntermediaryBank }) => IntermediaryBank.IsEnabled,
                      field.label
                    )}
                    validateFields={[]}
                  >
                    {(props) => (
                      <FormField
                        style={{ width: fieldWidth }}
                        {...props}
                      />
                    )}
                  </FinalField>
                </div>
              ))}
            </div>
          </AnimateHeight>
        )}
      </FinalFormSpy>
    </div>
  );
};

export default IntermediaryBankAccount;
