import React from "react";

const ProgressBar = ({ size, progress }) => {
  const stroke = 4;
  const center = size / 2;
  const radius = Math.round(center * 0.85);
  const circumference = 2 * Math.PI * radius;
  const offset = circumference * ((100 - progress) / 100);
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      style={{ transform: "rotate(-90deg)" }}
    >
      <circle
        cx={center}
        cy={center}
        r={radius}
        fill="transparent"
        className="progress-circle"
        strokeWidth={stroke}
        strokeDasharray={circumference}
        style={{ stroke: "var(--color-4_7)" }}
      />
      <circle
        cx={center}
        cy={center}
        r={radius}
        fill="transparent"
        strokeWidth={stroke}
        strokeDasharray={circumference}
        strokeDashoffset={offset}
        style={{
          transition: "stroke-dashoffset 2s linear",
          stroke: "var(--color-4)"
        }}
      />
    </svg>
  );
};

export default ProgressBar;
