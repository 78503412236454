import React from 'react';

const IndeterminateCheckbox = React.forwardRef(
  (
    {
      checked,
      onChange,
      readOnly = false,
      boxClasses = 'bg-white w-24 h-24 mr-8',
      checkMarkClasses = 'w-16 h-16',
      checkMarkColor = 'currentColor',
      dataQa = null,
      indeterminate,
      ...rest
    },
    ref
  ) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <div
        className={`${boxClasses} rounded flex flex-shrink-0 justify-center items-center relative border border-color-5`}
      >
        <input
          type="checkbox"
          className="opacity-0 absolute color-10"
          data-qa={dataQa}
          checked={checked}
          onChange={onChange}
          readOnly={readOnly}
          required
          ref={resolvedRef}
          {...rest}
        />
        <svg
          className={`${checkMarkClasses} hidden text-green-600 pointer-events-none`}
          viewBox="0 0 172 172"
        >
          <g
            fill="none"
            strokeWidth="none"
            strokeMiterlimit="10"
            fontFamily="none"
            fontWeight="none"
            fontSize="none"
            textAnchor="none"
            style={{ mixBlendMode: 'normal' }}
          >
            <path d="M0 172V0h172v172z" />
            <path
              d="M145.433 37.933L64.5 118.8658 33.7337 88.0996l-10.134 10.1341L64.5 139.1341l91.067-91.067z"
              fill={checkMarkColor}
              strokeWidth="1"
            />
          </g>
        </svg>
      </div>
    );
  }
);

export default IndeterminateCheckbox;
