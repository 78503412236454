import React, { useContext } from 'react';
import { LangContext } from 'States/lang/langState';
import { getTranslation } from 'Helpers/translations';
import { i18nextKeys } from 'Lang/i18nextKeys';
import i18nextTranslate from 'Lang/i18nextTranslate';
import BankDetailsInfoBox from 'Features/Checkout/CheckoutPayment/PaymentMethods/BankDetailsInfoBox';
import { SubsectionHeading } from 'Features/admin/ConfigManagement/shared';

const BankDetailsInfo = ({
  paymentMethod: {
    BeneficiaryDetails,
    AdditionalBankIdentifiers,
    IntermediaryBank
  },
  defaultLanguage
}) => {
  const { lang } = useContext(LangContext);

  return (
    <div className="w-full">
      <div
        data-qa="bank-details-info-wrapper"
        className="grid grid-cols-1 md:grid-cols-3 row-gap-16 md:row-gap-24 col-gap-40 justify-start"
      >
        <BankDetailsInfoBox
          value={BeneficiaryDetails.Name}
          tooltipDescription={getTranslation(
            "Name",
            lang,
            defaultLanguage.Code,
            BeneficiaryDetails.Translations
          )}
          translationKey={i18nextKeys.checkoutBankBeneficiary}
        />

        <BankDetailsInfoBox
          value={BeneficiaryDetails.Account}
          tooltipDescription={getTranslation(
            "Account",
            lang,
            defaultLanguage.Code,
            BeneficiaryDetails.Translations
          )}
          translationKey={i18nextKeys.commonBankBeneficiaryAccount}
        />
        <BankDetailsInfoBox
          value={BeneficiaryDetails.BankName}
          tooltipDescription={getTranslation(
            "BankName",
            lang,
            defaultLanguage.Code,
            BeneficiaryDetails.Translations
          )}
          translationKey={i18nextKeys.commonBankBeneficiaryName}
        />

        <BankDetailsInfoBox
          value={BeneficiaryDetails.BankAddress}
          tooltipDescription={getTranslation(
            "BankAddress",
            lang,
            defaultLanguage.Code,
            BeneficiaryDetails.Translations
          )}
          translationKey={i18nextKeys.commonBankBeneficiaryAddress}
        />
        <BankDetailsInfoBox
          value={BeneficiaryDetails.BIC}
          tooltipDescription={getTranslation(
            "BIC",
            lang,
            defaultLanguage.Code,
            BeneficiaryDetails.Translations
          )}
          translationKey={i18nextKeys.commonBankBeneficiaryBic}
        />
        <BankDetailsInfoBox
          value={BeneficiaryDetails.Swift}
          tooltipDescription={getTranslation(
            "Swift",
            lang,
            defaultLanguage.Code,
            BeneficiaryDetails.Translations
          )}
          translationKey={i18nextKeys.commonBankBeneficiarySwift}
        />

        {AdditionalBankIdentifiers.AreEnabled
          ? AdditionalBankIdentifiers.Identifiers.map(
            ({ Value, Translations }, idx) => {
              return (
                <BankDetailsInfoBox
                  key={idx}
                  value={Value}
                  tooltipDescription={getTranslation(
                    "Description",
                    lang,
                    defaultLanguage.Code,
                    Translations
                  )}
                  translationKey={getTranslation(
                    "Name",
                    lang,
                    defaultLanguage.Code,
                    Translations
                  )}
                />
              );
            }
          )
          : null}
      </div>

      {IntermediaryBank.IsEnabled && (
        <>
          <hr className="w-full border-color-5 my-16" />
          <SubsectionHeading
            text={i18nextTranslate(
              i18nextKeys.commonBankIntermediaryDetails
            )}
            tooltipText={getTranslation(
              "Description",
              lang,
              defaultLanguage.Code,
              IntermediaryBank.Translations
            )}
            dataQa="intermediary-account"
          />
          <div
            data-qa="intermediary-details-info-wrapper"
            className="grid grid-cols-1 md:grid-cols-3 row-gap-16 md:row-gap-24 col-gap-40 justify-start mb-32"
          >
            <BankDetailsInfoBox
              value={IntermediaryBank.BankName}
              translationKey={i18nextKeys.commonBankIntermediaryName}
            />
            <BankDetailsInfoBox
              value={IntermediaryBank.Swift}
              translationKey={i18nextKeys.commonBankIntermediarySwift}
            />
            <BankDetailsInfoBox
              value={IntermediaryBank.Account}
              translationKey={
                i18nextKeys.checkoutPaymentMethodsBankDetailsIntermediaryAccount
              }
            />
          </div>
        </>
      )}
    </div>
  );
};

export default BankDetailsInfo;
