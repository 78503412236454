import React from 'react';
import {i18nextKeys} from "../../../Lang/i18nextKeys";
import i18nextTranslate from "../../../Lang/i18nextTranslate";

const ErrorMessage = ({ error }) => {
  return (
    <p
      className="color-red-500 text-xs italic"
      style={{
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        opacity: error && error.show ? '1' : '0',
      }}
    >
      {(error && error.text) || i18nextTranslate(i18nextKeys.errorMessageFillOutThisField)}
    </p>
  );
};

export default ErrorMessage;
