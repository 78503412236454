import React, { useContext } from 'react';
import { UiContext } from 'States/ui/uiState';
import { WarningIcon } from 'Components/shared/symbols';
import { i18nextKeys } from 'Lang/i18nextKeys';
import i18nextTranslate from 'Lang/i18nextTranslate';
import Text from 'Components/shared/Text';

const RemovedListHeader = ({ expandTitle, toggleList }) => {

  const {
    breakpoints: { md, xxl }
  } = useContext(UiContext);

  const iconSize = xxl && "16" || md && "14" || "20";

  return (
    <tr data-qa="cart-unavailable-assets-header">
      <td colSpan="6" className="px-0 pb-0 pt-40 border-0">
        <div className="flex items-center flex-1 px-16 py-12 rounded-t bg-5">
          <div className="flex items-center flex-1 gap-16">
            <WarningIcon size={iconSize} />
            <Text textStyle="h3">
              {i18nextTranslate(i18nextKeys.cartAutoRemovedItems)}
            </Text>
          </div>
          <div>
            <span
              className="color-6 text-xs font-bold cursor-pointer"
              onClick={toggleList}
            >
              {expandTitle}
            </span>
          </div>
        </div>
      </td>
    </tr>
  );
};

export default RemovedListHeader;
