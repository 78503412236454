import React from 'react';
import { DIRECTION } from 'Helpers/icons';

const SingleChevron = ({
  size = "14",
  direction = DIRECTION.right,
  className,
  ...props
}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 14 14"
    fill="currentColor"
    className={className}
    transform={`rotate(${direction})`}
    {...props}
  >
    <path d="M9.625 7.00001C9.625 7.15683 9.56198 7.31363 9.43619 7.43319L5.47603 11.1955C5.22411 11.4348 4.81567 11.4348 4.56386 11.1955C4.31205 10.9563 4.31205 10.5683 4.56386 10.329L8.06803 7.00001L4.56398 3.67105C4.31217 3.43172 4.31217 3.0438 4.56398 2.80459C4.8158 2.56514 5.22423 2.56514 5.47615 2.80459L9.43631 6.56684C9.56212 6.68646 9.625 6.84325 9.625 7.00001Z" />
  </svg>
);

export default SingleChevron;
