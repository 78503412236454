import React, { useState, useRef, useContext } from 'react';
import { usePopper } from 'react-popper';
import { UiContext } from 'States/ui/uiState';
import { InfoIcon } from '../symbols';
import Text from '../Text';
import './Tooltip.css';

const Tooltip = ({
  children,
  component,
  text,
  dataQa,
  hideDelay = 0,
  childStyle,
  childClassName,
  textStyle,
  textClassName,
  width = '309px',
  disableHoverListener,
  useIcon = false,
}) => {
  const [visible, setVisible] = useState(false);
  const {
    breakpoints: { sm, xxl }
  } = useContext(UiContext);
  const triggerRef = useRef(null);
  const tooltipRef = useRef(null);
  const { styles, attributes } = usePopper(
    triggerRef.current,
    tooltipRef.current,
    {
      strategy: 'fixed',
      placement: 'top',
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 15],
          },
        },
        {
          name: 'preventOverflow',
          options: {
            padding: { left: 16, right: 16 },
          },
        },
        {
          name: 'arrow',
          options: {
            padding: 8,
          },
        },
      ],
    }
  );

  let timeout;

  const show = () => {
    if (!disableHoverListener) {
      setVisible(true);
      clearTimeout(timeout);
    }
  };

  const hide = () => {
    if (!disableHoverListener) {
      clearTimeout(timeout);
      timeout = setTimeout(() => setVisible(false), hideDelay);
    }
  };

  return (
    <>
      <div
        ref={triggerRef}
        style={childStyle}
        className={('relative', childClassName)}
        onMouseOver={show}
        onTouchStart={show}
        onMouseOut={hide}
        onTouchEnd={hide}
      >
        {useIcon ? (
          <InfoIcon
            dataQa={`${dataQa}-tooltip-icon`}
            size={xxl ? 14 : 12}
          />
        ) : children}
      </div>
      <div
        className={`tooltip bg-4--20 rounded-md p-12 z-50 ${visible ? 'visible' : 'invisible'
          } ${textClassName}`}
        ref={tooltipRef}
        style={{
          ...styles.popper,
          width: sm ? width : 'calc(100% - 32px)', // TODO: find a better way to center a tooltip on small screens
          ...textStyle
        }}
        {...attributes.popper}
      >
        {text ? (
          <Text
            color="color-white"
            dataQa={`${dataQa}-tooltip`}
            inline
          >
            {text}
          </Text>
        ) : null}
        {component ? component : null}
        <div
          className="tooltip-arrow"
          style={styles.arrow}
          data-popper-arrow
        ></div>
      </div>
    </>
  );
};

export default Tooltip;
