import React, { useContext } from 'react';
import { Field as FinalField } from 'react-final-form';
import { UiContext } from 'States/ui/uiState';
import i18nextTranslate from 'Lang/i18nextTranslate';
import { i18nextKeys } from 'Lang/i18nextKeys';
import { TrashCanSymbol } from 'Components/shared/symbols';
import { FormField } from 'Components/shared/formElements';
import { CountryInput } from 'Components/shared/formElements';
import { Button } from 'Components/shared/buttons';
import { PlusSymbol } from 'Components/shared/symbols';
import FormStep from 'Components/shared/formElements/FormStep';
import { isRequiredIfWithMessage } from 'Utils/validators';

const BANK_ADDRESS_FIELDS = {
  addressLine: "AddressLine",
  addressLine1: "AddressLine1",
  zipCode: "ZipCode",
  city: "City",
  stateOrRegion: "StateOrRegion",
  country: "Country"
};

const isAddressRequired = ({ bankAccount }, intermediary) => {
  if (!bankAccount) {
    return false;
  }
  const accountValues = intermediary
    ? bankAccount.IntermediaryBankAccount
    : bankAccount;
  const requiredFields = [
    intermediary
      ? BANK_ADDRESS_FIELDS.addressLine
      : BANK_ADDRESS_FIELDS.addressLine1,
    BANK_ADDRESS_FIELDS.zipCode,
    BANK_ADDRESS_FIELDS.city,
    BANK_ADDRESS_FIELDS.country
  ];

  if (!accountValues) {
    return false;
  }

  for (const requiredField of requiredFields) {
    if (!!accountValues[requiredField]) {
      return true;
    }
  }
  return false;
};

const BankAddressForm = ({
  initialValues = {},
  addIntermediaryBank = () => {},
  removeIntermediaryBank = () => {},
  showDeleteButton = false,
  isIntermediaryPresent = false,
  intermediary = false,
  style = {},
  dataQa = "bank"
}) => {
  const { breakpoints: { md } } = useContext(UiContext);
  const valuesKey = `bankAccount${intermediary ? '.IntermediaryBankAccount' : ''}`;
  const dataQaPrefix = intermediary
    ? `${dataQa}-intermediary`
    : dataQa

  return (
    <div
      className="flex flex-col"
      style={style}
    >
      <FormStep
        number="2"
        text={i18nextTranslate(
          intermediary
            ? i18nextKeys.commonBankIntermediaryAddress
            : i18nextKeys.commonBankAddress
        )}
        dataQa={`${dataQaPrefix}-address-title`}
      />
      <div className="flex-grow flex flex-col gap-6 xxl:gap-3">
        <FinalField
          name={`${valuesKey}.${intermediary
            ? BANK_ADDRESS_FIELDS.addressLine
            : BANK_ADDRESS_FIELDS.addressLine1
          }`}
          label={i18nextTranslate(i18nextKeys.commonAddressStreet)}
          initialValue={
            intermediary
              ? initialValues?.AddressLine
              : initialValues?.AddressLine1
          }
          validate={isRequiredIfWithMessage(
            (values) => isAddressRequired(values, intermediary),
            i18nextTranslate(i18nextKeys.commonAddressStreet)
          )}
          render={(props) => (
            <FormField
              dataQa={`${dataQaPrefix}-street`}
              required={props.input.value || props.meta.invalid}
              {...props}
            />
          )}
        />
        <div className="flex justify-between">
          <FinalField
            name={`${valuesKey}.${BANK_ADDRESS_FIELDS.zipCode}`}
            label={i18nextTranslate(i18nextKeys.commonAddressZip)}
            initialValue={initialValues?.ZipCode}
            validate={isRequiredIfWithMessage(
              (values) => isAddressRequired(values, intermediary),
              i18nextTranslate(i18nextKeys.commonAddressZip)
            )}
            render={(props) => (
              <FormField
                containerStyle={{ width: '45%' }}
                dataQa={`${dataQaPrefix}-zip`}
                required={props.input.value || props.meta.invalid}
                {...props}
              />
            )}
          />
          <FinalField
            name={`${valuesKey}.${BANK_ADDRESS_FIELDS.city}`}
            label={i18nextTranslate(i18nextKeys.commonAddressCity)}
            initialValue={initialValues?.City}
            validate={isRequiredIfWithMessage(
              (values) => isAddressRequired(values, intermediary),
              i18nextTranslate(i18nextKeys.commonAddressCity)
            )}
            render={(props) => (
              <FormField
                containerStyle={{ width: '45%' }}
                dataQa={`${dataQaPrefix}-city`}
                required={props.input.value || props.meta.invalid}
                {...props}
              />
            )}
          />
        </div>

        <FinalField
          name={`${valuesKey}.${BANK_ADDRESS_FIELDS.stateOrRegion}`}
          label={i18nextTranslate(i18nextKeys.commonAddressRegion)}
          initialValue={initialValues?.ZipCode}
          render={(props) => (
            <FormField
              dataQa={`${dataQaPrefix}-region`}
              {...props}
            />
          )}
        />
        <FinalField
          name={`${valuesKey}.${BANK_ADDRESS_FIELDS.country}`}
          label={i18nextTranslate(i18nextKeys.commonAddressCountry)}
          initialValue={initialValues?.Country}
          validate={isRequiredIfWithMessage(
            (values) => isAddressRequired(values, intermediary),
            i18nextTranslate(i18nextKeys.commonAddressCountry)
          )}
          render={(props) => (
            <CountryInput
              dataQa={`${dataQaPrefix}-country`}
              required={props.input.value || props.meta.invalid}
              menuPlacement={md ? "auto" : "top"}
              showErrors={false}
              {...props}
            />
          )}
        />
        {!intermediary && !isIntermediaryPresent && (
          <Button
            text={i18nextTranslate(i18nextKeys.commonBankIntermediaryAdd)}
            icon={PlusSymbol}
            onClick={addIntermediaryBank}
            dataQa="bank-intermediary-add"
            className="flex-row-reverse mt-auto"
            fullWidth
            outlined
          />
        )}
        {showDeleteButton && (
          <Button
            text={i18nextTranslate(i18nextKeys.commonBankIntermediaryDelete)}
            icon={TrashCanSymbol}
            onClick={removeIntermediaryBank}
            dataQa="bank-intermediary-delete"
            className="flex-row-reverse"
            fullWidth
            outlined
          />
        )}
      </div>
    </div>
  );
};

export default BankAddressForm;
