import React, { useContext } from 'react';
import { Button } from 'Components/shared/buttons';
import { UiContext } from 'States/ui/uiState';

const DefaultCardButtonGroup = ({ buttons = [], dataQa }) => {
  const {
    breakpoints: { md },
  } = useContext(UiContext);

  const buttonWidth = {
    md: "100%",
    default: buttons.length > 1 ? "142px" : "100%"
  };

  return (
    <div
      className="grid md:px-16 gap-12"
      style={{
        gridArea: 'buttons',
        gridTemplateColumns: !md ? (buttons[1] ? '1fr 1fr' : '1fr') : '1fr',
        height: "min-content"
      }}
    >
      {buttons[0] && (
        <Button
          dataQa={`${dataQa}-topButton`}
          onClick={buttons[0].onClick}
          text={buttons[0].text}
          width={buttonWidth}
        />
      )}
      {buttons[1] && (
        <Button
          dataQa={`${dataQa}-bottomButton`}
          onClick={buttons[1].onClick}
          text={buttons[1].text}
          className="justify-self-end"
          width={buttonWidth}
        />
      )}
    </div>
  );
};

export default DefaultCardButtonGroup;
