import React, { useState } from 'react';
import { useAsyncDebounce } from 'react-table';
import i18nextTranslate from 'Lang/i18nextTranslate';
import { i18nextKeys } from 'Lang/i18nextKeys';
import { AbortSymbol, SearchIcon } from '../symbols';

const ModalGlobalFilter = ({
  globalFilter,
  setGlobalFilter,
  dataQa = "search",
}) => {
  const [value, setValue] = useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 1000);

  return (
    <>
      <div
        data-qa={dataQa}
        className="text-black w-full relative"
      >
        <input
          data-qa={`${dataQa}-input`}
          className="outline-none p-4 px-12 w-100 w-full h-full bg-9 focus:bg-9-10 inputField rounded-l-md color-10"
          value={value || ''}
          onChange={(e) => {
            setValue(e.target.value);
            if (e.target.value.length >= 3 || e.target.value === '') {
              onChange(e.target.value);
            }
          }}
          placeholder={i18nextTranslate(
            i18nextKeys.kycManagementOverviewSearchByUser
          )}
        />
        {value && (
          <div
            data-qa={`${dataQa}-clear`}
            className="cursor-pointer w-full h-full absolute left-0 top-0 flex items-center justify-end"
            onClick={() => {
              setValue('');
              onChange('');
            }}
          >
            <AbortSymbol
              size="16"
              className="mr-16"
            />
          </div>
        )}
      </div>
      <div
        className="flex items-center px-16 py-8 bg-4 rounded-r-md font-bold cursor-pointer"
        style={{ minWidth: 'max-content' }}
        onClick={() => {
          setValue(value);
          onChange(value);
        }}
        data-qa={`${dataQa}-button`}
      >
        <SearchIcon className="mr-8" />
        {i18nextTranslate(i18nextKeys.tenantSettingsAdminTableSearchUser)}
      </div>
    </>
  );
};

export default ModalGlobalFilter;
