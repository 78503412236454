import React, { useState } from 'react';
import i18nextTranslate from 'Lang/i18nextTranslate';
import { i18nextKeys } from 'Lang/i18nextKeys';
import Text from 'Components/shared/Text';
import ItemRow from './ItemRow';
import RemovedListHeader from './components/RemovedListHeader';

const TableBody = ({
  availableAssets,
  unavailableAssets,
  currency,
  defaultLanguage,
  setQuantitiesValidity,
  inCheckoutProcess = false
}) => {
  const [openRows, setOpenRows] = useState(new Set());
  const [unavailableItemsVisible, setUnavailableItemsVisibility] = useState(true);

  const toggleUnavailableAssetList = () => {
    setUnavailableItemsVisibility(prevState => !prevState);
  };

  const toggleRow = (uniqueAssetId) => {
    if (openRows.has(uniqueAssetId)) {
      const updatedRows = new Set(openRows);
      updatedRows.delete(uniqueAssetId);
      setOpenRows(updatedRows);
      return;
    }
    const updatedRows = new Set(openRows).add(uniqueAssetId);
    setOpenRows(updatedRows);
  };

  const rowExpanded = (uniqueAssetId) => openRows.has(uniqueAssetId);

  const expandTitle = unavailableItemsVisible
    ? i18nextTranslate(i18nextKeys.cartTableHideItems)
    : i18nextTranslate(i18nextKeys.cartTableShowItems);

  return (
    <tbody>
      {availableAssets.map((uniqueAssetId, index) => (
        <ItemRow
          key={uniqueAssetId}
          uniqueAssetId={uniqueAssetId}
          defaultLanguage={defaultLanguage}
          currency={currency}
          index={index}
          toggleRow={() => toggleRow(uniqueAssetId)}
          rowExpanded={rowExpanded}
          setQuantitiesValidity={setQuantitiesValidity}
        />
      ))}
      {!inCheckoutProcess && (
        <tr>
          <td
            colSpan="6"
            className="px-12 py-8 bg-4_15 md:rounded-b"
          >
            <Text textStyle="text-small">
              {i18nextTranslate(i18nextKeys.cartMessageFees)}
            </Text>
          </td>
        </tr>
      )}
      {unavailableAssets.length ? (
        <>
          {availableAssets.length > 0 && (
            <RemovedListHeader
              toggleList={toggleUnavailableAssetList}
              expandTitle={expandTitle}
            />
          )}
          {unavailableItemsVisible
            ? unavailableAssets.map((uniqueAssetId, index) => (
              <ItemRow
                key={uniqueAssetId}
                uniqueAssetId={uniqueAssetId}
                defaultLanguage={defaultLanguage}
                currency={currency}
                index={index}
                toggleRow={() => toggleRow(uniqueAssetId)}
                rowExpanded={rowExpanded}
                assetUnavailable
              />
            ))
            : null}
        </>
      ) : null}
    </tbody>
  );
};

export default TableBody;
