import React, { useContext, useEffect, useMemo } from "react";
import { generatePath, useHistory } from "react-router-dom";
import { ROUTES } from "Router/Routes";
import { i18nextKeys } from "Lang/i18nextKeys";
import i18nextTranslate from "Lang/i18nextTranslate";
import { COLLECT_METHOD } from "Enums/CollectMethod";
import { UiContext } from "States/ui/uiState";
import { getCollectionMethodIcon } from "Helpers/icons";
import Text from "Components/shared/Text";
import MethodTile from "../MethodTile";

const CollectionMethods = ({
  collectionMethods,
  defaultLanguage,
  disabled = false,
  isPurchaseEnabled = false,
  pristine = true,
  showNotification = () => { }
}) => {
  const history = useHistory();

  const {
    breakpoints: { xxl }
  } = useContext(UiContext);

  const getIcon = (methodType) => getCollectionMethodIcon({
    size: xxl ? "40" : "32",
    className: "color-4_50 flex-shrink-0",
    collectionMethod: methodType
  });

  const openCollectionMethod = (collectionMethod) => {
    const pathname = generatePath(
      ROUTES.admin.config.collection,
      { type: collectionMethod.Type }
    );
    history.push({
      pathname,
      state: {
        collectionMethod // Not used for now
      }
    });
  };

  useEffect(() => {
    if (!pristine && isSingleEnabledMethod) {
      showNotification();
    }
  }, [collectionMethods]);

  const isSingleEnabledMethod = useMemo(() => {
    if (!isPurchaseEnabled) {
      return false;
    }
    const enabledMethods = collectionMethods.filter(
      ({ IsEnabled }) => IsEnabled
    );
    return enabledMethods.length < 2;
  }, [collectionMethods, isPurchaseEnabled]);

  const isConfigured = ({
    Type,
    IsEnabled,
    MethodFees,
    FallBackCountryFees,
    Translations
  }) => IsEnabled || (
    MethodFees.FixedFee >= 0 &&
    MethodFees.VariableFee >= 0 &&
    FallBackCountryFees.FixedFee >= 0 &&
    FallBackCountryFees.VariableFee >= 0 &&
    Translations.some(
      ({ LanguageCode, Description, Disclaimer }) =>
        LanguageCode === defaultLanguage.Code &&
        !!Description &&
        (Type !== COLLECT_METHOD.OneTimeWallet || Disclaimer)
    )
  );

  return (
    <>
      <Text
        textStyle="h2"
        className="mb-12 xxl:mb-16"
        style={{
          scrollMargin: 60
        }}
        dataQa="collectionMethods-title"
        uppercase
      >
        {i18nextTranslate(i18nextKeys.configCollectionTitle)}
      </Text>
      <Text
        textStyle="p4"
        className="mb-32"
        style={{ maxWidth: xxl ? "700px" : "600px" }}
      >
        {i18nextTranslate(i18nextKeys.configCollectionDescription)}
      </Text>
      <div className="flex flex-col gap-16">
        {collectionMethods.map((collectionMethod, index) =>
          <MethodTile
            key={collectionMethod.Type}
            toggleName={`collectionMethods[${index}].IsEnabled`}
            translationKeyPrefix="configCollection"
            method={collectionMethod}
            openMethod={openCollectionMethod}
            getIcon={getIcon}
            contentWidth={xxl ? "700px" : "600px"}
            isSingleEnabledMethod={isSingleEnabledMethod}
            isConfigured={isConfigured(collectionMethod)}
            disabled={disabled}
          />
        )}
      </div>
    </>
  );
};

export default CollectionMethods;
