import React, { useContext, useEffect, useState } from 'react';
import Select, { components } from 'react-select';
import { UiContext } from "States/ui/uiState";
import { Button } from '../../buttons';
import { DropdownArrow, EditIcon, PlusSymbol } from 'Components/shared/symbols';
import Text from '../../Text';
import Error from '../Error';
import i18nextTranslate from 'Lang/i18nextTranslate';
import { i18nextKeys } from 'Lang/i18nextKeys';
import OptionEmptyValue from './OptionEmptyValue';

export const DropdownIndicator = ({
  ...props
}) => {
  return (
    <components.DropdownIndicator {...props}>
      <DropdownArrow />
    </components.DropdownIndicator>
  );
};

const DropdownField = ({
  dataQa,
  className = '',
  style = {},
  meta: {
    touched,
    invalid,
    error
  } = {},
  input,
  label,
  placeholder,
  options = [],
  valueKey = 'value',
  labelKey = 'label',
  menuPlacement = 'auto',
  replacedComponents = {},
  isSearchable = false,
  disabled = false,
  showClearSelection = false,
  required = false,
  showErrors = false,
  withButton = false,
  buttonText = "",
  onButtonClick = () => {},
  showEditIcon = false,
  onEdit = () => { },
}) => {
  const {
    breakpoints: { xxl }
  } = useContext(UiContext);

  const [dropdownOptions, setDropdownOptions] = useState([]);

  useEffect(() => {
    const toggleEmptyValue = () => {
      let processedOptions = [...options];
      if (showClearSelection) {
        processedOptions.unshift({
          [valueKey]: "",
          [labelKey]: i18nextTranslate(i18nextKeys.commonClearSelection),
          emptyOption: true
        });
      }
      setDropdownOptions(processedOptions);
    };
    if (options.length && showClearSelection) {
      toggleEmptyValue();
    } else {
      setDropdownOptions(options)
    }
  }, [options, showClearSelection]);

  return (
    <div
      className={className}
      style={style}
    >
      {label ? (
        <Text
          textStyle="h3"
          className="mb-4"
        >
          <label data-qa={`${dataQa}-label`}>
            {label}{required ? " *" : null}
          </label>
        </Text>
      ) : null}
      <div
        className="flex items-center gap-16"
        data-qa={`${dataQa}-input`}
      >
        <div className="flex w-full">
          <Select
            components={{
              DropdownIndicator,
              IndicatorSeparator: () => null,
              ...!required && {
                Option: OptionEmptyValue
              },
              ...replacedComponents
            }}
            isDisabled={disabled}
            menuPlacement={menuPlacement}
            maxMenuHeight={200}
            isSearchable={isSearchable}
            options={dropdownOptions}
            getOptionValue={(option) => option[valueKey]}
            getOptionLabel={(option) => option[labelKey]}
            value={input.value}
            onChange={(option) => {
              const newValue = option.emptyOption
                ? ""
                : option[valueKey];
              input.onChange(newValue, option);
            }}
            noOptionsMessage={() => i18nextTranslate(i18nextKeys.inputNoResultsFound)}
            placeholder={placeholder}
            className="flex-grow"
            styles={{
              control: (
                {
                  color,
                  borderTopRightRadius,
                  borderBottomRightRadius,
                  borderStyle,
                  boxShadow,
                  ...provided
                },
                { isDisabled, menuIsOpen }
              ) => ({
                ...provided,
                color: 'var(--color-10)',
                backgroundColor:
                  isDisabled
                    ? 'var(--color-grey)'
                    : menuIsOpen
                      ? 'var(--color-9-10)'
                      : 'var(--color-9)',
                borderTopRightRadius: withButton ? 0 : '4px',
                borderBottomRightRadius: withButton ? 0 : '4px',
                borderStyle: 'none',
                minHeight: xxl ? '38px' : '35px',
                border: menuIsOpen ? '1px solid var(--color-5)' : 'none'
              }),

              dropdownIndicator: (provided, { selectProps }) => ({
                ...provided,
                color: 'var(--color-10)',
                transform: selectProps.menuIsOpen ? 'scale(1, -1)' : 'scale(1)',
              }),

              option: ({ cursor, ...provided }, { isFocused, isSelected }) => ({
                ...provided,
                cursor: 'pointer',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                color: 'var(--color-10)',
                backgroundColor: (isSelected && isFocused) || isFocused ? 'var(--color-9--5)' : 'var(--color-9-10)',
                padding: '4px 12px',
              }),

              singleValue: (provided) => ({
                ...provided,
                color: 'inherit'
              }),

              menuList: (provided) => ({
                ...provided,
                padding: 0,
                borderTop: '8px solid var(--color-9-10)',
                borderBottom: '8px solid var(--color-9-10)',
                borderRadius: '4px',
                backgroundColor: "var(--color-9-10)",
              }),

              noOptionsMessage: (provided) => ({
                ...provided,
                padding: '4px 12px',
                textAlign: 'left',
                color: 'var(--color-10)',
                opacity: '0.5'
              }),

              placeholder: (provided) => ({
                ...provided,
                color: 'var(--color-10)',
                opacity: '0.5'
              }),

              groupHeading: (provided) => ({
                ...provided,
                color: 'var(--color-10_40)',
                fontSize: '0.75rem',
                lineHeight: '1rem',
                fontWeight: 'bold',
                textTransform: 'none',
                padding: '8px 12px 4px 12px',
                margin: 0
              }),

              group: (provided) => ({
                ...provided,
                padding: '0'
              })
            }}
          />
          {withButton && (
            <Button
              text={buttonText}
              icon={PlusSymbol}
              onClick={onButtonClick}
              dataQa={`${dataQa}-button`}
              className="flex-row-reverse"
              style={{
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0
              }}
              disabled={disabled || !dropdownOptions.length}
            />
          )}
        </div>
        {showEditIcon && (
          <EditIcon
            onClick={onEdit}
            className="color-6 cursor-pointer"
            data-qa={`${dataQa}-edit`}
          />
        )}
      </div>
      {showErrors && (
        <Error
          error={touched && invalid && error}
          dataQa={`${dataQa}-error`}
        />
      )}
    </div>
  );
};

export default DropdownField;
