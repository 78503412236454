import React, { useState, useContext } from 'react';
import AnimateHeight from 'react-animate-height';
import { UiContext } from 'States/ui/uiState';
import { DIRECTION } from 'Helpers/icons';
import {
  EditIcon,
  SingleChevron,
  TrashCanSymbol
} from 'Components/shared/symbols';
import Text from 'Components/shared/Text';

const Stamp = ({
  badge,
  label,
  upperText,
  lowerText,
  style,
  dataQa = 'method-summary',
  onEditClick = () => {},
  onDeleteClick = () => {},
  children,
  expandable = false,
  editable = false
}) => {
  const {
    breakpoints: { md },
  } = useContext(UiContext);
  const [expanded, setExpanded] = useState(false);

  const toggleExpand = () => {
    if (!expandable) {
      return;
    }
    setExpanded(!expanded);
  };

  const Icons = () => (
    <div className="flex items-center gap-16">
      {editable && (
        <div className="flex gap-16">
          <EditIcon
            onClick={onEditClick}
            className="color-6 cursor-pointer"
          />
          <TrashCanSymbol
            size="16"
            onClick={onDeleteClick}
            className="color-6 cursor-pointer"
          />
        </div>
      )}
      {expandable && (
        <SingleChevron
          size="16"
          direction={expanded ? DIRECTION.up : DIRECTION.down}
          onClick={toggleExpand}
          className="color-6 cursor-pointer"
        />
      )}
    </div>
  );

  return (
    <div className="flex flex-col w-full gap-4">
      {label && (
        <Text
          textStyle="h3"
          dataQa={`${dataQa}-label`}
        >
          {label}
        </Text>
      )}
      <div
        className={`flex flex-col ${expanded ? '' : 'gap-12'}`}
        style={style}
      >
        <div
          className={`flex ${
              md || !editable ? 'h-40 flex-row items-center' : 'flex-col'
            } color-8`}
          data-qa={dataQa}
        >
          {!!badge && (
            <span
              className={`h-full flex items-center ${
                  md || !editable ? 'rounded-l justify-center w-1/6' : 'rounded-t justify-between'
                } px-16 md:px-0 py-8 md:py-0 font-black color-8_80 md:text-lg uppercase bg-2`}
              data-qa={`${dataQa}-badge`}
            >
              {badge}
              {!md && <Icons />}
            </span>
          )}
          <div
            className={`h-full flex justify-between items-center ${
                md || !editable ? 'rounded-r' : 'rounded-b'
              } px-12 py-4 flex-grow bg-2_50 truncate`}
          >
            <div className="flex flex-col truncate">
              <Text
                textStyle="h3"
                color='color-8_80'
                dataQa={`${dataQa}-text-upper`}
              >
                {upperText}
              </Text>
              <Text
                textStyle="h3"
                color='color-8_80'
                dataQa={`${dataQa}-text-lower`}
              >
                {lowerText}
              </Text>
            </div>
            {md && <Icons />}
          </div>
        </div>

        {expandable && (
          <AnimateHeight
            duration={750}
            height={expanded ? 'auto' : 0}
          >
            {children}
          </AnimateHeight>
        )}
      </div>
    </div>
  );
};

export default Stamp;
