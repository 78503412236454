import React, { useContext } from 'react';
import { UiContext } from 'States/ui/uiState';
import { IconCircleWrapper } from 'Helpers/icons';
import Modal from 'Components/shared/Modal/Modal';
import { Button } from 'Components/shared/buttons';
import Text from 'Components/shared/Text';
import i18nextTranslate from "Lang/i18nextTranslate";
import { i18nextKeys } from "Lang/i18nextKeys";

const ConfirmationModal = ({
  show,
  close,
  onConfirm,
  confirmButtonText,
  icon,
  title,
  description,
  dataQa = "confirmationModal",
  loading = false,
  showCloseButton = true
}) => {
  const {
    breakpoints: { xxl }
  } = useContext(UiContext);

  const buttonWidth = {
    xxl: '190px',
    xl: '170px',
    default: '140px'
  };

  return (
    <Modal
      dataQa={dataQa}
      show={show}
      close={close}
      shouldShowCloseButton={false}
    >
      <div className="flex flex-col px-16 py-20 md:px-72 md:py-32 items-center">
        <IconCircleWrapper
          icon={icon}
          size={xxl ? "80px" : "68px"}
          small
        />
        <Text
          textStyle="h2"
          className={icon ? "mt-24" : ""}
          dataQa={`${dataQa}-title`}
          uppercase
          center
        >
          {title}
        </Text>
        {description && (
          <Text
            dataQa={`${dataQa}-description`}
            className="mt-16 xxl:mt-20"
            center
          >
            {description}
          </Text>
        )}
        <div className="mt-24 md:mt-32 flex gap-16">
          {showCloseButton && (
            <Button
              dataQa={`${dataQa}-cancelButton`}
              text={i18nextTranslate(i18nextKeys.buttonCancel)}
              width={buttonWidth}
              onClick={close}
              disabled={loading}
              secondary
            />
          )}
          <Button
            dataQa={`${dataQa}-confirmButton`}
            text={confirmButtonText}
            width={buttonWidth}
            onClick={onConfirm}
            disabled={loading}
          />
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmationModal;
