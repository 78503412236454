import React, { useEffect } from "react";
import HeaderCell from "./HeaderCell";
import TableCell from "./TableCell";
import {
  useAsyncDebounce,
  useFilters,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable
} from "react-table";
import { DefaultColumnFilter } from "./Filter";
import PaginationFooter from "./PaginationFooter";
import LoadingSpinner from "Components/shared/LoadingSpinner";
import NoDataPage from "../NoDataPage/NoDataPage";
import FilterButton from "./FilterButton";

const Table = ({
  dataQa,
  columns,
  data,
  fetchData,
  pageCount: controlledPageCount,
  isLoading,
  defaultSortBy = "OpenCompletionDate",
  defaultFilters = [],
  defaultPageSize = 50,
  getRowProps = () => {},
  outerGlobalFilter,
  evenRowsClassName="bg-2",
  featureCellBg = "bg-transparent",
  lang = null
}) => {
  const tableInstance = useTable(
    {
      columns,
      data,
      defaultColumn: { Filter: DefaultColumnFilter },
      initialState: {
        sortBy: [{ id: defaultSortBy, desc: true }],
        filters: defaultFilters,
        pageIndex: 0,
        pageSize: defaultPageSize
      },
      manualPagination: true,
      manualSortBy: true,
      manualFilters: true,
      disableMultiSort: true,
      pageCount: controlledPageCount,
      manualGlobalFilter: true,
      autoResetGlobalFilter: false
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setGlobalFilter,
    state: { pageIndex, pageSize, sortBy, filters, globalFilter },
    setFilter
  } = tableInstance;

  const fetchDataDebounced = useAsyncDebounce(fetchData, 175);

  useEffect(() => {
    fetchDataDebounced(pageIndex, pageSize, sortBy, filters, globalFilter && globalFilter.trim());
  }, [fetchDataDebounced, pageIndex, pageSize, sortBy, filters, globalFilter, lang]);

  useEffect(() => {
    setGlobalFilter(outerGlobalFilter);
  }, [outerGlobalFilter]);

  return (
    <div className="w-full flex flex-col gap-12">
      {filters?.length > 0 && filters.map((filter) => (
        <FilterButton
          key={filter.value}
          dataQa={dataQa}
          onClick={() => setFilter("Status", undefined)}
          label={filter.value}
        />
      ))}
      <div className="w-full">
        {isLoading ? (
          <LoadingSpinner classes="m-auto" />
        ) : page.length > 0 ? (
          <>
            <div className="overflow-auto">
              <table
                {...getTableProps()}
                className="w-full"
                data-qa={`${dataQa}-table`}
              >
                <thead
                  data-qa={`${dataQa}-table-row-header`}
                  style={{ position: "sticky", top: "0" }}
                  className="bg-2"
                >
                {
                  // Loop over the header rows
                  headerGroups.map((headerGroup) => (
                    // Apply the header row props
                    <tr
                      {...headerGroup.getHeaderGroupProps()}
                      className="react-table-row"
                    >
                      {headerGroup.headers.map((column, index) => (
                        <HeaderCell
                          key={column.id}
                          column={column}
                          dataQa={`${dataQa}-table-header-${
                            columns[index].dataQa || index
                          }`}
                        />
                      ))}
                    </tr>
                  ))
                }
                </thead>
                {/* Apply the table body props */}
                <tbody {...getTableBodyProps()}>
                {
                  // Loop over the table rows of the current page
                  page.map((row, indexRow) => {
                    // Prepare the row for display
                    prepareRow(row);
                    return (
                      // Apply the row props
                      <tr
                        {...row.getRowProps(getRowProps(row))}
                        className={`react-table-row cursor-pointer ${indexRow % 2 ? evenRowsClassName : "bg-4_7"
                        }`}
                        data-qa={`${dataQa}-table-row-${indexRow}`}
                      >
                        {
                          // Loop over the rows cells
                          row.cells.map((cell, indexCell) => (
                            <TableCell
                              key={indexCell}
                              cell={cell}
                              dataQa={`${dataQa}-table-row-${indexRow}-${
                                cell.column.dataQa || `cell-${indexCell}`
                              }`}
                              className={`${indexCell === 0 ? featureCellBg : ''} `}
                            />
                          ))
                        }
                      </tr>
                    );
                  })
                }
                </tbody>
              </table>
            </div>
            <PaginationFooter
              gotoPage={gotoPage}
              previousPage={previousPage}
              nextPage={nextPage}
              pageCount={pageCount}
              canPreviousPage={canPreviousPage}
              canNextPage={canNextPage}
              pageOptions={pageOptions}
              pageIndex={pageIndex}
              dataQa={dataQa}
            />
          </>
        ) : (
          <NoDataPage
            style={{ height: "60vh" }}
            dataQa={`${dataQa}-table-empty`}
          />
        )}
      </div>
    </div>
  );
};

export default Table;
