import Big from 'big.js';
import {
  composeValidators,
  createValidator,
  isRequired,
  isRequiredIf,
  hasLengthGreaterThan,
  hasLengthLessThan,
} from 'revalidate';
import { findIndex } from 'lodash';
import valueMissing from 'revalidate/lib/internal/valueMissing';
import createValidatorFactory from 'revalidate/lib/createValidatorFactory';
import i18nextTranslate from 'Lang/i18nextTranslate';
import i18nextTranslateDynamically from 'Lang/i18nextTranslateDynamically';
import { i18nextKeys } from 'Lang/i18nextKeys';

export const isJsonIf = (condition) => createValidator(
  (message) => (value) => {
    if (!condition) {
      return;
    }
    try {
      JSON.parse(value);
    } catch {
      return message;
    }
  },
  (field) =>
    i18nextTranslateDynamically(i18nextKeys.helperValidatorJson, {
      field: field
    })
);

export const isArray = createValidator(
  (message) => (value) => {
    if (!Array.isArray(value)) return message;
  },
  (field) =>
    i18nextTranslateDynamically(i18nextKeys.helperValidatorArray, {
      field: field
    })
);

export const isObject = createValidator(
  (message) => (value) => {
    if (typeof value === "object" &&
      !Array.isArray(value) &&
      value !== null) {
      return;
    }
    return message;
  },
  (field) =>
    i18nextTranslateDynamically(i18nextKeys.helperValidatorObject, {
      field: field
    })
);

export const isValueUnique = (message, uniqueField) =>
  createValidator(
    (message) => (arrayOfObj) => {
      const uniqueValues = [...new Set(arrayOfObj.map(obj => obj[uniqueField]))];
      if (uniqueValues.length < arrayOfObj.length) {
        return message
      }
    },
    () => message
  );

export const isString = createValidator(
  (message) => (value) => {
    if (typeof value !== 'string') {
      return message;
    }
  },
  (field) =>
    i18nextTranslateDynamically(i18nextKeys.helperValidatorString, {
      field: field,
    })
);

export const isStringOfDigits = createValidator(
  (message) => (value) => {
    if (!/^\d+$/.test(value)) {
      return message;
    }
  },
  (field) =>
    i18nextTranslateDynamically(i18nextKeys.helperValidatorStringDigits, {
      field: field
    })
);

export const isBetween = (lowerBound, upperBound, message) =>
  createValidator(
    (message) => (value) => {
      if (Big(value).lt(lowerBound) || Big(value).gt(upperBound)) {
        return message;
      }
    },
    (field) => message ||
      i18nextTranslateDynamically(
        i18nextKeys.helperValidatorLowerBoundAndUpperBound,
        { field: field, lowerBound: lowerBound, upperBound: upperBound }
      )
  );

export const isNumber = createValidator(
  (message) => (value) => {
    if (value === undefined) return;
    if (value === null || typeof value !== 'number') {
      return message;
    }
  },
  (field) =>
    i18nextTranslateDynamically(i18nextKeys.helperValidatorNumber, {
      field: field,
    })
);

export const isPositiveNumberOrNumericString = createValidator(
  (message) => (value) => {
    if (value === undefined) return;
    if (
      value === null ||
      typeof Number(value) !== 'number' ||
      isNaN(Number(value))
    ) {
      return message;
    }
    if (Number(value) > 0) {
      return;
    }
    return message;
  },
  (field) =>
    i18nextTranslateDynamically(
      i18nextKeys.helperValidatorNumberNumericString,
      { field: field }
    )
);

export const isNonNegativeInteger = createValidator(
  (message) => (value) => {
    if (Number.isInteger(value) && value >= 0) {
      return;
    }
    return message;
  },
  (field) => i18nextTranslateDynamically(
    i18nextKeys.helperValidatorNonNegativeInteger,
    { field }
  )
);

export const isNonNegativeNumber = createValidator(
  (message) => (value) => {
    if (typeof value === 'number' && value >= 0) {
      return;
    }
    return message;
  },
  (field) => i18nextTranslateDynamically(
    i18nextKeys.helperValidatorNonNegativeNumber,
    { field }
  )
);

export const isPositiveNumber = createValidator(
  (message) => (value) => {
    if (typeof value === 'number' && value > 0) {
      return;
    }
    return message;
  },
  (field) =>
    i18nextTranslateDynamically(i18nextKeys.helperValidatorPositiveNumber, {
      field: field,
    })
);

export const isHexString = createValidator(
  (message) => (value) => {
    if (typeof value === 'string'
      && /^0x[0-9A-F]{3,}$/i.test(value)
      && !isNaN(Number(value))) {
      return;
    }
    return message;
  },
  (field) =>
    i18nextTranslateDynamically(i18nextKeys.helperValidatorHexString, {
      field: field,
    })
);

export const isRequiredNumber = (fieldName) =>
  composeValidators(isFieldRequired, isNumber)(fieldName);

export const isRequiredString = (fieldName) =>
  composeValidators(isFieldRequired, isString)(fieldName);
  
export const isRequiredStringIf = (field, condition) =>
  composeValidators(
    isString,
    isRequiredIfWithMessage(
      condition,
      field,
      i18nextTranslateDynamically(
        i18nextKeys.helperValidatorEmptyString,
        { field }
      ))
  )(field);

  export const isRequiredOrEmptyString = (fieldName, condition) =>
    composeValidators(
      isString,
      isRequiredIfWithMessage(condition, fieldName),
    )(fieldName);
  
export const isNonEmptyStringIf = (fieldName, condition) =>
  composeValidators(
    isRequiredStringIf(fieldName, condition),
    hasLengthGreaterThan(0)
  )(fieldName);

export const hasLength = (length) =>
  createValidator(
    (message) => (value) => {
      if (value.length !== length) return message;
    },
    (field) =>
      i18nextTranslateDynamically(i18nextKeys.helperValidatorLength, {
        field: field,
        length: length,
      })
  );

export const hasLengthGreaterThanIf = (length, condition, messageKey) => createValidator(
  (field) => (value) => {
    if (!condition || value.length > length) {
      return;
    }
    return i18nextTranslate(messageKey);
  },
  () => {}
);

export const isRequiredStringOfLength = (fieldName, length) =>
  composeValidators(isFieldRequired, isString, hasLength(length))(fieldName);

export const isRequiredNumberOrNumericString = (fieldName) => {
  return composeValidators(
    isFieldRequired,
    isPositiveNumberOrNumericString
  )(fieldName);
};

export const isBoolean = (fieldName) => {
  return isFieldOneOf([true, false])(fieldName);
};

export const isRequiredBoolean = (fieldName) => {
  return composeValidators(
    isFieldRequired,
    isFieldOneOf([true, false])
  )(fieldName);
};

export const isRequiredArray = (fieldName) => {
  return composeValidators(isFieldRequired, isArray)(fieldName);
};

export const isNumericStringBetween = (fieldName, lowerBound, upperBound) =>
  composeValidators(
    isFieldRequired,
    isString,
    isStringOfDigits,
    isBetween(lowerBound, upperBound)
  )(fieldName);

export const isNumberBetween = (fieldName, lowerBound, upperBound) =>
  composeValidators(isNumber, isBetween(lowerBound, upperBound))(fieldName);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : { default: obj };
}
const _createSecondValidator = _interopRequireDefault(createValidator);
const _valueSecondMissing = _interopRequireDefault(valueMissing);
export const isFieldRequired = (0, _createSecondValidator.default)(
  function (message) {
    return function (value) {
      if ((0, _valueSecondMissing.default)(value)) {
        return message;
      }
    };
  },
  function (field) {
    return i18nextTranslateDynamically(i18nextKeys.validatorMessage, {
      field: field,
    });
  }
);

const _findSecondIndex = _interopRequireDefault(findIndex);
const _createSecondValidatorFactory = _interopRequireDefault(
  createValidatorFactory
);
const defaultComparer = function (value, optionValue) {
  return value === optionValue;
};
export const isFieldOneOf = (0, _createSecondValidatorFactory.default)(
  function (message, values) {
    const comparer =
      arguments.length > 2 && arguments[2] !== undefined
        ? arguments[2]
        : defaultComparer;
    return function (value) {
      const valuesClone = values.slice(0);
      if (value === undefined) {
        return;
      }
      const valueIndex = (0, _findSecondIndex.default)(valuesClone, function (
        optionValue
      ) {
        return comparer(value, optionValue);
      });
      if (valueIndex === -1) {
        return message;
      }
    };
  },
  function (field, values) {
    return i18nextTranslateDynamically(i18nextKeys.validatorOneOfMessage, {
      field: field,
      values: JSON.stringify(values.slice(0)),
    });
  }
);

export const isRequiredWithMessage = (fieldValue) => {
  return isRequired({
    message: i18nextTranslateDynamically(i18nextKeys.validatorMessage, {
      field: fieldValue,
    }),
  });
};

export const isRequiredIfWithMessage = (condition, field, message) => {
  return isRequiredIf(condition)({
    message: message || i18nextTranslateDynamically(
      i18nextKeys.validatorMessage,
      { field }
    )
  });
};

export const isMaxLengthExceeded = (condition = 0) => {
  return hasLengthLessThan(condition + 1)({
    message: i18nextTranslate(i18nextKeys.validatorMaxLengthExceeded),
  });
};
