import i18nextTranslate from "../Lang/i18nextTranslate";
import {i18nextKeys} from "../Lang/i18nextKeys";

export const ORDER_STATUS = {
  Draft: 'Draft',
  Aborted: 'Aborted',
  PaymentError: 'PaymentError',
  PaymentTimeout: 'PaymentTimeout',
  Received: 'Received',
  WaitingForPayment: 'WaitingForPayment',
  SendingError: 'SendingError',
  Sent: 'Sent',
  Error: 'Error',
};

const enumToFriendlyString = (e) => {
  const {
    PaymentError,
    PaymentTimeout,
    SendingError,
    WaitingForPayment,
    Draft,
    Aborted,
    Received,
    Sent,
    Error
  } = ORDER_STATUS;
  switch (e) {
    case PaymentError: {
      return i18nextTranslate(i18nextKeys.enumOrderStatusPaymentError);
    }
    case PaymentTimeout: {
      return i18nextTranslate(i18nextKeys.enumOrderStatusPaymentTimeout);
    }
    case SendingError: {
      return i18nextTranslate(i18nextKeys.enumOrderStatusSendingError);
    }
    case WaitingForPayment: {
      return i18nextTranslate(i18nextKeys.enumOrderStatusWaitingForPayment);
    }
    case Draft: {
      return i18nextTranslate(i18nextKeys.enumOrderStatusDraft);
    }
    case Aborted: {
      return i18nextTranslate(i18nextKeys.enumOrderStatusAborted);
    }
    case Received: {
      return i18nextTranslate(i18nextKeys.enumOrderStatusReceived);
    }
    case Sent: {
      return i18nextTranslate(i18nextKeys.enumOrderStatusSent);
    }
    case Error: {
      return i18nextTranslate(i18nextKeys.enumOrderStatusError);
    }
    default: {
      return i18nextTranslate(i18nextKeys.enumInvalidEnum);
    }
  }
};

const friendlyStringToEnum = (str) => {
  const {
    PaymentError,
    PaymentTimeout,
    SendingError,
    WaitingForPayment,
    Draft,
    Aborted,
    Received,
    Sent,
    Error
  } = ORDER_STATUS;
  switch (str) {
    case i18nextTranslate(i18nextKeys.enumOrderStatusPaymentError): {
      return PaymentError;
    }
    case i18nextTranslate(i18nextKeys.enumOrderStatusPaymentTimeout): {
      return PaymentTimeout;
    }
    case i18nextTranslate(i18nextKeys.enumOrderStatusSendingError): {
      return SendingError;
    }
    case i18nextTranslate(i18nextKeys.enumOrderStatusWaitingForPayment): {
      return WaitingForPayment;
    }
    case i18nextTranslate(i18nextKeys.enumOrderStatusDraft): {
      return Draft;
    }
    case i18nextTranslate(i18nextKeys.enumOrderStatusAborted): {
      return Aborted;
    }
    case i18nextTranslate(i18nextKeys.enumOrderStatusReceived): {
      return Received;
    }
    case i18nextTranslate(i18nextKeys.enumOrderStatusSent): {
      return Sent;
    }
    case i18nextTranslate(i18nextKeys.enumOrderStatusError): {
      return Error;
    }
    default: {
      return ORDER_STATUS[str] || i18nextTranslate(i18nextKeys.enumInvalidEnum);
    }
  }
};

const OrderStatus = {
  values: ORDER_STATUS,
  enumToFriendlyString,
  friendlyStringToEnum,
};

export default OrderStatus;
