import React, { useContext } from 'react';
import { UiContext } from 'States/ui/uiState';
import { DIRECTION } from 'Helpers/icons';
import { SortIcon, SingleChevron } from '../symbols';
import Text from '../Text';
import Filter from './Filter';

const HeaderCell = ({
  column,
  dataQa,
}) => {
  const {
    breakpoints: { xxl }
  } = useContext(UiContext);

  return (
    <th
      {...column.getHeaderProps({ className: column.className })}
      data-qa={dataQa}
    >
      <div
        {...column.getSortByToggleProps()}
        className={`flex items-center gap-6 ${
          column.disableSortBy ? "" : "cursor-pointer"
        } color-8_60 text-left`}
        style={{ width: column.width }}
      >
        <Text
          textStyle="h3"
          color="text-current"
        >
          {column.render('Header')}
        </Text>
        {!column.disableSortBy && (
          column.isSorted ? (
            <SingleChevron
              direction={
                column.isSortedDesc
                  ? DIRECTION.up
                  : DIRECTION.down
              }
              size={xxl ? 16 : 14}
            />
          ) : (
            <SortIcon size={xxl ? 16 : 14} />
          )
        )}
        {column.canFilter && (
          <Filter column={column} />
        )}
      </div>
    </th>
  );
};

export default HeaderCell;
