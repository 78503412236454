const FAVICON_FILE_NAME = 'favicon.png';

const loadFavicon = (blobUrl) => {
  const link = document.createElement('link');
  link.type = 'image/png';
  link.rel = 'shortcut icon';
  link.href = `${blobUrl}/${FAVICON_FILE_NAME}`;
  document.getElementsByTagName('head')[0].appendChild(link);
};

export default loadFavicon;
