import React from 'react';

const TrashCanSymbol = ({
  size = "14",
  className,
  ...props
}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 14 14"
    fill="currentColor"
    className={className}
    {...props}
  >
    <path d="M11.4488 3.81934C11.2799 3.81934 11.1181 3.88639 10.9987 4.00576C10.8793 4.12512 10.8123 4.28702 10.8123 4.45583V11.5788C10.794 11.9006 10.6493 12.2023 10.4097 12.4179C10.1701 12.6336 9.85493 12.7458 9.53293 12.7302H4.45376C4.13176 12.7458 3.81662 12.6336 3.577 12.4179C3.33738 12.2023 3.19268 11.9006 3.17442 11.5788V4.45583C3.17442 4.28702 3.10736 4.12512 2.988 4.00576C2.86863 3.88639 2.70674 3.81934 2.53793 3.81934C2.36912 3.81934 2.20723 3.88639 2.08787 4.00576C1.9685 4.12512 1.90144 4.28702 1.90144 4.45583V11.5788C1.91961 12.2383 2.19842 12.8638 2.6768 13.3182C3.15518 13.7726 3.79415 14.0189 4.45376 14.0032H9.53293C10.1925 14.0189 10.8315 13.7726 11.3099 13.3182C11.7883 12.8638 12.0671 12.2383 12.0852 11.5788V4.45583C12.0852 4.28702 12.0182 4.12512 11.8988 4.00576C11.7795 3.88639 11.6176 3.81934 11.4488 3.81934Z" />
    <path d="M12.0907 1.90946H9.54478V0.636488C9.54478 0.467681 9.47772 0.305788 9.35835 0.186423C9.23899 0.0670583 9.07709 0 8.90829 0H5.08936C4.92055 0 4.75866 0.0670583 4.63929 0.186423C4.51993 0.305788 4.45287 0.467681 4.45287 0.636488V1.90946H1.90692C1.73811 1.90946 1.57622 1.97652 1.45685 2.09589C1.33749 2.21525 1.27043 2.37715 1.27043 2.54595C1.27043 2.71476 1.33749 2.87665 1.45685 2.99602C1.57622 3.11538 1.73811 3.18244 1.90692 3.18244H12.0907C12.2595 3.18244 12.4214 3.11538 12.5408 2.99602C12.6602 2.87665 12.7272 2.71476 12.7272 2.54595C12.7272 2.37715 12.6602 2.21525 12.5408 2.09589C12.4214 1.97652 12.2595 1.90946 12.0907 1.90946ZM5.72585 1.90946V1.27298H8.2718V1.90946H5.72585Z" />
    <path d="M6.36314 10.1817V5.72633C6.36314 5.55752 6.29608 5.39563 6.17671 5.27627C6.05735 5.1569 5.89545 5.08984 5.72664 5.08984C5.55783 5.08984 5.39594 5.1569 5.27657 5.27627C5.15721 5.39563 5.09015 5.55752 5.09015 5.72633V10.1817C5.09015 10.3506 5.15721 10.5125 5.27657 10.6318C5.39594 10.7512 5.55783 10.8182 5.72664 10.8182C5.89545 10.8182 6.05735 10.7512 6.17671 10.6318C6.29608 10.5125 6.36314 10.3506 6.36314 10.1817Z" />
    <path d="M8.904 10.1817V5.72633C8.904 5.55752 8.83694 5.39563 8.71757 5.27627C8.59821 5.1569 8.43631 5.08984 8.26751 5.08984C8.0987 5.08984 7.9368 5.1569 7.81744 5.27627C7.69807 5.39563 7.63101 5.55752 7.63101 5.72633V10.1817C7.63101 10.3506 7.69807 10.5125 7.81744 10.6318C7.9368 10.7512 8.0987 10.8182 8.26751 10.8182C8.43631 10.8182 8.59821 10.7512 8.71757 10.6318C8.83694 10.5125 8.904 10.3506 8.904 10.1817Z" />
  </svg>
);

export default TrashCanSymbol;
