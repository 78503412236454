import React from 'react';
import ErrorMessage from './ErrorMessage';
import Text from '../Text';

const DateField = ({
  data: { id, key, label, required, valid, error, value },
  onChange,
  onFocus,
  disabled,
}) => {
  return (
    <div className="w-1/2 px-12 mb-12 md:mb-4">
      {label ? (
        <Text
          textStyle="h3"
          className="truncate mb-4"
        >
          <label htmlFor={id}>
            {label}{required ? " *" : null}
          </label>
        </Text>
      ) : null}
      <input
        name={key}
        type="date"
        value={value}
        className={`appearance-none block w-full h-40 px-16
                  bg-9 text-gray-700
                  focus:outline-none focus:bg-9-10 color-10
                  border rounded ${error && error.show ? 'border-red-500' : ''}
                  ${valid ? 'border-green-500' : ''}`}
        id={id}
        onChange={onChange}
        onFocus={onFocus}
        disabled={disabled}
      />
      <ErrorMessage error={error} />
    </div>
  );
};

export default DateField;
