import React from 'react';
import { HorizontalRule } from 'Components/shared/Tile';
import BankDetails from "Components/BankAccount/Summary/BankDetails";
import { LabeledValue } from "Components/shared/Text";
import { i18nextKeys } from 'Lang/i18nextKeys';
import i18nextTranslate from 'Lang/i18nextTranslate';

const BankAccountSummary = ({
  bankAccount,
  className = "",
  dataQa = "bank"
}) => {
  return (
    <div className={`flex flex-col gap-24 xl:gap-32 ${className}`}>
      {!!bankAccount && (
        <BankDetails
          bankAccount={bankAccount}
          dataQa={dataQa}
        />
      )}
      {!!bankAccount?.AdditionalDetails && (
        <>
          <HorizontalRule />
          <LabeledValue
            label={i18nextTranslate(i18nextKeys.commonBankAdditionalDetails)}
            value={bankAccount.AdditionalDetails}
            dataQa={`${dataQa}-additionalDetails`}
            column
          />
        </>
      )}
      {bankAccount.IntermediaryBankAccount && (
        <>
          <HorizontalRule />
          <BankDetails
            bankAccount={bankAccount.IntermediaryBankAccount}
            dataQa={`${dataQa}-intermediary`}
            intermediary
          />
        </>
      )}
    </div>
  );
};

export default BankAccountSummary;
