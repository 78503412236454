const getDiscoveryServiceEndpoint = (discoveryApiHost) => {
  let fqdn, host;
  if (process.env.NODE_ENV === 'development') {
    fqdn = process.env.REACT_APP_FQDN;
    host = '/disco';
  } else {
    fqdn = window.location.hostname;
    host = discoveryApiHost;
  }

  return `${host}/${fqdn}`;
};

export default getDiscoveryServiceEndpoint;
