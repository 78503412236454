import React, { useContext } from 'react';
import i18nextTranslate from 'Lang/i18nextTranslate';
import { i18nextKeys } from 'Lang/i18nextKeys';
import { UiContext } from 'States/ui/uiState';
import { CopyIcon } from 'Components/shared/symbols';
import { copyToClipboardAndNotify } from 'Utils/utils';
import { useFormatNumber } from 'Utils/formatNumber';

const AmountAndReference = ({
  amount,
  currency,
  reference,
  className = "grid-cols-2 md:grid-cols-3"
}) => {
  const formatNumber = useFormatNumber();
  const {
    breakpoints: { xxl }
  } = useContext(UiContext);

  return (
    <div
      data-qa="checkout-amount-and-reference"
      className={`grid gap-y-8 gap-x-12 sm:gap-x-24 ${className
        } w-full rounded bg-4--20 py-8 xxl:py-12 px-12 xxl:px-16 text-left break-word color-white text-sm xxl:text-lg`}
    >
      <p className="font-bold">
        {i18nextTranslate(
          i18nextKeys.checkoutPaymentMethodsBankDetailsAmount
        )}:
      </p>
      <div className="flex justify-between">
        <p
          onClick={() =>
            copyToClipboardAndNotify(
              amount,
              i18nextTranslate(
                i18nextKeys.checkoutPaymentMethodsBankDetailsAmount
              )
            )
          }
        >
          {formatNumber(amount)} {currency}
        </p>
        <div
          className="flex items-center justify-end ml-32"
          onClick={() =>
            copyToClipboardAndNotify(
              amount,
              i18nextTranslate(
                i18nextKeys.checkoutPaymentMethodsBankDetailsAmount
              )
            )
          }
        >
          <CopyIcon size={xxl ? 16 : 14} />
        </div>
      </div>
      <p className="font-bold row-start-2">
        {i18nextTranslate(
          i18nextKeys.checkoutPaymentMethodsBankDetailsPaymentReference
        )}:
      </p>
      <div className="flex row-start-2 justify-between">
        <p
          className="flex items-center break-all"
          onClick={() =>
            copyToClipboardAndNotify(
              reference,
              i18nextTranslate(
                i18nextKeys.checkoutPaymentMethodsBankDetailsPaymentReference
              )
            )
          }
        >
          {reference}
        </p>
        <div
          className="flex items-center justify-end ml-32"
          onClick={() =>
            copyToClipboardAndNotify(
              reference,
              i18nextTranslate(
                i18nextKeys.checkoutPaymentMethodsBankDetailsPaymentReference
              )
            )
          }
        >
          <CopyIcon size={xxl ? 16 : 14} />
        </div>
      </div>
    </div>
  );
};

export default AmountAndReference;
