const changedFields = (getFieldInfo, fieldNames) => {
  return fieldNames.filter((fieldName) => getFieldInfo(fieldName).dirty);
};

export const dataFieldsChanged = (getFieldInfo, dataFields) => {
  return !!changedFields(getFieldInfo, dataFields).length;
};

export const userDataChanged = (getFieldInfo) => {
  const userDataFields = [
    'FirstName',
    'LastName',
    'BirthDate',
    'Nationality',
    'PhoneNumber',
  ];
  return dataFieldsChanged(getFieldInfo, userDataFields);
};

export const homeAddressChanged = (
  getFieldInfo,
  fieldGroup = "HomeAddress." // TODO: remove fieldGroup after user form is unified across the app
) => {
  const homeAddressFields = [
    `${fieldGroup}City`,
    `${fieldGroup}ZipCode`,
    `${fieldGroup}State`,
    `${fieldGroup}Country`,
    `${fieldGroup}AddressLine`,
  ];
  return dataFieldsChanged(getFieldInfo, homeAddressFields);
};

export const nameChanged = (getFieldInfo) => {
  const nameFields = ['FirstName', 'LastName'];
  return dataFieldsChanged(getFieldInfo, nameFields);
};
