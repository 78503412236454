import React, { useState, useEffect, useContext } from 'react';
import {
  Switch,
  Route,
  useLocation,
  useHistory,
  Redirect,
  matchPath,
} from 'react-router-dom';
import useConfigSettings from 'Hooks/useConfigSettings';
import Header from 'Components/shared/Header';
import Breadcrumbs from 'Components/shared/Header/Breadcrumbs';
import { updateHeaderNavigation } from 'Helpers/updateHeaderNavigation';
import CollectionMethodSelection from './CollectionMethodSelection';
import CheckoutCustomerDetails from './CheckoutCustomerDetails';
import CheckoutOrderSummary from './CheckoutOrderSummary';
import CheckoutPayment from './CheckoutPayment';
import CheckoutFinishPending from './CheckoutCompletionScreens/CheckoutFinishPending';
import CompletionBankTransfer from './CheckoutCompletionScreens/CompletionBankTransfer';
import Completion from './CheckoutCompletionScreens/Completion';
import CheckoutTimeExpired from './CheckoutCompletionScreens/CheckoutTimeExpired';
import i18nextTranslate from 'Lang/i18nextTranslate';
import { i18nextKeys } from 'Lang/i18nextKeys';
import useFeatureAvailability from 'Hooks/useFeatureAvailability';
import TENANT_FEATURE from 'Enums/TenantFeature';
import { isTenantFeatureAvailable } from 'Helpers/toggleFeatures';
import { EnvContext } from 'States/env/envState';
import { AuthContext } from 'States/auth/authState';
import { ROUTES } from 'Router/Routes';

const initialHeaderNavigation = [
  {
    text: i18nextTranslate(i18nextKeys.checkoutHeaderNavigationSelectWallet),
    active: true,
  },
  {
    text: i18nextTranslate(i18nextKeys.checkoutHeaderNavigationDetails),
    active: false,
  },
  {
    text: i18nextTranslate(i18nextKeys.checkoutHeaderNavigationYourOrder),
    active: false,
  },
  {
    text: i18nextTranslate(i18nextKeys.checkoutHeaderNavigationPayment),
    active: false,
  },
];

const Checkout = () => {
  const { isAdmin, isAuthenticated } = useContext(AuthContext);
  const { env } = useContext(EnvContext);
  const { data: features } = useFeatureAvailability.query({
    queryFnArgs: !isAuthenticated ? [env?.TenantId] : [],
    enabled: !!env,
    staleTime: 300000,
  });
  const {
    data: { RestrictedMode }
  } = useConfigSettings.query({
    refetchOnMount: false
  })
  const location = useLocation();
  const history = useHistory();
  const [headerNavigation, setHeaderNavigation] = useState(
    initialHeaderNavigation
  );
  const [backNavigated, setBackNavigated] = useState(false);

  useEffect(() => {
    setBackNavigated(false);
  }, []);

  useEffect(() => {
    let headerNum = 0;
    if (matchPath(location.pathname, { path: '/checkout', exact: true })) {
      headerNum = 0;
    } else if (matchPath(location.pathname, ROUTES.checkoutCollectionMethod)) {
      headerNum = 0;
    } else if (matchPath(location.pathname, ROUTES.checkoutCustomerDetails)) {
      headerNum = 1;
    } else if (matchPath(location.pathname, ROUTES.checkoutOrderSummary)) {
      headerNum = 2;
    } else if (matchPath(location.pathname, ROUTES.checkoutPaymentMethod)) {
      headerNum = 3;
    } else if (matchPath(location.pathname, ROUTES.checkoutPending)) {
      headerNum = 3;
    } else if (matchPath(location.pathname, ROUTES.checkoutFinish)) {
      headerNum = 3;
    } else if (matchPath(location.pathname, ROUTES.checkoutFinishBankTransfer)) {
      headerNum = 3;
    } else if (matchPath(location.pathname, ROUTES.checkoutFinishExpired)) {
      headerNum = 3;
    }

    setHeaderNavigation((currentHeaderNav) =>
      updateHeaderNavigation(currentHeaderNav, headerNum)
    );
  }, [location]);

  const onCheckoutFinish = () => {
    if (isTenantFeatureAvailable(features, TENANT_FEATURE.purchase)) {
      history.replace(ROUTES.shop);
    } else if (
      isTenantFeatureAvailable(features, TENANT_FEATURE.customFeaturedAsset)
    ) {
      history.replace(ROUTES.featuredAssetBase);
    } else {
      history.replace(ROUTES.base);
    }
  };

  return (
    <div>
      <Header
        text={i18nextTranslate(i18nextKeys.checkoutHeader)}
        children={<Breadcrumbs navigationSteps={headerNavigation} />}
      />
      <Switch>
        <Route exact path={ROUTES.checkoutCollectionMethod}>
          <CollectionMethodSelection />
        </Route>
        <Route path={ROUTES.checkoutCustomerDetails}>
          <CheckoutCustomerDetails />
        </Route>
        <Route exact path={ROUTES.checkoutOrderSummary}>
          <CheckoutOrderSummary />
        </Route>
        <Route exact path={ROUTES.checkoutPaymentMethod}>
          <CheckoutPayment />
        </Route>
        <Route
          exact
          path={`${ROUTES.checkoutPending}/:paymentMethod?`}
          render={({ match }) => (
            <CheckoutFinishPending
              backNavigated={backNavigated}
              setBackNavigated={setBackNavigated}
              paymentMethod={match.params.paymentMethod}
              onCheckoutFinish={onCheckoutFinish}
            />
          )}
        ></Route>
        <Route exact path={ROUTES.checkoutFinishBankTransfer}>
          <CompletionBankTransfer restrictedMode={RestrictedMode && !isAdmin} />
        </Route>
        <Route exact path={ROUTES.checkoutFinish}>
          <Completion restrictedMode={RestrictedMode && !isAdmin} />
        </Route>
        <Route exact path={ROUTES.checkoutFinishExpired}>
          <CheckoutTimeExpired onCheckoutFinish={onCheckoutFinish} />
        </Route>
        <Route>
          <Redirect to={ROUTES.checkoutCollectionMethod} />
        </Route>
      </Switch>
    </div>
  );
};

export default Checkout;
