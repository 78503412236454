import React from 'react';

function DoubleChevron({
  size = "20",
  left = false,
  ...props
}) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      className="fill-current"
      transform={`rotate(${left ? '180' : '360'})`}
      {...props}
    >
      <path d="M16.7858 10C16.7858 10.224 16.6957 10.448 16.516 10.6188L10.8587 15.9936C10.4988 16.3355 9.9153 16.3355 9.55557 15.9936C9.19583 15.6518 9.19583 15.0976 9.55557 14.7557L14.5615 10L9.55574 5.24436C9.19601 4.90246 9.19601 4.34829 9.55574 4.00655C9.91547 3.66448 10.499 3.66448 10.8588 4.00655L16.5162 9.3812C16.6959 9.55208 16.7858 9.77608 16.7858 10Z" />
      <path d="M10.3571 10C10.3571 10.224 10.267 10.448 10.0873 10.6188L4.42995 15.9936C4.07007 16.3355 3.48659 16.3355 3.12686 15.9936C2.76712 15.6518 2.76712 15.0976 3.12686 14.7557L8.13282 10L3.12703 5.24436C2.7673 4.90246 2.7673 4.34829 3.12703 4.00655C3.48676 3.66448 4.07025 3.66448 4.43013 4.00655L10.0875 9.3812C10.2672 9.55208 10.3571 9.77608 10.3571 10Z" />
    </svg>
  );
}

export default DoubleChevron;
