import React, { useCallback, useContext } from "react";
import api from "Api";
import i18nextTranslate from "Lang/i18nextTranslate";
import i18nextTranslateDynamically from "Lang/i18nextTranslateDynamically";
import { i18nextKeys } from "Lang/i18nextKeys";
import linkify from "Helpers/linkify";
import { UiContext } from "States/ui/uiState";
import { MemoizedValidationField, FormField } from "Components/shared/formElements";
import Text from "Components/shared/Text";
import { SubsectionHeading } from "../../shared";
import payrexx_guide from "Assets/images/payrexx_guide.svg"

const BankCard = ({
  providerConfig,
  fieldWidth
}) => {
const {
    breakpoints: { xxl },
  } = useContext(UiContext);

  const validate = useCallback(
    (value, { payrexx }) => {
      if (!value) {
        return i18nextTranslateDynamically(
          i18nextKeys.validatorMessage,
          { field: i18nextTranslate(
            value === payrexx?.ApiUrl
              ? i18nextKeys.configPaymentCreditCardAccountName
              : i18nextKeys.configPaymentApiSecret
          ) }
        );
      }
    }, []
  );

  const validateAsync = useCallback(
    async (value, { payrexx }) => {
      try {
        await api.Config.PaymentProviderConfigurations.validate({
          ...providerConfig,
          ApiUrl: payrexx.ApiUrl,
          ApiSecret: payrexx.ApiSecret
        });
        return undefined;
      } catch {
        return i18nextTranslate(
          i18nextKeys.configPaymentCreditCardAccountInvalid
        );
      }
    }, []
  );

  const nameToUrl = (name) => {
    return name ? `https://${name}.payrexx.com` : "";
  };

  const urlToName = (url) => {
    if (!url) {
      return url;
    }
    return url.match(/^https:\/\/(\w+)\.payrexx\.com/)?.[1];
  };

  return providerConfig ? (
    <div className="flex gap-88 xxl:gap-96">
      <div
        className="flex flex-col"
        style={{ width: fieldWidth }}
      >
        <SubsectionHeading
          text={i18nextTranslate(
            i18nextKeys.configPaymentCreditCardAccountTitle
          )}
          dataQa="payrexx"
        />
        <MemoizedValidationField
          debounceTime={3000}
          name="payrexx.ApiUrl"
          label={i18nextTranslate(
            i18nextKeys.configPaymentCreditCardAccountName
          )}
          initialValue={providerConfig.ApiUrl}
          format={urlToName}
          parse={nameToUrl}
          subscription={{
            active: true,
            error: true,
            touched: true,
            value: true
          }}
          validate={validate}
          validateAsync={validateAsync}
          validateFields={[]}
          className="mb-8"
          dataQa="payrexx-name"
          required
        >
          {(props) => <FormField {...props} />}
        </MemoizedValidationField>
        <MemoizedValidationField
          debounceTime={3000}
          name="payrexx.ApiSecret"
          label={i18nextTranslate(
            i18nextKeys.configPaymentApiSecret
          )}
          initialValue={providerConfig.ApiSecret}
          subscription={{
            active: true,
            error: true,
            touched: true,
            value: true
          }}
          validate={validate}
          validateAsync={validateAsync}
          validateFields={[]}
          dataQa="payrexx-key"
          type="password"
          required
        >
          {(props) => <FormField {...props} />}
        </MemoizedValidationField>
      </div>
      <div
        className="flex flex-col"
        style={{ width: fieldWidth }}
      >
        <Text
          textStyle="p4"
          className="mb-16 xxl:mb-20"
          dataQa="payrexx-name-description"
          dangerouslySetContent
        >
          {linkify(i18nextTranslate(
            i18nextKeys.configPaymentCreditCardAccountNameDescription
          ))}
        </Text>
        <img
          data-qa="payrexx-name-image"
          style={{ height: xxl ? '38px' : '35px' }}
          src={payrexx_guide}
          alt="Payrexx account name"
        />
        <Text
          textStyle="p4"
          className="mt-24"
          dataQa="payrexx-key-description"
          dangerouslySetContent
        >
          {linkify(i18nextTranslate(
            i18nextKeys.configPaymentCreditCardAccountKeyDescription
          ))}
        </Text>
      </div>
    </div>
  ) : null;
};

export default BankCard;
