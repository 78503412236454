import React, { useState } from 'react';
import {
  Form as FinalForm,
  Field as FinalField,
  FormSpy as FinalFormSpy,
} from 'react-final-form';
import api from 'Api';
import useHandleError from 'Utils/handleError';
import { BTC_OPTION, ETH_OPTION } from 'Enums/WalletType';
import Modal from 'Components/shared/Modal';
import LoadingSpinner from 'Components/shared/LoadingSpinner';
import Text from 'Components/shared/Text';
import { Button } from 'Components/shared/buttons';
import { FormField, DropdownField } from 'Components/shared/formElements';
import { i18nextKeys } from 'Lang/i18nextKeys';
import i18nextTranslate from 'Lang/i18nextTranslate';
import walletValidator from './walletValidator';

const WALLET_TYPE_OPTIONS = [
  { Type: BTC_OPTION },
  { Type: ETH_OPTION }
];

const WalletFormModal = ({
  show,
  close,
  onWalletFormSubmit,
  wallet = {},
  wallets = [],
  disableWalletType = false
}) => {
  const [loading, setLoading] = useState(false);
  const handleError = useHandleError();

  const submitForm = async (formValues) => {
    try {
      setLoading(true);
      if (wallet.Id) {
        await api.Wallets.put(formValues);
      } else {
        const alreadyExisting = wallets.find(({ Address }) => {
          return Address.toLowerCase() === wallet.Address.toLowerCase();
        });

        if (alreadyExisting) {
          handleError({
            error: {},
            message: i18nextTranslate(
              i18nextKeys.errorWalletCardAddWalletAlreadyConnected
            ),
            finalNote: false
          });
          return;
        }
        await api.Wallets.create(formValues);
      }
      onWalletFormSubmit();
    } catch (error) {
      const message = i18nextTranslate(
        i18nextKeys.errorWalletCardAddWalletCouldNotSave
      );
      handleError({ error, message });
    } finally {
      setLoading(false);
    }
  };

  const buttonWidth = {
    xxl: '190px',
    md: '170px',
    default: '142px'
  };

  return (
    <Modal
      dataQa="wallet-modal"
      show={show}
      close={close}
    >
      {loading ? (
        <div className="flex justify-center bg-3 p-24">
          <LoadingSpinner />
        </div>
      ) : (
        <div className="flex flex-col gap-20 md:gap-24 color-white p-24 bg-3 rounded">
          <Text
            textStyle="h2"
            uppercase
          >
            {i18nextTranslate(
              wallet?.Id
                ? i18nextKeys.cardsWalletFormHeaderEdit
                : i18nextKeys.cardsWalletFormHeaderAdd
            )}
          </Text>
          <FinalForm
            initialValues={wallet}
            validate={walletValidator}
            onSubmit={submitForm}
            render={({ handleSubmit }) => {
              return (
                <div className="flex flex-col gap-24 md:gap-32">
                  <div className="w-full flex flex-col md:flex-row gap-20 md:gap-24">
                    <div className="flex gap-16 md:gap-24">
                      <FinalField
                        className="w-1/2"
                        name="Name"
                        label={i18nextTranslate(i18nextKeys.commonWalletName)}
                        render={(props) => (
                          <FormField
                            dataQa="wallet-name"
                            {...props}
                          />
                        )}
                        required
                      />
                      <FinalField
                        className="w-1/2"
                        name="Type"
                        label={i18nextTranslate(i18nextKeys.commonCurrency)}
                        initialValue={wallet.Type || BTC_OPTION}
                        render={({ input: { value, onChange }, ...props }) => (
                          <DropdownField
                            dataQa="wallet-type"
                            options={WALLET_TYPE_OPTIONS}
                            disabled={disableWalletType}
                            valueKey="Type"
                            labelKey="Type"
                            input={{
                              value: { Type: value },
                              onChange
                            }}
                            {...props}
                          />
                        )}
                        required
                      />
                    </div>
                    <FinalField
                      className="flex-grow"
                      name="Address"
                      label={i18nextTranslate(i18nextKeys.commonWalletAddress)}
                      render={(props) => (
                        <FormField
                          dataQa="wallet-address"
                          {...props}
                        />
                      )}
                      required
                    />
                  </div>
                  <div className="flex justify-between gap-16">
                    <FinalFormSpy subscription={{ pristine: true }}>
                      {(props) => (
                        <div className="flex justify-between flex-grow">
                          <Button
                            dataQa="wallet-cancelButton"
                            text={i18nextTranslate(i18nextKeys.buttonCancel)}
                            onClick={close}
                            width={buttonWidth}
                            secondary
                          />

                          <Button
                            dataQa="wallet-saveButton"
                            disabled={props.pristine}
                            text={i18nextTranslate(
                              wallet?.Id
                                ? i18nextTranslate(i18nextKeys.buttonSaveChanges)
                                : i18nextTranslate(i18nextKeys.buttonAdd)
                            )}
                            onClick={handleSubmit}
                            width={buttonWidth}
                          />
                        </div>
                      )}
                    </FinalFormSpy>
                  </div>
                </div>
              );
            }}
          />
        </div>
      )}
    </Modal>
  );
};

export default WalletFormModal;
