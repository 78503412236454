import React, { useCallback, useState, useContext, useEffect } from 'react';
import { useAmplitude } from 'react-amplitude-hooks';
import { Trans } from 'react-i18next';
import api from 'Api';
import { EnvContext } from 'States/env/envState';
import { UiContext } from "States/ui/uiState";
import useHandleError from 'Utils/handleError';
import TransactionStatus from 'Enums/TransactionStatus';
import OrderStatus from 'Enums/OrderStatus';
import { useFormatNumber } from 'Utils/formatNumber';
import { Chip } from 'Components/shared/Chip';
import { Button } from 'Components/shared/buttons';
import ConfirmationModal from 'Components/shared/ConfirmationModal';
import LoadingSpinner from 'Components/shared/LoadingSpinner';
import {
  Tile,
  TileInfoCard,
  HorizontalRule
} from "Components/shared/Tile";
import FeeTooltip from "Features/Cart/FeeTooltip";
import i18nextTranslate from 'Lang/i18nextTranslate';
import { i18nextKeys } from 'Lang/i18nextKeys';
import i18nextTranslateDynamically from 'Lang/i18nextTranslateDynamically';
import useConfigSettings from 'Hooks/useConfigSettings';
import { getTranslation } from 'Helpers/translations';
import { LangContext } from 'States/lang/langState';
import AssetInformation from './AssetInformation';

const PurchaseSummary = ({ order, setOrder, isAdmin }) => {
  const { lang } = useContext(LangContext);
  const formatNumber = useFormatNumber();
  const { env } = useContext(EnvContext);
  const [assets, setAssets] = useState([]);
  const [assetToSend, setAssetToSend] = useState(null);
  const [showSendAssetModal, setShowSendAssetModal] = useState(false);
  const [sendingAsset, setSendingAsset] = useState(false);
  const handleError = useHandleError();
  const {
    breakpoints: { md }
  } = useContext(UiContext);
  const { logEvent } = useAmplitude();

  const feesData = {
    CollectionMethod: {
      type: "CollectionMethod",
      label: i18nextTranslate(i18nextKeys.commonFeeMethod),
      description: i18nextTranslate(i18nextKeys.checkoutFeeMethodDescription),
      dataQa: "collection"
    },
    Country: {
      type: "Country",
      label: i18nextTranslate(i18nextKeys.commonFeeCountry),
      description: i18nextTranslate(i18nextKeys.checkoutFeeCountryDescription),
      dataQa: "country"
    }
  };

  const {
    data: defaultLanguage
  } = useConfigSettings.query({
    select: useCallback(({ Languages }) => Languages.find(({ IsDefault }) => IsDefault), [])
  });

  useEffect(() => {
    const fetchAssetTranslations = async () => {
      const assetIds = order.PurchasedAssets.map(asset => `'${asset.UniqueAssetId}'`);
      const { value: assets } = await api.Assets.getAssets(env.TenantId, assetIds);
      setAssets(assets);
    };
    fetchAssetTranslations();
  }, []);

  const openSendAssetModal = (asset) => {
    if (!isAdmin) return;
    setAssetToSend(asset);
    setShowSendAssetModal(true);
  };

  const sendAssetSparks = async () => {
    if (!isAdmin) return;
    try {
      setSendingAsset(true);
      const payload = {
        UniqueAssetId: assetToSend.UniqueAssetId,
        OrderId: order.Id
      };
      const { value: transactionId } = await api.Sparks.send(payload);
      logEvent("Transfer is initiated");
      const updatedAssets = order.PurchasedAssets.map((purchasedAsset) => {
        if (purchasedAsset.Id === assetToSend.Id) {
          purchasedAsset.TransactionId = transactionId;
          purchasedAsset.TransactionState = TransactionStatus.values.Received;
        }
        return purchasedAsset;
      });
      setOrder({ ...order, PurchasedAssets: updatedAssets });
    } catch (error) {
      setSendingAsset(false);
      const message = i18nextTranslate(
        i18nextKeys.errorPurchaseManagementDetailsSendingFailure
      );
      handleError({ error, message });
    } finally {
      setShowSendAssetModal(false);
      setSendingAsset(false);
    }
  };

  const sendEnabled =
    (order.Status === OrderStatus.values.Received ||
      order.Status === OrderStatus.values.SendingError) &&
    order.TargetEthAddress;

  return (
    <>
      <Tile
        title={i18nextTranslate(i18nextKeys.orderPurchaseSummary)}
        bottomText={i18nextTranslateDynamically(
          i18nextKeys.purchaseManagementDetailsSummaryTotal,
          {
            amount: formatNumber(order.AmountDue),
            currency: order.AmountDueCurrency,
          }
        )}
        className="flex flex-col"
        dataQa="order-summary"
        expandable
      >
        {assets.length ? order.PurchasedAssets.map((asset, index) => {
          return (
            <div key={asset.UniqueAssetId}>
              {index > 0 && <HorizontalRule className="my-24" />}
              <div
                key={asset.Id}
                className="flex flex-col gap-24"
              >
                <div
                  className="grid col-gap-24 row-gap-20"
                  style={{ gridTemplateColumns: md ? "repeat(2, 1fr)" : "1fr" }}
                >
                  <AssetInformation
                    asset={asset}
                    index={index}
                    translations={assets[index].Translations}
                    defaultLanguage={defaultLanguage}
                  />
                  {isAdmin
                    && asset.TransactionState !== TransactionStatus.values.NotInitiated
                    && (
                      <TileInfoCard
                        dataQa={`order-summary-asset-${index}-transactionStatus`}
                        title={i18nextTranslate(i18nextKeys.orderTransactionStatus)}
                        text={
                          <Chip
                            text={TransactionStatus.enumToFriendlyString(asset.TransactionState)}
                          />
                        }
                        link={`${env.Endpoints.WlmFrontEnd}/Dashboard/${asset.TransactionId}`}
                      />
                    )}
                </div>
                {isAdmin && (
                  <Button
                    data-qa={`order-summary-${index}-sendButton`}
                    disabled={!sendEnabled}
                    text={
                      asset.TransactionState === TransactionStatus.values.NotInitiated
                        ? i18nextTranslate(i18nextKeys.buttonSend)
                        : i18nextTranslate(i18nextKeys.buttonResend)
                    }
                    onClick={() => openSendAssetModal({
                      ...asset,
                      Translations: assets[index].Translations
                    })}
                    width={{
                      xxl: "160px",
                      default: "140px"
                    }}
                  />
                )}
              </div>
            </div>
          );
        }) : (
          <LoadingSpinner classes='m-auto' />
        )}
        {/* TODO: https://coreledgerag.visualstudio.com/TEOS/_workitems/edit/18963 */}
        <div
          className="grid col-gap-24 row-gap-20 mt-32"
          style={{ gridTemplateColumns: md ? "repeat(2, 1fr)" : "1fr" }}
        >
          {order.Fees.map((fee) => {
            const dataQa = `fee-${feesData[fee.Type]?.dataQa}`;
            return (
              <TileInfoCard
                key={dataQa}
                title={feesData[fee.Type]?.label}
                text={`${formatNumber(fee.TotalAmount)} ${order.AmountDueCurrency}`}
                infoComponent={
                  <FeeTooltip
                    description={feesData[fee.Type]?.description}
                    percentageText={i18nextTranslateDynamically(
                      i18nextKeys.redemptionFeePercentage,
                      { percentage: formatNumber(fee.VariableRate) }
                    )}
                    units={order.AmountDueCurrency}
                    fee={fee}
                    dataQa={dataQa}
                  />
                }
                dataQa={dataQa}
              />
            );
          })}
        </div>
      </Tile>
      <ConfirmationModal
        show={showSendAssetModal}
        close={() => setShowSendAssetModal(false)}
        loading={sendingAsset}
        onConfirm={sendAssetSparks}
        confirmButtonText={i18nextTranslate(i18nextKeys.commonProceed)}
        title={i18nextTranslate(i18nextKeys.purchaseManagementDetailsSendConfirmationTitle)}
        description={
          <Trans
            i18nKey={
              i18nextKeys.purchaseManagementDetailsSendConfirmationMessage
            }
          >
            <span className="color-4">
              {{
                numberOfUnits: formatNumber(assetToSend?.Units),
                assetName: getTranslation("Name", lang, defaultLanguage.Code, assetToSend?.Translations) || assetToSend?.Name,
                uom: assetToSend?.UnitOfMeasure
              }}
            </span>
            {{
              issuerAddress: assetToSend?.IssuerAddress,
              receiverFirstName: order.User.FirstName,
              receiverLastName: order.User.LastName,
              receiverTargetEthAddress: order.TargetEthAddress
            }}
          </Trans>
        }
        dataQa="admin-orderDetails-sendModal"
      />
    </>
  );
};

export default PurchaseSummary;
