import React, { useMemo } from 'react';
import i18nextTranslateDynamically from 'Lang/i18nextTranslateDynamically';
import { i18nextKeys } from 'Lang/i18nextKeys';
import Dropdown from 'Components/Dropdown/Dropdown';
import { FilterIcon } from 'Components/shared/symbols';

export const DefaultColumnFilter = ({
  column: {
    filterValue,
    setFilter,
    preFilteredRows: { length },
  },
}) => {
  return (
    <input
      className="bg-2 color-10"
      value={filterValue || ''}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
      placeholder={i18nextTranslateDynamically(
        i18nextKeys.tableFilterPlaceholder,
        { length: length }
      )}
    />
  );
};

export const SelectColumnFilter = ({
  column: {
    setFilter,
    preFilteredRows,
    id,
    options: predefinedOptions,
    dataQa = "column",
  },
}) => {
  const options =
    useMemo(() => {
      if (predefinedOptions) {
        return predefinedOptions;
      }
      const options = new Set();
      preFilteredRows.forEach((row) => {
        options.add(row.values[id]);
      });
      return [...options.values()];
    }, [id, preFilteredRows, predefinedOptions]);

  return (
    <Dropdown
      options={options}
      optionSelected={(option) => {
        setFilter(option);
      }}
      icon={FilterIcon}
      dataQa={dataQa}
    />
  );
};

const Filter = ({ column }) =>
  column.canFilter && column.render('Filter')

export default Filter;
