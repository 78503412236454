import React from 'react';

const FunctionalityIcon = ({ size, ...props }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      fill="currentColor"
      {...props}
    >
      <path d="M31.3114 18.1562H14.5481C14.3815 18.1562 14.2217 18.2228 14.1044 18.3411C13.9871 18.4594 13.9218 18.6196 13.9231 18.7862L13.9871 26.8578C13.9899 27.2011 14.2689 27.4779 14.6121 27.4779H20.1266C20.4717 27.4779 20.7516 27.198 20.7516 26.8529C20.7516 26.5078 20.4717 26.2279 20.1266 26.2279H15.2321L15.1781 19.4063H30.6913L30.7454 26.2279H25.7968C25.4517 26.2279 25.1718 26.5078 25.1718 26.8529C25.1718 27.198 25.4517 27.4779 25.7968 27.4779H31.3754C31.5421 27.4779 31.7018 27.4114 31.8191 27.2931C31.9365 27.1748 32.0017 27.0146 32.0004 26.8479L31.9364 18.7763C31.9337 18.433 31.6547 18.1562 31.3114 18.1562Z" />
      <path d="M23.5508 26.6145C23.45 26.3728 23.2021 26.2152 22.9401 26.2294C22.6801 26.2435 22.4516 26.4235 22.3758 26.6722C22.301 26.9173 22.3887 27.1943 22.5931 27.3495C22.8047 27.5102 23.0991 27.5198 23.3208 27.3732C23.5669 27.2104 23.662 26.886 23.5508 26.6145Z" />
      <path d="M5.79388 17.0215C2.59913 17.0215 0 19.621 0 22.8163C0 26.0116 2.59913 28.6112 5.79388 28.6112C8.98863 28.6112 11.5878 26.0116 11.5878 22.8163C11.5878 19.621 8.98863 17.0215 5.79388 17.0215ZM5.79388 27.3612C3.28838 27.3612 1.25 25.3224 1.25 22.8163C1.25 20.3103 3.28838 18.2715 5.79388 18.2715C8.29944 18.2715 10.3378 20.3103 10.3378 22.8163C10.3378 25.3224 8.29944 27.3612 5.79388 27.3612Z" />
      <path d="M5.79388 3.38574C2.59913 3.38574 0 5.98524 0 9.18056C0 12.3758 2.59913 14.9754 5.79388 14.9754C8.98863 14.9754 11.5878 12.3758 11.5878 9.18056C11.5878 5.98524 8.98863 3.38574 5.79388 3.38574ZM5.79388 13.7254C3.28838 13.7254 1.25 11.6866 1.25 9.18056C1.25 6.67449 3.28838 4.63568 5.79388 4.63568C8.29944 4.63568 10.3378 6.67449 10.3378 9.18056C10.3378 11.6866 8.29944 13.7254 5.79388 13.7254Z" />
      <path d="M7.42593 7.5483C7.18193 7.30417 6.78612 7.30423 6.54206 7.54836L5.79324 8.2973L5.04449 7.54836C4.80056 7.30436 4.40474 7.3043 4.16062 7.5483C3.91655 7.7923 3.91649 8.18805 4.16055 8.43217L4.90949 9.18123L4.16055 9.9303C3.91649 10.1744 3.91655 10.5701 4.16062 10.8142C4.28262 10.9362 4.44256 10.9972 4.60249 10.9972C4.76243 10.9972 4.92243 10.9362 5.04443 10.8141L5.79324 10.0652L6.54206 10.8141C6.66406 10.9362 6.82406 10.9972 6.98399 10.9972C7.14393 10.9972 7.30387 10.9362 7.42587 10.8142C7.66993 10.5702 7.67 10.1744 7.42593 9.9303L6.67699 9.18123L7.42593 8.43217C7.67006 8.18811 7.67 7.79236 7.42593 7.5483Z" />
      <path d="M14.6121 13.8421H31.3754C31.5421 13.8421 31.7018 13.7756 31.8191 13.6573C31.9365 13.539 32.0017 13.3788 32.0004 13.2122L31.9364 5.14057C31.9337 4.79732 31.6547 4.52051 31.3114 4.52051H14.5481C14.3815 4.52051 14.2217 4.58707 14.1044 4.70532C13.9871 4.82363 13.9218 4.98382 13.9231 5.15045L13.9871 13.2221C13.9899 13.5653 14.2689 13.8421 14.6121 13.8421ZM30.6914 5.77045L30.7454 12.5921H15.2322L15.1781 5.77045H30.6914Z" />
      <path d="M18.4711 8.49024H28.5844C28.9296 8.49024 29.2094 8.21036 29.2094 7.86523C29.2094 7.52011 28.9296 7.24023 28.5844 7.24023H18.4711C18.126 7.24023 17.8461 7.52011 17.8461 7.86523C17.8461 8.21036 18.126 8.49024 18.4711 8.49024Z" />
      <path d="M18.4711 11.1211H24.9876C25.3328 11.1211 25.6126 10.8413 25.6126 10.4961C25.6126 10.151 25.3328 9.87109 24.9876 9.87109H18.4711C18.126 9.87109 17.8461 10.151 17.8461 10.4961C17.8461 10.8413 18.126 11.1211 18.4711 11.1211Z" />
      <path d="M18.4711 22.3281H28.5844C28.9296 22.3281 29.2094 22.0482 29.2094 21.7031C29.2094 21.3579 28.9296 21.0781 28.5844 21.0781H18.4711C18.126 21.0781 17.8461 21.3579 17.8461 21.7031C17.8461 22.0482 18.126 22.3281 18.4711 22.3281Z" />
      <path d="M25.6126 24.334C25.6126 23.9889 25.3328 23.709 24.9876 23.709H18.4711C18.126 23.709 17.8461 23.9889 17.8461 24.334C17.8461 24.6791 18.126 24.959 18.4711 24.959H24.9876C25.3328 24.959 25.6126 24.6792 25.6126 24.334Z" />
      <path d="M7.37542 21.3345C7.09592 21.1322 6.70511 21.1948 6.50267 21.4744L5.50874 22.848L5.01174 22.3735C4.76217 22.1351 4.36649 22.1441 4.12811 22.3939C3.88974 22.6435 3.89886 23.0391 4.14855 23.2775L5.16355 24.2466C5.2803 24.3581 5.43511 24.4196 5.59505 24.4196C5.61399 24.4196 5.63298 24.4188 5.65192 24.4171C5.83167 24.4006 5.99561 24.3073 6.10142 24.161L7.5153 22.2072C7.71773 21.9276 7.65511 21.5369 7.37542 21.3345Z" />
    </svg>
  );
}

export default FunctionalityIcon;
