import React, { useContext, useState } from 'react';
import AnimateHeight from 'react-animate-height';
import i18nextTranslate from 'Lang/i18nextTranslate';
import { i18nextKeys } from 'Lang/i18nextKeys';
import { UiContext } from "States/ui/uiState";
import { copyToClipboardAndNotify } from 'Utils/utils';
import { DIRECTION } from 'Helpers/icons';
import { LinkIcon, SingleChevron } from 'Components/shared/symbols';
import { Button } from '../buttons';
import Text, { LabeledValue } from '../Text';
import Tooltip from '../Tooltip';

export const defaultFieldValue = "-";

export const Tile = ({
  dataQa,
  title,
  alwaysVisibleContent,
  children,
  bottomText,
  className = '',
  style = {},
  borderRadius = 'rounded',
  defaultPadding = "px-16 py-20",
  mdPadding = "md:p-24",
  xlPadding = "xxl:p-24",
  xxlPadding = "xxl:p-24",
  expandable = false,
  defaultExpanded = false,
  reference = null
}) => {
  const [expanded, setExpanded] = useState(defaultExpanded);
  const {
    breakpoints: { md }
  } = useContext(UiContext);

  const toggleExpansion = () => {
    if (!expandable) {
      return;
    }
    setExpanded(!expanded);
  };

  return (
    <div
      ref={reference}
      className={`relative ${defaultPadding} ${mdPadding} ${
        xlPadding
      } ${xxlPadding} bg-3 w-full ${className} ${borderRadius}`}
      style={style}
      data-qa={dataQa}
    >
      <div
        className={`w-full flex justify-between items-start gap-8 ${
          !md && expandable ? "cursor-pointer" : "cursor-default"
        }`}
        onClick={toggleExpansion}
        data-qa={`${dataQa}-expansionToggle`}
      >
        {title && (
          <Text
            dataQa={`${dataQa}-title`}
            textStyle="h2"
            uppercase
          >
            {title}
          </Text>
        )}
        {!md && expandable && (
          <SingleChevron
            size="16"
            direction={expanded ? DIRECTION.up : DIRECTION.down}
            className="color-4 flex-shrink-0"
            data-qa={`${dataQa}-expandArrow`}
          />
        )}
      </div>
      {!!alwaysVisibleContent && alwaysVisibleContent}
      <AnimateHeight
        duration={750}
        height={!expandable || md || expanded ? 'auto' : 0}
      >
        <div className={title ? "mt-24" : ""}>
          {children}
          {!!bottomText && (
            <Text
              dataQa={`${dataQa}-bottomText`}
              textStyle="p2"
              className={`flex justify-center w-auto bg-4_7 ${
                borderRadius
              } rounded-t-none py-14 -mb-20 -mx-16 md:-mb-24 md:-mx-24 mt-24 md:mt-32`}
              uppercase
            >
              {bottomText}
            </Text>
          )}
        </div>
      </AnimateHeight>
    </div>
  );
};

export const HorizontalRule = ({
  defaultMargin = "-mx-16",
  mdMargin = "md:-mx-24",
  xlMargin = "xl:-mx-24",
  xxlMargin = "xxl:-mx-24",
  className = ""
}) => (
  <hr className={`${defaultMargin} ${mdMargin} ${
    xlMargin
  } ${xxlMargin} ${className} border-color-5`} />
);

export const TileCardContent = ({
  data,
  className = "",
  copyOnClick = false
}) => (
  data.map((block, index) => (
    <div
      key={index}
      className={className}
    >
      {!!block.title && (
        <Text
          textStyle="p2"
          color="color-4"
          className="mb-16"
          dataQa={`${block.dataQa}-title`}
        >
          {block.title}
        </Text>
      )}
      <div
        className="flex flex-col gap-6"
      >
        {block.items.map((item, index2) => (
          <LabeledValue
            key={index2}
            label={item.name}
            value={item.value}
            defaultValue={defaultFieldValue}
            copyOnClick={copyOnClick}
            dataQa={item.dataQa}
          />
        ))}
      </div>
    </div>
  ))
);

export const TileInfoCard = ({
  title,
  titleTextStyle = "h3",
  text,
  link,
  width = "auto",
  className = "",
  textClassName = "",
  textProps = {},
  copyOnClick = false,
  copyText = "",
  showTooltip = false,
  showCharacters = 19,
  infoComponent = null,
  showEditButton = false,
  editDisabled = false,
  onEdit = () => {},
  dataQa = "data"
}) => (
  <div
    className={`flex flex-col gap-6 ${className}`}
    style={{ width }}
    data-qa={dataQa}
  >
    <span className="flex items-center gap-8">
      <Text
        dataQa={`${dataQa}-label`}
        textStyle={titleTextStyle}
        inline
      >
        {title}
      </Text>
      {showEditButton && (
        <Button
          text={i18nextTranslate(i18nextKeys.buttonEdit)}
          onClick={onEdit}
          disabled={editDisabled}
          small
        />
      )}
      {infoComponent && (
        <Tooltip
          dataQa={dataQa}
          component={infoComponent}
          width="400px"
          useIcon
        />
      )}
    </span>
    <div className="flex items-center gap-8">
      <Text
        className={`${textClassName} ${
          copyOnClick ? "cursor-pointer" : "cursor-default"
        }`}
        dataQa={`${dataQa}${showTooltip ? "" : "-value"}`}
        onClick={() => {
          if (copyOnClick) {
            copyToClipboardAndNotify(copyText || text, title)
          }
        }}
        {...textProps}
        inline
      >
        {showTooltip && text ? (
          <Tooltip
            text={text || copyText}
            textStyle={{ overflowWrap: "anywhere" }}
            dataQa={`${dataQa}-value`}
          >
            <span
              className="block truncate"
              data-qa={`${dataQa}-value`}
            >
              {text.slice(0, showCharacters)}...
            </span>
          </Tooltip>
        ) : (
          text || defaultFieldValue
        )}
      </Text>
      {link && (
        <a
          href={link}
          target="blank"
          className="color-4"
        >
          <LinkIcon />
        </a>
      )}
    </div>
  </div>
);
