import React from 'react';

const Checkbox = ({
  onChange = () => {},
  dataQa = null,
  checked = false,
  required = false,
  readOnly = false,
  disabled = false
}) => {
  return (
    <div
      className={`${disabled
        ? 'bg-grey'
        : checked
          ? 'bg-4--20'
          : 'bg-9 border border-color-5'
        } w-16 h-16 relative rounded flex flex-shrink-0 justify-center items-center`}
    >
      <input
        type="checkbox"
        className={`opacity-0 absolute bg-9 color-10 ${
          disabled || readOnly ? 'cursor-default' : 'cursor-pointer'
        }`}
        data-qa={dataQa}
        checked={checked}
        onChange={onChange}
        readOnly={readOnly}
        required={required}
        disabled={disabled}
      />
      {checked && (
        <svg
          width="8"
          height="8"
          viewBox="0 0 8 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_4867_66733)">
            <path
              d="M7.96737 1.94147L3.00714 6.9017C2.96345 6.9454 2.8927 6.9454 2.84912 6.9017L0.0326915 4.08518C-0.0108972 4.04169 -0.0108972 3.97095 0.0326915 3.92726L0.717045 3.24291C0.760736 3.19932 0.831478 3.19932 0.875067 3.24291L2.92823 5.29597L7.1251 1.0991C7.16889 1.05551 7.23943 1.05551 7.28312 1.0991L7.96737 1.78345C8.01106 1.82704 8.01106 1.89768 7.96737 1.94147Z"
              fill="white"
            />
          </g>
          <defs>
            <clipPath id="clip0_4867_66733">
              <rect width="8" height="8" fill="white" />
            </clipPath>
          </defs>
        </svg>
      )}
    </div>
  );
};

export default Checkbox;
