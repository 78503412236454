import React, { useContext } from "react";
import { UiContext } from "States/ui/uiState";
import { IconCircleWrapper } from "Helpers/icons";
import Header from "../Header";
import LoadingSpinner from "../LoadingSpinner";
import Text from "../Text";

const SimplePage = ({
  header,
  headerChildren,
  icon: Icon,
  title,
  description,
  children,
  dataQa = "page",
  loading = false
}) => {
  const {
    breakpoints: { xxl }
  } = useContext(UiContext);
  return (
    <>
      <Header
        text={header}
        children={headerChildren}
      />
      <div className="flex flex-col h-full items-center justify-center">
        {loading ? (
          <LoadingSpinner />
        ) : (
          <div
            className="w-11/12 md:w-3/4 lg:w-3/5 flex flex-col items-center gap-24"
            style={{ maxWidth: "600px" }}
          >
            <IconCircleWrapper
              icon={
                <Icon
                  size={xxl ? 72 : 60}
                  dataQa={`${dataQa}-icon`}
                />
              }
              size={xxl ? '144px' : '120px'}
              dataQa={dataQa}
            />
            <div className="flex flex-col items-center gap-16">
              {!!title && (
                <Text
                  textStyle="h1"
                  dataQa={`${dataQa}-title`}
                >
                  {title}
                </Text>
              )}
              {!!description && (
                <Text
                  textStyle="p1"
                  dataQa={`${dataQa}-description`}
                  dangerouslySetContent
                  center
                >
                  {description}
                </Text>
              )}
            </div>

            {children}
          </div>
        )}
      </div>
    </>
  );
};

export default SimplePage;
