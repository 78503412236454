import { i18nextKeys } from 'Lang/i18nextKeys';
import i18nextTranslate from 'Lang/i18nextTranslate';

export const SHUFTI_PRO_VERIFICATION_STATE = {
  REQUEST_RECEIVED: 'Received',
  REQUEST_PENDING: 'Pending',
  REQUEST_TIMEOUT: 'Timeout',
  VERIFICATION_CANCELLED: 'Cancelled',
  VERIFICATION_ACCEPTED: 'Accepted',
  VERIFICATION_DECLINED: 'Declined',
  VERIFICATION_STATUS_ERROR: 'Error',
};

const enumToFriendlyString = (e) => {
  const {
    REQUEST_RECEIVED,
    REQUEST_PENDING,
    REQUEST_TIMEOUT,
    VERIFICATION_CANCELLED,
    VERIFICATION_ACCEPTED,
    VERIFICATION_DECLINED,
    VERIFICATION_STATUS_ERROR,
  } = SHUFTI_PRO_VERIFICATION_STATE;

  switch (e) {
    case REQUEST_RECEIVED: {
      return i18nextTranslate(i18nextKeys.shuftiProRequestReceived);
    }
    case REQUEST_PENDING: {
      return i18nextTranslate(i18nextKeys.shuftiProRequestPending);
    }
    case REQUEST_TIMEOUT: {
      return i18nextTranslate(i18nextKeys.shuftiProRequestTimeout);
    }
    case VERIFICATION_CANCELLED: {
      return i18nextTranslate(i18nextKeys.shuftiProVerificationCancelled);
    }
    case VERIFICATION_ACCEPTED: {
      return i18nextTranslate(i18nextKeys.shuftiProVerificationAccepted);
    }
    case VERIFICATION_DECLINED: {
      return i18nextTranslate(i18nextKeys.shuftiProVerificationDeclined);
    }
    case VERIFICATION_STATUS_ERROR: {
      return i18nextTranslate(i18nextKeys.shuftiProVerificationError);
    }
    default: {
      return i18nextTranslate(i18nextKeys.enumNone);
    }
  }
};

const ShuftiProVerificationState = {
  ...SHUFTI_PRO_VERIFICATION_STATE,
  enumToFriendlyString,
};

export default ShuftiProVerificationState;
