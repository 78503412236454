import React from 'react';
import { useHistory } from 'react-router-dom';
import { DateTime, Duration } from 'luxon';
import { ClockIcon } from 'Components/shared/symbols';
import useTimer from 'Hooks/useTimer';
import i18nextTranslateDynamically from 'Lang/i18nextTranslateDynamically';
import { i18nextKeys } from 'Lang/i18nextKeys';
import { ROUTES } from 'Router/Routes';
import Text from 'Components/shared/Text';

const CheckoutTimer = ({
  startDateISO,
  durationISO,
  showDaysLeft = false,
  showHoursLeft = false,
  showTimer = false,
  translationKey = i18nextKeys.checkoutTimerDraftOrderMessage,
}) => {
  const history = useHistory();
  const startDate = DateTime.fromISO(startDateISO);
  const initialDuration = Duration.fromISO(durationISO);
  const remainingDuration = useTimer(startDate, initialDuration);

  if (remainingDuration.invalid) {
    history.replace(ROUTES.checkoutPendingExpired);
  }

  let staticTimer = '';
  let timer = '';
  let dayVariables = {};
  if (showDaysLeft) {
    const staticDaysFormatted = String(initialDuration.days).padStart(2, '0');
    const staticHoursFormatted = String(initialDuration.hours).padStart(2, '0');
    timer = remainingDuration.toFormat("dd hh");
    dayVariables = {
      days: staticDaysFormatted,
      hours: staticHoursFormatted
    };
  } else if (showHoursLeft) {
    const staticHoursFormatted = String(initialDuration.hours).padStart(2, '0');
    const staticMinutesFormatted = String(initialDuration.minutes).padStart(2, '0');
    timer = remainingDuration.toFormat("hh:mm");
    staticTimer = `${staticHoursFormatted}:${staticMinutesFormatted}`;
  } else {
    const staticMinutesFormatted = String(initialDuration.minutes).padStart(2, '0');
    const staticSecondsFormatted = String(initialDuration.seconds).padStart(2, '0');
    timer = remainingDuration.toFormat("mm:ss");
    staticTimer = `${staticMinutesFormatted}:${staticSecondsFormatted}`;
  }

  return (
    <div className="flex justify-between items-center" data-qa="checkout-timer">
      <Text
        color="color-4"
        className="flex gap-12 xxl:gap-14"
      >
        <ClockIcon />
        {i18nextTranslateDynamically(
          translationKey,
          showDaysLeft ? dayVariables : { timeLeft: staticTimer }
        )}
      </Text>
      {showTimer && <span className="text-lg font-semibold color-4">{timer}</span>}
    </div>
  );
};

export default CheckoutTimer;
