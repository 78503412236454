import React from 'react';
import { Redirect } from 'react-router-dom';
import { ROUTES } from './Routes';

const NotFound = ({ redirectPath }) => (
  <Redirect
    to={redirectPath || {
      pathname: ROUTES.error,
      state: { errorCode: 404 },
    }}
  />
);

export default NotFound;
