import React from 'react';
import i18nextTranslate from 'Lang/i18nextTranslate';
import { i18nextKeys } from 'Lang/i18nextKeys';
import Modal from 'Components/shared/Modal';
import { Button } from 'Components/shared/buttons';

const ResponseModal = ({
  dataQa = 'response',
  showModal = false,
  hideModal = () => { },
  title = '',
  response = '',
}) => (
  <Modal
    dataQa={`${dataQa}-modal`}
    show={showModal}
    width={'w-auto'}
    close={hideModal}
  >
    <div className="flex flex-col py-24 px-64">
      <h2 className="text-xl color-8 font-bold">{title}</h2>
      <span
        className="mt-24 mb-24 color-8 overflow-x-auto"
        style={{ maxHeight: 320 }}
      >
        {response}
      </span>
      <div className="flex flex-start w-full">
        <Button
          dataQa={`${dataQa}-modal-closeButton`}
          onClick={hideModal}
          text={i18nextTranslate(i18nextKeys.buttonClose)}
          width={{
            xxl: "190px",
            md: "170px",
            default: "140px"
          }}
          secondary
        />
      </div>
    </div>
  </Modal>
);

export default ResponseModal;
