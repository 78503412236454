import React, { useContext } from "react";
import { UiContext } from "States/ui/uiState";
import Text from "Components/shared/Text";

const SignupReminder = ({ text }) => {
  const {
    breakpoints: { md, lg, xxl }
  } = useContext(UiContext);
  return (
    <div
      className="fixed z-40 bg-3 rounded-md p-16 xxl:px-28"
      style={{
        width: xxl ? '514px' : md ? '405px' : '328px',
        bottom: lg ? '24px' : '16px',
        right: lg ? '24px' : '16px',
        ...!md && { maxWidth: 'calc(100% - 32px)' }
      }}
    >
      <Text className="text-center">
        {text}
      </Text>
    </div>
  );
}

export default SignupReminder;
