import React from 'react';
import { useHistory } from 'react-router-dom';
import Header from 'Components/shared/Header/Header';
import { Button } from 'Components/shared/buttons';
import { isTenantFeatureAvailable } from 'Helpers/toggleFeatures';
import i18nextTranslate from 'Lang/i18nextTranslate';
import { i18nextKeys } from 'Lang/i18nextKeys';
import TENANT_FEATURE from 'Enums/TenantFeature';
import { ROUTES } from 'Router/Routes';

const AccountHeader = ({ features }) => {
  const history = useHistory();

  const toOrderHistory = () => {
    history.push(ROUTES.orders);
  };

  const toRedemptionHistory = () => {
    history.push(ROUTES.redemptions);
  };

  const buttonWidth = {
    xxl: '190px',
    md: '170px',
    default: '156px'
  };

  return (
    <Header text={i18nextTranslate(i18nextKeys.accountHeader)}>
      <span className="flex mt-16 md:mt-0 gap-16 md:gap-12">
        {(isTenantFeatureAvailable(features, TENANT_FEATURE.purchase) ||
          isTenantFeatureAvailable(
            features,
            TENANT_FEATURE.customFeaturedAsset
          )) && (
            <Button
              dataQa="account-profileTile-orderHistoryButton"
              text={i18nextTranslate(i18nextKeys.buttonOrderHistory)}
              onClick={toOrderHistory}
              width={buttonWidth}
              tertiary
            />
          )}

        {isTenantFeatureAvailable(features, TENANT_FEATURE.redeem) && (
          <Button
            dataQa="account-profileTile-redeemHistoryButton"
            text={i18nextTranslate(i18nextKeys.buttonRedemptionHistory)}
            onClick={toRedemptionHistory}
            width={buttonWidth}
            tertiary
          />
        )}
      </span>
    </Header>
  );
};

export default AccountHeader;
