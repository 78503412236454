import React from "react";
import Modal from "Components/shared/Modal";
import { Button } from "Components/shared/buttons";
import i18nextTranslate from "Lang/i18nextTranslate";
import { i18nextKeys } from "Lang/i18nextKeys";

const KycDeclineReasonModal = ({ show, close, declineReason }) => (
  <Modal
    dataQa="account-kyc-declineReasonModal"
    show={show}
    close={close}
  >
    <h1
      className="sticky top-0 px-16 py-20 md:pt-32 md:px-32  font-bold text-xl bg-3"
      data-qa="account-kyc-declineReasonModal-title"
    >
      {i18nextTranslate(i18nextKeys.cardsKycDeclinedReasonModalHeader)}
    </h1>
    <p
      className="px-16 md:px-32 text-sm"
      data-qa="account-kyc-declineReasonModal-reason"
    >
      {declineReason}
    </p>
    <div
      className="sticky bottom-0 px-16 py-20 md:pb-32 md:px-32 bg-3"
      data-qa="account-kyc-declineReasonModal-closeButton"
    >
      <Button
        onClick={close}
        text={i18nextTranslate(i18nextKeys.buttonClose)}
        width={{
          xxl: "190px",
          default: "170px"
        }}
      />
    </div>
  </Modal>
);

export default KycDeclineReasonModal;
