import React from 'react';

const RefreshIcon = ({
  size = "14",
  className,
  ...props
}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 14 14"
    fill="currentColor"
    className={className}
    {...props}
  >
    <path d="M11.4348 7.3702C11.1089 7.32061 10.8195 7.54397 10.7728 7.86242C10.6555 8.66222 10.2863 9.39198 9.70526 9.97303C8.2264 11.4519 5.82189 11.4507 4.34361 9.97303C2.86562 8.49475 2.86562 6.08937 4.34361 4.61138C4.89959 4.05569 5.60145 3.69339 6.38697 3.56149C6.72478 3.49738 7.08796 3.4863 7.4648 3.529C7.98221 3.58856 8.46381 3.76024 8.9033 4.01229L8.14385 4.14197C7.82658 4.19639 7.6129 4.49774 7.66762 4.81531C7.71604 5.09957 7.96273 5.30035 8.24185 5.30035C8.27435 5.30035 8.30736 5.29779 8.34096 5.29207L10.3769 4.944C10.529 4.9178 10.6651 4.83235 10.7546 4.70588C10.844 4.5797 10.8794 4.42302 10.8531 4.27036L10.5045 2.2347C10.4509 1.91742 10.1536 1.70492 9.83115 1.75817C9.51387 1.8126 9.3002 2.11424 9.35491 2.43181L9.44667 2.9676C8.88206 2.65085 8.25924 2.44593 7.5975 2.36974C7.1019 2.31333 6.62112 2.32903 6.18105 2.41332C5.16792 2.58307 4.24736 3.0579 3.51872 3.78649C1.58584 5.71936 1.58584 8.86447 3.51872 10.7979C4.48513 11.7646 5.75493 12.2477 7.02444 12.2477C8.29424 12.2477 9.5634 11.7646 10.5302 10.7979C11.2895 10.0385 11.7726 9.08205 11.9269 8.03217C11.9737 7.71314 11.7532 7.41686 11.4348 7.3702Z" />
  </svg>
);

export default RefreshIcon;
