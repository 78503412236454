import React from "react";
import { Field as FinalField } from "react-final-form";
import linkify from "Helpers/linkify";
import { i18nextKeys } from "Lang/i18nextKeys";
import i18nextTranslate from "Lang/i18nextTranslate";
import { SingleChevron } from "Components/shared/symbols";
import { TextButton } from "Components/shared/buttons";
import Text from "Components/shared/Text";
import Toggle from "Components/shared/Toggle";
import Tooltip from "Components/shared/Tooltip";

const MethodTile = ({
  toggleName,
  translationKeyPrefix,
  method,
  openMethod = () => {},
  getIcon = () => {},
  contentWidth = "600px",
  isSingleEnabledMethod = false,
  isConfigured = false,
  disabled = false,
  disableConfigure = false
}) => {
  return (
    <div className="flex justify-between gap-16 p-20 bg-4_7 rounded">
      <div className="flex gap-20">
        {getIcon(method.Type)}
        <div
          className="flex flex-col gap-10"
          style={{ maxWidth: contentWidth }}
        >
          <Text textStyle="p2">
            {i18nextTranslate(
              i18nextKeys[`${translationKeyPrefix}${method.Type}Title`]
            )}
          </Text>
          <Text
            textStyle="p4"
            dangerouslySetContent
          >
            {linkify(i18nextTranslate(
              i18nextKeys[`${translationKeyPrefix}${method.Type}Description`]
            ))}
          </Text>
        </div>
      </div>
      <div className="flex flex-col justify-between">
        <FinalField
          name={toggleName}
          type="checkbox"
          subscription={{ value: true }}
          render={({ input }) => {
            return (
              <div className="flex gap-12">
                <Text dataQa={`${method.Type}-status`}>
                  {i18nextTranslate(
                    input.checked
                      ? i18nextKeys.enabled
                      : i18nextKeys.disabled
                  )}
                </Text>
                <Tooltip
                  text={i18nextTranslate(
                    isConfigured && input.checked && isSingleEnabledMethod
                      ? i18nextKeys[`${translationKeyPrefix}Tooltip`]
                      : i18nextKeys.commonConfigTooltip
                  )}
                  disableHoverListener={
                    isConfigured && !(input.checked && isSingleEnabledMethod)
                  }
                  textStyle={!isConfigured ? { width: "auto" } : {}}
                >
                  <Toggle
                    toggled={input.checked}
                    onToggle={input.onChange}
                    disabled={
                      disabled ||
                      !isConfigured ||
                      (input.checked && isSingleEnabledMethod)}
                    dataQa={method.Type}
                  />
                </Tooltip>
              </div>
            );
          }}
        />
        <TextButton
          text={i18nextTranslate(i18nextKeys.configConfigure)}
          icon={SingleChevron}
          onClick={() => openMethod(method)}
          dataQa={`${method.Type}-configure`}
          className="self-end"
          disabled={disableConfigure}
        />
      </div>
    </div>
  );
};

export default MethodTile;
