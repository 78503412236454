import React, { useContext } from "react";
import { UiContext } from "States/ui/uiState";
import { DIRECTION } from 'Helpers/icons';
import Text from "../Text";

const TextButton = ({
  text,
  icon: Icon,
  iconDirection = DIRECTION.right,
  iconSize = {
    xxl: "16",
    default: "14"
  },
  onClick,
  type = 'button',
  dataQa,
  className = "",
  color = "color-6 hover:color-4 active:color-4--20",
  style = {},
  disabled = false
}) => {
  const {
    breakpoints: { xxl }
  } = useContext(UiContext);

  if (!(text || text?.length) && !Icon) {
    return null;
  };

  return (
    <button
      type={type}
      onClick={onClick}
      disabled={disabled}
      className={`flex items-center focus:outline-none gap-8 ${
        disabled
          ? "color-grey cursor-default"
          : color
      } ${className}`}
      style={style}
      data-qa={dataQa}
    >
      {text && text.length ? (
        <Text
          textStyle="text-button"
          color="currentColor"
          dataQa={`${dataQa}-text`}
          uppercase
        >
          {text}
        </Text>
      ) : null}
      {Icon ? (
        <Icon
          size={xxl ? iconSize.xxl : iconSize.default}
          direction={iconDirection}
        />
      ) : null}
    </button>
  );
};

export default TextButton;
