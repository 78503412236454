import React from 'react';

function LogoutSymbol(props) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      className="fill-current"
      {...props}
    >
      <path d="M8.625 16H0.78125C0.57405 16 0.375336 15.9177 0.228823 15.7712C0.08231 15.6247 0 15.426 0 15.2188L0 0.78125C0 0.57405 0.08231 0.375336 0.228823 0.228823C0.375336 0.08231 0.57405 0 0.78125 0L8.625 0C8.8322 0 9.03091 0.08231 9.17743 0.228823C9.32394 0.375336 9.40625 0.57405 9.40625 0.78125C9.40625 0.98845 9.32394 1.18716 9.17743 1.33368C9.03091 1.48019 8.8322 1.5625 8.625 1.5625H1.5625V14.4375H8.625C8.8322 14.4375 9.03091 14.5198 9.17743 14.6663C9.32394 14.8128 9.40625 15.0115 9.40625 15.2188C9.40625 15.426 9.32394 15.6247 9.17743 15.7712C9.03091 15.9177 8.8322 16 8.625 16Z" />
      <path d="M15.0147 8.78125H5.84375C5.63655 8.78125 5.43784 8.69894 5.29132 8.55243C5.14481 8.40591 5.0625 8.2072 5.0625 8C5.0625 7.7928 5.14481 7.59409 5.29132 7.44757C5.43784 7.30106 5.63655 7.21875 5.84375 7.21875H15.0147C15.2219 7.21875 15.4206 7.30106 15.5671 7.44757C15.7136 7.59409 15.7959 7.7928 15.7959 8C15.7959 8.2072 15.7136 8.40591 15.5671 8.55243C15.4206 8.69894 15.2219 8.78125 15.0147 8.78125Z" />
      <path d="M11.2529 12.7352C11.0982 12.7353 10.9471 12.6894 10.8185 12.6034C10.6899 12.5175 10.5898 12.3953 10.5307 12.2524C10.4717 12.1094 10.4563 11.9522 10.4867 11.8006C10.5171 11.6489 10.5918 11.5097 10.7013 11.4006L14.1126 8.00024L10.7013 4.59993C10.6266 4.52799 10.5669 4.44188 10.5258 4.34664C10.4847 4.25139 10.463 4.14892 10.462 4.04519C10.4609 3.94147 10.4805 3.83856 10.5196 3.74249C10.5587 3.64642 10.6166 3.55909 10.6898 3.48562C10.763 3.41215 10.8501 3.35399 10.9461 3.31455C11.042 3.2751 11.1449 3.25516 11.2486 3.25588C11.3523 3.2566 11.4549 3.27797 11.5503 3.31874C11.6456 3.35952 11.7319 3.41888 11.8041 3.49337L15.771 7.44681C15.8439 7.51939 15.9017 7.60564 15.9411 7.70061C15.9805 7.79558 16.0009 7.89741 16.0009 8.00024C16.0009 8.10308 15.9805 8.2049 15.9411 8.29988C15.9017 8.39485 15.8439 8.4811 15.771 8.55368L11.8041 12.5071C11.7318 12.5795 11.6458 12.6369 11.5512 12.6761C11.4566 12.7152 11.3553 12.7353 11.2529 12.7352Z" />
    </svg>
  );
}

export default LogoutSymbol;
