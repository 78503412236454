import React, { useState, useContext, useEffect } from "react";
import i18nextTranslate from "Lang/i18nextTranslate";
import { i18nextKeys } from "Lang/i18nextKeys";
import { useFormatNumber } from 'Utils/formatNumber';
import { objectArrayToObject } from 'Utils/utils';
import { DIRECTION } from 'Helpers/icons';
import { TileInfoCard } from "Components/shared/Tile";
import i18nextTranslateDynamically from "Lang/i18nextTranslateDynamically";
import { TextButton } from "Components/shared/buttons";
import { Tile, HorizontalRule } from "Components/shared/Tile";
import { SingleChevron } from "Components/shared/symbols";
import ToggleShowHide from "Components/shared/ToggleShowHide";
import FeeTooltip from "Features/Cart/FeeTooltip";
import { ConfigContext } from 'States/config/configState';
import { UiContext } from "States/ui/uiState";

const RedemptionRequest = ({ redemptionRequest }) => {
  const formatNumber = useFormatNumber();
  const {
    config: {
      redeemSettings: {
        additionalInfoRedemptionRequest
      }
    }
  } = useContext(ConfigContext);

  const {
    breakpoints: { md }
  } = useContext(UiContext);

  const [fees, setFees] = useState(null);
  const [showFees, setShowFees] = useState(false);

  // TODO: https://coreledgerag.visualstudio.com/TEOS/_workitems/edit/18963
  // const onShowFeesToggle = () => {
  //   setShowFees(prev => !prev)
  // };

  useEffect(() => {
    if (redemptionRequest.Fees && redemptionRequest.Fees.length) {
      const fees = objectArrayToObject(redemptionRequest.Fees, 'Type');
      setFees(fees);
    }
  }, []);

  const feesData = [
    {
      type: "Issuer",
      label: i18nextTranslate(i18nextKeys.redemptionFeeIssuer),
      description: i18nextTranslate(i18nextKeys.redemptionFeeIssuerDescription)
    },
    {
      type: "Method",
      label: i18nextTranslate(i18nextKeys.commonFeeMethod),
      description: i18nextTranslate(i18nextKeys.redemptionFeeMethodDescription)
    },
    {
      type: "Country",
      label: i18nextTranslate(i18nextKeys.commonFeeCountry),
      description: i18nextTranslate(i18nextKeys.redemptionFeeCountryDescription)
    }
  ];

  const userPaysUom = redemptionRequest.asset.UnitOfMeasureCode || redemptionRequest.asset.CurrencyCode;

  return (
    <Tile
      title={i18nextTranslate(i18nextKeys.redeemManagementDetailsRedemptionRequestHeader)}
      expandable
    >
      <div className="flex flex-col gap-24">
        <div
          className="grid"
          style={{
            gridTemplateColumns: !md ? "1fr" : "repeat(3, 1fr)",
            rowGap: "20px",
            columnGap: "24px"
          }}
        >
          <TileInfoCard
            title={i18nextTranslate(i18nextKeys.redeemManagementDetailsRequestInformationGrossAmount)}
            text={i18nextTranslateDynamically(i18nextKeys.redeemManagementDetailsRequestInformationNetAmountValue, {
              amount: formatNumber(redemptionRequest.GrossAmount.AssetUnitsString),
              uom: userPaysUom,
              sparksAmount: formatNumber(redemptionRequest.GrossAmount.AssetSparks)
            })}
          />
          <TileInfoCard
            title={i18nextTranslate(i18nextKeys.redeemManagementDetailsRequestInformationNetAmount)}
            text={`${formatNumber(redemptionRequest.NetAmount.AssetUnitsString)
              } ${userPaysUom}`}
          />
        </div>
        {!!fees && (
          <div>
            {/* TODO: https://coreledgerag.visualstudio.com/TEOS/_workitems/edit/18963 */}
            {/* <TextButton
              text={i18nextTranslate(
                showFees
                  ? i18nextKeys.redeemManagementDetailsRedemptionRequestHideFees
                  : i18nextKeys.redeemManagementDetailsRedemptionRequestShowFees
              )}
              onClick={onShowFeesToggle}
              icon={SingleChevron}
              iconDirection={
                showFees
                  ? DIRECTION.up
                  : DIRECTION.down
              }
              dataQa="fees-toggle"
            /> */}
            <div
              className={`grid ${showFees ? 'mt-16' : ''}`}
              style={{
                gridTemplateColumns: !md ? "1fr" : "repeat(3, 1fr)",
                rowGap: "20px",
                columnGap: "24px"
              }}
            >
              {feesData.map(({ type, label, description }) => {
                const dataQa = `fee-${type.toLowerCase()}`;
                return (
                  <TileInfoCard
                    key={`fee-${type}`}
                    title={label}
                    text={`${formatNumber(fees[type].TotalAmount.AssetUnitsString)} ${userPaysUom}`}
                    infoComponent={
                      <FeeTooltip
                        description={description}
                        percentageText={i18nextTranslateDynamically(
                          i18nextKeys.redemptionFeePercentage,
                          { percentage: formatNumber(fees[type]?.VariableRate) }
                        )}
                        units={userPaysUom}
                        fee={{
                          FixedAmount: fees[type]?.FixedAmount.AssetUnitsString,
                          VariableAmount: fees[type]?.VariableAmount.AssetUnitsString,
                          TotalAmount: fees[type]?.TotalAmount.AssetUnitsString
                        }}
                        dataQa={dataQa}
                      />
                    }
                    dataQa={dataQa}
                  />
                )
              })}
            </div>
          </div>
        )}
        {(!!redemptionRequest.AdditionalDetails || additionalInfoRedemptionRequest.enabled) && (
          <>
            <HorizontalRule />
            <TileInfoCard
              title={additionalInfoRedemptionRequest.name}
              text={redemptionRequest.AdditionalDetails}
              dataQa="additonal-details"
            />
          </>
        )}
      </div>
    </Tile>
  )
}

export default RedemptionRequest
