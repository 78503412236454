import {
  SET_LATEST_KYC_CASE,
  SET_CURRENT_KYC_TIER,
  SET_LOADING
} from "./kycTypes";

const kycReducer = (state, action) => {
  switch (action.type) {
    default:
      return state;

    case SET_LATEST_KYC_CASE: {
      return {
        ...state,
        latestKycCase: action.payload.value || {}
      };
    }
    case SET_CURRENT_KYC_TIER: {
      return {
        ...state,
        currentKycTier: action.payload.value
      };
    }
    case SET_LOADING: {
      return {
        ...state,
        isLoading: action.payload.value
      }
    }
  }
};

export default kycReducer;