import React, { useContext } from "react";
import { LangContext } from "States/lang/langState";
import { CheckboxWithText } from "../formElements";

const CustomTerms = ({ defaultLanguageCode, index = 0, translations = {} }) => {
  const { lang } = useContext(LangContext);

  const translatedTerm = translations[lang] || translations[defaultLanguageCode] || translations["en"] ||
    Object.values(translations).find(translation => !!translation);

  return (
    <CheckboxWithText
      name={`customTerms.${index + 1}`}
      text={translatedTerm}
      dataQa={`terms-custom-${index + 1}`}
      required
      showErrors
    />
  );
};

export default CustomTerms;
