import api from "Api";
import { useMutation, useQuery } from "react-query";
import queryClients from 'Helpers/queryClients';

const COLLECTION_METHODS_QUERY_KEY = 'collectionMethodsQuery';

const useCollectionMethodsQuery = (config) =>
  useQuery({
    queryKey: COLLECTION_METHODS_QUERY_KEY,
    queryFn: () => api.Config.CollectionMethods.getAll(),
    ...config
  });

const COLLECTION_METHOD_QUERY_KEY = 'collectionMethodQuery';

const useCollectionMethodQuery = ({ type, ...config }) => useQuery({
  queryKey: [COLLECTION_METHOD_QUERY_KEY, type],
  queryFn: () => api.Config.CollectionMethods.get(type),
  ...config
});

const COLLECTION_METHOD_MUTATION_KEY = 'collectionMethodsMutation';

const useCollectionMethodMutation = (config) =>
  useMutation({
    mutationKey: COLLECTION_METHOD_MUTATION_KEY,
    mutationFn: ({ type, data }) => api.Config.CollectionMethods.patch(type, data),
    onSuccess: () => queryClients.rootQueryClient.invalidateQueries(
      COLLECTION_METHODS_QUERY_KEY
    ),
    ...config
  });

const useCollectionMethods = {
  methodsQuery: useCollectionMethodsQuery,
  methodQuery: useCollectionMethodQuery,
  mutation: useCollectionMethodMutation
};

export default useCollectionMethods;
