import React from 'react';

const WalletSymbol = ({
  size = "32",
  className = "color-4",
  ...props
}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 32 32"
    fill="currentColor"
    className={className}
    {...props}
  >
    <path d="M28.8772 23.4V25.7C28.8772 26.9687 27.8449 28 26.5772 28H6.79719C4.76767 28 3.11719 26.3495 3.11719 24.32C3.11719 24.32 3.11719 8.6938 3.11719 8.68C3.11719 6.65048 4.76767 5 6.79719 5H23.8172C24.5799 5 25.1972 5.61824 25.1972 6.38C25.1972 7.14176 24.5799 7.76 23.8172 7.76H6.79719C6.29027 7.76 5.87719 8.17216 5.87719 8.68C5.87719 9.18784 6.29027 9.6 6.79719 9.6H26.5772C27.8449 9.6 28.8772 10.6313 28.8772 11.9V14.2H24.2772C21.7407 14.2 19.6772 16.2636 19.6772 18.8C19.6772 21.3364 21.7407 23.4 24.2772 23.4H28.8772Z" />
    <path d="M28.8756 16.0391V21.5591H24.2756C22.7512 21.5591 21.5156 20.3235 21.5156 18.7991C21.5156 17.2746 22.7512 16.0391 24.2756 16.0391H28.8756Z" />
  </svg>
);

export default WalletSymbol;
