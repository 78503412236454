import React from 'react';

const MenuSymbol = () => {
  return (
    <svg viewBox="0 0 100 50" width="30" height="30" className="fill-current">
      <rect y="0" width="100" height="10"></rect>
      <rect y="25" width="100" height="10"></rect>
      <rect y="50" width="100" height="10"></rect>
    </svg>
  );
};

export default MenuSymbol;
