import React from 'react';

const BankTransferSymbol = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 244 172"
    fill="currentColor"
    className="color-4"
  >
    <path d="M.333 122.5V86h36.5V61.667L85.5 104.25l-48.666 42.583V122.5H.334zm243.334-76.65v15.817h-146V45.85l73-45.017 73 45.017zm-146 100.983h146v24.334h-146v-24.334zm60.833-73h24.334v60.834H158.5V73.833zm-48.666 0h24.333v60.834h-24.333V73.833zm97.333 0H231.5v60.834h-24.333V73.833z" />
  </svg>
);

export default BankTransferSymbol;
