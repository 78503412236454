import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Amplitude, LogOnMount } from 'react-amplitude-hooks';
import { ROUTES } from 'Router/Routes';
import AssetCard from './AssetCard/AssetCard';
import Header from 'Components/shared/Header';
import SignupReminder from 'Features/Signup/SignupReminder';
import { AuthContext } from 'States/auth/authState';
import { EnvContext } from 'States/env/envState';
import { CartContext } from 'States/cart/cartState';
import i18nextTranslate from 'Lang/i18nextTranslate';
import { i18nextKeys } from 'Lang/i18nextKeys';
import useHandleError from 'Utils/handleError';
import { UiContext } from 'States/ui/uiState';
import { ConfigContext } from 'States/config/configState';
import useKycTierCheck from 'Hooks/useKycTierCheck';
import api from 'Api';
import CartButton from './CartButton';

const Purchase = () => {
  const handleError = useHandleError();
  const history = useHistory();
  const {
    breakpoints: { md, xxl },
  } = useContext(UiContext);
  const { isActive, isAuthenticated, isBlocked } = useContext(AuthContext);
  const { env } = useContext(EnvContext);
  const { cartItems, loadCart, loadingCart } = useContext(CartContext);
  const [assets, setAssets] = useState([]);
  const {
    config: { kycTierFunctionalRestrictions },
  } = useContext(ConfigContext);
  const { isUserTierSufficient } = useKycTierCheck(
    kycTierFunctionalRestrictions?.visibility?.purchase
  );

  useEffect(() => {
    if (isActive) {
      if (!isUserTierSufficient) history.replace(ROUTES.welcome);
      loadCart();
    }
  }, [isActive]);

  useEffect(() => {
    const loadAssets = async () => {
      try {
        const { value: assetsConfig } = await api.Assets.getAssetsConfig(env.TenantId);
        const { value: assetsData } = await api.Assets.getAssets(env.TenantId);
        const assets = assetsConfig.map(assetConfig => {
          const assetData = assetsData.find(asset => asset.Id === assetConfig.Id);
          return { ...assetConfig, ...assetData };
        });
        setAssets(assets || []);
      } catch (error) {
        const message = i18nextTranslate(
          i18nextKeys.errorPurchaseAssetLoadingError
        );
        handleError({ error, message });
      }
    };
    loadAssets();
  }, []);

  return (
    <Amplitude
      eventProperties={{
        scope: 'purchase',
      }}
    >
      <LogOnMount eventType="Shop opened">
        <div data-qa="purchase">
          <Header
            text={i18nextTranslate(i18nextKeys.headerShop)}
            wrapChildren={false}
          >
            {isActive && (
              <CartButton
                itemsCount={Object.keys(cartItems).length}
                isLoading={loadingCart}
              />
            )}
          </Header>
          <div
            className="grid row-gap-32 col-gap-40 justify-center xl:px-24"
            style={{
              gridTemplateColumns: md
                ? `repeat(auto-fit, ${xxl ? '206px' : '188px'})`
                : 'repeat(auto-fit, 100%)',
              gridAutoRows: '1fr',
            }}
          >
            {assets.map((asset, index) => (
              <AssetCard
                key={asset.Id}
                dataQa={`purchase-assetCard-${index}`}
                asset={asset}
              />
            ))}
            {isAuthenticated && !isActive && !isBlocked && (
              <SignupReminder
                text={i18nextTranslate(i18nextKeys.signupNotificationPurchase)}
              />
            )}
          </div>
        </div>
      </LogOnMount>
    </Amplitude>
  );
};

export default Purchase;
