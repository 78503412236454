import React from 'react';
import Text from 'Components/shared/Text';

const ConfigDropdown = ({
  options,
  dropdownOpen = false,
  selectOption = () => {},
  dataQa
}) => {

  return Object.keys(options).length ? (
    <div className="dropdown relative">
      <div
        data-qa={`${dataQa}-sections`}
        className={`${
          dropdownOpen ? 'block' : 'hidden'
        } absolute top-8 py-6 bg-3-5 border border-color-4_25 rounded text-black w-max`}
        style={{ top: "8px", left: '-16px' }}
      >
        {Object.keys(options).map((key) => (
          <Text
            key={key}
            textStyle="h3"
            color="color-6 hover:color-8"
            dataQa={`${dataQa}-${key}`}
            className="dropdown-item cursor-pointer px-16 py-6"
            onClick={() => selectOption(options[key])}
          >
            {options[key]}
          </Text>
        ))}
      </div>
    </div>
  ) : null;
};

export default ConfigDropdown;
