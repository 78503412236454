import React, { useContext } from 'react';
import { UiContext } from 'States/ui/uiState';
import { MenuSymbol } from 'Components/shared/symbols';

const BurgerMenu = ({
  className,
  symbolColor = 'color-7',
  colorClass = 'bg-4--20',
  rounded = 'rounded-full',
  style = {},
}) => {
  const {
    smallScreenSidebarShowing,
    setSmallScreenSidebarShowing,
  } = useContext(UiContext);

  return (
    <span
      className={`
        ${className}
        ${symbolColor} cursor-pointer md:ml-0
        flex items-center justify-center ${colorClass} ${rounded}
      `}
      onClick={() => setSmallScreenSidebarShowing(!smallScreenSidebarShowing)}
      style={style}
    >
      <MenuSymbol />
    </span>
  );
};

export default BurgerMenu;
