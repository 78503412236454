import React, { useContext, useCallback, useMemo, useEffect } from 'react';
import { useAmplitude } from 'react-amplitude-hooks';
import Big from 'big.js';
import { getTranslation } from 'Helpers/translations';
import { useFormatNumber } from 'Utils/formatNumber';
import { Chip } from 'Components/shared/Chip';
import { NumberInput } from 'Components/shared/formElements';
import { CartContext } from 'States/cart/cartState';
import { LangContext } from 'States/lang/langState';
import i18nextTranslate from 'Lang/i18nextTranslate';
import { i18nextKeys } from 'Lang/i18nextKeys';
import DescriptionTableRow from './components/DescriptionTableRow';
import TableRowWrapper from './components/TableRowWrapper';
import MobileActionButtons from './components/MobileActionButtons';
import DesktopActionButtons from './components/DesktopActionButtons';
import { UiContext } from 'States/ui/uiState';
import usePurchaseMethods from 'Hooks/usePurchaseMethods';

const ItemRow = ({
  uniqueAssetId,
  defaultLanguage,
  currency,
  index,
  toggleRow,
  rowExpanded,
  assetUnavailable = false,
  setQuantitiesValidity
}) => {
  const {
    cartItems,
    fullAssetInfo,
    removeFromCart,
    setQuantity,
    pendingCartOperation
  } = useContext(CartContext);
  const { lang } = useContext(LangContext);

  const {
    Name,
    Description,
    UnitOfMeasureCode,
    CurrencyCode,
    Translations,
    maxPurchasableUnits
  } = fullAssetInfo[uniqueAssetId];

  useEffect(() => {
    if (assetUnavailable || cartItems[uniqueAssetId].Units < maxPurchasableUnits) {
      return;
    }
    setQuantity(uniqueAssetId, maxPurchasableUnits);
  }, [uniqueAssetId]);

  const formatNumber = useFormatNumber();
  const {
    breakpoints: { md, lg, xxl }
  } = useContext(UiContext);
  const { logEvent } = useAmplitude((inheritedProps) => ({
    ...inheritedProps,
  }));

  const handleRemoveAsset = useCallback(() => {
    removeFromCart(uniqueAssetId);
    logEvent('Cart changed');
  }, [removeFromCart, uniqueAssetId]);

  const handleChangeValue = (value) => {
    if (assetUnavailable) {
      return;
    }
    setQuantity(uniqueAssetId, value, true);
    logEvent("Amount changed_add to cart", {
      amount: value
    });
  };

  const translatedName = useMemo(() => getTranslation(
    "Name", lang, defaultLanguage, Translations
  ) || Name, [lang]);

  const translatedDescription = useMemo(() => getTranslation(
    "Description", lang, defaultLanguage, Translations
  ) || Description, [lang]);

  const { data: currentPrice } = usePurchaseMethods.purchaseMethodsQuery({
    enabled: false,
    select: useCallback(({ value }) => {
      const price = value.find((asset) => asset.Id === uniqueAssetId)?.Price;
      return price;
    }, [])
  });

  const totalAmount = useMemo(() => {
    return assetUnavailable
      ? `0 ${currency}`
      : `${formatNumber(Big(currentPrice).times(cartItems[uniqueAssetId]?.Units || 0).toNumber())} ${currency}`;
  }, [assetUnavailable, cartItems, uniqueAssetId, currency, currentPrice]);

  const itemPrice = useMemo(() => `1 ${
      UnitOfMeasureCode || CurrencyCode || translatedName
    } = ${formatNumber(currentPrice)} ${currency}`,
    [UnitOfMeasureCode, translatedName, CurrencyCode, currency, currentPrice]
  );

  const amount = assetUnavailable ? 0 : (cartItems[uniqueAssetId]?.Units || 0);

  const maxPossibleAmount = useMemo(() => `${formatNumber(maxPurchasableUnits)} ${
    UnitOfMeasureCode || CurrencyCode || translatedName
  }`, [maxPurchasableUnits, UnitOfMeasureCode, CurrencyCode, translatedName]);

  const badgeTitle = useMemo(() => {
    if (assetUnavailable) {
      return i18nextTranslate(i18nextKeys.cartBadgeSoldOut);
    }
    const isMaxAmount = Number(amount) === Number(maxPurchasableUnits);
    if (isMaxAmount) {
      return i18nextTranslate(i18nextKeys.cartBadgeMaxQuantity);
    }
    return null;
  }, [amount]);

  const setValidity = (validity) => {
    setQuantitiesValidity((state) => {
      return {
        ...state,
        [uniqueAssetId]: validity
      }
    })
  }

  return (
    <>
      <TableRowWrapper
        dataQa={`cart${assetUnavailable ? '-unavailable' : ''}-asset-${index}`}
        index={index}
      >
        <td className={`pl-16 text-sm py-12 ${md ? 'h-full' : 'py-12 h-104'} ${!md ? 'w-4/6' : ''}`}>
          {
            md ? (
              <span className="flex flex-row gap-12 xxl:gap-16 items-center text-left">
                {badgeTitle && (
                  <Chip
                    text={badgeTitle}
                    dataQa={`chip-${index}`}
                    width={xxl ? "104px" : "90px"}
                  />
                )}
                <span className='xxl:text-sm'>{translatedName}</span>
              </span>
            ) : (
              <div className="flex flex-col h-full justify-between gap-6">
                <span>{translatedName}</span>
                <div className="flex flex-col gap-12">
                  {badgeTitle && (
                    <Chip
                      text={badgeTitle}
                      dataQa={`chip-${index}`}
                      width="90px"
                    />
                  )}
                  {!assetUnavailable && (
                    <span className='text-sm'>
                      <span className='text-xs font-bold'>
                        {i18nextTranslate(i18nextKeys.commonTotal)}:{' '}
                      </span>
                      {totalAmount}
                    </span>
                  )}
                </div>
              </div>
            )
          }
        </td>
        {lg && (
          <td className="px-12 py-8 w-1/6 text-right text-sm xxl:color-8">
            {itemPrice}
          </td>
        )}
        <td className={`py-12 w-1/4 md:w-1/3 ${!md && 'align-top'}`}>
          <div className="flex flex-col md:flex-row gap-4 md:gap-12 md:items-center">
            <NumberInput
              dataQa={`${assetUnavailable ? 'unavailable-' : ''}asset-row-${index}-input`}
              style={{
                maxWidth: '105px',
                height: xxl ? '30px' : '28px'
              }}
              onChange={handleChangeValue}
              value={amount}
              max={maxPurchasableUnits}
              disabled={assetUnavailable}
              readOnly={pendingCartOperation}
              setValidity={setValidity}
              textRight
              showOwnErrors
            />
            {!assetUnavailable ? (
              <span className="md:flex md:flex-col text-xs leading-tight overflow-hidden">
                <span className="font-bold text-left">
                  {`${i18nextTranslate(i18nextKeys.commonMax)}:`}
                </span>{' '}
                <span
                  className="text-left overflow-hidden"
                  style={{ textOverflow: 'ellipsis' }}
                >
                  {maxPossibleAmount}
                </span>
              </span>
            ) : null}
          </div>
        </td>
        {md ? (
          <td className="pl-8 py-8 pr-24 text-sm text-right w-1/5 xxl:color-8">
            {totalAmount}
          </td>
        ) : null}
        {!md ? (
          <MobileActionButtons
            toggleRow={toggleRow}
            handleRemoveAsset={handleRemoveAsset}
            rowExpanded={rowExpanded(uniqueAssetId)}
          />
        ) : (
          <DesktopActionButtons
            toggleRow={toggleRow}
            handleRemoveAsset={handleRemoveAsset}
            rowExpanded={rowExpanded(uniqueAssetId)}
          />
        )}
      </TableRowWrapper>
      <TableRowWrapper
        dataQa={`cart${assetUnavailable ? '-unavailable' : ''}-asset-${index}-description`}
        rowExpanded={rowExpanded(uniqueAssetId)}
        index={index}
      >
        <DescriptionTableRow
          showConversionRate={!lg}
          unitOfMeasure={UnitOfMeasureCode}
          currencyCode={CurrencyCode}
          description={translatedDescription}
          name={translatedName}
          price={currentPrice}
          currency={currency}
        />
      </TableRowWrapper>
    </>
  );
};

export default ItemRow;
