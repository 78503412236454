import React from 'react';
import i18nextTranslate from 'Lang/i18nextTranslate';
import { i18nextKeys } from 'Lang/i18nextKeys';
import { Chip } from '../Chip';

const FilterButton = ({
  onClick = () => { },
  label,
  dataQa = ''
}) => (
  <Chip
    dataQa={`${dataQa}-filter-${label}`}
    onRemove={onClick}
    text={
      <>
        {(() => {
          switch (label) {
            case 'Open':
              return i18nextTranslate(i18nextKeys.kycManagementDetailsOpen);
            case 'Approved':
              return i18nextTranslate(i18nextKeys.kycManagementDetailsApproved);
            case 'Declined':
              return i18nextTranslate(i18nextKeys.kycManagementDetailsDeclined);
            default:
              return label;
          }
        })()}
      </>
    }
    removable
  />
);

export default FilterButton;
