import { KYC_PROVIDER } from 'Enums/KycProvider';
import { ShuftiProVerificationState } from 'Enums/KycVerificationState';

const getShuftiProVerificationState = (verification) => {
  return ShuftiProVerificationState.enumToFriendlyString(verification.Status);
};

const getVerificationState = (verification) => {
  if (!verification || !verification.Status) {
    return '-';
  }
  switch (verification.Provider) {
    case KYC_PROVIDER.SHUFTI_PRO:
    case KYC_PROVIDER.manual: {
      return getShuftiProVerificationState(verification);
    }
    default: {
      return '-';
    }
  }
};

export default getVerificationState;
