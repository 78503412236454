import React from "react";
import { copyToClipboardAndNotify } from 'Utils/utils';
import Text from "./Text";

const LabeledValue = ({
  label,
  value,
  defaultValue = "-",
  dataQa,
  style = {},
  column = false,
  copyOnClick = false
}) => {
  return (
    <div
      className={`flex ${
        column ? 'flex-col gap-4' : 'flex-row gap-8 flex-wrap'
      } color-8`}
      style={style}
      onClick={() => {
        if (copyOnClick && value) {
          copyToClipboardAndNotify(value, label);
        }
      }}
    >
      <Text
        textStyle="p2"
        dataQa={`${dataQa}-label`}
      >
        {`${label}:`}
      </Text>
      <Text dataQa={`${dataQa}-value`}>
        {value || defaultValue}
      </Text>
    </div>
  );
};

export default LabeledValue;
