import React from 'react';

const toggleSymbol = ({ ...props }) => {
  return (
    <svg
      width="30"
      height="16"
      viewBox="0 0 30 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="fill-current"
      {...props}
    >
      <path d="M22.0605 0.0605469H7.93945C3.56162 0.0605469 0 3.62217 0 8C0 12.3778 3.56162 15.9395 7.93945 15.9395H22.0605C26.4384 15.9395 30 12.3778 30 8C30 3.62217 26.4384 0.0605469 22.0605 0.0605469ZM22.0605 14.1816C18.652 14.1816 15.8789 11.4086 15.8789 8C15.8789 4.59148 18.652 1.81836 22.0605 1.81836C25.4691 1.81836 28.2422 4.59148 28.2422 8C28.2422 11.4086 25.4691 14.1816 22.0605 14.1816Z" />
    </svg>
  );
};

export default toggleSymbol;
