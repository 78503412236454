import React, { useContext, useMemo, useState } from "react";
import { useHistory, generatePath } from 'react-router-dom';
import { useAmplitude } from 'react-amplitude-hooks';
import { DefaultCard } from 'Components/shared/cards';
import InfoModal from 'Components/shared/Modal/InfoModal';
import {
  BankTransferSymbol,
  BitcoinSymbol,
  EthereumSymbol,
  ForkliftSymbol,
  Erc20Symbol,
} from 'Components/shared/symbols';
import {
  BANK_TRANSFER,
  BITCOIN,
  ETH,
  PHYSICAL,
  ERC20,
} from 'Enums/RedemptionMethodTypes';
import { getTranslation } from 'Helpers/translations';
import { i18nextKeys } from 'Lang/i18nextKeys';
import i18nextTranslateDynamically from 'Lang/i18nextTranslateDynamically';
import { ConfigContext } from 'States/config/configState';
import { EnvContext } from 'States/env/envState';
import { LangContext } from 'States/lang/langState';
import { UiContext } from 'States/ui/uiState';
import { ROUTES } from 'Router/Routes';
import RedeemMethodSelectionButtonGroup from './RedeemMethodSelectionButtonGroup';

const RedemptionMethodCard = ({ redemptionMethod, defaultLanguageCode }) => {
  const {
    breakpoints: { md }
  } = useContext(UiContext);
  const { env } = useContext(EnvContext);
  const history = useHistory();
  const { logEvent } = useAmplitude();
  const { lang } = useContext(LangContext);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const {
    config: { kycTierFunctionalRestrictions },
  } = useContext(ConfigContext);

  const requiredTier = Math.max(
    kycTierFunctionalRestrictions?.availability?.redemption || 0,
    redemptionMethod.kycTier || 0
  );

  const icons = {
    [BANK_TRANSFER]: BankTransferSymbol,
    [BITCOIN]: BitcoinSymbol,
    [ETH]: EthereumSymbol,
    [ERC20]: Erc20Symbol,
    [PHYSICAL]: ForkliftSymbol
  };

  const Icon = icons[redemptionMethod.type];

  const translatedAssetName = useMemo(() => {
    if (redemptionMethod.userPays.initialTranslation.LanguageCode === lang) {
      return redemptionMethod.userPays.initialTranslation.Name;
    }
    return getTranslation("Name", lang, defaultLanguageCode, redemptionMethod.userPays.Translations) ||
      redemptionMethod.userPays.initialTranslation.Name
  }, [lang]);

  const openModal = () => {
    setShowInfoModal(true);
    logEvent("Redemption details checked", {
      "redemption method type": type
    });
  };

  const goToForm = () => history.push(
    generatePath(ROUTES.redemptionForm, { id: redemptionMethod.id })
  );

  return (
    <>
      <DefaultCard
        dataQa={`redeem-method-${redemptionMethod.id}`}
        name={redemptionMethod.name}
        description={i18nextTranslateDynamically(
          i18nextKeys.redeemMethodSelectionPayWith,
          { userPaysName: translatedAssetName }
        )}
        iconUrl={
          redemptionMethod.icon && `${env.BlobUrl}/${redemptionMethod.icon}`
        }
        svg={!redemptionMethod.icon && <Icon size={md ? '100px' : '50px'} />}
        buttonGroup={() =>
          RedeemMethodSelectionButtonGroup({
            kycTier: requiredTier,
            openModal,
            goToForm
          })
        }
      />
      {showInfoModal && (
        <InfoModal
          show={showInfoModal}
          close={() => setShowInfoModal(false)}
          content={{
            title: redemptionMethod.name,
            description: redemptionMethod.description,
            icon: redemptionMethod.icon
            ? `${env.BlobUrl}/${redemptionMethod.icon}`
            : <Icon size="60%" />
          }}
          actionButton={{
            onClick: goToForm
          }}
          dataQa="redemption-method-infoModal"
        />
      )}
    </>
  );
};

export default RedemptionMethodCard;
