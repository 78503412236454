import React from 'react';
import { DIRECTION } from 'Helpers/icons';

const Arrow = ({
  size = "14",
  direction = DIRECTION.right,
  className,
  ...props
}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 14 14"
    fill="currentColor"
    className={className}
    transform={`rotate(${direction})`}
    {...props}
  >
    <path d="M3.07757 6.40999L9.52906 6.40999L6.71051 3.51565C6.48525 3.28434 6.48525 2.90476 6.71051 2.67345C6.76394 2.61847 6.82741 2.57485 6.89728 2.54508C6.96715 2.51532 7.04205 2.5 7.1177 2.5C7.19334 2.5 7.26824 2.51532 7.33811 2.54508C7.40798 2.57485 7.47145 2.61847 7.52489 2.67345L11.3311 6.58199C11.3846 6.63686 11.4271 6.70203 11.4561 6.77378C11.4851 6.84553 11.5 6.92245 11.5 7.00013C11.5 7.0778 11.4851 7.15472 11.4561 7.22647C11.4271 7.29822 11.3846 7.36339 11.3311 7.41826L7.52489 11.3268C7.47141 11.3817 7.40793 11.4253 7.33807 11.455C7.2682 11.4847 7.19332 11.5 7.1177 11.5C7.04207 11.5 6.96719 11.4847 6.89733 11.455C6.82746 11.4253 6.76398 11.3817 6.71051 11.3268C6.65703 11.2719 6.61462 11.2067 6.58568 11.135C6.55674 11.0632 6.54184 10.9863 6.54184 10.9087C6.54184 10.831 6.55674 10.7541 6.58568 10.6824C6.61462 10.6106 6.65703 10.5454 6.71051 10.4905L9.52906 7.59619L3.07757 7.59619C2.75991 7.59619 2.5 7.3293 2.5 7.00309C2.5 6.67689 2.75991 6.40999 3.07757 6.40999Z" />
  </svg>
);

export default Arrow;
