import React from 'react';

const SortIcon = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 14 14"
    fill="currentColor"
  >
    <path d="M6.99967 14C6.88019 14 6.76073 13.952 6.66963 13.8561L3.80311 10.8389C3.62076 10.6469 3.62076 10.3358 3.80311 10.1439C3.98539 9.95204 4.28097 9.95204 4.46334 10.1439L6.99967 12.8137L9.53602 10.144C9.71837 9.95213 10.0139 9.95213 10.1962 10.144C10.3786 10.3358 10.3786 10.647 10.1962 10.839L7.32971 13.8562C7.23858 13.9521 7.11911 14 6.99967 14Z" />
    <path d="M6.99967 0C6.88019 0 6.76073 0.0480187 6.66963 0.143855L3.80311 3.16112C3.62076 3.35306 3.62076 3.66425 3.80311 3.85611C3.98539 4.04796 4.28097 4.04796 4.46334 3.85611L6.99967 1.18626L9.53602 3.85601C9.71837 4.04787 10.0139 4.04787 10.1962 3.85601C10.3786 3.66416 10.3786 3.35296 10.1962 3.16103L7.32971 0.143761C7.23857 0.04791 7.11911 0 6.99967 0Z" />
  </svg>
);

export default SortIcon;
