import React from "react";
import { Button, TextButton } from "Components/shared/buttons";
import Text from "Components/shared/Text";
import Tooltip from "Components/shared/Tooltip";
import SubsectionHeading from "../shared/SubsectionHeading";

const Footer = ({
  title,
  description,
  tooltipText,
  primaryButtonText,
  primaryButtonTooltipText,
  primaryButtonWidth = {
    xxl: "383px",
    default: "281px"
  },
  onPrimaryButtonClick = () => { },
  primaryButtonDisabled = false,
  primaryButtonLoading = false,
  secondaryButtonText,
  secondaryButtonTooltipText,
  onSecondaryButtonClick = () => { },
  secondaryButtonDisabled = false,
  dataQa = "settings"
}) => {
  return (
    <div className="sticky bottom-0 flex items-center justify-between gap-16 pl-32 pr-16 rounded-b bg-3-10">
      <div className="flex flex-col gap-4 py-12">
        <SubsectionHeading
          text={title}
          tooltipText={tooltipText}
          color="color-8"
          className=""
          dataQa=""
        />
        <Text
          textStyle="text-small"
          dataQa={`${dataQa}-footer-description`}
        >
          {description}
        </Text>
      </div>
      <div className="flex flex-shrink-0 gap-24 py-16">
        <Tooltip
          text={secondaryButtonTooltipText}
          childClassName="flex items-center"
          disableHoverListener={!secondaryButtonTooltipText}
        >
          <TextButton
            text={secondaryButtonText}
            // type="submit"
            onClick={onSecondaryButtonClick}
            disabled={secondaryButtonDisabled}
            dataQa={`${dataQa}-footer-button-secondary`}
          />
        </Tooltip>
        <Tooltip
          text={primaryButtonTooltipText}
          disableHoverListener={!primaryButtonTooltipText}
        >
          <Button
            text={primaryButtonText}
            // type="submit"
            onClick={onPrimaryButtonClick}
            disabled={primaryButtonDisabled}
            loading={primaryButtonLoading}
            width={primaryButtonWidth}
            dataQa={`${dataQa}-footer-button-primary`}
          />
        </Tooltip>
      </div>
    </div>
  );
};

export default Footer;
