import React, { useEffect, useState, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Fade from 'Components/shared/Fade';
import LoadingSpinner from 'Components/shared/LoadingSpinner';
import { Button } from 'Components/shared/buttons';
import { CartContext } from 'States/cart/cartState';
import api from 'Api';
import i18nextTranslate from 'Lang/i18nextTranslate';
import { i18nextKeys } from 'Lang/i18nextKeys';
import BankDetailsInfo from '../CheckoutPayment/PaymentMethods/BankDetailsInfo';
import AmountAndReference from '../AmountAndReference';
import CheckoutTimer from '../CheckoutTimer';
import useHandleError from 'Utils/handleError';
import { LogOnMount } from 'react-amplitude-hooks';
import codeToCountry from 'Utils/country-codes';
import { COLLECT_METHOD } from 'Enums/CollectMethod';
import { STORAGE_KEYS } from 'Enums';
import { ROUTES } from 'Router/Routes';

const CompletionBankTransfer = ({ restrictedMode = false }) => {
  const handleError = useHandleError();
  const history = useHistory();
  const location = useLocation();
  const { emptyCart } = useContext(CartContext);

  const [loading, setLoading] = useState(true);
  const [shippingAddress, setShippingAddress] = useState(null);

  const order = location?.state?.order || {
    ...JSON.parse(sessionStorage.getItem(STORAGE_KEYS.order)),
  };
  const paymentMethod = location?.state?.paymentMethod;
  const defaultLanguage = location?.state?.defaultLanguage;

  useEffect(() => {
    if (order && Object.keys(order).length !== 0 &&
      !!paymentMethod &&
      !!defaultLanguage
    ) {
      finishCheckout();
    } else {
      history.replace(
        restrictedMode
          ? ROUTES.featuredAssetBase
          : {
              pathname: ROUTES.cart,
              state: {},
            }
      );
    }
  }, []);

  const finishCheckout = async () => {
    try {
      setLoading(true);
      sessionStorage.removeItem(STORAGE_KEYS.order);
      if (order.CollectMethod === COLLECT_METHOD.TokenCard) {
        const { ShippingAddress } = await api.PurchaseOrder.details(order.Id, [
          'ShippingAddress',
        ]);
        setShippingAddress(ShippingAddress);
      }
      emptyCart();
      setLoading(false);
    } catch (error) {
      const message = i18nextTranslate(
        i18nextKeys.errorCartCheckoutPaymentOrderUnsuccessful
      );
      handleError({ error, message });
    }
  };

  const toOrdersList = () => history.replace(ROUTES.orders);
  const toHomePage = () => history.replace(ROUTES.base)

  const buttonWidth = {
    xxl: '190px',
    md: '170px',
    default: '156px'
  };

  return (
    <LogOnMount eventType="Order completed">
      <Fade show={true}>
        {loading ? (
          <div className="flex justify-center">
            <LoadingSpinner />
          </div>
        ) : (
          <div
            data-qa="checkout-completion-tokenCard"
            className="flex flex-col items-start color-8"
          >
            <div className="bg-4_10 rounded w-full mb-32 p-16">
              <CheckoutTimer
                startDateISO={order?.ReceivedOn}
                durationISO={order?.timeouts?.BankTransferTimeoutAfter}
                translationKey={i18nextKeys.checkoutTimerBankTransferMessage}
                showDaysLeft
              />
            </div>
            <div className="flex flex-col-reverse md:flex-row justify-between mb-32 md:mb-40 w-full xl:w-auto xl:gap-48">
              <div className="mt-24 md:mt-0">
                <p className="text-xl font-semibold color-4 mb-24 md:mb-12 xxl:text-2xl">
                  {i18nextTranslate(i18nextKeys.checkoutCompletionHeader)}
                </p>
                <p className="text-sm font-bold mb-16 xxl:color-8">
                  {i18nextTranslate(shippingAddress
                    ? i18nextKeys.checkoutCompletionAddressTokenCard
                    : i18nextKeys.checkoutCompletionAddressWallet
                  )}
                </p>
                {shippingAddress ? (
                  <ul className="text-sm xxl:color-8">
                    <li>
                      {shippingAddress.FirstName} {shippingAddress.LastName}
                    </li>
                    <li>{shippingAddress.AddressLine}</li>
                    <li>
                      {shippingAddress.ZipCode} {shippingAddress.City}
                    </li>
                    <li>{shippingAddress.State}</li>
                    <li>
                      {i18nextTranslate(codeToCountry[shippingAddress.Country])}
                    </li>
                  </ul>
                ) : (
                  <p className='text-sm xxl:color-8'>
                    {order?.TargetEthAddress}
                  </p>
                )}
              </div>
            </div>
            <p className="font-bold text-sm sm:mb-16 xxl:color-8">
              {i18nextTranslate(
                i18nextKeys.checkoutCompletionTokenCardEnsureDeposit
              )}
            </p>
            <BankDetailsInfo
              paymentMethod={paymentMethod}
              defaultLanguage={defaultLanguage}
            />
            <p className="font-bold text-xs xxl:text-sm mb-4 mt-24">
              {i18nextTranslate(
                i18nextKeys.checkoutPaymentMethodsBankDetailsAmountAndReference
              )}
            </p>
            <AmountAndReference
              amount={order.AmountDue}
              currency={order.AmountDueCurrency}
              reference={order.ReferenceCode}
            />
            <div className="flex w-full justify-between items-center mt-20 md:mt-40">
              {!restrictedMode && (
                <Button
                  dataQa="button-home"
                  text={i18nextTranslate(i18nextKeys.buttonHome)}
                  width={buttonWidth}
                  onClick={toHomePage}
                  tertiary
                />
              )}
              <Button
                dataQa="button-orders"
                text={i18nextTranslate(i18nextKeys.checkoutFinishButton)}
                width={buttonWidth}
                onClick={toOrdersList}
                secondary
              />
            </div>
          </div>
        )}
      </Fade>
    </LogOnMount>
  );
};

export default CompletionBankTransfer;
