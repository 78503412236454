import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import amplitude from 'amplitude-js';
import { AmplitudeProvider } from 'react-amplitude-hooks';
import './index.css';
import App from './App';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import queryClients from 'Helpers/queryClients';
import * as serviceWorker from './serviceWorker';
import EnvState from './States/env/envState';
import ConfigState from './States/config/configState';
import AuthState from './States/auth/authState';
import UiState from './States/ui/uiState';
import LangState from './States/lang/langState';
import './Lang/i18nextConfig';
import tailwindBreakpoints from './Assets/tailwind-breakpoints.json';

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClients.rootQueryClient}>
      <BrowserRouter>
        <EnvState>
          <LangState>
            <AmplitudeProvider
              amplitudeInstance={amplitude.getInstance()}
              apiKey="770a6c7dd6d12d7c1b23bc2ae05d0f48"
              config={{
                deferInitialization: true,
              }}
            >
              <ConfigState>
                <AuthState>
                  <UiState screens={tailwindBreakpoints}>
                    <App />
                  </UiState>
                </AuthState>
              </ConfigState>
            </AmplitudeProvider>
          </LangState>
        </EnvState>
      </BrowserRouter>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
