const linkify = (inputText) => {
  let replacedText;

  // markdown URLs starting with http://, https://, or ftp://
  const markdownLink1 = /\[(.+)\]\s?\((\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])\)/gim;
  replacedText = inputText.replace(markdownLink1, "<a href=\"$2\" target=\"_blank\">$1</a>");

  // URLs starting with http://, https://, or ftp://
  const urlLink1 = /(href=\")?(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])(<\/a>)?/gim;
  replacedText = replacedText.replace(urlLink1, (match, g1, g2, g3, g4) => {
    if (g1 || g4) {
      return match;
    }
    return `<a href=\"${g2}\" target=\"_blank\">${g2}</a>`
  });

  // markdown URLs starting with "www."
  const markdownLink2 = /\[(.+)\]\s?\((www\.[\S]+(\b|$))\)/gim;
  replacedText = replacedText.replace(markdownLink2, "<a href=\"http://$2\" target=\"_blank\">$1</a>");

  // markdown with local links starting with slash, e.g. /about
  const markdownLink3 = /\[(.+)\]\s?\((\/[A-Za-z-]+(\b|$))\)/gim;
  replacedText = replacedText.replace(markdownLink3, "<a href=\"$2\" target=\"_blank\">$1</a>");
  
  // URLs starting with "www." (without // before it, or it'd re-link the ones done above)
  const urlLink2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
  replacedText = replacedText.replace(urlLink2, "$1<a href=\"http://$2\" target=\"_blank\">$2</a>");

  // Change email addresses to mailto: links
  const email = /(([a-zA-Z0-9\-\_\.])+@[a-zA-Z\_]+?(\.[a-zA-Z]{2,6})+)/gim;
  replacedText = replacedText.replace(email, '<a href="mailto:$1">$1</a>');

  return replacedText;
}

export default linkify;
