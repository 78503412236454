import React from 'react';

const CryptoSymbol = ({
  size = "40",
  className = "color-4"
}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 40 40"
    fill="currentColor"
    className={className}
  >
    <g>
      <path d="M29.4396 10.9328C30.2083 10.4995 30.6237 9.63208 30.4805 8.76195C30.3026 7.41857 28.7012 6.91142 27.4378 6.80469V4.78516H26.1923V6.78691H25.356V4.78516H24.1105V6.78691H21.6016V8.07695H22.5357C22.9539 8.07695 23.1585 8.2015 23.1585 8.53956V14.0822C23.1585 14.5448 22.9272 14.6872 22.6781 14.6872H21.7261V16.0039H24.146V18.0502H25.4005V16.0039H26.2367V18.0502H27.4556V16.0039H27.7225C30.2847 16.0039 31.1388 14.7495 31.1388 13.0413C31.1184 12.0342 30.42 11.1676 29.4396 10.9328ZM25.356 8.15701H26.2456C26.8684 8.15701 28.0961 8.26378 28.0961 9.27799C28.1388 9.93991 27.6593 10.52 27.0018 10.6036H25.356V8.15701ZM26.7349 14.5626V14.5537H25.356V11.8847H26.9574C27.331 11.8847 28.7367 12.0092 28.7367 13.0057C28.7367 14.0021 28.0695 14.5626 26.7349 14.5626Z" />
      <path d="M9.16707 24.6235V17.29L5.6543 23.3223L9.16707 24.6235Z" />
      <path d="M9.80469 25.2984V28.1457L13.3654 23.9795L9.80469 25.2984Z" />
      <path d="M9.80469 17.29V24.6235L13.3175 23.3223L9.80469 17.29Z" />
      <path d="M9.17581 25.2984L5.61523 23.9795L9.17581 28.1457V25.2984Z" />
      <path d="M9.45832 14.4482C7.81748 14.4482 6.2135 14.9348 4.84919 15.8464C3.48489 16.758 2.42154 18.0537 1.79362 19.5696C1.1657 21.0856 1.00141 22.7536 1.32152 24.363C1.64163 25.9723 2.43177 27.4505 3.59201 28.6107C4.75226 29.771 6.2305 30.5611 7.83981 30.8812C9.44911 31.2013 11.1172 31.0371 12.6331 30.4091C14.1491 29.7812 15.4448 28.7179 16.3564 27.3536C17.268 25.9893 17.7545 24.3853 17.7545 22.7444C17.7545 20.5442 16.8805 18.434 15.3246 16.8781C13.7688 15.3223 11.6586 14.4482 9.45832 14.4482V14.4482ZM9.45832 29.6579C8.09095 29.6579 6.7543 29.2525 5.61738 28.4928C4.48046 27.7331 3.59434 26.6534 3.07107 25.3901C2.54781 24.1269 2.4109 22.7368 2.67765 21.3957C2.94441 20.0546 3.60286 18.8227 4.56973 17.8559C5.5366 16.889 6.76847 16.2305 8.10956 15.9638C9.45065 15.697 10.8407 15.8339 12.104 16.3572C13.3673 16.8805 14.447 17.7666 15.2067 18.9035C15.9663 20.0404 16.3718 21.3771 16.3718 22.7444C16.3718 24.578 15.6434 26.3365 14.3469 27.633C13.0504 28.9296 11.2919 29.6579 9.45832 29.6579Z" />
      <path d="M27.1773 20.4785C25.2537 20.4785 23.3732 21.0489 21.7738 22.1177C20.1743 23.1864 18.9277 24.7054 18.1915 26.4826C17.4554 28.2598 17.2628 30.2154 17.6381 32.1021C18.0133 33.9888 18.9397 35.7218 20.2999 37.0821C21.6601 38.4423 23.3932 39.3686 25.2798 39.7439C27.1665 40.1192 29.1221 39.9266 30.8993 39.1904C32.6766 38.4543 34.1956 37.2077 35.2643 35.6082C36.333 34.0087 36.9035 32.1283 36.9035 30.2047C36.9035 27.6251 35.8787 25.1512 34.0547 23.3272C32.2307 21.5032 29.7568 20.4785 27.1773 20.4785V20.4785ZM27.1773 38.3098C25.5743 38.3098 24.0072 37.8344 22.6744 36.9438C21.3415 36.0532 20.3026 34.7874 19.6892 33.3063C19.0757 31.8253 18.9152 30.1957 19.2279 28.6234C19.5407 27.0512 20.3126 25.607 21.4461 24.4735C22.5797 23.34 24.0238 22.568 25.5961 22.2553C27.1683 21.9425 28.798 22.103 30.279 22.7165C31.76 23.33 33.0259 24.3688 33.9165 25.7017C34.8071 27.0346 35.2824 28.6016 35.2824 30.2047C35.2824 32.3543 34.4285 34.4158 32.9085 35.9358C31.3885 37.4558 29.3269 38.3098 27.1773 38.3098Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.8633 11.4856C14.8686 5.13698 20.018 -0.005339 26.3667 4.16011e-06C32.7118 0.00534732 37.8523 5.14942 37.8523 11.4945C37.8462 15.4463 35.8497 18.9305 32.8117 20.999C32.1549 20.598 31.4578 20.2689 30.7327 20.0169C33.8196 18.4257 35.9306 15.2066 35.9306 11.4945C35.9253 6.20458 31.6389 1.91815 26.3489 1.91281C21.0572 1.91281 16.7672 6.20278 16.7672 11.4945C16.7672 15.5128 19.2408 18.9534 22.7482 20.3767C22.2116 20.6193 21.6935 20.9065 21.1995 21.2366C21.0529 21.3346 20.9089 21.436 20.7677 21.5406C17.2425 19.5751 14.8596 15.8084 14.8633 11.4856ZM31.7769 21.6268C30.9429 21.181 30.0467 20.8583 29.1167 20.6704C28.2404 20.9344 27.3112 21.0763 26.3489 21.0763C25.6876 21.0763 25.042 21.0093 24.4184 20.8817C23.5148 21.1505 22.6505 21.5506 21.8542 22.0733C22.5413 22.3661 23.2628 22.594 24.0109 22.7492C24.523 22.5307 25.0588 22.3645 25.6103 22.2548C26.9718 21.984 28.3764 22.068 29.6902 22.4938C30.419 22.2725 31.1172 21.9808 31.7769 21.6268Z"
      />
      <path d="M23.7977 33.4255H31.0047V31.7293H26.3645L27.0897 30.0332H30.1585V28.337H27.8153L28.7309 26.1957L27.1752 25.5273L25.9738 28.337H23.3887V30.0332H25.2483L23.7977 33.4255Z" />
    </g>
  </svg>
);

export default CryptoSymbol;
