import React from 'react';

const UserProfileIcon = ({ size, dataQa }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 60 60"
    fill="currentColor"
    data-qa={dataQa}
  >
    <path d="M54.7266 7.14844H5.27344C2.36566 7.14844 0 9.5141 0 12.4219V47.5781C0 50.4859 2.36566 52.8516 5.27344 52.8516H54.7266C57.6343 52.8516 60 50.4859 60 47.5781V12.4219C60 9.5141 57.6343 7.14844 54.7266 7.14844ZM56.4844 47.5781C56.4844 48.5474 55.6958 49.3359 54.7266 49.3359H5.27344C4.30418 49.3359 3.51562 48.5474 3.51562 47.5781V12.4219C3.51562 11.4526 4.30418 10.6641 5.27344 10.6641H54.7266C55.6958 10.6641 56.4844 11.4526 56.4844 12.4219V47.5781Z" />
    <path d="M22.9688 14.1797C14.2407 14.1797 7.03125 21.2609 7.03125 30C7.03125 38.7271 14.2296 45.8203 22.9688 45.8203C31.6902 45.8203 38.7891 38.7219 38.7891 30C38.7891 21.2767 31.6921 14.1797 22.9688 14.1797ZM22.9688 42.3047C20.0933 42.3047 17.4432 41.3316 15.3357 39.7009C16.8859 36.9785 19.7689 35.2734 22.9688 35.2734C26.1533 35.2734 29.0242 36.9621 30.5796 39.6619C28.4839 41.3161 25.8397 42.3047 22.9688 42.3047ZM19.4531 28.2422C19.4531 26.3037 21.0302 24.7266 22.9688 24.7266C24.9073 24.7266 26.4844 26.3037 26.4844 28.2422C26.4844 30.1807 24.9073 31.7578 22.9688 31.7578C21.0302 31.7578 19.4531 30.1807 19.4531 28.2422ZM33.0671 37.0219C31.8274 35.2364 30.1488 33.8298 28.2112 32.9213C29.3227 31.6773 30 30.0376 30 28.2422C30 24.3652 26.8458 21.2109 22.9688 21.2109C19.0917 21.2109 15.9375 24.3652 15.9375 28.2422C15.9375 30.0376 16.6148 31.6773 17.7264 32.9213C15.7637 33.8415 14.0677 35.2736 12.8234 37.0921C11.3902 35.0862 10.5469 32.6389 10.5469 30C10.5469 23.2152 16.1193 17.6953 22.9688 17.6953C29.7536 17.6953 35.2734 23.2152 35.2734 30C35.2734 32.6084 34.457 35.0292 33.0671 37.0219Z" />
    <path d="M51.2109 17.6953H44.0625C43.0917 17.6953 42.3047 18.4823 42.3047 19.4531C42.3047 20.4239 43.0917 21.2109 44.0625 21.2109H51.2109C52.1817 21.2109 52.9688 20.4239 52.9688 19.4531C52.9688 18.4823 52.1817 17.6953 51.2109 17.6953Z" />
    <path d="M51.2109 24.7266H44.0625C43.0917 24.7266 42.3047 25.5136 42.3047 26.4844C42.3047 27.4552 43.0917 28.2422 44.0625 28.2422H51.2109C52.1817 28.2422 52.9688 27.4552 52.9688 26.4844C52.9688 25.5136 52.1817 24.7266 51.2109 24.7266Z" />
    <path d="M51.2109 31.7578H44.0625C43.0917 31.7578 42.3047 32.5448 42.3047 33.5156C42.3047 34.4864 43.0917 35.2734 44.0625 35.2734H51.2109C52.1817 35.2734 52.9688 34.4864 52.9688 33.5156C52.9688 32.5448 52.1817 31.7578 51.2109 31.7578Z" />
    <path d="M51.2109 38.7891H44.0625C43.0917 38.7891 42.3047 39.5761 42.3047 40.5469C42.3047 41.5177 43.0917 42.3047 44.0625 42.3047H51.2109C52.1817 42.3047 52.9688 41.5177 52.9688 40.5469C52.9688 39.5761 52.1817 38.7891 51.2109 38.7891Z" />
  </svg>
);

export default UserProfileIcon;
