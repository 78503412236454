import React, { useContext, useState } from 'react';
import { useAmplitude } from 'react-amplitude-hooks';
import { Form as FinalForm, Field as FinalField } from 'react-final-form';
import AnimateHeight from 'react-animate-height';
import api from 'Api';
import { COLLECT_METHOD } from 'Enums/CollectMethod';
import { ETH_OPTION } from 'Enums/WalletType';
import useHandleError from 'Utils/handleError';
import Modal from 'Components/shared/Modal';
import { CheckboxWithText, FormField } from 'Components/shared/formElements';
import { walletAddressValidator } from 'Components/shared/formElements/validators';
import { isRequiredIfWithMessage } from 'Utils/validators';
import { Button } from 'Components/shared/buttons';
import i18nextTranslate from 'Lang/i18nextTranslate';
import { i18nextKeys } from 'Lang/i18nextKeys';
import { UiContext } from 'States/ui/uiState';

let formRef;

const OneTimeWalletModal = ({
  show,
  close,
  onSelectCollectionMethod,
  content = {}
}) => {
  const [savingWallet, setSavingWallet] = useState(false);
  const handleError = useHandleError();
  const {
    breakpoints: { md, xxl },
  } = useContext(UiContext);
  const { logEvent } = useAmplitude();

  const resetFormAndClose = () => {
    if (savingWallet) {
      return;
    }
    formRef.restart();
    close();
  };

  const confirmOneTimeWallet = async ({ Address, Name, save }) => {
    if (save) {
      setSavingWallet(true);
      try {
        await api.Wallets.create({
          Address,
          Name,
          Type: ETH_OPTION
        });
        setSavingWallet(false);
      } catch (error) {
        handleError({ error });
      }
    }
    logEvent("New wallet provided", {
      saved: save
    });
    onSelectCollectionMethod(
      COLLECT_METHOD.OneTimeWallet,
      { ethereumAddress: Address }
    );
  };

  const buttonWidth = {
    xxl: '190px',
    md: '170px',
    default: '142px'
  };

  const fieldWidth = {
    width: xxl ? "500px" : md ? "404px" : "100%"
  }

  return (
    <Modal
      show={show}
      close={resetFormAndClose}
      dataQa="checkout-collectionMethod-oneTimeWalletModal"
      shouldShowCloseButton={false}
    >
      <FinalForm
        onSubmit={confirmOneTimeWallet}
      >
        {({ handleSubmit, form, pristine, invalid, values }) => {
          formRef = form;
          return (
            <form
              onSubmit={handleSubmit}
              className="flex flex-col py-20 px-16 md:px-24 md:pt-24 md:pb-32"
            >
              <h2 className="color-8 font-extrabold text-xsm uppercase mb-16 xl:mb-24 xxl:color-8">
                {i18nextTranslate(
                  i18nextKeys.collectionMethodInputOneTimeAddress
                )}
              </h2>
              <p
                data-qa="checkout-collectionMethod-detailsModal-text"
                className="color-8 font-normal text-sm text-left xxl:color-8"
              >
                {content.description}
              </p>
              <p
                data-qa="checkout-collectionMethod-detailsModal-text"
                className="color-8 font-normal text-sm text-left mb-24 xxl:color-8"
              >
                {i18nextTranslate(
                  i18nextKeys.collectionMethodInputOneTimeAddressFormat
                )}{' '}
                <span className="text-sm font-bold break-words xxl:color-8">
                  {i18nextTranslate(
                    i18nextKeys.collectionMethodInputOneTimeAddressSampleAddress
                  )}
                </span>
              </p>
              <div className="flex flex-col gap-16 mb-16">
                <div className="flex flex-col md:flex-row gap-20 md:gap-24">
                  <FinalField
                    name="Address"
                    placeholder={i18nextTranslate(i18nextKeys.commonWalletAddress)}
                    label={i18nextTranslate(i18nextKeys.commonWalletEthereumAddress)}
                    style={{ ...fieldWidth }}
                    validate={walletAddressValidator()}
                    component={FormField}
                    dataQa="oneTimeWallet-address"
                    required
                  />
                  <CheckboxWithText
                    name="save"
                    text={i18nextTranslate(i18nextKeys.commonWalletSave)}
                    className="items-center"
                    style={{ width: md ? '45%' : '100%' }}
                    dataQa="oneTimeWallet-save"
                  />
                </div>
                <AnimateHeight
                  duration={750}
                  height={values.save ? 'auto' : 0}
                >
                  <FinalField
                    name="Name"
                    placeholder={i18nextTranslate(i18nextKeys.commonWalletName)}
                    label={i18nextTranslate(
                      i18nextKeys.collectionMethodInputOneTimeAddressNameLabel
                    )}
                    style={{ ...fieldWidth }}
                    validate={isRequiredIfWithMessage(
                      (values) => values.save,
                      i18nextTranslate(i18nextKeys.commonWalletName)
                    )}
                    component={FormField}
                    dataQa="oneTimeWallet-name"
                    required
                  />
                </AnimateHeight>
              </div>
              <CheckboxWithText
                name="disclaimer"
                text={content.disclaimer}
                textStyle={
                  md
                    ? {}
                    : {
                      WebkitMaskImage:
                        'linear-gradient(180deg, #000 68%, transparent)',
                      maskImage:
                        'linear-gradient(180deg, #000 68%, transparent)',
                      overflowY: 'scroll',
                      height: 112,
                      paddingBottom: 25,
                    }
                }
                dataQa="checkout-collectionMethod-oneTimeWalletAddress-disclaimer"
                required
              />
              <div className="flex justify-between w-full mt-24">
                <Button
                  text={i18nextTranslate(i18nextKeys.buttonClose)}
                  onClick={resetFormAndClose}
                  dataQa="checkout-collectionMethod-oneTimeWalletCancel"
                  width={buttonWidth}
                  disabled={savingWallet}
                  secondary
                />
                <Button
                  type="submit"
                  disabled={pristine || invalid || savingWallet}
                  loading={savingWallet}
                  text={i18nextTranslate(i18nextKeys.buttonConfirm)}
                  dataQa="checkout-collectionMethod-oneTimeWalletConfirm"
                  width={buttonWidth}
                />
              </div>
            </form>
          );
        }}
      </FinalForm>
    </Modal>
  );
};

export default OneTimeWalletModal;
