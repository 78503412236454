import {
  LOAD_CONFIG_SUCCESS,
  SET_COOKIE_SETTINGS,
} from './configTypes';

const configReducer = (state, action) => {
  switch (action.type) {
    default:
      return state;

    case LOAD_CONFIG_SUCCESS: {
      return {
        ...state,
        config: action.payload.config
      };
    }

    case SET_COOKIE_SETTINGS: {
      return {
        ...state,
        cookieSettings: action.payload.cookieSettings,
      };
    }
  }
};

export default configReducer;
