import React from "react";
import { components } from "react-select";
import { PlusSymbol } from "Components/shared/symbols";

const OptionAddAnother = (props) => {
  const { data, children } = props;

  return data.addAnother ? (
    <components.Option {...props}>
      <div className="flex gap-8 items-center">
        <PlusSymbol />
        {children}
      </div>
    </components.Option>
  ) : (
    <components.Option {...props}>
      {children}
    </components.Option>
  )
}

export default OptionAddAnother;
