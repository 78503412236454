import { i18nextKeys } from 'Lang/i18nextKeys';
import i18nextTranslate from 'Lang/i18nextTranslate';
import i18nextTranslateDynamically from 'Lang/i18nextTranslateDynamically';

export const getKycTableData = ({
  config,
  featureList = [],
  assets = [],
  paymentMethods = []
}) => {
  const {
    kycTierFunctionalRestrictions: { availability },
    redeemSettings: { redemptionMethods },
  } = config;

  let data = [];
  for (const feature of Object.keys(availability)) {
    const translationKey = `kycTiersAvailability${
      feature[0].toUpperCase() + feature.slice(1)
    }`;
    const matchingFeature = featureList.find(
      ({ Name }) => Name === `${feature}.availability`
    );
    data.push({
      feature: i18nextTranslate(i18nextKeys[translationKey]),
      kycTier: matchingFeature?.RequiredKycTier || 0,
    });
  }

  for (const paymentMethod of paymentMethods) {
    if (!paymentMethod.IsEnabled) {
      continue;
    }
    const paymentMethodTranslationKey = `kycTiersPaymentMethod${paymentMethod.Type}`;
    const feature = i18nextTranslateDynamically(
      i18nextKeys.kycTiersPaymentMethodTemplate,
      {
        paymentMethod: i18nextTranslate(
          i18nextKeys[paymentMethodTranslationKey]
        ),
      }
    );
    data.push({
      feature,
      kycTier: paymentMethod.KYCTier,
    });
  }

  for (const method of redemptionMethods) {
    const { type, id, name, userGets, userPays } = method;
    const asset = assets.find(({ Id }) => userPays.uniqueAssetId === Id);
    const feature = i18nextTranslateDynamically(
      i18nextKeys.kycTiersRedemptionTemplate,
      {
        userPaysAsset: asset?.Name,
        userGetsUOM: userGets.uom,
        methodName: name
      }
    );
    const matchingFeature = featureList.find(
      ({ Name }) => Name === `redemption.methods.${type}.${id}`
    );
    data.push({
      feature,
      kycTier: matchingFeature?.RequiredKycTier || 0,
    });
  }

  return data;
};
