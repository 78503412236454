import React from 'react';
import './loadingSpinner.css';

const LoadingSpinner = ({
  size = 'h-48 w-48',
  color = "border-color-4",
  classes = '',
  dataQa = "loading",
  ...props
}) => (
  <div
    className={`${size} border-color-4_7 border-4 rounded-full relative loader ${classes}`}
    data-qa={dataQa}
    {...props}
  >
    <div className={`${color} rounded-full absolute loaderSpinnerInner ${size}`}></div>
  </div>
);

export default LoadingSpinner;
