import React, { useContext } from 'react';
import { UiContext } from 'States/ui/uiState';
import linkify from 'Helpers/linkify';
import Modal from 'Components/shared/Modal';
import { Button } from 'Components/shared/buttons';
import Text from 'Components/shared/Text';
import i18nextTranslate from 'Lang/i18nextTranslate';
import { i18nextKeys } from 'Lang/i18nextKeys';
import { isValidUrl } from 'Utils/utils';

const InfoModal = ({
  show,
  close,
  content = {},
  actionButton = {},
  dataQa = "info-modal",
  showButtons = true,
  children
}) => {
  const {
    breakpoints: { md, xxl }
  } = useContext(UiContext);

  const buttonWidth = {
    xxl: '190px',
    md: '156px',
    default: '142px'
  };

  const iconSize = xxl ? buttonWidth.xxl : md ? buttonWidth.md : "56px";

  return (
    <Modal
      dataQa={dataQa}
      show={show}
      close={close}
    >
      <div className="flex flex-col gap-24 md:gap-32 bg-3 rounded modalContent p-16 md:p-24">
        <div className="flex flex-col gap-16">
          <div className="flex gap-16 md:gap-24 items-center md:items-start">
            {content?.icon && (
              <>
                {isValidUrl(content?.icon) ? (
                  // TODO: replace with 'aspect-ratio' property, when it gains more support
                  <div
                    className="flex-shrink-0 rounded"
                    style={{
                      backgroundImage: `url(${content.icon})`,
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                      width: iconSize,
                      height: iconSize
                    }}
                    data-qa={`${dataQa}-picture`}
                  />
                ) : (
                  <div
                    data-qa={`${dataQa}-picture`}
                    className="flex-shrink-0 flex justify-center items-center bg-gray-400 rounded"
                    style={{
                      width: iconSize,
                      height: iconSize
                    }}
                  >
                    {content?.icon}
                  </div>
                )}
              </>
            )}
            <div className="flex flex-col gap-12 md:gap-16 w-full">
              <Text
                textStyle="h2"
                dataQa={`${dataQa}-title`}
                uppercase
              >
                {content?.title}
              </Text>
              {!!content?.subtitle && md && (
                <Text
                  textStyle="h2"
                  className="truncate"
                  dataQa={`${dataQa}-title`}
                  uppercase
                >
                  {content?.subtitle}
                </Text>
              )}
              {md && (
                <div className="flex flex-col gap-32">
                  <Text
                    dataQa={`${dataQa}-description`}
                    dangerouslySetContent
                  >
                    {linkify(content?.description || '')}
                  </Text>
                  {children}
                </div>
              )}
            </div>
          </div>
          {!md && (
            <div className="flex flex-col gap-32">
              <Text
                dataQa={`${dataQa}-description`}
                dangerouslySetContent
              >
                {linkify(content?.description || '')}
              </Text>
              {children}
            </div>
          )}
        </div>
        {showButtons && (
          <div className="flex gap-12 justify-between">
            <Button
              dataQa={`${dataQa}-closeButton`}
              text={i18nextTranslate(i18nextKeys.buttonClose)}
              onClick={close}
              width={buttonWidth}
              secondary
            />
            {!!actionButton.onClick && (
              <Button
                text={actionButton.text || i18nextTranslate(i18nextKeys.buttonSelectAndContinue)}
                onClick={actionButton.onClick}
                width={buttonWidth}
                dataQa={`${dataQa}-actionButton`}
              />
            )}
          </div>
        )}
      </div>
    </Modal>
  );
};

export default InfoModal;
