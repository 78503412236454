import React from 'react';

const FilterIcon = ({
  size = "14",
  reference,
  ...props
}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 14 14"
    fill="currentColor"
    ref={reference}
    {...props}
  >
    <path d="M5.90529 12.9062C5.78926 12.9062 5.67798 12.8602 5.59593 12.7781C5.51388 12.6961 5.46779 12.5848 5.46779 12.4688V8.26219L1.41216 3.80187C1.25251 3.62595 1.14736 3.40746 1.10946 3.17293C1.07157 2.93841 1.10256 2.69792 1.19868 2.48067C1.2948 2.26341 1.45192 2.07873 1.65096 1.94903C1.85 1.81933 2.0824 1.75019 2.31997 1.75H11.6781C11.9157 1.75019 12.1481 1.81933 12.3471 1.94903C12.5462 2.07873 12.7033 2.26341 12.7994 2.48067C12.8955 2.69792 12.9265 2.93841 12.8886 3.17293C12.8507 3.40746 12.7456 3.62595 12.5859 3.80187L8.53029 8.26219V10.7188C8.53025 10.7843 8.51549 10.849 8.48709 10.908C8.45869 10.9671 8.41739 11.019 8.36623 11.06L6.17873 12.81C6.10117 12.8722 6.00472 12.9062 5.90529 12.9062ZM2.31997 2.625C2.25189 2.62517 2.18532 2.64507 2.12832 2.68229C2.07131 2.7195 2.02632 2.77245 1.99877 2.83471C1.97123 2.89696 1.96232 2.96587 1.97313 3.03309C1.98394 3.1003 2.01399 3.16295 2.05966 3.21344L6.22904 7.80719C6.30047 7.88574 6.3409 7.98759 6.34279 8.09375V11.5588L7.65529 10.5V8.09375C7.65503 7.98457 7.6956 7.87923 7.76904 7.79844L11.9384 3.20469C11.9814 3.15397 12.0091 3.09214 12.0184 3.02633C12.0277 2.96053 12.0182 2.89344 11.9909 2.83281C11.9637 2.77219 11.9199 2.7205 11.8645 2.68373C11.8092 2.64696 11.7445 2.62659 11.6781 2.625H2.31997Z" />
  </svg>
);

export default FilterIcon;
