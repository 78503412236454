import React, { useContext } from "react";
import { UiContext } from "States/ui/uiState";
import i18nextTranslate from 'Lang/i18nextTranslate';
import { i18nextKeys } from 'Lang/i18nextKeys';
import codeToCountry from 'Utils/country-codes';
import { TileCardContent } from "Components/shared/Tile";

const BankDetails = ({
  bankAccount,
  intermediary = false,
  copyOnClick = false,
  dataQa = "bank"
}) => {
  const { breakpoints: { md } } = useContext(UiContext);

  const bankAccountData = [
    {
      title: i18nextTranslate(
        intermediary
          ? i18nextKeys.commonBankIntermediaryAccountDetails
          : i18nextKeys.commonBankAccountDetails
      ),
      items: [
        {
          name: i18nextTranslate(
            intermediary
              ? i18nextKeys.commonBankIntermediaryName
              : i18nextKeys.commonBankName
          ),
          value: bankAccount.BankName,
          dataQa: `${dataQa}-name`
        },
        ...(intermediary
          ? []
          : [{
              name: i18nextTranslate(i18nextKeys.commonCurrency),
              value: bankAccount.Currency,
              dataQa: `${dataQa}-currency`
            }]
        ),
        {
          name: i18nextTranslate(
            intermediary
              ? i18nextKeys.commonBankIntermediaryIban
              : i18nextKeys.commonBankIban
          ),
          value: bankAccount.IBAN,
          dataQa: `${dataQa}-iban`
        },
        {
          name: i18nextTranslate(
            intermediary
              ? i18nextKeys.commonBankIntermediarySwift
              : i18nextKeys.commonBankSwift
          ),
          value: bankAccount.SWIFT,
          dataQa: `${dataQa}-swift`
        },
        ...(intermediary
          ? []
          : [{
              name: i18nextTranslate(i18nextKeys.commonBankBic),
              value: bankAccount.BIC,
              dataQa: `${dataQa}-bic`
            }]
        )
      ],
      dataQa: `${dataQa}-details`
    },
    {
      title: i18nextTranslate(
        intermediary
          ? i18nextKeys.commonBankIntermediaryAddress
          : i18nextKeys.commonBankAddress
      ),
      items: [
        {
          name: i18nextTranslate(i18nextKeys.commonAddressStreet),
          value: bankAccount[intermediary ? 'AddressLine' : 'AddressLine1'],
          dataQa: `${dataQa}-street`
        },
        {
          name: i18nextTranslate(i18nextKeys.commonAddressZip),
          value: bankAccount.ZipCode,
          dataQa: `${dataQa}-zip`
        },
        {
          name: i18nextTranslate(i18nextKeys.commonAddressCity),
          value: bankAccount.City,
          dataQa: `${dataQa}-city`
        },
        {
          name: i18nextTranslate(i18nextKeys.commonAddressRegion),
          value: bankAccount.StateOrRegion,
          dataQa: `${dataQa}-region`
        },
        {
          name: i18nextTranslate(i18nextKeys.commonAddressCountry),
          value: i18nextTranslate(codeToCountry[bankAccount.Country]),
          dataQa: `${dataQa}-country`
        },
      ],
      dataQa: `${dataQa}-address`
    }
  ];

  return (
    <div className={`grid gap-24 ${md ? 'grid-cols-2' : 'grid-cols-1'}`}>
      <TileCardContent
        data={bankAccountData}
        copyOnClick={copyOnClick}
      />
    </div>
  );
};

export default BankDetails;
