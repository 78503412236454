import { useEffect, useState } from "react";

const useOnScreen = (reference) => {
  const [isIntersecting, setIntersecting] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => setIntersecting(entry.isIntersecting)
    );
    if (reference.current) {
      observer.observe(reference.current);
    }
    return () => observer.disconnect();
  }, [reference]);

  return isIntersecting;
};

export default useOnScreen;
