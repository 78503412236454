import React from "react";
import { AbortSymbol } from "../symbols";
import Text from "../Text";

const Chip = ({
  text,
  onRemove = () => {},
  dataQa = "chip",
  width = "max-content",
  removable = false
}) => (
  <div
    className="flex-grow-0 flex gap-8 justify-center items-center rounded px-8 py-4 bg-4_7"
    style={{ width }}
    data-qa={dataQa}
  >
    <Text
      textStyle="h3"
      color="color-4"
      dataQa={`${dataQa}-text`}
    >
      {text}
    </Text>
    {removable && (
      <AbortSymbol
        className="color-4 cursor-pointer"
        onClick={onRemove}
        data-qa={`${dataQa}-remove`}
      />
    )}
  </div>
);

export default Chip;
