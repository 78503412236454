import { matchPath, useHistory, useLocation } from "react-router-dom";
import i18nextTranslate from 'Lang/i18nextTranslate';
import { i18nextKeys } from 'Lang/i18nextKeys';
import { ROUTES } from 'Router/Routes';
import { getUserManager } from "Helpers/auth";
import errorNotification from 'Helpers/errorNotification';

const useHandleError = () => {
  const history = useHistory();
  const location = useLocation();

  const handleError = async ({
    error,
    message = i18nextTranslate(i18nextKeys.errorDefaultMessage),
    finalNote = true,
    errorDetails = []
  }) => {
    if (
      matchPath(location.pathname, ROUTES.error) ||
      matchPath(location.pathname, ROUTES.logout)
    ) {
      return;
    }
    const errorCode = error?.response?.status;
    switch (errorCode) {
      case 400:
        errorNotification(error, message, finalNote);
        break;
      case 401:
        const userManager = await getUserManager();
        await userManager.removeUser();
        window.location.replace(ROUTES.base);
        break;
      default:
        history.push(ROUTES.error, { errorCode, errorDetails });
    };
  };

  return handleError;
};

export default useHandleError;
