import React from "react";

const EmptyCartIcon = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 60 60"
    fill="currentColor"
    className="color-8"
  >
    <path d="M36.6667 14.1663C36.6667 8.19346 41.5271 3.33301 47.5 3.33301C53.4729 3.33301 58.3333 8.19346 58.3333 14.1663C58.3333 20.1392 53.4729 24.9997 47.5 24.9997C41.5271 24.9997 36.6667 20.1392 36.6667 14.1663ZM44.3948 18.4751C45.113 19.4915 46.1481 19.9997 47.5 19.9997C48.8519 19.9997 49.8817 19.4915 50.5893 18.4751C51.3076 17.4588 51.6667 16.0092 51.6667 14.1262C51.6667 12.2433 51.3076 10.8097 50.5893 9.82544C49.8817 8.83049 48.8519 8.33301 47.5 8.33301C46.1481 8.33301 45.113 8.83049 44.3948 9.82544C43.6871 10.8097 43.3333 12.2433 43.3333 14.1262C43.3333 16.0092 43.6871 17.4588 44.3948 18.4751ZM48.8625 17.0629C48.5667 17.6728 48.1126 17.9777 47.5 17.9777C46.8874 17.9777 46.4332 17.6728 46.1375 17.0629C45.8418 16.4424 45.6939 15.4635 45.6939 14.1262C45.6939 12.7889 45.8418 11.8261 46.1375 11.2376C46.4332 10.6492 46.8874 10.355 47.5 10.355C48.1126 10.355 48.5667 10.6492 48.8625 11.2376C49.1582 11.8261 49.3061 12.7889 49.3061 14.1262C49.3061 15.4635 49.1582 16.4424 48.8625 17.0629Z" />
    <path d="M16.0547 44.0625C17.0273 44.0625 50.3633 44.0625 49.0549 44.0625L52.9516 29.0336C51.7303 29.4674 50.4443 29.7367 49.1071 29.8573L49.0702 30H40.8158L40.9467 28.4307C39.732 27.8527 38.6224 27.1137 37.6015 26.259L37.2896 30H29.976L29.3899 22.9688H34.5531C33.8298 21.8905 33.2899 20.6958 32.8467 19.4531H16.2927L13.9485 8.90625H0V12.4219H11.1298L17.3782 40.5469H16.0547C13.1468 40.5469 10.7812 42.9124 10.7812 45.8203C10.7812 48.7282 13.1468 51.0938 16.0547 51.0938H18.1363C17.9393 51.6463 17.8125 52.2323 17.8125 52.8516C17.8125 55.7595 20.178 58.125 23.0859 58.125C25.9938 58.125 28.3594 55.7595 28.3594 52.8516C28.3594 52.2323 28.2326 51.6463 28.0356 51.0938H35.7144C35.5174 51.6463 35.3906 52.2323 35.3906 52.8516C35.3906 55.7595 37.7562 58.125 40.6641 58.125C43.572 58.125 45.9375 55.7595 45.9375 52.8516C45.9375 52.2323 45.8107 51.6463 45.6137 51.0938H49.4531V47.5781H16.0547C15.0848 47.5781 14.2969 46.7902 14.2969 45.8203C14.2969 44.8505 15.0848 44.0625 16.0547 44.0625ZM40.5229 33.5156H48.1586L46.3357 40.5469H39.9368L40.5229 33.5156ZM36.9967 33.5156L36.4106 40.5469H30.8548L30.2687 33.5156H36.9967ZM27.3287 40.5469H20.9809L19.4181 33.5156H26.7427L27.3287 40.5469ZM17.074 22.9688H25.8639L26.4499 30H18.6369L17.074 22.9688ZM24.8438 52.8516C24.8438 53.8214 24.0558 54.6094 23.0859 54.6094C22.1161 54.6094 21.3281 53.8214 21.3281 52.8516C21.3281 51.8817 22.1161 51.0938 23.0859 51.0938C24.0558 51.0938 24.8438 51.8817 24.8438 52.8516ZM42.4219 52.8516C42.4219 53.8214 41.6339 54.6094 40.6641 54.6094C39.6942 54.6094 38.9062 53.8214 38.9062 52.8516C38.9062 51.8817 39.6942 51.0938 40.6641 51.0938C41.6339 51.0938 42.4219 51.8817 42.4219 52.8516Z" />
  </svg>
);

export default EmptyCartIcon;
