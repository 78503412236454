import React, { memo, useCallback, useEffect, useState } from "react";
import api from "Api";
import { i18nextKeys } from "Lang/i18nextKeys";
import i18nextTranslate from "Lang/i18nextTranslate";
import i18nextTranslateDynamically from "Lang/i18nextTranslateDynamically";
import { PAYMENT_DOCUMENT_TEMPLATE, PAYMENT_METHOD } from "Enums";
import useHandleError from "Utils/handleError";
import linkify from "Helpers/linkify";
import LoadingSpinner from "Components/shared/LoadingSpinner";
import Text from "Components/shared/Text";
import { FileUpload } from "Features/admin/shared";
import { SubsectionHeading } from "Features/admin/ConfigManagement/shared";
import { TranslationsForm } from "Features/admin/ConfigManagement/shared/translations";

const Templates = ({
  contentWidth,
  fieldWidth,
  paymentMethodEnabled,
  paymentMethodName,
  paymentMethodType
}) => {
  const [currentTemplates, setCurrentTemplates] = useState(null);
  const [loadingInitial, setLoadingInitial] = useState(true);
  const handleError = useHandleError();

  const content = {
    [PAYMENT_METHOD.BankTransfer]: {
      title: i18nextKeys.configPaymentTemplateTitleBank,
      description: i18nextKeys.configPaymentTemplateDescriptionBank
    },
    default: {
      title: i18nextKeys.configPaymentTemplateTitleOther,
      description: i18nextKeys.configPaymentTemplateDescriptionOther
    }
  };

  const defaultValue = {
    FileName: undefined
  };

  useEffect(() => {
    const loadTemplates = async () => {
      try {
        const { value: templates } = await api.Config.Templates.getByType(
          PAYMENT_DOCUMENT_TEMPLATE[paymentMethodType]
        );
        setCurrentTemplates(templates);
      } catch (error) {
        handleError({ error });
      } finally {
        setLoadingInitial(false);
      }
    };
    loadTemplates();
  }, []);

  const getFormData = (template, languageCode) => {
    const data = new FormData();
    data.append("LanguageCode", languageCode);
    data.append("Type", PAYMENT_DOCUMENT_TEMPLATE[paymentMethodType]);
    data.append("File", template);
    return data;
  };

  const testTemplate = useCallback(
    async (
      abortController,
      handleProgress,
      languageCode,
      template
    ) => {
      try {
        const data = typeof template === "string"
          ? await api.Config.Templates.testCurrent(
            languageCode,
            PAYMENT_DOCUMENT_TEMPLATE[paymentMethodType],
            abortController.signal
          )
          : await api.Config.Templates.testNew(
            getFormData(template, languageCode),
            abortController.signal,
            handleProgress
          );
        const link = URL.createObjectURL(data);
        window.open(link, '_blank');
      } catch (error) {
        if (abortController.signal?.aborted) {
          return;
        } else {
          handleError({ error });
        }
      }
    }, []
  );

  const validateTemplate = useCallback(
    (template, values, { pristine, modified }, isDefaultLanguage) => {
      // checking modified as a partial workaround for
      // https://github.com/final-form/react-final-form-arrays/issues/147
      if (pristine || !modified || !isDefaultLanguage) {
        return undefined;
      }
      if (template === undefined) {
        return paymentMethodEnabled
          ? i18nextTranslateDynamically(
              i18nextKeys.validatorMessage,
              { field: i18nextTranslate(i18nextKeys.commonTemplate) }
            )
          : undefined;
      }
    }, []
  );

  const validateTemplateAsync = useCallback(
    async (
      abortController,
      handleProgress,
      languageCode,
      template
    ) => {
      try {
        await api.Config.Templates.validate(
          getFormData(template, languageCode),
          abortController.signal,
          handleProgress
        );
        return;
      } catch (error) {
        if (abortController.signal?.aborted) {
          return;
        }
        const message = error.response?.data?.Message;
        if (message) {
          return message;
        } else {
          handleError({ error });
        }
      }
    }, []
  );

  const getTemplateComponent = useCallback((props) => (
    <FileUpload
      accept={{ "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [".docx"] }}
      buttonText={i18nextTranslate(i18nextKeys.configPaymentTemplateTest)}
      buttonAction={testTemplate}
      fieldName="FileName"
      validate={validateTemplate}
      validateAsync={validateTemplateAsync}
      fieldWidth={fieldWidth}
      {...props}
    />
  ), [])

  return (
    <div style={{ width: contentWidth }}>
      <div className="flex flex-col gap-16 xxl:gap-20 mb-20">
        <SubsectionHeading
          text={i18nextTranslateDynamically(
            (content[paymentMethodType] || content.default).title,
            { name: paymentMethodName }
          )}
          dataQa="template"
          className=""
        />
        <div data-qa="template-description">
          <Text
            textStyle="p4"
            inline
          >
            {`${i18nextTranslate(
              (content[paymentMethodType] || content.default).description
            )} ${i18nextTranslate(
              i18nextKeys.configPaymentTemplateDescription
            )}`}
          </Text>
        </div>
        <div className="flex flex-col gap-8 mb-24">
          <Text
            textStyle="p2"
            dataQa="template-requirements-title"
          >
            {i18nextTranslate(
              i18nextKeys.configPaymentTemplateRequirementsTitle
            )}
          </Text>
          <ol
            className="p4 color-8 text-paragraph list-inside"
            style={{ listStyleType: "lower-latin" }}
          >
            <li>{i18nextTranslate(i18nextKeys.configPaymentTemplateRequirementsFirst)}</li>
            <li
              dangerouslySetInnerHTML={{
                __html: linkify(
                  i18nextTranslate(i18nextKeys.configPaymentTemplateRequirementsSecond)
                )
              }}
            />
            <li>{i18nextTranslate(i18nextKeys.configPaymentTemplateRequirementsThird)}</li>
          </ol>
        </div>
      </div>
      {loadingInitial ? (
        <div className="flex justify-center px-32 py-24">
          <LoadingSpinner />
        </div>
      ) : (
        <TranslationsForm
          defaultValue={defaultValue}
          fieldArrayName="templates"
          fields={getTemplateComponent}
          fieldWidth={fieldWidth}
          translations={currentTemplates}
        />
      )}
    </div>
  );
};

export default memo(Templates);
