import React, { useContext, useState } from 'react';
import { useAmplitude } from 'react-amplitude-hooks';
import { ROUTES } from 'Router/Routes';
import { ConfigContext } from 'States/config/configState';
import KycLevelInfoBox from 'Components/shared/KycLevelInfoBox';
import { Tile, HorizontalRule } from 'Components/shared/Tile';
import Text from 'Components/shared/Text';
import { Button } from 'Components/shared/buttons';
import i18nextTranslate from 'Lang/i18nextTranslate';
import { i18nextKeys } from 'Lang/i18nextKeys';
import { KYC_CASE_STATUS } from 'Enums/KycCaseStatus';
import { KYC_PROVIDER } from 'Enums/KycProvider';
import KycDeclineReasonModal from './KycDeclineReasonModal';

const KycCard = ({
  isLoading,
  kycTierNumber,
  maxTierNumber,
  kycCaseTier,
  kycCaseStatus,
  kycCaseDeclineReason
}) => {
  const { logEvent } = useAmplitude();
  const {
    config: { kycTiersDescription }
  } = useContext(ConfigContext);
  const [showDeclineReasonModal, setShowDeclineReasonModal] = useState(false);
  const shouldShowCaseStatus = !!kycCaseTier && kycCaseStatus !== KYC_CASE_STATUS.approved;

  const startKYC = () => {
    logEvent("KYC started", {
      origin: "account",
      provider: kycTiersDescription[1]?.providers[0].name !== KYC_PROVIDER.manual
    });
    window.open(ROUTES.kyc, '_blank');
  };

  return (
    <Tile
      title={i18nextTranslate(i18nextKeys.cardsKycCurrentTierHeader)}
      alwaysVisibleContent={
        <>
          <Text className="mt-12 mb-20">
            {i18nextTranslate(i18nextKeys.cardsKycCurrentTierDescription)}
          </Text>
          <div className="flex">
            <KycLevelInfoBox
              isLoading={isLoading}
              kycTierNumber={kycTierNumber}
              dataQa="account-kyc-currentTier"
            />
          </div>
        </>
      }
      dataQa="account-kyc"
      expandable={shouldShowCaseStatus}
    >
      <div
        className="relative flex flex-col items-start w-full"
        data-qa="account-kyc"
      >
        {shouldShowCaseStatus && (
          <div className="w-full">
            <HorizontalRule className="mb-20" />
            <Text
              dataQa="account-kyc-lastCheck-header"
              textStyle="h2"
              uppercase
            >
              {i18nextTranslate(i18nextKeys.cardsKycLastCheckHeader)}
            </Text>
            <Text className="mt-12 mb-20">
              {i18nextTranslate(i18nextKeys.cardsKycLastCheckDescription)}
            </Text>
            <div className="flex">
              <KycLevelInfoBox
                isLoading={isLoading}
                kycTierNumber={kycCaseTier}
                kycCaseStatus={kycCaseStatus}
                dataQa="account-kyc-currentCase"
              />
            </div>

            {kycCaseStatus === KYC_CASE_STATUS.declined
              && kycCaseDeclineReason
              && (
                <div
                  className="md:w-4/6 text-sm"
                  data-qa="account-kyc-currentCase-decline"
                >
                  <h3 className="mt-20 mb-8 font-bold">
                    {i18nextTranslate(i18nextKeys.cardsKycDeclinedReason)}:
                  </h3>
                  <p
                    className="overflow-hidden"
                    style={{
                      display: '-webkit-box',
                      WebkitBoxOrient: 'vertical',
                      WebkitLineClamp: 3
                    }}
                    data-qa="account-kyc-currentCase-decline-reason"
                  >
                    {kycCaseDeclineReason}
                  </p>
                  <button
                    className="color-4 underline font-bold mt-8"
                    onClick={() => setShowDeclineReasonModal(true)}
                    data-qa="account-kyc-currentCase-decline-openReasonModal"
                  >
                    {i18nextTranslate(i18nextKeys.cardsKycDeclinedOpenReasonModal)}
                  </button>
                </div>
              )}
          </div>
        )}
        {kycTierNumber !== maxTierNumber
          && kycCaseStatus !== KYC_CASE_STATUS.open
          && (
            <Button
              text={i18nextTranslate(
                kycTierNumber ? i18nextKeys.buttonUpgradeKyc : i18nextKeys.buttonStartKyc
              )}
              className="self-left md:self-right md:absolute bottom-0 right-0 mt-20 md:mt-0"
              onClick={startKYC}
              dataQa={`account-kyc-${kycTierNumber ? 'upgrade' : 'start'}-button`}
              width={{
                xxl: '190px',
                default: '170px'
              }}
            />
          )
        }
      </div>
      <KycDeclineReasonModal
        show={showDeclineReasonModal}
        close={() => setShowDeclineReasonModal(false)}
        declineReason={kycCaseDeclineReason}
      />
    </Tile>
  );
};

export default KycCard;
