import formatDate from 'Utils/formatDate';
import PayoutMethodEnum from 'Enums/PayoutMethod';
import RedemptionStatusEnum from 'Enums/RedemptionStatus';
import i18nextTranslate from '../Lang/i18nextTranslate';
import { i18nextKeys } from '../Lang/i18nextKeys';

export const getRedeemTableColumns = ({
  formatNumber,
  xxlScreenSize = false,
  singleUser = false
}) => [
  {
    Header: i18nextTranslate(i18nextKeys.commonReferenceCode),
    accessor: 'ReferenceCode',
    id: 'ReferenceCode',
    disableSortBy: true,
    color: 'color-4',
    width: xxlScreenSize ? '172px' : '135px',
    disableFilters: true,
  },
  {
    Header: i18nextTranslate(i18nextKeys.helperRedeemUser),
    accessor: (row) => `${row.UserFirstName} ${row.UserLastName}`,
    id: 'User',
    color: 'color-4',
    width: xxlScreenSize ? '190px' : '150px',
    disableFilters: true,
    disableSortBy: singleUser
  },
  {
    Header: i18nextTranslate(i18nextKeys.commonDate),
    accessor: (row) => `${formatDate(row.ReceivedOn)}`,
    id: 'ReceivedOn',
    color: 'color-8',
    width: xxlScreenSize ? '90px' : '82px',
    disableFilters: true,
  },
  {
    Header: i18nextTranslate(i18nextKeys.helperRedeemMethod),
    id: 'PayoutMethod',
    accessor: (row) => PayoutMethodEnum.enumToFriendlyString(row.PayoutMethod),
    color: 'color-8',
    width: xxlScreenSize ? '166px' : '135px',
    disableFilters: true,
  },
  {
    Header: i18nextTranslate(i18nextKeys.redemptionAssetName),
    id: 'RedeemAssetName',
    accessor: 'RedeemAssetName',
    color: 'color-8',
    width: xxlScreenSize ? '166px' : '135px',
    disableFilters: true,
  },
  {
    Header: i18nextTranslate(i18nextKeys.redemptionGross),
    id: 'GrossAmount/AssetUnits',
    accessor: (row) => `${formatNumber(row.GrossAmount.AssetUnitsString)
      } ${row.RedeemAssetUnitOfMeasureCode || row.RedeemAssetCurrencyCode}`,
    color: 'color-8',
    width: xxlScreenSize ? '152px' : '118px',
    disableFilters: true,
  },
  {
    Header: i18nextTranslate(i18nextKeys.redemptionPayout),
    id: 'GrossAmount/PayoutUnits',
    accessor: (row) => `${formatNumber(row.GrossAmount.PayoutUnitsString)
      } ${row.Currency}`,
    color: 'color-8',
    width: xxlScreenSize ? '152px' : '118px',
    disableFilters: true,
  },
  {
    Header: i18nextTranslate(i18nextKeys.helperRedeemStatus),
    accessor: (row) => RedemptionStatusEnum.enumToFriendlyString(row.Status),
    color: 'color-8',
    width: xxlScreenSize ? '101px' : '85px',
    disableFilters: true,
  },
];
