import React from 'react';

const PageIcon = ({ size, ...props }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      fill="currentColor"
      {...props}
    >
      <path d="M26.6667 21.333H24.6667V1.99967C24.6667 1.63167 24.368 1.33301 24 1.33301H7.99999C7.63199 1.33301 7.33332 1.63167 7.33332 1.99967V3.99968H5.33332C4.95999 3.99968 4.66666 4.29301 4.66666 4.66634V27.333C4.66666 29.173 6.15999 30.6664 7.99999 30.6664H24C25.0933 30.6664 26.0533 30.1464 26.6667 29.333C27.08 28.7864 27.3333 28.0797 27.3333 27.333V21.9997C27.3333 21.6263 27.04 21.333 26.6667 21.333ZM8.66666 2.66634H23.3333V25.333H22V23.333V21.9997V4.66634C22 4.29301 21.7067 3.99968 21.3333 3.99968H8.66666V2.66634ZM7.99999 29.333C6.89332 29.333 5.99999 28.4397 5.99999 27.333V5.33301H20.6667V21.9997V23.333V27.333C20.6667 28.0797 20.92 28.7864 21.3333 29.333H7.99999ZM22 27.333V26.6664H24C24.368 26.6664 24.6667 26.3677 24.6667 25.9997V22.6663H26V27.333C26 28.4397 25.1067 29.333 24 29.333C22.8933 29.333 22 28.4397 22 27.333Z" />
      <path d="M10.2533 15.9994H16.4133C17.2933 15.9994 18 15.2927 18 14.4127V8.25268C18 7.37268 17.2933 6.66602 16.4133 6.66602H10.2533C9.37332 6.66602 8.66666 7.37268 8.66666 8.25268V14.4127C8.66666 15.2927 9.37332 15.9994 10.2533 15.9994ZM9.99999 8.25268C9.99999 8.10602 10.1067 7.99935 10.2533 7.99935H16.4133C16.56 7.99935 16.6667 8.10602 16.6667 8.25268V14.4127C16.6667 14.5594 16.56 14.666 16.4133 14.666H10.2533C10.1067 14.666 9.99999 14.5594 9.99999 14.4127V8.25268Z" />
      <path d="M18.6667 17.333H7.99998C7.63198 17.333 7.33331 17.6317 7.33331 17.9997C7.33331 18.3677 7.63198 18.6663 7.99998 18.6663H18.6667C19.0347 18.6663 19.3333 18.3677 19.3333 17.9997C19.3333 17.6317 19.0347 17.333 18.6667 17.333Z" />
      <path d="M18.6667 22.666H7.99998C7.63198 22.666 7.33331 22.9647 7.33331 23.3327C7.33331 23.7007 7.63198 23.9993 7.99998 23.9993H18.6667C19.0347 23.9993 19.3333 23.7007 19.3333 23.3327C19.3333 22.9647 19.0347 22.666 18.6667 22.666Z" />
      <path d="M18.6667 20H7.99998C7.63198 20 7.33331 20.2987 7.33331 20.6667C7.33331 21.0347 7.63198 21.3333 7.99998 21.3333H18.6667C19.0347 21.3333 19.3333 21.0347 19.3333 20.6667C19.3333 20.2987 19.0347 20 18.6667 20Z" />
      <path d="M17.3333 25.333H9.33332C8.96532 25.333 8.66666 25.6317 8.66666 25.9997C8.66666 26.3677 8.96532 26.6663 9.33332 26.6663H17.3333C17.7013 26.6663 18 26.3677 18 25.9997C18 25.6317 17.7013 25.333 17.3333 25.333Z" />
      <path d="M12.8619 13.1378C12.8833 13.1591 13.1099 13.3778 13.4406 13.3245C13.7713 13.2711 13.9166 12.9925 13.9299 12.9645L15.2633 10.2978C15.4286 9.96848 15.2939 9.56848 14.9646 9.40314C14.6353 9.23914 14.2366 9.37248 14.0699 9.70181L13.1499 11.5405L12.4726 10.8605C12.2126 10.6005 11.7899 10.6005 11.5299 10.8605C11.2699 11.1205 11.2699 11.5431 11.5299 11.8031L12.8619 13.1378Z" />
    </svg>
  );
}

export default PageIcon;
