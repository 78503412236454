import React from 'react';
import { useHistory } from 'react-router-dom';
import i18nextTranslate from 'Lang/i18nextTranslate';
import i18nextTranslateDynamically from 'Lang/i18nextTranslateDynamically';
import { i18nextKeys } from 'Lang/i18nextKeys';
import { ROUTES } from 'Router/Routes';
import { Button } from 'Components/shared/buttons';
import { Arrow } from 'Components/shared/symbols';

const RedemptionFormFooter = ({
  submitButtonText,
  submitDisabled = false,
  requiredKycTier = 0,
  backButtonAction = () => {},
  hideBackButton = false,
}) => {
  const history = useHistory();

  const redirectToKyc = () =>
    history.push({
      pathname: ROUTES.kyc,
      state: {
        returnUrl: window.location.href
      }
    })

  const buttonWidth = {
    xxl: '190px',
    default: '156px'
  };

  return (
    <div className={`flex gap-8 ${
      hideBackButton ? "justify-end" : "justify-between"
    }`}>
      {!hideBackButton && (
        <Button
          dataQa="back"
          text={i18nextTranslate(i18nextKeys.buttonBack)}
          width={buttonWidth}
          onClick={backButtonAction}
          tertiary
        />
      )}
      {requiredKycTier ? (
        <Button
          dataQa="button-kyc"
          className="justify-self-end"
          text={i18nextTranslateDynamically(
            i18nextKeys.kycGetTierToContinue,
            { tier: requiredKycTier }
          )}
          icon={Arrow}
          width={buttonWidth}
          onClick={redirectToKyc}
        />
      ) : (
        <Button
          type="submit"
          dataQa="button-submit"
          className="justify-self-end"
          text={submitButtonText}
          width={buttonWidth}
          disabled={submitDisabled}
        />
      )}
    </div>
  );
};

export default RedemptionFormFooter;
