import React from 'react';

const TabBar = ({
  children,
  className = "",
  dataQa = "tabBar"
}) => {
  return (
    <>
      <div
        className={`sticky z-20 top-0 ${
          className
        } flex gap-32 rounded-t border-b border-color-5 bg-3 color-8`}
        id="tabBar"
        data-qa={dataQa}
      >
        {children}
      </div>
    </>
  );
};

export default TabBar;
