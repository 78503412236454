import React, { useCallback, useContext, useMemo, useState } from "react";
import { useAmplitude } from "react-amplitude-hooks";
import api from "Api";
import { getTranslation } from 'Helpers/translations';
import formatDateTime from "Utils/formatDateTime";
import i18nextTranslate from "Lang/i18nextTranslate";
import { i18nextKeys } from "Lang/i18nextKeys";
import { LangContext } from "States/lang/langState";
import PayoutMethod from "Enums/PayoutMethod";
import RedemptionStatusEnum from 'Enums/RedemptionStatus';
import { Chip } from 'Components/shared/Chip';
import {
  Tile,
  TileInfoCard,
  HorizontalRule
} from "Components/shared/Tile";
import { AdditionalDataModal, StatusUpdater } from "Features/admin/shared";
import useConfigSettings from 'Hooks/useConfigSettings';

const RequestInformation = ({
  redemptionRequest,
  setRedemptionRequest,
  isAdmin = false
}) => {
  const { lang } = useContext(LangContext);
  const { logEvent } = useAmplitude();
  const [showAdditionalDataModal, setShowAdditionalDataModal] = useState(false);

  const onRedemptionStatusChange = (Status) => {
    setRedemptionRequest({ ...redemptionRequest, Status });
    logEvent("Redemption status changed");
  };

  const onAdditionalDataChange = (AdditionalData) => {
    setRedemptionRequest({ ...redemptionRequest, AdditionalData });
    logEvent("Add details redemption");
  };

  const {
    data: defaultLanguage
  } = useConfigSettings.query({
    select: useCallback(({ Languages }) => Languages.find(({ IsDefault }) => IsDefault), [])
  });

  const assetName = useMemo(() =>
    getTranslation("Name", lang, defaultLanguage.Code, redemptionRequest.asset.Translations) ||
      redemptionRequest.asset.Name, [lang]);

  return (
    <>
      <Tile
        title={i18nextTranslate(i18nextKeys.redeemManagementDetailsRequestInformationHeader)}
      >
        <div className="flex flex-col gap-24">
          <div className="grid gap-20 grid-cols-1 md:grid-cols-3">
            <TileInfoCard
              title={i18nextTranslate(i18nextKeys.commonDate)}
              text={formatDateTime(redemptionRequest.ReceivedOn)}
              textProps={{ uppercase: true }}
              copyOnClick
            />
            <TileInfoCard
              title={i18nextTranslate(i18nextKeys.redemptionAssetName)}
              text={assetName}
              copyOnClick
            />
            <TileInfoCard
              title={i18nextTranslate(i18nextKeys.redemptionUniqueAssetId)}
              text={redemptionRequest.RedeemUniqueAssetId}
              showTooltip
              copyOnClick
            />
            <TileInfoCard
              title={i18nextTranslate(i18nextKeys.redemptionId)}
              text={redemptionRequest.Id}
              showTooltip
              copyOnClick
            />
            <TileInfoCard
              title={i18nextTranslate(i18nextKeys.commonReferenceCode)}
              text={redemptionRequest.ReferenceCode}
              copyOnClick
            />
            <TileInfoCard
              title={i18nextTranslate(i18nextKeys.redemptionMethod)}
              text={PayoutMethod.enumToFriendlyString(redemptionRequest.PayoutMethodData.PayoutMethod)}
              copyOnClick
            />
            {!isAdmin && (
              <TileInfoCard
                title={i18nextTranslate(i18nextKeys.redemptionStatus)}
                text={
                  <Chip text={RedemptionStatusEnum.enumToFriendlyString(redemptionRequest.Status)} />
                }
                dataQa="redemption-status"
              />
            )}
          </div>
          {isAdmin && (
            <>
              <StatusUpdater
                id={redemptionRequest.Id}
                status={redemptionRequest.Status}
                statusEnum={RedemptionStatusEnum}
                updateRemoteStatus={api.Redemptions.updateDelta}
                updateLocalStatus={onRedemptionStatusChange}
                label={i18nextTranslate(i18nextKeys.redemptionStatus)}
                errorMessage={i18nextTranslate(
                  i18nextKeys.errorRedeemManagementDetailsNoRedemptionStatusUpdate
                )}
                dataQa="redemption-status-update"
              />
              <HorizontalRule />
              <TileInfoCard
                title={i18nextTranslate(i18nextKeys.commonAdditionalData)}
                text={redemptionRequest.AdditionalData}
                textClassName="break-all"
                textProps={{
                  showNumberOfLines: 3,
                  showFullTextOnClick: true
                }}
                showEditButton={isAdmin}
                onEdit={() => setShowAdditionalDataModal(true)}
              />
            </>
          )}
        </div>
      </Tile>

      {isAdmin && (
        <AdditionalDataModal
          id={redemptionRequest.Id}
          additionalData={redemptionRequest.AdditionalData}
          updateRemoteData={api.Redemptions.updateDelta}
          updateLocalData={onAdditionalDataChange}
          show={showAdditionalDataModal}
          close={() => setShowAdditionalDataModal(false)}
        />
      )}
    </>
  );
};

export default RequestInformation;
