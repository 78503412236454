import { USER_STATUS } from 'Enums';
import {
  SET_AUTHENTICATED,
  SET_LOADING,
  SET_ADMIN,
  SET_EMAIL_CONFIRMED,
  SET_STATUS
} from './authTypes';

const authReducer = (state, action) => {
  switch (action.type) {
    default:
      return state;

    case SET_AUTHENTICATED: {
      const isAuthenticated = action.payload.isAuthenticated;
      const user = action.payload.user;
      return {
        ...state,
        isAuthenticated,
        user,
        loading: false,
        ...!isAuthenticated && {
          isActive: false,
          isBlocked: false
        }
      };
    }

    case SET_ADMIN: {
      return {
        ...state,
        isAdmin: action.payload
      };
    }

    case SET_EMAIL_CONFIRMED: {
      return {
        ...state,
        isEmailConfirmed: true,
        isActive: state.status === USER_STATUS.Approved
      };
    }

    case SET_STATUS: {
      const status = action.payload;
      const isActive = status === USER_STATUS.Approved &&
        state.isEmailConfirmed;
      const isBlocked = status === USER_STATUS.Rejected ||
        status === USER_STATUS.Disabled;
      return {
        ...state,
        status,
        isBlocked,
        isActive
      };
    }

    case SET_LOADING: {
      return {
        ...state,
        loading: action.payload,
      };
    }
  }
};

export default authReducer;
