import React, { createContext, useReducer } from 'react';
import api from 'Api';
import { i18nextKeys } from 'Lang/i18nextKeys';
import i18nextTranslate from 'Lang/i18nextTranslate';
import useHandleError from 'Utils/handleError';
import kycReducer from './kycReducer';
import {
  SET_CURRENT_KYC_TIER,
  SET_LATEST_KYC_CASE,
  SET_LOADING
} from './kycTypes';

export const KycContext = createContext();

const KycState = ({ isKycEnabled, currentKycTier, children }) => {
  const initialState = {
    isKycEnabled: isKycEnabled,
    latestKycCase: {},
    currentKycTier: currentKycTier || 0,
    isLoading: false
  };

  const [state, dispatch] = useReducer(kycReducer, initialState);
  const handleError = useHandleError();

  const setLatestKycCase = (value) => {
    dispatch({ type: SET_LATEST_KYC_CASE, payload: { value } });
  }

  const setCurrentKycTier = (value) => {
    dispatch({ type: SET_CURRENT_KYC_TIER, payload: { value } });
  }

  const setLoading = (value) => {
    dispatch({ type: SET_LOADING, payload: { value } });
  }

  const getUserKycData = async () => {
    try {
      setLoading(true);
      const { TierNumber, LatestCase } = await api.Kyc.getUserKycData();
      setLatestKycCase(LatestCase);
      setCurrentKycTier(TierNumber);
      return { TierNumber, LatestCase };
    } catch (error) {
      if (error.response?.status === 404) {
        return;
      } else {
        const message = i18nextTranslate(i18nextKeys.kycLoadUserTierError);
        handleError({ error, message });
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <KycContext.Provider
      value={{
        isKycEnabled: state.isKycEnabled,
        isKycCaseLoading: state.isLoading,
        currentKycTier: state.currentKycTier,
        latestKycCase: state.latestKycCase,
        getUserKycData,
        setCurrentKycTier,
        setLatestKycCase
      }}
    >
      {children}
    </KycContext.Provider>
  );
};

export default KycState;