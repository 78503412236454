import React, { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { useAmplitude } from 'react-amplitude-hooks';
import api from 'Api';
import Text from 'Components/shared/Text';
import Stamp from 'Components/shared/Stamp';
import { Tile } from 'Components/shared/Tile';
import useHandleError from 'Utils/handleError';
import i18nextTranslate from 'Lang/i18nextTranslate';
import { i18nextKeys } from 'Lang/i18nextKeys';
import BankAccountFormModal from 'Components/BankAccount/Form/BankAccountFormModal';
import { Button } from 'Components/shared/buttons';
import ConfirmationModal from 'Components/shared/ConfirmationModal';
import BankAccountSummary from 'Components/BankAccount/Summary/BankAccountSummary';

const BANK_ACCOUNT_DEFAULT_STATE = { IsActive: true };

const BankAccountsCard = () => {
  const [showBankAccountModal, setShowBankAccountModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [bankAccounts, setBankAccounts] = useState([]);
  const [currentBankAcc, setCurrentBankAcc] = useState(BANK_ACCOUNT_DEFAULT_STATE);
  const handleError = useHandleError();
  const { logEvent } = useAmplitude();

  useEffect(() => {
    const fetchBankAccounts = async () => {
      try {
        const { value: bankAccounts } = await api.BankAccounts.list();
        setBankAccounts(bankAccounts);
      } catch (error) {
        handleError({ error })
      }
    }
    fetchBankAccounts();
  }, []);

  const onEditClick = (bankAccount = BANK_ACCOUNT_DEFAULT_STATE) => {
    setCurrentBankAcc(bankAccount);
    setShowBankAccountModal(true);
  };

  const onBankFormSubmit = async () => {
    try {
      logEvent(
        currentBankAcc.Id
          ? "Bank account edited"
          : "Bank account added"
      );
      setShowBankAccountModal(false);
      setCurrentBankAcc(BANK_ACCOUNT_DEFAULT_STATE);
      const { value: bankAccounts } = await api.BankAccounts.list();
      setBankAccounts(bankAccounts);
    } catch (error) {
      handleError({ error });
    }
  };

  const onDeleteIconClick = (bankAccount) => {
    setCurrentBankAcc(bankAccount);
    setShowConfirmationModal(true);
  };

  const deleteBankAccount = async (bankAccountId) => {
    try {
      setShowConfirmationModal(false);
      await api.BankAccounts.delete(bankAccountId);
      setBankAccounts((bankAccounts) =>
        bankAccounts.filter((bankAccount) => bankAccount.Id !== bankAccountId)
      );
      setCurrentBankAcc(BANK_ACCOUNT_DEFAULT_STATE);
    } catch (error) {
      const message = i18nextTranslate(
        i18nextKeys.errorBankAccountsCardsDeleteBankAccount
      );
      handleError({ error, message });
    }
  };

  const buttonWidth = {
    xxl: '190px',
    default: '170px'
  };

  return (
    <Tile
      title={i18nextTranslate(i18nextKeys.accountBankAccountsHeading)}
      dataQa="account-bankAccounts"
      expandable={!!bankAccounts.length}
    >
      <div className="items-start w-full">
        <div className="flex flex-col">
          {bankAccounts.map((bankAccount, index) => (
            <div key={index} className="flex items-center w-full">
              <Stamp
                key={index}
                onEditClick={() => onEditClick(bankAccount)}
                onDeleteClick={() => onDeleteIconClick(bankAccount)}
                badge={`${bankAccount.Currency || ''}`}
                upperText={bankAccount.BankName}
                lowerText={bankAccount.IBAN}
                style={{
                  flexGrow: 1,
                }}
                expandable
                editable
              >
                <BankAccountSummary
                  bankAccount={bankAccount}
                  className={`pt-20 md:pt-24 ${
                    index === bankAccounts.length - 1 ? '' : 'pb-32'
                  }`}
                />
              </Stamp>
            </div>
          ))}
        </div>

        {bankAccounts.length ? (
          <Button
            text={i18nextTranslate(
              i18nextKeys.cardsBankAccountsFormAddBankAccount
            )}
            onClick={() => onEditClick()}
            className="mt-24 md:mt-32"
            width={buttonWidth}
          />
        ) : (
          <div className="flex flex-col md:flex-row justify-between md:items-center">
            <Text className="text-sm mb-24 md:mb-0">
              {i18nextTranslate(
                i18nextKeys.cardsBankAccountsNoBankAccountStored
              )}
            </Text>
            <Button
              text={i18nextTranslate(
                i18nextKeys.cardsBankAccountsFormAddBankAccount
              )}
              onClick={() => onEditClick()}
              width={buttonWidth}
            />
          </div>
        )}
      </div>
      {showBankAccountModal && (
        <BankAccountFormModal
          show={showBankAccountModal}
          close={() => setShowBankAccountModal(false)}
          onBankFormSubmit={onBankFormSubmit}
          bankAccount={currentBankAcc}
        />
      )}

      <ConfirmationModal
        show={showConfirmationModal}
        close={() => setShowConfirmationModal(false)}
        onConfirm={() => deleteBankAccount(currentBankAcc.Id)}
        confirmButtonText={i18nextTranslate(i18nextKeys.buttonDelete)}
        title={i18nextTranslate(i18nextKeys.accountBankAccountsDeletionHeading)}
        description={
          <Trans i18nKey={i18nextKeys.accountBankAccountsDeletionText}>
            <Text
              textStyle="p2"
              color="color-4"
              className="inline"
            >
              {{ bankAccount: `${currentBankAcc.BankName} (${currentBankAcc.IBAN})` }}
            </Text>
          </Trans>
        }
        dataQa="bankAccount-delete-confirmation-modal"
      />
    </Tile>
  );
};

export default BankAccountsCard;
