import React from "react";

const ErrorIcon = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 60 60"
    fill="currentColor"
    className="color-8"
  >
    <path d="M2.91669 5.83301V41.3232C2.91669 44.5937 5.57754 47.2546 8.84804 47.2546H25.2206V50.4409H22.7538C19.7217 50.4409 17.2549 52.9077 17.2549 55.9397V59.9997H42.7451V55.9397C42.7451 52.9077 40.2783 50.4409 37.2462 50.4409H34.7794V47.2546H51.152C54.4225 47.2546 57.0834 44.5937 57.0834 41.3232V5.83301H2.91669ZM53.8971 9.01928V37.6958H6.10296V9.01928H53.8971ZM39.5588 55.9397V56.8134H20.4412V55.9397C20.4412 54.6645 21.4786 53.6271 22.7538 53.6271H37.2461C38.5214 53.6271 39.5588 54.6646 39.5588 55.9397ZM31.5932 50.4409H28.4069V47.2546H31.5932V50.4409ZM51.152 44.0683H8.84804C7.33446 44.0683 6.10296 42.8368 6.10296 41.3232V40.882H53.8971V41.3232C53.8971 42.8368 52.6656 44.0683 51.152 44.0683Z" />
    <path d="M16.9328 22.2152L19.1858 19.9622L21.439 22.2152L23.6919 19.9622L21.4389 17.7092L23.6919 15.456L21.439 13.2031L19.1858 15.4561L16.9328 13.2031L14.6798 15.456L16.9328 17.7092L14.6798 19.9622L16.9328 22.2152Z" />
    <path d="M39.0923 22.2152L41.3454 19.9622L43.5984 22.2152L45.8514 19.9622L43.5984 17.7092L45.8514 15.456L43.5984 13.2031L41.3454 15.4561L39.0923 13.2031L36.8394 15.456L39.0924 17.7092L36.8394 19.9622L39.0923 22.2152Z" />
    <path d="M19.6928 31.3401C25.8578 27.0883 34.1422 27.088 40.3077 31.3403L44.4961 34.2287L46.305 31.6057L42.1166 28.7173C38.5376 26.2489 34.3478 24.9443 30.0002 24.9443C25.6525 24.9443 21.4628 26.2489 17.8838 28.7172L13.6954 31.6057L15.5042 34.2287L19.6928 31.3401Z" />
  </svg>
);

export default ErrorIcon;
