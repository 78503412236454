import { useState, useEffect } from 'react';
import { DateTime, Interval } from 'luxon';

const getDuration = (startDate, duration) => {
  return Interval.fromDateTimes(DateTime.now(), startDate.plus(duration)).toDuration([
    'days', 'hours', 'minutes', 'seconds'
  ]);
};

const useTimer = (startDate, duration) => {
  const [remainingDuration, setRemainingDuration] = useState(getDuration(startDate, duration));

  useEffect(() => {
    const interval = setInterval(() => {
      setRemainingDuration(getDuration(startDate, duration));
    }, 1000);
    return () => clearInterval(interval);
  }, [startDate, duration]);

  return remainingDuration;
};

export default useTimer;
