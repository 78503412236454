import React, { useContext, useEffect } from 'react';
import PhoneInputReact from 'react-phone-input-2';
import 'react-phone-input-2/lib/bootstrap.css';
import { LangContext } from 'States/lang/langState';
import { UiContext } from "States/ui/uiState";
import Text from '../Text';
import Error from './Error';
import * as indexAll from './index';

const PhoneInput = ({
  className = 'w-full px-12',
  meta: { touched, error, valid },
  input,
  required,
  label,
  dataQa,
  disabled,
  scrollContainerRef,
}) => {
  const { lang } = useContext(LangContext);
  const {
    breakpoints: { xxl },
  } = useContext(UiContext);

  let reactTelField, reactTelInput;

  /* TODO: These are some monkey-patched workarounds to solve #14742.
            Keep an eye on https://github.com/bl00mber/react-phone-input-2/pull/507 which would be a better solution
  */
  useEffect(() => {
    if (scrollContainerRef?.current) {
      reactTelField = document.querySelector(
        `[data-qa="${dataQa}"] .react-tel-input`
      );
      reactTelInput = reactTelField?.querySelector('input');

      scrollContainerRef.current.addEventListener('scroll', onScroll);
      return () =>
        scrollContainerRef.current?.removeEventListener('scroll', onScroll);
    }
  }, []);

  useEffect(() => {
    if (scrollContainerRef?.current) {
      const mutationObserver = new MutationObserver(() => {
        onClassChange(reactTelInput);
      });
      mutationObserver.observe(reactTelInput, {
        attributes: true,
        attributeFilter: ['class'],
      });
    }
  }, []);

  const onScroll = () => {
    fixDropdownHeight();
  };

  const onClassChange = (reactTelInput) => {
    if (reactTelInput.className.includes('open')) {
      fixDropdownHeight();
    }
  };

  const fixDropdownHeight = () => {
    const countryListDropdown = reactTelField?.querySelector(`.country-list`);
    if (countryListDropdown) {
      const { top } = reactTelInput.getBoundingClientRect();
      countryListDropdown.style.top = `${top + reactTelInput.offsetHeight}px`;
    }
  };

  return (
    <span
      className={className}
      data-qa={dataQa}
    >
      {label && (
        <Text
          textStyle="h3"
          className="mb-4 truncate"
          dataQa={`${dataQa}-label`}
        >
          <label>
            {label}{required ? " *" : null}
          </label>
        </Text>
      )}
      <PhoneInputReact
        preferredCountries={['ch', 'de', 'at']}
        country={'ch'}
        localization={indexAll[lang]}
        {...input}
        disabled={disabled}
        inputClass={`w-full appearance-none block w-full
                  font-sans phone-input
                  ${touched && error ? 'border-red-500 ' : ''}
                  ${valid ? 'border-green-500 ' : ''}
                  focus:outline-none focus:boxShadowNoneImportant inputField focus:bg-9-10`}
        inputStyle={{
          height: xxl ? '38px' : '35px',
          paddingTop: 0,
          paddingBottom: 0,
          paddingRight: "10px",
          backgroundColor: disabled ? 'var(--color-9--10)' : 'var(--color-9)',
          color: 'var(--color-10)',
          borderRadius: '4px',
        }}
      />
      {
        touched && error ? (
          <Error
            error={touched && error}
            dataQa={`${dataQa}-error`}
          />
        ) : null
      }
    </span>
  );
};

export default PhoneInput;
