import React, { useContext, useState } from "react";
import { useAsyncDebounce } from "react-table";
import { AbortSymbol, SearchIcon } from "../symbols";
import { UiContext } from "States/ui/uiState";

const GlobalFilter = ({
  className = '',
  globalFilter,
  setGlobalFilter,
  placeholder,
  dataQa = "search",
  style,
  width
}) => {
  const [value, setValue] = useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 1000);
  const {
    breakpoints: { xxl },
  } = useContext(UiContext);

  return (
    <div
      data-qa={dataQa}
      className={`
        ${className}
        rounded flex gap-8 items-center bg-3-2 border border-color-5 p-10 color-8_60 outline-none
      `}
      style={{
        height: xxl ? "38px" : "35px",
        width: width || (xxl ? "270px" : "240px"),
        ...style
      }}
    >
      <SearchIcon />
      <input
        data-qa={`${dataQa}-input`}
        className="bg-transparent w-full text-sm color-8 outline-none searchInput"
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          if (e.target.value.length >= 3 || e.target.value === "") {
            onChange(e.target.value);
          }
        }}
        placeholder={placeholder}
      />
      {value && (
        <AbortSymbol
          size="16"
          className="flex-shrink-0 color-8 cursor-pointer"
          onClick={() => {
            setValue("");
            onChange("");
          }}
          data-qa={`${dataQa}-clear`}
        />
      )}
    </div>
  );
};

export default GlobalFilter;
