import React, { useEffect, useRef, useState } from 'react';
import Text from '../Text';
import Tooltip from '../Tooltip';

const TableCell = ({ cell, dataQa, className }) => {
  const textElementRef = useRef();

  const compareSize = () => {
    const compare =
      textElementRef.current.scrollWidth > textElementRef.current.clientWidth;
    setHover(compare);
  };

  useEffect(() => {
    compareSize();
    window.addEventListener('resize', compareSize);
  }, []);

  useEffect(
    () => () => {
      window.removeEventListener('resize', compareSize);
    },
    []
  );

  const [hoverStatus, setHover] = useState(false);

  return (
    <td
      style={cell.column.style}
      className={className}
    >
      <Tooltip
        disableHoverListener={!hoverStatus}
        {...cell.getCellProps([{
            childClassName: cell.column.className
        }])}
        text={cell.value}
        childStyle={{ width: cell.column.width }}
        dataQa={dataQa}
      >
        <Text
          reference={textElementRef}
          color={cell.column.color}
          className="block truncate"
          dataQa={dataQa}
        >
          {cell.render('Cell')}
        </Text>
      </Tooltip>
    </td>
  );
};

export default TableCell;
