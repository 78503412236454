import { combineValidators } from 'revalidate';
import i18nextTranslate from 'Lang/i18nextTranslate';
import { i18nextKeys } from 'Lang/i18nextKeys';
import { isRequiredWithMessage } from 'Utils/validators';

const UserInfoFormValidator = combineValidators({
  FirstName: isRequiredWithMessage(
    i18nextTranslate(i18nextKeys.commonFirstName)
  ),
  LastName: isRequiredWithMessage(
    i18nextTranslate(i18nextKeys.commonLastName)
  ),
  PhoneNumber: isRequiredWithMessage(
    i18nextTranslate(i18nextKeys.commonPhoneNumber)
  ),
  HomeAddress: combineValidators({
    AddressLine: isRequiredWithMessage(
      i18nextTranslate(i18nextKeys.cardsKycDetailsFormAddressLine)
    ),
    ZipCode: isRequiredWithMessage(
      i18nextTranslate(i18nextKeys.commonAddressZip)
    ),
    City: isRequiredWithMessage(
      i18nextTranslate(i18nextKeys.commonAddressCity)
    ),
    Country: isRequiredWithMessage(
      i18nextTranslate(i18nextKeys.commonAddressCountry)
    ),
  }),
});

export default UserInfoFormValidator;
