import i18nextTranslate from "../Lang/i18nextTranslate";
import {i18nextKeys} from "../Lang/i18nextKeys";

export const COLLECT_METHOD = {
  None: 'None',
  TokenCard: 'TokenCard',
  OwnWallet: 'OwnWallet',
  OwnNonSignableWallet: 'OwnNonSignableWallet',
  OneTimeWallet: 'OneTimeWallet'
};

const enumToFriendlyString = (e) => {
  const {
    TokenCard,
    OwnWallet,
    OwnNonSignableWallet,
    OneTimeWallet
  } = COLLECT_METHOD;
  switch (e) {
    case TokenCard: {
      return i18nextTranslate(i18nextKeys.enumCollectMethodTokenCardName);
    }
    case OwnWallet: {
      return i18nextTranslate(i18nextKeys.enumCollectMethodOwnWalletName);
    }
    case OwnNonSignableWallet:
    case OneTimeWallet: {
      return i18nextTranslate(i18nextKeys.enumCollectMethodOwnWalletNsName);
    }
    default: {
      return i18nextTranslate(i18nextKeys.enumNone);
    }
  }
};

const friendlyStringToEnum = (str) => {
  const { TokenCard, OwnWallet, OwnNonSignableWallet } = COLLECT_METHOD;
  switch (str) {
    case i18nextTranslate(i18nextKeys.enumCollectMethodTokenCardName): {
      return TokenCard;
    }
    case i18nextTranslate(i18nextKeys.enumCollectMethodOwnWalletName): {
      return OwnWallet;
    }
    case i18nextTranslate(i18nextKeys.enumCollectMethodOwnWalletNsName): {
      return OwnNonSignableWallet;
    }
    default: {
      return COLLECT_METHOD[str] || COLLECT_METHOD.None;
    }
  }
};

const CollectMethod = {
  values: COLLECT_METHOD,
  enumToFriendlyString,
  friendlyStringToEnum,
};

export default CollectMethod;
