import { useContext } from 'react';
import { useQuery } from 'react-query';
import api from 'Api';
import { DYNAMIC_PRICE_POLLING_INTERVAL } from 'Helpers/assets';
import { EnvContext } from 'States/env/envState';
import useHandleError from 'Utils/handleError';

const PURCHASE_METHODS_QUERY_KEY = 'purchaseMethodsQuery';

const usePurchaseMethodsQuery = ({
  queryKeys = [],
  queryFnArgs = [],
  onSuccess = () => {},
  onError = () => {},
  enablePolling = false,
  ...config
}) => {
  const { env } = useContext(EnvContext);
  const handleError = useHandleError();
  return useQuery({
    queryKey: [PURCHASE_METHODS_QUERY_KEY, ...queryKeys],
    queryFn: () => api.Assets.getAssetsConfig(env?.TenantId),
    select: ({ value }) => value,
    onError: (error) => {
      onError(error);
      handleError({ error });
    },
    refetchInterval: enablePolling ? DYNAMIC_PRICE_POLLING_INTERVAL : false,
    ...config,
  });
};

const FEATURED_PURCHASE_METHODS_QUERY_KEY = 'featuredPurchaseMethodsQuery';

const useFeaturedPurchaseMethodsQuery = ({
  queryKeys = [],
  queryFnArgs = [],
  onSuccess = () => {},
  onError = () => {},
  ...config
}) => {
  const { env } = useContext(EnvContext);
  const handleError = useHandleError();
  return useQuery({
    queryKey: [FEATURED_PURCHASE_METHODS_QUERY_KEY, ...queryKeys],
    queryFn: () => api.Assets.getAssetsConfig(env?.TenantId, "IsFeaturedAsset"),
    select: ({ value }) => value,
    onError: (error) => {
      onError(error);
      handleError({ error });
    },
    staleTime: 300000,
    ...config,
  });
};

const usePurchaseMethodPollingQuery = ({
  queryKeys = [],
  queryFnArgs = [],
  onSuccess = () => {},
  onError = () => {},
  id,
  ...config
}) => {
  const { env } = useContext(EnvContext);
  const handleError = useHandleError();
  return useQuery({
    queryKey: [id, ...queryKeys],
    queryFn: () => api.Assets.getAssetConfig(env?.TenantId, id),
    select: ({ Price }) => Price,
    onError: (error) => {
      onError(error);
      handleError({ error });
    },
    refetchInterval: DYNAMIC_PRICE_POLLING_INTERVAL,
    ...config,
  });
};

const usePurchaseMethods = {
  purchaseMethodsQuery: usePurchaseMethodsQuery,
  featuredMethodsQuery: useFeaturedPurchaseMethodsQuery,
  methodPollingQuery: usePurchaseMethodPollingQuery
};

export default usePurchaseMethods;
