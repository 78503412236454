import React from 'react';
import i18nextTranslate from 'Lang/i18nextTranslate';
import { i18nextKeys } from 'Lang/i18nextKeys';

const WalletInformation = ({ wallet }) => {
  return (
    <div className="flex flex-col md:flex-row mb-16">
      <div className="flex flex-col w-full">
        <span data-qa="walletInfo-walletAddress">
          <span className="color-8 font-bold text-sm">
            {i18nextTranslate(i18nextKeys.commonWalletAddress)}:
          </span>
          <span className="text-sm"> {wallet.Address}</span>
        </span>
        <span data-qa="walletInfo-walletType">
          <span className="color-8 font-bold text-sm">
            {i18nextTranslate(i18nextKeys.cardsWalletType)}:
          </span>
          <span className="text-sm"> {wallet.Type}</span>
        </span>
      </div>
    </div>
  );
};

export default WalletInformation;
