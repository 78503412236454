import React from "react";
import Text, { LabeledValue } from "Components/shared/Text";
import i18nextTranslate from "Lang/i18nextTranslate";
import { i18nextKeys } from "Lang/i18nextKeys";

const WalletSummary = ({ wallet, dataQa = "wallet" }) => {
  return (
    <div className="flex flex-col gap-16">
      <Text
        textStyle="p2"
        color="color-4"
        dataQa={`${dataQa}-title`}
      >
        {i18nextTranslate(i18nextKeys.commonWalletDetails)}
      </Text>
      <div
        className="flex flex-col gap-6"
      >
        <LabeledValue
          label={i18nextTranslate(i18nextKeys.commonWalletName)}
          value={wallet.Name}
          dataQa={`${dataQa}-name`}
        />
        <LabeledValue
          label={i18nextTranslate(i18nextKeys.commonWalletAddress)}
          value={wallet.Address}
          dataQa={`${dataQa}-address`}
        />
      </div>
    </div>
  )
};

export default WalletSummary;
