import React from "react";
import { i18nextKeys } from "Lang/i18nextKeys";
import i18nextTranslate from "Lang/i18nextTranslate";
import { useFormatNumber } from "Utils/formatNumber";
import Text from "Components/shared/Text";

const FeeTooltip = ({
  description,
  percentageText,
  units,
  altUnits,
  fee = {},
  altFee = {},
  dataQa
}) => {
  const formatNumber = useFormatNumber();

  return (
    <div className="flex flex-col gap-24">
      <Text
        dataQa={`fee-${dataQa}-description`}
        inline
      >
        {description}
      </Text>
      <div>
        <div data-qa={`fee-${dataQa}-variable`}>
          <Text
            textStyle="p2"
            dataQa={`fee-${dataQa}-variable-label`}
            inline
          >
            {`${i18nextTranslate(i18nextKeys.commonFeeVariable)} (${percentageText}): `}
          </Text>
          <Text
            dataQa={`fee-${dataQa}-variable-amount`}
            inline
          >
            {formatNumber(fee.VariableAmount)} {units}
          </Text>
          {altUnits && (
            <Text
              dataQa={`fee-${dataQa}-variable-amount-alt`}
              inline
            >
              {` (${formatNumber(altFee.VariableAmount)} ${altUnits})`}
            </Text>
          )}
        </div>
        <div data-qa={`fee-${dataQa}-fixed`}>
          <Text
            textStyle="p2"
            dataQa={`fee-${dataQa}-fixed-label`}
            inline
          >
            {`${i18nextTranslate(i18nextKeys.commonFeeFixed)}: `}
          </Text>
          <Text
            dataQa={`fee-${dataQa}-fixed-amount`}
            inline
          >
            {formatNumber(fee.FixedAmount)} {units}
          </Text>
          {altUnits && (
            <Text
              dataQa={`fee-${dataQa}-fixed-amount-alt`}
              inline
            >
              {` (${formatNumber(altFee.FixedAmount)} ${altUnits})`}
            </Text>
          )}
        </div>
        <div data-qa={`fee-${dataQa}-total`}>
          <Text
            textStyle="p2"
            dataQa={`fee-${dataQa}-total-label`}
            inline
          >
            {`${i18nextTranslate(i18nextKeys.commonTotal)}: `}
          </Text>
          {!!(Number(fee.VariableAmount) && Number(fee.FixedAmount)) && (
            <Text inline>
              {`${formatNumber(fee.VariableAmount)} + ${
                formatNumber(fee.FixedAmount)
              } = `}
            </Text>
          )}
          <Text
            dataQa={`fee-${dataQa}-total-amount`}
            inline
          >
            {formatNumber(fee.TotalAmount)} {units}
          </Text>
          {altUnits && (
            <Text
              dataQa={`fee-${dataQa}-total-amount-alt`}
              inline
            >
              {` (${formatNumber(altFee.TotalAmount)} ${altUnits})`}
            </Text>
          )}
        </div>
      </div>
    </div>
  );
};

export default FeeTooltip;
