import React, { useEffect, useState } from 'react';
import './fade.css';

const Fade = ({ show, children, className = '', maxWidth = '100%' }) => {
  const [render, setRender] = useState(show);

  useEffect(() => {
    if (show) setRender(true);
  }, [show]);

  const onAnimationEnd = () => {
    if (!show) setRender(false);
  };

  return (
    render && (
      <div
        className={`fade ${show ? 'show' : 'not-show'} ${className}`}
        style={{
          maxWidth,
          animation: `${show ? 'fadeIn' : 'fadeOut'} 1s`,
        }}
        onAnimationEnd={onAnimationEnd}
      >
        {children}
      </div>
    )
  );
};

export default Fade;
