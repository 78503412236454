import React, { useEffect } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { ROUTES } from 'Router/Routes';
import { STORAGE_KEYS } from 'Enums';

const CheckoutFinishPending = ({
  onCheckoutFinish,
  paymentMethod,
  setBackNavigated,
  backNavigated,
}) => {
  const location = useLocation();
  const order = location?.state?.order || {
    ...JSON.parse(sessionStorage.getItem(STORAGE_KEYS.order)),
  };

  useEffect(() => {
    if (backNavigated) {
      setBackNavigated(false);
      onCheckoutFinish();
    } else {
      setBackNavigated(true);
    }
  }, []);

  return (
    <Redirect
      push
      to={{
        pathname: paymentMethod
          ? `${ROUTES.checkoutFinish}/${paymentMethod}`
          : ROUTES.checkoutFinish,
        state: { ...location?.state, order },
      }}
    />
  );
};

export default CheckoutFinishPending;
