import React, { Fragment, useEffect } from "react";
import NavigationStep from "./NavigationStep";

const Breadcrumbs = ({ navigationSteps = [] }) => {
  const refs = navigationSteps.reduce((acc, value) => {
    acc[value.text] = React.createRef();
    return acc;
  }, {});

  useEffect(() => {
    if (refs && Object.keys(refs).length > 0) {
      const activeItem = Object.keys(refs).find((key) => {
        return refs[key].current?.classList.contains("active");
      });
      refs[activeItem]?.current.scrollIntoView({ inline: "end" });
    }
  }, [navigationSteps]);

  return (
    navigationSteps.length ? (
      <ul
        className="flex flex-no-wrap items-center gap-8 lg:gap-12 xxl:gap-20 overflow-scroll header-navigation"
        data-qa="header-navigation"
      >
        {navigationSteps.map(({ text, active }, index) => {
          return (
            <Fragment key={index}>
              <NavigationStep
                refs={refs}
                text={text}
                index={index}
                active={active}
              />
              {index !== navigationSteps.length - 1 ? (
                <span
                  className="color-8 text-xs cursor-default"
                  data-qa={`navigation-step-${index + 1}-arrow`}
                >
                  &gt;
                </span>
              ) : null}
            </Fragment>
          );
        })}
      </ul>
    ) : null
  );
}

export default Breadcrumbs;
