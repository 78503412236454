import React from 'react';
import DefaultCardKycButtonGroup from 'Components/shared/cards/DefaultCardKycButtonGroup';
import { i18nextKeys } from 'Lang/i18nextKeys';

const CheckoutPaymentButtonGroup = ({
  requiredTier,
  selectPaymentMethod,
  disabled = false
}) => {
  return (
    <DefaultCardKycButtonGroup
      buttonConfig={{
        topButton: {
          show: false,
          label: {},
        },
        bottomButton: {
          show: true,
          onClick: selectPaymentMethod,
          label: {
            insufficientTier: i18nextKeys.kycGetTierToSelect,
            sufficientTier: i18nextKeys.buttonSelectThisMethod,
            disabled: i18nextKeys.buttonNotAvailable
          },
          origin: "payment tile",
          disabled
        }
      }}
      requiredTier={requiredTier}
    />
  );
};

export default CheckoutPaymentButtonGroup;
