import React from 'react';

const TokenCardSymbol = ({
  size = "32",
  className = "color-4",
  ...props
}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 32 32"
    fill="currentColor"
    className={className}
    {...props}
  >
    <path d="M28.728 10.7159V9.18182C28.728 7.42455 27.3034 6 25.5462 6H6.45526C4.69798 6 3.27344 7.42455 3.27344 9.18182V10.7159C3.27344 10.8728 3.40065 11 3.55753 11H28.4439C28.6008 11 28.728 10.8728 28.728 10.7159Z" />
    <path d="M3.27344 13.5575V22.8189C3.27344 24.5762 4.69798 26.0007 6.45526 26.0007H25.5462C27.3034 26.0007 28.728 24.5762 28.728 22.8189V13.5575C28.728 13.4007 28.6008 13.2734 28.4439 13.2734H3.55753C3.40065 13.2734 3.27344 13.4007 3.27344 13.5575ZM12.3643 20.5462C12.3643 21.0482 11.9573 21.4553 11.4553 21.4553H6.9098C6.40776 21.4553 6.00071 21.0482 6.00071 20.5462V19.6371C6.00071 19.135 6.40776 18.728 6.9098 18.728C6.9098 18.728 10.9532 18.728 11.4553 18.728C11.9573 18.728 12.3643 19.135 12.3643 19.6371V20.5462Z" />
  </svg>
);

export default TokenCardSymbol;
