export const DYNAMIC_PRICE_POLLING_INTERVAL = 60000;

export const getMaxPurchasableUnits = (
  availableAmountAtIssuer,
  maxAmountBlobConfig,
  roundDown = true,
) => {
  let maxUnits;
  if (!isNaN(maxAmountBlobConfig)) {
    maxUnits = Math.min(availableAmountAtIssuer, maxAmountBlobConfig);
  } else {
    maxUnits = availableAmountAtIssuer;
  }
  return roundDown ? Math.floor(maxUnits) : maxUnits;
};

export const sortCartItems = (cartItems, fullAssetsInfo) => {
  const sortedAssets = {
    availableAssets: [],
    unavailableAssets: [],
    assetsExceedingMaxAmount: []
  };
  if (!fullAssetsInfo) {
    return sortedAssets;
  }
  Object.keys(cartItems).reduce((sortedAssets, Id) => {
    const currentItemFullInfo = fullAssetsInfo[Id];
    if (currentItemFullInfo?.availableAmount === 0 || currentItemFullInfo?.maxAmount === 0) {
      sortedAssets.unavailableAssets.push(Id);
    } else {
      sortedAssets.availableAssets.push(Id);
      if (cartItems[Id]?.Units > currentItemFullInfo?.maxPurchasableUnits) {
        sortedAssets.assetsExceedingMaxAmount.push(Id);
      }
    }
    return sortedAssets;
  }, sortedAssets);
  return sortedAssets;
};
