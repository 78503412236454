import api from 'Api';
import { useQuery } from 'react-query';

const USER_DATA_QUERY_KEY = 'userDataQuery';

const useUserDataQuery = ({ queryKeys = [], queryFnArgs = [], ...config }) => {
  return useQuery({
    queryKey: [USER_DATA_QUERY_KEY, ...queryKeys],
    queryFn: async () => await api.User.get(...queryFnArgs),
    refetchOnWindowFocus: false,
    ...config,
  });
};

const useUserData = {
  query: useUserDataQuery,
};

export default useUserData;
