import React from 'react';
import AnimateHeight from 'react-animate-height';
import LoadingSpinner from 'Components/shared/LoadingSpinner';
import { Button } from 'Components/shared/buttons';
import {
  Form as FinalForm,
  Field as FinalField,
  FormSpy as FinalFormSpy,
} from 'react-final-form';
import {
  FormField,
  PhoneInput,
  CountryInput,
  DateInput,
} from 'Components/shared/formElements';
import userInfoFormValidator from './userInfoFormValidator';
import i18nextTranslate from 'Lang/i18nextTranslate';
import { i18nextKeys } from 'Lang/i18nextKeys';

const UserInfoForm = ({
  user,
  isLoading,
  onSubmit,
  close,
  modalContainerRef,
}) => {
  const buttonWidth = {
    xxl: '190px',
    md: '170px',
    default: '140px'
  };

  return (
    <>
      <AnimateHeight duration={750} height={!isLoading ? 0 : 'auto'}>
        <LoadingSpinner />
      </AnimateHeight>

      <AnimateHeight duration={750} height={isLoading ? 0 : 'auto'}>
        <div className="flex flex-col items-center color-8 p-24">
          <h2 className="mr-auto font-bold">
            {i18nextTranslate(i18nextKeys.cardsUserEditPersonalInfo)}
          </h2>
          <FinalForm
            validate={userInfoFormValidator}
            onSubmit={onSubmit}
            initialValues={user}
            render={({ handleSubmit }) => (
              <div className="mt-24 w-full flex flex-wrap">
                <FinalField
                  className="w-1/2 md:w-1/4 pr-8"
                  name={`FirstName`}
                  label={i18nextTranslate(i18nextKeys.commonFirstName)}
                  render={(props) => (
                    <FormField dataQa="userInfo-fNameField" {...props} />
                  )}
                  required
                />
                <FinalField
                  className="w-1/2 md:w-1/4 pl-8 md:pr-8"
                  name={`LastName`}
                  label={i18nextTranslate(i18nextKeys.commonLastName)}
                  render={(props) => (
                    <FormField dataQa="userInfo-lNameField" {...props} />
                  )}
                  required
                />

                <FinalField
                  className="w-1/2 md:w-1/4 pr-8 md:pl-8"
                  name={`Nationality`}
                  label={i18nextTranslate(i18nextKeys.commonNationality)}
                  render={(props) => (
                    <FormField dataQa="userInfo-nationalityField" {...props} />
                  )}
                />

                <FinalField
                  className="w-1/2 md:w-1/4 pl-8"
                  name={`BirthDate`}
                  label={i18nextTranslate(i18nextKeys.commonBirthDate)}
                  render={(props) => (
                    <DateInput dataQa="userInfo-birthdateField" {...props} />
                  )}
                />

                <FinalField
                  className="w-1/2 md:w-1/4 pr-8"
                  name={`PhoneNumber`}
                  label={i18nextTranslate(i18nextKeys.commonPhoneNumber)}
                  dataQa="userInfo-phoneNumber"
                  scrollContainerRef={modalContainerRef}
                  component={PhoneInput}
                  required
                />

                <FinalField
                  className="w-1/2 md:w-1/4 pl-8 md:pr-8"
                  name={`HomeAddress.ZipCode`}
                  label={i18nextTranslate(i18nextKeys.commonAddressZip)}
                  render={(props) => (
                    <FormField dataQa="userInfo-zipField" {...props} />
                  )}
                  required
                />

                <FinalField
                  className="w-full md:w-1/2 md:pl-8"
                  name="HomeAddress.AddressLine"
                  label={i18nextTranslate(
                    i18nextKeys.cardsKycDetailsFormAddressLine
                  )}
                  render={(props) => (
                    <FormField dataQa="userInfo-Addr1Field" {...props} />
                  )}
                  required
                />
                <FinalField
                  className="w-1/2 md:w-1/4 pr-8"
                  name={`HomeAddress.City`}
                  label={i18nextTranslate(i18nextKeys.commonAddressCity)}
                  render={(props) => (
                    <FormField dataQa="userInfo-cityField" {...props} />
                  )}
                  required
                />
                <FinalField
                  className="w-1/2 md:w-1/4 pl-8 md:pr-8"
                  name={`HomeAddress.State`}
                  label={i18nextTranslate(i18nextKeys.cardsKycDetailsFormState)}
                  render={(props) => (
                    <FormField dataQa="userInfo-stateField" {...props} />
                  )}
                />
                <FinalField
                  className="w-full md:w-1/2 md:pl-8"
                  name={`HomeAddress.Country`}
                  menuPlacement={'top'}
                  label={i18nextTranslate(i18nextKeys.commonAddressCountry)}
                  render={(props) => (
                    <CountryInput dataQa="userInfo-countryField" {...props} />
                  )}
                  required
                />

                <FinalFormSpy subscription={{ pristine: true }}>
                  {(props) => (
                    <div className="flex justify-between w-full mt-32 md:mt-40 xxl:mt-64">
                      <Button
                        dataQa="userInfo-cancelButton"
                        text={i18nextTranslate(i18nextKeys.buttonCancel)}
                        onClick={close}
                        width={buttonWidth}
                        secondary
                      />

                      <Button
                        dataQa="userInfo-saveButton"
                        text={i18nextTranslate(i18nextKeys.buttonSave)}
                        onClick={handleSubmit}
                        width={buttonWidth}
                        disabled={props.pristine}
                      />
                    </div>
                  )}
                </FinalFormSpy>
              </div>
            )}
          />
        </div>
      </AnimateHeight>
    </>
  );
};

export default UserInfoForm;
