import React from 'react';

const InfoIcon = ({
  dataQa = "info-icon",
  size = 12
}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 12 12"
    fill="currentColor"
    className="color-8_50"
    data-qa={dataQa}
  >
    <path d="M6.59845 5.40039H5.39844V9.0004H6.59845V5.40039Z" />
    <path d="M5.99999 0C2.68501 0 0 2.68501 0 5.99999C0 9.31497 2.68501 12 5.99999 12C9.31496 12 12 9.31499 12 5.99999C12 2.68498 9.31499 0 5.99999 0ZM5.99999 10.8C3.35399 10.8 1.19998 8.64598 1.19998 5.99999C1.19998 3.35399 3.35399 1.20001 5.99999 1.20001C8.64598 1.20001 10.8 3.35399 10.8 5.99999C10.8 8.64598 8.64598 10.8 5.99999 10.8Z" />
    <path d="M6.59845 3H5.39844V4.2H6.59845V3Z" />
  </svg>
);

export default InfoIcon;
