import React, { useContext } from "react";
import { UiContext } from "States/ui/uiState";
import { i18nextKeys } from "Lang/i18nextKeys";
import i18nextTranslate from "Lang/i18nextTranslate";
import { IconCircleWrapper } from 'Helpers/icons';
import { NoDataIcon } from "Components/shared/symbols";
import Text from "Components/shared/Text";

const NoDataPage = ({
  style = {},
  dataQa = "noData"
}) => {
  const {
    breakpoints: { xxl },
  } = useContext(UiContext);
  return (
    <div
      className="flex flex-col items-center justify-center gap-24"
      style={style}
      data-qa={dataQa}
    >
      <IconCircleWrapper
        icon={
          <NoDataIcon
            size={xxl ? 72 : 60}
            dataQa={`${dataQa}-icon`}
          />
        }
        size={xxl ? '144px' : '120px'}
        dataQa={dataQa}
      />
      <Text
        textStyle="h1"
        dataQa={`${dataQa}-text`}
      >
        {i18nextTranslate(i18nextKeys.commonErrorNoData)}
      </Text>
    </div>
  );
};

export default NoDataPage;
