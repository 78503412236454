import React from 'react';

function EthereumSymbol({ size }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 182 292"
      fill="currentColor"
      className="color-4"
    >
      <path d="M90.334 218.626L.7 165.728l89.58 126.281 89.681-126.281-89.68 52.898h.054zM91.666 0L2.068 148.71l89.598 52.989 89.635-52.934L91.666 0z" />
    </svg>
  );
}

export default EthereumSymbol;
