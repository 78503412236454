import React from "react";
import { components } from "react-select";
import { PlusSymbol } from "Components/shared/symbols";

const OptionAddAnotherBankAccount = (props) => {
  const { data, children } = props;

  return data.addAnother ? (
    <components.Option {...props}>
      <div className="flex gap-8 items-center">
        <PlusSymbol />
        {children}
      </div>
    </components.Option>
  ) : (
    <components.Option {...props}>
      <div className="flex justify-between">
        <span className="color-20">
          {data.BankName}
        </span>
        <span className="color-grey">
          {data.IBAN}
        </span>
      </div>
    </components.Option>
  )
}

export default OptionAddAnotherBankAccount;
