import { toast } from 'react-toastify';
import i18nextTranslate from 'Lang/i18nextTranslate';
import { i18nextKeys } from 'Lang/i18nextKeys';
import i18nextTranslateDynamically from 'Lang/i18nextTranslateDynamically';

const errorNotification = (
  error,
  message = i18nextTranslate(i18nextKeys.errorDefaultMessage),
  finalNote = true
) => {
  console.error(error);
  const toastId = toast.error(
    i18nextTranslateDynamically(i18nextKeys.errorToastError, {
      message: message,
      finalNote: finalNote
        ? i18nextTranslate(i18nextKeys.errorToastErrorFinalNote)
        : '',
      clickMessage:
        error.message || error.response
          ? i18nextTranslate(i18nextKeys.errorToastErrorClickMessage)
          : '',
    }),
    {
      onClick: () => {
        if (error.message || error.response || error.correlationId)
          toast.update(toastId, {
            render:
              `${error?.response?.data?.error?.message
                || error?.response?.data?.message
                || error.message}
              (${error.correlationId})`,
            autoClose: false,
          });
      },
      closeOnClick: false,
      className: 'bg-red',
    }
  );
};

export default errorNotification;
