import React, { useContext } from "react";
import { Field as FinalField } from 'react-final-form';
import { UiContext } from "States/ui/uiState";
import i18nextTranslate from "Lang/i18nextTranslate";
import { i18nextKeys } from "Lang/i18nextKeys";
import { isRequiredIfWithMessage } from "Utils/validators";
import {
  CountryInput,
  FormField,
  PhoneInput
} from 'Components/shared/formElements';
import { Tile } from "Components/shared/Tile";
import FormStep from "Components/shared/formElements/FormStep";

const UserDetailsForm = ({
  user = {},
  title,
  firstStepTitle,
  secondStepTitle,
  includeEmail = false,
  disableEmail = false,
  includePhoneNumber = false,
  disablePhoneNumber = false,
  dataQa = "beneficiary",
  customField = null,
  fieldGroup = null,
  borderRadius = "rounded",
  requiredToggleName = "", // TODO: find a better approach for triggering required state
  required = true,
  expandable = false,
  disabled = false,
  onCountryChange = () => {}
}) => {
  const {
    breakpoints: { md }
  } = useContext(UiContext);

  const fieldPrefix = fieldGroup + '.';
  return (
    <Tile
      title={title}
      xlPadding="xl:p-32"
      xxlPadding="xxl:p-32"
      borderRadius={borderRadius}
      dataQa={dataQa}
      expandable={expandable}
    >
      <div className="flex flex-col md:flex-row md:justify-between gap-32 md:gap-0">
        <div style={{ width: md ? '45%' : '100%' }}>
          <FormStep
            number="1"
            text={firstStepTitle}
            dataQa={`${dataQa}-info-title`}
          />
          <div className="flex flex-col gap-6 xxl:gap-3">
            <FinalField
              name={`${fieldGroup ? fieldPrefix : ''}FirstName`}
              label={i18nextTranslate(i18nextKeys.commonFirstName)}
              initialValue={user.FirstName}
              subscription={{
                active: true,
                error: true,
                touched: true,
                value: true
              }}
              validate={isRequiredIfWithMessage(
                (values) => !!values ? !values[requiredToggleName] : required,
                i18nextTranslate(i18nextKeys.commonFirstName)
              )}
              validateFields={[]}
              render={(props) => (
                <FormField
                  dataQa={`${dataQa}-firstName`}
                  {...props}
                />
              )}
              disabled={disabled}
              required
              />
            <FinalField
              name={`${fieldGroup ? fieldPrefix : ''}LastName`}
              label={i18nextTranslate(i18nextKeys.commonLastName)}
              initialValue={user.LastName}
              subscription={{
                active: true,
                error: true,
                touched: true,
                value: true
              }}
              validate={isRequiredIfWithMessage(
                (values) => !!values ? !values[requiredToggleName] : required,
                i18nextTranslate(i18nextKeys.commonLastName)
              )}
              validateFields={[]}
              render={(props) => (
                <FormField
                  dataQa={`${dataQa}-lastName`}
                  {...props}
                />
              )}
              disabled={disabled}
              required
            />
            {includeEmail && (
              <FinalField
                name={`${fieldGroup ? fieldPrefix : ''}EmailAddress`}
                label={i18nextTranslate(i18nextKeys.commonEmail)}
                initialValue={user.EmailAddress}
                subscription={{
                  active: true,
                  error: true,
                  touched: true,
                  value: true
                }}
                validate={isRequiredIfWithMessage(
                  (values) => !!values ? !values[requiredToggleName] : required,
                  i18nextTranslate(i18nextKeys.commonEmail)
                )}
                validateFields={[]}
                render={(props) => (
                  <FormField
                    disabled={disabled || disableEmail}
                    dataQa={`${dataQa}-email`}
                    className="w-full"
                    {...props}
                  />
                )}
                required
              />
            )}
            {includePhoneNumber && (
              <FinalField
                name={`${fieldGroup ? fieldPrefix : ''}PhoneNumber`}
                label={i18nextTranslate(i18nextKeys.commonPhoneNumber)}
                initialValue={user.PhoneNumber}
                subscription={{
                  error: true,
                  touched: true,
                  valid: true,
                  value: true
                }}
                validate={isRequiredIfWithMessage(
                  (values) => !!values ? !values[requiredToggleName] : required,
                  i18nextTranslate(i18nextKeys.commonPhoneNumber)
                )}
                validateFields={[]}
                render={(props) => (
                  <PhoneInput
                    disabled={disabled || disablePhoneNumber}
                    dataQa={`${dataQa}-phoneNumber`}
                    className="w-full"
                    {...props}
                  />
                )}
                required
              />
            )}
          </div>
        </div>
        <div style={{ width: md ? '45%' : '100%' }}>
          <FormStep
            number="2"
            text={secondStepTitle}
            dataQa={`${dataQa}-address-title`}
          />
          <div className="flex flex-col gap-6 xxl:gap-3">
            <FinalField
              name={`${fieldGroup ? fieldPrefix : ''}AddressLine`}
              label={i18nextTranslate(i18nextKeys.commonAddressStreet)}
              initialValue={user.AddressLine || user.HomeAddress?.AddressLine}
              subscription={{
                active: true,
                error: true,
                touched: true,
                value: true
              }}
              validate={isRequiredIfWithMessage(
                (values) => !!values ? !values[requiredToggleName] : required,
                i18nextTranslate(i18nextKeys.commonAddressStreet)
              )}
              validateFields={[]}
              render={(props) => (
                <FormField
                  dataQa={`${dataQa}-street`}
                  {...props}
                />
              )}
              disabled={disabled}
              required
            />
            <div className="flex justify-between">
              <FinalField
                name={`${fieldGroup ? fieldPrefix : ''}ZipCode`}
                label={i18nextTranslate(i18nextKeys.commonAddressZip)}
                initialValue={user.ZipCode || user.HomeAddress?.ZipCode}
                subscription={{
                  active: true,
                  error: true,
                  touched: true,
                  value: true
                }}
                validate={isRequiredIfWithMessage(
                  (values) => !!values ? !values[requiredToggleName] : required,
                  i18nextTranslate(i18nextKeys.commonAddressZip)
                )}
                validateFields={[]}
                render={(props) => (
                  <FormField
                    containerStyle={{ width: '45%' }}
                    dataQa={`${dataQa}-zip`}
                    {...props}
                  />
                )}
                disabled={disabled}
                required
              />

              <FinalField
                name={`${fieldGroup ? fieldPrefix : ''}City`}
                label={i18nextTranslate(i18nextKeys.commonAddressCity)}
                initialValue={user.City || user.HomeAddress?.City}
                subscription={{
                  active: true,
                  error: true,
                  touched: true,
                  value: true
                }}
                validate={isRequiredIfWithMessage(
                  (values) => !!values ? !values[requiredToggleName] : required,
                  i18nextTranslate(i18nextKeys.commonAddressCity)
                )}
                validateFields={[]}
                render={(props) => (
                  <FormField
                    containerStyle={{ width: '45%' }}
                    dataQa={`${dataQa}-city`}
                    {...props}
                  />
                )}
                disabled={disabled}
                required
              />
            </div>

            <FinalField
              name={`${fieldGroup ? fieldPrefix : ''}State`}
              label={i18nextTranslate(i18nextKeys.commonAddressRegion)}
              initialValue={user.State || user.HomeAddress?.State}
              subscription={{
                active: true,
                error: true,
                touched: true,
                value: true
              }}
              render={(props) => (
                <FormField
                  dataQa={`${dataQa}-region`}
                  {...props}
                />
              )}
              disabled={disabled}
            />
            <FinalField
              name={`${fieldGroup ? fieldPrefix : ''}Country`}
              label={i18nextTranslate(i18nextKeys.commonAddressCountry)}
              initialValue={user.Country || user.HomeAddress?.Country}
              validate={isRequiredIfWithMessage(
                (values) => !!values ? !values[requiredToggleName] : required,
                i18nextTranslate(i18nextKeys.commonAddressCountry)
              )}
              validateFields={[]}
              render={({ input, ...props }) => (
                <CountryInput
                  dataQa={`${dataQa}-country`}
                  menuPlacement={md ? "auto" : "top"}
                  input={{
                    value: input.value,
                    onChange: (value) => {
                      onCountryChange(value);
                      input.onChange(value);
                    }
                  }}
                  {...props}
                />
              )}
              disabled={disabled}
              required
            />
            {customField}
          </div>
        </div>
      </div>
    </Tile>
  );
};

export default UserDetailsForm;