import React from 'react';

const NoDataIcon = ({ size, dataQa }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 60 60"
    fill="currentColor"
    data-qa={dataQa}
  >
    <path d="M42.2222 56.6667C34.2578 56.6667 27.7778 50.1867 27.7778 42.2222C27.7778 34.2578 34.2578 27.7778 42.2222 27.7778C50.1867 27.7778 56.6667 34.2578 56.6667 42.2222C56.6667 50.1867 50.1867 56.6667 42.2222 56.6667ZM42.2222 31.1111C36.0956 31.1111 31.1111 36.0955 31.1111 42.2222C31.1111 48.3489 36.0956 53.3333 42.2222 53.3333C48.3489 53.3333 53.3333 48.3489 53.3333 42.2222C53.3333 36.0955 48.3489 31.1111 42.2222 31.1111Z" />
    <path d="M38.3333 47.7777C37.9066 47.7777 37.48 47.6155 37.1555 47.2888C36.5044 46.6377 36.5044 45.5821 37.1555 44.931L44.9333 37.1533C45.5844 36.5021 46.64 36.5021 47.2911 37.1533C47.9422 37.8044 47.9422 38.8599 47.2911 39.511L39.5133 47.2888C39.1866 47.6155 38.76 47.7777 38.3333 47.7777Z" />
    <path d="M46.1111 47.7777C45.6844 47.7777 45.2578 47.6155 44.9333 47.2888L37.1555 39.511C36.5044 38.8599 36.5044 37.8044 37.1555 37.1533C37.8066 36.5021 38.8622 36.5021 39.5133 37.1533L47.2911 44.931C47.9422 45.5821 47.9422 46.6377 47.2911 47.2888C46.9644 47.6155 46.5378 47.7777 46.1111 47.7777Z" />
    <path d="M26.6889 32.2222H13.8889C12.9689 32.2222 12.2222 31.4755 12.2222 30.5555V20.5555C12.2222 19.6355 12.9689 18.8889 13.8889 18.8889H46.1111C47.0311 18.8889 47.7778 19.6355 47.7778 20.5555V23.1777C47.7778 24.0977 47.0311 24.8444 46.1111 24.8444C45.1911 24.8444 44.4444 24.0977 44.4444 23.1777V22.2222H15.5556V28.8889H26.6889C27.6089 28.8889 28.3556 29.6355 28.3556 30.5555C28.3556 31.4755 27.6089 32.2222 26.6889 32.2222Z" />
    <path d="M23.4445 38.8671H14C13.08 38.8671 12.3333 38.1205 12.3333 37.2005C12.3333 36.2805 13.08 35.5338 14 35.5338H23.4445C24.3645 35.5338 25.1111 36.2805 25.1111 37.2005C25.1111 38.1205 24.3645 38.8671 23.4445 38.8671Z" />
    <path d="M22.8667 45.5338H14C13.08 45.5338 12.3333 44.7872 12.3333 43.8672C12.3333 42.9472 13.08 42.2005 14 42.2005H22.8667C23.7867 42.2005 24.5333 42.9472 24.5333 43.8672C24.5333 44.7872 23.7867 45.5338 22.8667 45.5338Z" />
    <path d="M24.6444 52.2222H9.44445C6.07334 52.2222 3.33334 49.4822 3.33334 46.1111V9.44442C3.33334 6.07331 6.07334 3.33331 9.44445 3.33331H50.5556C53.9267 3.33331 56.6667 6.07331 56.6667 9.44442V27.6C56.6667 28.52 55.92 29.2666 55 29.2666C54.08 29.2666 53.3333 28.52 53.3333 27.6V9.44442C53.3333 7.91331 52.0867 6.66665 50.5556 6.66665H9.44445C7.91334 6.66665 6.66668 7.91331 6.66668 9.44442V46.1111C6.66668 47.6422 7.91334 48.8889 9.44445 48.8889H24.6444C25.5644 48.8889 26.3111 49.6355 26.3111 50.5555C26.3111 51.4755 25.5644 52.2222 24.6444 52.2222Z" />
    <path d="M55 14.4444H5.00001C4.08001 14.4444 3.33334 13.6977 3.33334 12.7778C3.33334 11.8578 4.08001 11.1111 5.00001 11.1111H55C55.92 11.1111 56.6667 11.8578 56.6667 12.7778C56.6667 13.6977 55.92 14.4444 55 14.4444Z" />
  </svg>
);

export default NoDataIcon;
