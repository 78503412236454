import { SET_LANG } from './langTypes';

const langReducer = (state, action) => {
  switch (action.type) {
    default:
      return state;

    case SET_LANG: {
      return {
        ...state,
        lang: action.payload,
      };
    }
  }
};

export default langReducer;
