import React, { useContext } from 'react';
import { ConfigContext } from 'States/config/configState';
import LoadingSpinner from 'Components/shared/LoadingSpinner';
import i18nextTranslate from 'Lang/i18nextTranslate';
import { i18nextKeys } from 'Lang/i18nextKeys';
import i18nextTranslateDynamically from 'Lang/i18nextTranslateDynamically';

const KycLevelInfoBox = ({
  isLoading = true,
  kycTierNumber = 0,
  kycCaseStatus = null,
  dataQa = "kyc-tier",
  fullWidth = false
}) => {
  const { config: { kycTiersDescription } } = useContext(ConfigContext);
  return (
    <div
      className="uppercase py-8 px-16 border-4 border-color-4 text-xs font-bold text-center"
      style={{
        maxWidth: fullWidth ? '100%' : '254px'
      }}
    >
      {isLoading ? (
        <LoadingSpinner size="h-24 w-24" />
      ) : (
        <p data-qa={dataQa}>
          {kycTierNumber
            ? i18nextTranslateDynamically(i18nextKeys.kycLevelInfoBoxTier, {
              tierNumber: kycTierNumber,
              tierName: kycTiersDescription[kycTierNumber]?.name
            })
            : i18nextTranslate(i18nextKeys.kycLevelInfoBoxNotVerified)}
          {kycCaseStatus && `: ${i18nextTranslate(i18nextKeys['kycCaseStatus' + kycCaseStatus])}`}
        </p>
      )}
    </div>
  );
};

export default KycLevelInfoBox;
