import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import translations_de from './resources/de/translations.json';
import translations_en from './resources/en/translations.json';
import translations_es from './resources/es/translations.json';
import translations_fr from './resources/fr/translations.json';

const fallbackLng = 'en';
const defaultLocale = 'en-EN';

const websiteLangToLocale = {
  en: 'en-EN',
  es: 'es-AR',
  de: 'de-DE',
  fr: 'fr-FR'
};

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources: {
      de: {
        translations: translations_de,
      },
      en: {
        translations: translations_en,
      },
      es: {
        translations: translations_es,
      },
      fr: {
        translations: translations_fr,
      }
    },
    fallbackLng,
    debug: false,

    ns: ['translations'],
    defaultNS: 'translations',

    keySeparator: false,

    interpolation: {
      escapeValue: false,
    },
  });

export { i18next as default, defaultLocale, websiteLangToLocale };
