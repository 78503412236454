import React, { useContext } from "react";
import { useFormatNumber } from 'Utils/formatNumber';
import i18nextTranslate from "Lang/i18nextTranslate";
import { i18nextKeys } from "Lang/i18nextKeys";
import PayoutMethod from "Enums/PayoutMethod";
import Tooltip from "Components/shared/Tooltip";
import { UiContext } from "States/ui/uiState";
import BankAccountSummary from "Components/BankAccount/Summary/BankAccountSummary";
import UserDetailsSummary from "Components/UserDetails/UserDetailsSummary";
import {
  Tile,
  HorizontalRule,
  TileInfoCard,
  defaultFieldValue
} from "Components/shared/Tile";

const BankAccountPayoutDetails = ({ redemptionRequest }) => {
  const formatNumber = useFormatNumber();
  return (
    <Tile
      title={i18nextTranslate(i18nextKeys.redeemFinishPayoutDetails)}
      expandable
    >
      <div className="flex flex-col gap-24">
        <TileInfoCard
          title={i18nextTranslate(i18nextKeys.redeemFinishAmountReceive)}
          text={`${
            formatNumber(redemptionRequest.NetAmount.PayoutUnitsString)
          } ${redemptionRequest.Currency}`}
          dataQa="receive"
        />
        <HorizontalRule />
        <BankAccountSummary
          bankAccount={redemptionRequest?.PayoutMethodData?.BankAccount}
          copyOnClick
        />
        <HorizontalRule />
        <UserDetailsSummary
          beneficiary={redemptionRequest?.PayoutMethodData?.BeneficiaryAddress}
          addressTitle={i18nextKeys.redemptionBeneficiaryAddress}
          copyOnClick
          />
      </div>
    </Tile>
  );
};

export const PhysicalPayoutDetails = ({ redemptionRequest, dataQa = "payout" }) => {
  const formatNumber = useFormatNumber();
  return (
    <Tile
      title={i18nextTranslate(i18nextKeys.redeemFinishPayoutDetails)}
      dataQa={dataQa}
      expandable
    >
      <div className="flex flex-col gap-24">
        <TileInfoCard
          title={i18nextTranslate(i18nextKeys.redeemFinishAmountReceive)}
          text={`${
            formatNumber(redemptionRequest.NetAmount.PayoutUnitsString)
          } ${redemptionRequest.Currency}`}
          dataQa="receive"
        />
        <HorizontalRule />
        <UserDetailsSummary
          beneficiary={redemptionRequest?.PayoutMethodData?.BeneficiaryAddress}
          addressTitle={i18nextKeys.commonAddressShipping}
          showPhoneNumber
          copyOnClick
        />
      </div>
    </Tile>
  );
};

const ERC20PayoutDetails = ({ redemptionRequest }) => {
  const {
    breakpoints: { md }
  } = useContext(UiContext);
  const formatNumber = useFormatNumber();

  return (
    <Tile
      title={i18nextTranslate(i18nextKeys.redeemFinishPayoutDetails)}
      expandable
    >
      <div className="flex flex-col gap-24">
        <div
          className="grid"
          style={{
            gridTemplateColumns: !md ? "1fr" : "repeat(3, 1fr)",
            rowGap: "20px",
            columnGap: "24px"
          }}
        >
          <TileInfoCard
            title={i18nextTranslate(i18nextKeys.redeemFinishAmountReceive)}
            text={`${
              formatNumber(redemptionRequest.NetAmount.PayoutUnitsString)
            } ${redemptionRequest.Currency}`}
            dataQa="receive"
          />
          <TileInfoCard
            title={i18nextTranslate(i18nextKeys.commonWalletAddress)}
            text={(
              <Tooltip
                text={redemptionRequest?.PayoutMethodData?.Wallet?.Address}
                textClassName="break-all"
                dataQa="wallet-address"
              >
                <span
                  className="block truncate"
                  data-qa="wallet-address"
                >
                  {
                    redemptionRequest?.PayoutMethodData?.Wallet?.Address?.slice
                      ? `${redemptionRequest?.PayoutMethodData?.Wallet?.Address?.slice(0, 19)}...`
                      : defaultFieldValue
                  }
                </span>
              </Tooltip>
            )}
            copyText={redemptionRequest?.PayoutMethodData?.Wallet?.Address}
            dataQa="wallet-address"
            copyOnClick
          />
        </div>
        <HorizontalRule />
        <UserDetailsSummary
          beneficiary={redemptionRequest?.PayoutMethodData?.BeneficiaryAddress}
          addressTitle={i18nextKeys.redemptionBeneficiaryAddress}
          showPhoneNumber
          copyOnClick
        />
      </div>
    </Tile>
  )
}

const EthBitPayoutDetails = ({ redemptionRequest }) => {
  const formatNumber = useFormatNumber();
  return (
    <Tile
      title={i18nextTranslate(i18nextKeys.redeemFinishPayoutDetails)}
      expandable
    >
      <div
        className="grid"
        style={{
          gridTemplateColumns: "repeat(3, 1fr)",
          rowGap: "20px",
          columnGap: "24px"
        }}
      >
        <TileInfoCard
          title={i18nextTranslate(i18nextKeys.redeemFinishAmountReceive)}
          text={`${
            formatNumber(redemptionRequest.NetAmount.PayoutUnitsString)
          } ${redemptionRequest.Currency}`}
          dataQa="receive"
        />
        <TileInfoCard
          title={i18nextTranslate(i18nextKeys.commonWalletAddress)}
          text={(
            <Tooltip
              text={redemptionRequest?.PayoutMethodData?.Wallet?.Address}
              textClassName="break-all"
            >
              <span className="block truncate">
                {
                  redemptionRequest?.PayoutMethodData?.Wallet?.Address?.slice
                    ? `${redemptionRequest?.PayoutMethodData?.Wallet?.Address?.slice(0, 19)}...`
                    : defaultFieldValue
                }
              </span>
            </Tooltip>
          )}
          copyText={redemptionRequest?.PayoutMethodData?.Wallet?.Address}
          copyOnClick
        />
      </div>
    </Tile>
  )
}

const RedemptionPayoutDetails = ({ redemptionRequest }) => {
  switch (redemptionRequest.PayoutMethodData.PayoutMethod) {
    case PayoutMethod.values.BankTransfer:
      return <BankAccountPayoutDetails redemptionRequest={redemptionRequest} />;
    case PayoutMethod.values.PhysicalDelivery:
      return <PhysicalPayoutDetails redemptionRequest={redemptionRequest} />;
    case PayoutMethod.values.EthereumToken:
      return <ERC20PayoutDetails redemptionRequest={redemptionRequest} />;
    case PayoutMethod.values.BitcoinPayment:
    case PayoutMethod.values.Ethereum:
      return <EthBitPayoutDetails redemptionRequest={redemptionRequest} />;
    default:
      return null;
  };
}

export default RedemptionPayoutDetails;
