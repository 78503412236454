import React from 'react';
import i18nextTranslate from 'Lang/i18nextTranslate';
import { i18nextKeys } from 'Lang/i18nextKeys';

const CaseHistoryTableHeader = () => {
  return (
    <div
      data-qa="kyc-cases-table-headerRow"
      className="header-row grid w-full rounded-b sm:rounded-none"
      style={{
        gridTemplateColumns:
          'minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr)',
      }}
    >
      <span className="py-16 px-12">
        {i18nextTranslate(i18nextKeys.kycManagementDetailsTableId)}
      </span>
      <span className="py-16 px-12">
        {i18nextTranslate(i18nextKeys.kycManagementDetailsTableOpenDate)}
      </span>
      <span className="py-16 px-12">
        {i18nextTranslate(i18nextKeys.kycManagementDetailsTableCompletionDate)}
      </span>
      <span className="py-16 px-12">
        {i18nextTranslate(i18nextKeys.kycManagementDetailsTableCaseStatus)}
      </span>
    </div>
  );
};

export default CaseHistoryTableHeader;
