import React, { memo } from "react";
import { Field as FinalField } from "react-final-form";
import { i18nextKeys } from "Lang/i18nextKeys";
import i18nextTranslate from "Lang/i18nextTranslate";
import { isRequiredWithMessage } from "Utils/validators";
import useConfigSettings from 'Hooks/useConfigSettings';
import { NumberInput } from "Components/shared/formElements";
import Text from "Components/shared/Text";

const MethodFeeForm = ({
  title,
  description,
  fieldWidth
}) => {
  const {
    data: {
      Currency = {}
    }
  } = useConfigSettings.query({});

  return (
    <div className="flex flex-col gap-24">
      <div className="flex flex-col gap-16">
        <Text
          textStyle="p2"
          color="color-4"
          dataQa="fee-method-title"
        >
          {title}
        </Text>
        <Text
          textStyle="p4"
          dataQa="fee-method-description"
        >
          {description}
        </Text>
      </div>
      <div
        className="flex flex-col gap-24"
        style={{ width: fieldWidth }}
      >
        <FinalField
          name="MethodFees.VariableFee"
          subscription={{
            error: true,
            invalid: true,
            touched: true,
            value: true
          }}
          validate={isRequiredWithMessage(
            i18nextTranslate(i18nextKeys.commonFee)
          )}
          validateFields={[]}
          render={({ input, meta }) => (
            <NumberInput
              scale={2}
              labelText={`${i18nextTranslate(
                i18nextKeys.configCollectionFeesMethodVariable
              )} (${i18nextTranslate(
                i18nextKeys.commonFeePercentage
              )})`}
              innerLabelText="%"
              dataQa="fee-method-variable"
              showInnerLabel
              staticInnerLabel
              decimal
              required
              {...input}
              {...meta}
            />
          )}
        />
        <FinalField
          name="MethodFees.FixedFee"
          subscription={{
            error: true,
            invalid: true,
            touched: true,
            value: true
          }}
          validate={isRequiredWithMessage(
            i18nextTranslate(i18nextKeys.commonFee)
          )}
          validateFields={[]}
          render={({ input, meta }) => (
            <NumberInput
              scale={2}
              labelText={i18nextTranslate(
                i18nextKeys.configCollectionFeesMethodFixed
              )}
              innerLabelText={Currency.Code}
              dataQa="fee-method-fixed"
              showInnerLabel
              staticInnerLabel
              decimal
              required
              {...input}
              {...meta}
            />
          )}
        />
      </div>
    </div>
  );
};

export default memo(MethodFeeForm);
