let dev = {
  blob: '/blob',
  wlpApi: '/wlpapi/odata',
  wlpApiHost: '/wlpapi',
  kycApi: '/kycapi/odata',
  authApi: '/authapi/api'
};

let prod;

let initialized = false;
export const initialize = (env) => {
  if (initialized) return;
  initialized = true;
  prod = {
    blob: env.BlobUrl,
    wlpApi: env.Endpoints.WlpApi + '/odata',
    wlpApiHost: env.Endpoints.WlpApi,
    kycApi: env.Endpoints.KycApi + '/odata',
    authApi: env.Endpoints.AuthApi + '/api'
  };
};

export const get = () => {
  if (!initialized) {
    return;
  }
  return process.env.NODE_ENV === 'development' ? dev : prod;
};

const Urls = {
  initialize,
  get
};

export default Urls;
