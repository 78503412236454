import React, { useContext } from "react";
import { i18nextKeys } from 'Lang/i18nextKeys';
import i18nextTranslate from 'Lang/i18nextTranslate';
import { UiContext } from 'States/ui/uiState';
import Text, { ExpandableText } from 'Components/shared/Text';
import TextButton from "../buttons/TextButton";
import { WarningIcon } from 'Components/shared/symbols';

const Warning = ({
  visibleText = "",
  expandableText = "",
  buttonText = "",
  onButtonClick = () => {},
  buttonDisabled = false,
  className = "",
  dataQa = "warning"
}) => {
  const {
    breakpoints: { xxl }
  } = useContext(UiContext);

  return (
    <div
      className={`flex gap-16 p-16 rounded-4 bg-4_7 ${className}`}
      data-qa={dataQa}
    >
      <WarningIcon
        size={xxl ? "20" : "16"}
        className="flex-shrink-0 color-white"
        data-qa={`${dataQa}-icon`}
      />
      <div className="flex-grow flex flex-col md:flex-row gap-12 md:gap-16">
        {!!expandableText ? (
          <ExpandableText
            visibleText={visibleText}
            expandableText={expandableText}
            showButtonText={i18nextTranslate(i18nextKeys.commonReadMore)}
            hideButtonText={i18nextTranslate(i18nextKeys.commonReadLess)}
            className="flex-grow"
            dataQa={`${dataQa}-description`}
          />
        ) : (
          <Text
            textStyle="p4"
            dataQa={`${dataQa}-description`}
          >
            {visibleText}
          </Text>
        )}
        {!!buttonText && (
          <TextButton
            text={buttonText}
            onClick={onButtonClick}
            dataQa={`${dataQa}-button`}
            className="flex-shrink-0 self-start"
            disabled={buttonDisabled}
          />
        )}
      </div>
    </div>
  );
};

export default Warning;
