import React from 'react';
import i18nextTranslate from 'Lang/i18nextTranslate';
import { i18nextKeys } from 'Lang/i18nextKeys';
import Modal from 'Components/shared/Modal';
import { Button } from 'Components/shared/buttons';

const DeclineCaseModal = ({
  showModal = false,
  hideModal = () => { },
  denialReason = '',
  setDenialReason = () => { },
  onSkip = () => { },
  onAdd = () => { },
}) => {
  const buttonWidth = {
    xxl: "190px",
    md: "170px",
    default: "140px"
  };

  return (
    <Modal
      dataQa="checkout-collectionMethod-detailsModal"
      show={showModal}
      width={'w-auto'}
      close={hideModal}
    >
      <div className="flex flex-col py-24 px-64">
        <h2 className="text-xl color-8 font-bold">
          {i18nextTranslate(i18nextKeys.kycManagementDetailsDeclineTitle)}
        </h2>
        <textarea
          className="mt-24 mb-24 p-8 rounded-sm text-black"
          value={denialReason}
          onChange={(e) => {
            setDenialReason(e.target.value);
          }}
          cols={50}
          rows={7}
        />
        <div className="flex justify-between w-full">
          <Button
            dataQa="checkout-collectionMethod-detailsCloseButton"
            onClick={onSkip}
            text={i18nextTranslate(i18nextKeys.buttonSkip)}
            width={buttonWidth}
            secondary
          />
          <Button
            dataQa="checkout-collectionMethod-detailsCloseButton"
            onClick={onAdd}
            text={i18nextTranslate(i18nextKeys.buttonAdd)}
            width={buttonWidth}
          />
        </div>
      </div>
    </Modal>
  );
};

export default DeclineCaseModal;
